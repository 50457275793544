import React from 'react';
import { SimpleIcon } from 'common/components';
import './DashboardCourse.scss';

export const DashboardCourse = ({ course }) => (
  <div className="iq4-dashboard-course">
    <div className="iq4-dashboard-course__icon">
      <SimpleIcon name="educationCircle" />
    </div>
    <div className="iq4-dashboard-course__container">
      <p className="iq4-dashboard-course__item">
        <span className="iq4-dashboard-course__item-label iq4-dashboard-course__item-label--bold">
          {course.label}
        </span>
      </p>
      <p className="iq4-dashboard-course__item">
        <span className="iq4-dashboard-course__item-label">Code:</span> {course.code}
      </p>
      {course.institution && (
        <p className="iq4-dashboard-course__item">
          <span className="iq4-dashboard-course__item-label">Institution:</span>{' '}
          {course.institution}
        </p>
      )}
      {course.focusArea && (
        <p className="iq4-dashboard-course__item">
          <span className="iq4-dashboard-course__item-label">Focus Area:</span> {course.focusArea}
        </p>
      )}
      {course.curriculum && (
        <p className="iq4-dashboard-course__item">
          <span className="iq4-dashboard-course__item-label">Curriculum:</span> {course.curriculum}
        </p>
      )}
    </div>
  </div>
);
