import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MainNavbar,
  UserProfilePersonal,
  SimpleIcon,
  TabbedSkillList,
  UserProfileCredentials,
  UserProfileSkillsSnapshot,
  UserProfileSkillsAnalysis,
  TopSkillsCard,
  Button,
  DashboardModal,
  ActionCard,
  MultilineDisplayer,
} from 'common/components';
import {
  createConnection,
  fetchInvites,
  fetchRoleProfile,
  fetchRoleProfiles,
  fetchUserProfile,
  fetchWalletSharingSettings,
} from 'store/features/search/searchSlice';
import {
  selectUserProfileRoleProfiles,
  selectUser,
  selectUserSkillCountsByCategory,
  selectUserTopTenSkills,
  selectRoleProfileDetailsWithPreview,
  selectIsSendingMessage,
  selectUserConnectMessage,
  selectWalletSharingSettings,
} from 'store/features/search/selectors';
import { selectCurrentUser } from 'store/features/dashboard/selectors';
import { MainNavOptions } from 'routes/MainNavOptions';
import { ROUTES } from 'routes/routeNames';
import './UserProfilePage.scss';
import moment from 'moment';
import { STUDENT_INVITE_STATUS, STUDENT_INVITE_STATUS_LABEL } from '../../common/utils';
import { UserPublicProfile } from './UserPublicProfile';

export const UserProfilePage = () => {
  const dispatch = useDispatch();

  const [animate, setAnimate] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const {
    location: { pathname, search },
    ...history
  } = useHistory();
  const params = useParams();
  const [skillCardTabIndex, setSkillCardTabIndex] = useState(0);
  const [saveToShortlist, setSaveToShortList] = useState(false);
  const [displayConnectModal, setDisplayConnectModal] = useState(false);
  const [displayMessageConfirmation, setDisplayMessageConfirmation] = useState(false);
  const [connectMessage, setConnectMessage] = useState('');
  const [hasSendFailed, setHasSendFailed] = useState(false);
  const [showConnectMessage, setShowConnectMessage] = useState(false);
  const [showPublicProfile, setShowPublicProfile] = useState(false);

  const roleProfiles = useSelector(selectUserProfileRoleProfiles);
  const user = useSelector(selectUser);
  const dashboardUser = useSelector(selectCurrentUser);
  const userSkillCountsByCategory = useSelector(selectUserSkillCountsByCategory);
  const userTopTenSkills = useSelector(selectUserTopTenSkills);
  const isSendingMessage = useSelector(selectIsSendingMessage);
  const userConnectMessage = useSelector(selectUserConnectMessage);
  const walletSharingSettings = useSelector(selectWalletSharingSettings);

  const hasSentConnectMessage = !!userConnectMessage;
  const isConnectionActive = userConnectMessage?.status === 2;

  useEffect(() => {
    dispatch(fetchUserProfile(params.id));
    dispatch(fetchInvites());
  }, [search]);

  useEffect(() => {
    if (user && dashboardUser) {
      dispatch(fetchWalletSharingSettings({ studentId: user.id, dashboardId: dashboardUser.id }));
    }
  }, [user, dashboardUser]);

  // animation
  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      setAnimate(true);

      window.setTimeout(() => {
        setAnimate(false);
      }, 350);
    }
  }, [pathname]);

  // get the skills card tab index based on the url
  useEffect(() => {
    const categoryQuery = new URLSearchParams(search).get('category');
    const categoryToTabIndexMap = { technology: 1, professional: 2, businessDevelopment: 3 };
    setSkillCardTabIndex(categoryToTabIndexMap[categoryQuery] || 0);
  }, [search]);

  const handleSkillCategoryClick = (category) => {
    history.push(`${ROUTES.USER_PROFILE}/${params.id}/skills?category=${category.categoryId}`);
  };

  const handleTabChange = (tabIndex) => {
    if (skillCardTabIndex !== 0 && tabIndex === 0) {
      history.push(`${ROUTES.USER_PROFILE}/${params.id}/skills`);
      return;
    }

    const tabIndexToCateogry = ['technology', 'professional', 'businessDevelopment'];
    if (skillCardTabIndex !== tabIndex) {
      history.push(
        `${ROUTES.USER_PROFILE}/${params.id}/skills?category=${tabIndexToCateogry[tabIndex - 1]}`,
      );
    }
  };

  const handleSkillListBackClick = () => {
    history.push(`${ROUTES.USER_PROFILE}/${params.id}`);
  };

  const handleContact = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const handleSaveToShortlistToggle = () => {
    setSaveToShortList(!saveToShortlist);
  };

  function handleCancelMessage() {
    setConnectMessage('');
    setDisplayConnectModal(false);
  }

  function handleConnectClick() {
    if (hasSentConnectMessage) {
      setShowConnectMessage(!showConnectMessage);
    } else {
      setDisplayConnectModal(true);
    }
  }

  const MAX_NUMBER_OF_TOP_SKILLS = 10;

  async function sendConnectionRequest() {
    const sendConnection = await dispatch(
      createConnection({ message: connectMessage, studentId: user.id }),
    );

    //check if the connection was sent successfully
    if (sendConnection.meta.requestStatus === 'fulfilled') {
      setDisplayMessageConfirmation(true);
      setConnectMessage('');
    }
  }

  if (!user) return null;

  return (
    <div className="iq4-user-profile-page">
      <DashboardModal
        title="Invitation to connect"
        isOpen={displayConnectModal}
        onClose={() => void setDisplayConnectModal(false)}
      >
        {!displayMessageConfirmation && (
          <>
            <div>To:</div>
            <div className="iq4-learner__admin--person-modal">
              <SimpleIcon name="personCircle" />
              <div className="iq4-learner__admin--person-name">
                {user?.profile?.firstName} {user?.profile?.lastName}
              </div>
            </div>
            <div className="iq4-learner__admin--add-msg">Add a Message:</div>
            <textarea
              className="iq4-learner__admin--text"
              onChange={(e) => setConnectMessage(e.target.value)}
              value={connectMessage}
            />
            <div className="iq4-learner__admin--footer-container">
              <div
                className="iq4-learner__admin--cancel"
                role="button"
                tabIndex={0}
                onClick={handleCancelMessage}
                onKeyPress={handleCancelMessage}
              >
                Cancel
              </div>
              <div className="iq4-learner__admin--send-error-container">
                {hasSendFailed && (
                  <div className="iq4-learner__admin--send-error">
                    “Your message has failed to send. Please try again later”
                  </div>
                )}
                <Button
                  disable={isSendingMessage}
                  className="iq4-learner__admin--send-msg"
                  onClick={sendConnectionRequest}
                >
                  Send {!isSendingMessage && <SimpleIcon name="arrow" />}
                  {isSendingMessage && (
                    <CircularProgress size={12} className="iq4-learner__admin--progress" />
                  )}
                </Button>
              </div>
            </div>
          </>
        )}
        {displayMessageConfirmation && (
          <div className="iq4-learner__admin--confirmation">
            <div className="iq4-learner__admin--confirmation-label">
              Your message has been sent!
            </div>
            <div
              className="iq4-learner__admin--confirmation-back"
              role="button"
              tabIndex={0}
              onClick={() => setDisplayConnectModal(false)}
              onKeyPress={() => setDisplayConnectModal(false)}
            >
              Back <SimpleIcon name="arrow" />
            </div>
          </div>
        )}
      </DashboardModal>
      <MainNavbar MainNavOptions={MainNavOptions} />
      <div className="iq4-user-profile-page__container">
        <div className="iq4-user-profile-page__top-section">
          <div className="iq4-user-profile-page__top-section-header">
            <div
              className="iq4-user-profile-page__top-section-header--item"
              style={showPublicProfile ? { justifyContent: 'flex-start' } : {}}
            >
              <div
                className="iq4-user-profile-page__top-section-header--item-inner"
                style={
                  showPublicProfile ? { display: 'flex', alignItems: 'center', marginLeft: 0 } : {}
                }
              >
                <Link
                  onClick={() =>
                    showPublicProfile
                      ? setShowPublicProfile(false)
                      : showConnectMessage
                      ? setShowConnectMessage(false)
                      : void history.goBack()
                  }
                  aria-label="Navigate to dashboard."
                  className="iq4-user-profile-page__top-section-header-icon"
                >
                  <SimpleIcon name="arrow" />
                </Link>
                {showPublicProfile && (
                  <h1 style={{ marginLeft: 40 }}>
                    {user?.profile?.firstName} {user?.profile?.lastName}
                  </h1>
                )}
              </div>
            </div>
            {!showPublicProfile && (
              <>
                <div
                  className="iq4-user-profile-page__top-section-header--item
                iq4-user-profile-page__top-section-header-title"
                >
                  <div className="iq4-user-profile-page__top-section-header--item-inner">
                    Viewing Record {showConnectMessage ? '> Messages' : ''}
                  </div>
                </div>
                <div
                  className="iq4-user-profile-page__top-section-header--item
                iq4-user-profile-page__top-actions"
                >
                  <div className="iq4-user-profile-page__top-section-header--item-inner">
                    {user?.profile.email && (
                      <Button
                        variation="ghost"
                        onClick={handleConnectClick}
                        className="iq4-user-profile-page__top-actions--button
                      iq4-user-profile-page__top-actions--button-contact"
                      >
                        <SimpleIcon name="email" role="presentation" />
                        {!hasSentConnectMessage && (
                          <span>Connect with {user?.profile.firstName.toLowerCase()}</span>
                        )}
                        {hasSentConnectMessage && <span>View Connect Message</span>}
                      </Button>
                    )}
                    <Button
                      variation="ghost"
                      onClick={() => {
                        handleSaveToShortlistToggle();
                      }}
                      className={`iq4-user-profile-page__top-actions--button 
                    ${saveToShortlist ? 'iq4-user-profile-page__top-actions--button-enabled' : ''}`}
                    >
                      <SimpleIcon name="tick" role="presentation" />
                      Save to shortlist
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!showPublicProfile && (
          <>
            <Grid container classes={{ root: 'iq4-user-profie-page__top-card' }}>
              <Grid item md={6}>
                <UserProfilePersonal
                  profile={user?.profile}
                  origin={user?.origin}
                  pathways={user?.pathways}
                />
              </Grid>
              <Grid item md={6}>
                <UserProfileSkillsSnapshot
                  skillCountsByCategory={userSkillCountsByCategory}
                  onClick={handleSkillCategoryClick}
                />
              </Grid>
            </Grid>
            {!showConnectMessage && (
              <Grid container classes={{ root: 'iq4-user-profie-page__top-card' }}>
                <div
                  className={`iq4-user-profile-page__transition ${
                    animate ? 'iq4-user-profile-page__transition--animate' : ''
                  }`}
                >
                  <Switch>
                    <Route exact path={`${ROUTES.USER_PROFILE}/:id`}>
                      {roleProfiles.length > 0 && (
                        <Grid item md={12}>
                          <UserProfileSkillsAnalysis
                            roleProfiles={roleProfiles}
                            profile={user?.profile}
                            userId={params.id}
                            fetchRoleProfile={fetchRoleProfile}
                            selectRoleProfileDetailsWithPreview={
                              selectRoleProfileDetailsWithPreview
                            }
                          />
                        </Grid>
                      )}
                      <Grid item md={12}>
                        <UserProfileCredentials credentials={user?.credentials} noLink={true} />
                      </Grid>
                      <Grid item md={12}>
                        <TopSkillsCard
                          topSkills={userTopTenSkills}
                          maxNumberOfSkills={MAX_NUMBER_OF_TOP_SKILLS}
                          showManageYourSkills={false}
                          seeMoreLink={`${ROUTES.USER_PROFILE}/${user?.id}/skills`}
                          emptyStateMessage="This user has not indicated their top skills"
                        />
                      </Grid>
                    </Route>
                    <Route exact path={`${ROUTES.USER_PROFILE}/:id/skills`}>
                      <Grid item md={12}>
                        <TabbedSkillList
                          onBackClick={handleSkillListBackClick}
                          onTabChange={handleTabChange}
                          activeTabIndex={skillCardTabIndex}
                          skills={user?.skills}
                        />
                      </Grid>
                    </Route>
                  </Switch>
                </div>
              </Grid>
            )}
            {showConnectMessage && (
              <ActionCard
                title="Messages"
                isDivideVisible={false}
                className="iq4-user-profile-skills-analysis"
              >
                <div className="iq4-connect-message__container">
                  <div className="iq4-connect-message__top-container">
                    <div>
                      <div className="iq4-connect-message__invitation">Invitation to connect</div>
                      <div className="iq4-connect-message__item">
                        <div>Date:</div>
                        <div>
                          {moment(new Date(userConnectMessage.dateCreated)).format('MM/DD/YYYY')}
                        </div>
                      </div>
                      <div className="iq4-connect-message__item">
                        <div>To:</div>
                        <strong>
                          {user?.profile?.firstName} {user?.profile?.lastName}
                        </strong>
                      </div>
                      <div className="iq4-connect-message__item">
                        <div>From:</div>
                        <strong>
                          {user?.profile?.firstName} {user?.profile?.lastName}
                        </strong>
                      </div>
                    </div>
                    <div>
                      Status{' '}
                      <span className="iq4-connect-message__status">
                        {
                          STUDENT_INVITE_STATUS_LABEL[
                            STUDENT_INVITE_STATUS[userConnectMessage.status]
                          ]
                        }
                      </span>
                    </div>
                  </div>
                  <div className="iq4-connect-message__bottom-container">
                    <strong>Message</strong>
                    <div className="iq4-connect-message__body">
                      <MultilineDisplayer text={userConnectMessage.msg} />
                    </div>
                  </div>
                </div>
                {isConnectionActive && (
                  <div className="iq4-connect-message__container" style={{ marginTop: 20 }}>
                    <div className="iq4-connect-message__top-container">
                      <div>
                        <div className="iq4-connect-message__invitation">Connection shared</div>
                        <div className="iq4-connect-message__item">
                          <div>Date:</div>
                          <div>
                            {moment(new Date(userConnectMessage.dateCreated)).format('MM/DD/YYYY')}
                          </div>
                        </div>
                        <div className="iq4-connect-message__item">
                          <div>From:</div>
                          <strong>
                            {user?.profile?.firstName} {user?.profile?.lastName}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="iq4-connect-message__bottom-container">
                      <>
                        <div className="iq4-connect-message__pre-expand">
                          <div className="iq4-connect-message__pre-expand--flex-3">Name</div>
                          <div className="iq4-connect-message__pre-expand--flex-2 iq4-connection-row--margin-left">
                            <div>Date Received</div>
                          </div>
                          <div className="iq4-connect-message__pre-expand--flex-2">
                            <div>Status</div>
                          </div>
                          <div className="iq4-connect-message__pre-expand--flex-3-center">
                            Action
                          </div>
                        </div>
                        <div className="iq4-connection-row">
                          <div className="iq4-connection-row--container">
                            <div className="iq4-connection-row--avatar-container iq4-connect-message__pre-expand--flex-3">
                              <span className="iq4-connection-row--avatar-container--avatar">
                                <SimpleIcon name="student" />
                              </span>
                              <span className="iq4-connection-row--avatar-container--label">
                                {user?.profile?.firstName} {user?.profile?.lastName}
                              </span>
                            </div>
                            <div className="iq4-connect-message__pre-expand--flex-2">
                              {moment(new Date(userConnectMessage.dateCreated)).format(
                                'MM/DD/YYYY',
                              )}
                            </div>
                            <div className="iq4-connect-message__pre-expand--flex-2">
                              <div className="iq4-connection-row--status-new">
                                {
                                  STUDENT_INVITE_STATUS_LABEL[
                                    STUDENT_INVITE_STATUS[userConnectMessage.status]
                                  ]
                                }
                              </div>
                            </div>
                            <div className="iq4-connect-message__pre-expand--flex-3-center">
                              <div className="iq4-connection-row--actions">
                                <>
                                  <div
                                    className="iq4-connection-row--actions--accept"
                                    tabIndex={0}
                                    role="button"
                                    onClick={() => setShowPublicProfile(true)}
                                  >
                                    VIEW
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                )}
              </ActionCard>
            )}
          </>
        )}
        {showPublicProfile && (
          <UserPublicProfile userId={user?.profile?.email} dashboardUserId={dashboardUser?.id} />
        )}
      </div>
    </div>
  );
};
