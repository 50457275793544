import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_T_SHAPE_URL,
  GET_SKILL_INFO_URL,
  PUBLIC_PROFILE_URL,
} from 'common/constants/endpoints';

export const fetchTShapeGraphicData = createAsyncThunk(
  't/fetchTShapeGraphicData',
  async ({ roleId, id, isPublicProfile, dashboardUserId }) => {
    const url = isPublicProfile
      ? `${PUBLIC_PROFILE_URL}/${id}/${dashboardUserId}?filter=tShape&jId=${roleId}`
      : `${GET_T_SHAPE_URL}/${roleId}`;
    const res = await fetch(url);
    const resData = await res.json();
    return deriveTShapeData(resData);
  },
);

export const fetchTShapeSkillInfo = createAsyncThunk(
  't/fetchTShapeSkillInfo',
  async ({ id, type = 'ler', userId }) => {
    if (!id) return null;

    const res = await fetch(
      `/${type}${GET_SKILL_INFO_URL}/${id}${userId ? `?userId=${userId}` : ''}`,
    );
    return res.json();
  },
);

export const tSlice = createSlice({
  name: 't',
  initialState: {
    graphicData: null,
    selectedSkillInfo: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchTShapeGraphicData.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchTShapeGraphicData.fulfilled]: (state, action) => {
      state.graphicData = action.payload;
      state.loading = false;
    },

    [fetchTShapeGraphicData.rejected]: (state, action) => {
      state.graphicData = null;
      state.loading = false;
    },

    [fetchTShapeSkillInfo.fulfilled]: (state, action) => {
      state.selectedSkillInfo = action.payload;
    },
  },
});

export default tSlice.reducer;

function deriveTShapeData(tShapeData) {
  let tMappings = {
    top: {
      missing: [],
      matched: [],
      unmatched: [],
    },

    bottomLeft: {
      missing: [],
      matched: [],
      unmatched: [],
    },

    bottomRight: {
      missing: [],
      matched: [],
      unmatched: [],
    },
  };

  Object.keys(tShapeData).forEach((skillTypeKey) => {
    try {
      tShapeData[skillTypeKey].forEach((skill) => {
        const tSection = competenceGroupIdToSection(skill.competenceGroupId);
        tMappings[tSection][skillTypeKey].push(skill);
      });
    } catch (e) {}
  });

  const derivedData = {};
  Object.keys(tMappings).forEach((tSection) => {
    const section = tMappings[tSection];

    const dataAsArr = [
      ...(deriveStrucuture(section.matched, 'matched') || []),
      // ...(deriveStrucuture(section.unmatched, 'unmatched') || []), // uncomment this to add the unmatched skills back in
      ...(deriveStrucuture(section.missing, 'missing') || []),
    ];

    derivedData[tSection] = deriveTriangularDataFormat(dataAsArr);
  });

  return derivedData;
}

function deriveTriangularDataFormat(list, rows = 11, columns = 11) {
  let triangularArr = [];

  for (let colPos = 0; colPos < columns; colPos++) {
    for (let rowPos = 0; rowPos < rows; rowPos++) {
      if (!triangularArr[rowPos]) triangularArr[rowPos] = [];

      if (!triangularArr[rowPos][colPos]) triangularArr[rowPos][colPos] = list.shift();

      // fill in other sections
      let currentRowIndex = rowPos - 1;
      let currentColumnIndex = colPos + 1;
      while (currentRowIndex >= 0 && currentColumnIndex < columns) {
        if (!triangularArr[currentRowIndex][currentColumnIndex])
          triangularArr[currentRowIndex][currentColumnIndex] = list.shift();

        currentRowIndex--;
        currentColumnIndex++;
      }
    }
  }

  return triangularArr
    .filter((arr) => {
      return !!arr[0];
    })
    .reduce((outerArr, innerArr, index) => {
      outerArr[index] = {
        id: index,
        stack: innerArr.filter((val) => !!val),
      };
      return outerArr;
    }, []);
}

function competenceGroupIdToSection(competenceGroupId) {
  switch (competenceGroupId) {
    case 'CG01':
      return 'bottomLeft';
    case 'CG02':
      return 'bottomRight';
    case 'CG03':
      return 'top';
    case 'CG04':
      return 'top';
    default:
      return '';
  }
}

function deriveStrucuture(list, skillsType) {
  return list.map((skill) => ({
    id: skill.competenceId,
    label: skill.label,
    type: getSkillTypeMap(skillsType),
    t2: { id: skill.tier2Id, label: skill.tier2Label },
  }));
}

function getSkillTypeMap(type) {
  switch (type) {
    case 'matched':
      return 'FILLED';
    case 'unmatched':
      return 'FILLED_LINES';
    case 'missing':
      return 'LINES';
    default:
      return null;
  }
}
