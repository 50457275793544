import React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';

const selectUser = (state) => state.dashboard.user || [];
const selectUsers = (state) => state.dashboard.users || [];
const selectAchievements = (state) => state.dashboard.achievements || [];
const selectSkills = (state) => state.dashboard.skills || [];
const selectFramework = (state) => state.dashboard.framework || [];
const selectResults = (state) => state.dashboard.results;
const selectMetrics = (state) => state.dashboard.metrics;
export const selectIsLoggedIn = (state) => state.dashboard.isLoggedIn;
export const selectIsLoggingIn = (state) => state.dashboard.isLoggingIn;
export const selectHasLoginError = (state) => state.dashboard.hasLoginError;
export const selectIsBootstrapping = (state) => state.dashboard.isBootstrapping;
export const selectIsSessionExpired = (state) =>
  !!(state.dashboard.isLoggedIn && state.dashboard.isSessionExpired);

export const selectTopCardsLoaded = (state) => state.dashboard.topCardsLoaded;
export const selectUsersLoaded = (state) => state.dashboard.usersLoaded;
export const selectSkillsLoaded = (state) => state.dashboard.skillsLoaded;
export const selectRolesLoaded = (state) => state.dashboard.rolesLoaded;
export const selectDashboardQueryLoaded = (state) => state.dashboard.dashboardQueryLoaded;

export const selectCurrentUser = createSelector([selectUser], (user) => user);

export const selectAllUsers = createSelector([selectUsers], (users) => users);

export const selectUserById = (state, id) => {
  const userById = state.dashboard.users.find((user) => {
    return user.id === parseInt(id);
  });

  return userById
    ? {
        ...userById,
        userProfilePhoto: {
          image: userById.avatarURL,
        },
      }
    : null;
};

export const selectAllFramework = createSelector([selectFramework], (framework) => framework);

export const selectAllAchievements = createSelector(
  [selectAchievements],
  (achievements) => achievements,
);

export const selectSupplySkills = createSelector([selectSkills], (skills) => {
  return skills.map((skill) => ({
    id: skill.id,
    label: `${skill.competency} (${skill.specialtyArea})`,
  }));
});

export const selectAllCategories = createSelector([selectFramework], (framework) =>
  framework.map((category) => ({
    id: category.id,
    label: category.label,
  })),
);

export const selectAllSpecialityAreas = createSelector([selectFramework], (framework) => {
  const specialityAreas = [];

  framework.forEach((category) => {
    category.specialtyAreas.forEach((specialtyArea) => {
      specialityAreas.push(specialtyArea);
    });
  });

  return specialityAreas;
});

export const selectAllWorkRoles = createSelector([selectFramework], (framework) => {
  const workRoles = [];

  framework.forEach((category) => {
    category.specialtyAreas.forEach((specialtyArea) => {
      specialtyArea.workRoles.forEach((workRole) => {
        workRoles.push({
          ...workRole,
          category: { id: category.id, label: category.label },
          specialtyArea: { id: specialtyArea.id, label: specialtyArea.label },
        });
      });
    });
  });

  return workRoles;
});

export const selectAllResults = createSelector([selectResults], (results) => results);

export const selectSummaryMetrics = createSelector(
  [selectMetrics, selectCurrentUser],
  (metrics, currentUser) => {
    const { institution } = currentUser;

    return [
      {
        count: metrics.users,
        label: 'Students enrolled',
        description: `Total student count enrolled in ${institution}`,
      },
      {
        count: metrics.skills,
        label: 'Total skills to Courses',
        description: `The number of skills being taught in ${institution} Courses`,
      },
      {
        count: metrics.missing,
        label: 'Missing skills',
        description: `Missing skills in ${institution} courses needed for market roles`,
      },
      {
        count: metrics.roles,
        label: 'Possible Roles',
        description: `The amount of possible roles for ${institution} students in the market`,
      },
    ];
  },
);

export const selectSkillsStatChartMetrics = createSelector([selectResults], (results) => {
  const { skills } = results;

  return [
    {
      type: 'matched',
      count: skills && skills.filter((skill) => skill.type === 'matched').length,
      label: 'Matched skills',
      description:
        'These are the skills the college are teaching for all NICE Category roles. Select each skill to view the Knowledge, Skills and Abilities.',
    },
    {
      type: 'missing',
      count: skills && skills.filter((skill) => skill.type === 'missing').length,
      label: 'Missing skills',
      description:
        'These are the skills the college is not teaching for all NICE Category roles. Select each skill to view the Knowledge, Skills and Abilities.',
    },
  ];
});

export const selectTopSummaryData = createSelector(
  [selectResults, selectAchievements],
  (results, achievements) => {
    const { top } = results;

    const getTopCourses = (allCourses, topRoles) => {
      const NUM_TOP_COURSES = 3;

      if (!allCourses || allCourses.length === 0 || !topRoles || topRoles.length === 0) return []; // If data not available, return []

      // Get unique skills for top roles
      let allSkills = [];

      topRoles.forEach((role) => {
        allSkills = allSkills.concat(role.skills);
      });

      const uniqueTopSkillIds = [...new Set(allSkills.map((skill) => skill.id))];
      // ----

      return allCourses
        .map((course) => {
          // Get skillsCount for all courses
          let skillsCount = 0;
          const courseSkillsIds = course.skills.map((skill) => skill.id);

          uniqueTopSkillIds.forEach((topSkillId) => {
            if (courseSkillsIds.includes(topSkillId)) {
              skillsCount++;
            }
          });

          return {
            id: course.id,
            skillsCount,
          };
        })
        .sort((a, b) => (a.skillsCount < b.skillsCount ? 1 : -1)) // Order by skillsCount
        .slice(0, NUM_TOP_COURSES) // Take top 3 courses
        .map((course) => achievements.find((achievement) => achievement.id === course.id)); // Return course data from achievements array
    };

    return [
      {
        type: 'course',
        title: 'Top Courses',
        list: getTopCourses(achievements, top.roles),
        icon: 'education',
        infoText: (
          <>
            <strong>Top Courses</strong> are for those skills in demand
          </>
        ),
      },
      {
        type: 'skill',
        title: 'Top Skills',
        list: top.skills.map((skill, index) => {
          return {
            ...skill,
            pill: index === 0 ? 'In Demand' : null,
          };
        }),
        icon: 'skills',
        infoText: (
          <>
            <strong>Top Skills</strong> are those most in demand in the market
          </>
        ),
      },
      {
        type: 'role',
        title: 'Top Roles',
        list: top.roles.map((role, index) => {
          return {
            ...role,
            pill: index === 0 ? 'Hiring Surge' : null,
          };
        }),
        icon: 'portfolio',
        infoText: (
          <>
            <strong>Top Roles</strong> are those most in demand in the market
          </>
        ),
      },
    ];
  },
);

const getRolesById = (roleIds, framework) => {
  const roles = [];
  framework &&
    framework.forEach((category) => {
      category.specialtyAreas &&
        category.specialtyAreas.forEach((specialtyArea) => {
          specialtyArea.workRoles &&
            specialtyArea.workRoles.forEach((workRole) => {
              if (roleIds.includes(workRole.id)) {
                roles.push(workRole);
              }
            });
        });
    });

  return roles;
};

export const selectResultsSkillsByType = createSelector(
  (state, type) =>
    _get(state, 'dashboard.results.skills', [])
      .filter((skill) => skill.type === type)
      .map((skill) => ({
        id: skill.id,
        label: skill.competency,
        category: skill.category,
        specialtyArea: skill.specialtyArea,
        ksas: skill.ksas,
        relatedRoles: getRolesById(skill.roles, state.dashboard.framework),
        type: skill.type,
      })),
  (skillsResults) => skillsResults,
);

export const selectItemDetails = createSelector(
  (state, item) => {
    const { framework, skills, achievements } = state.dashboard;
    let itemDetails;

    if (!item) return;

    const formatSkillsForWorkRole = (skills) => {
      return {
        unmatched: skills.filter((skill) => skill.type === 'unmatched'),
        matched: skills.filter((skill) => skill.type === 'matched'),
        missing: skills.filter((skill) => skill.type === 'missing'),
      };
    };

    switch (item.type) {
      case 'role':
        framework.forEach((category) => {
          category.specialtyAreas &&
            category.specialtyAreas.forEach((specialtyArea) => {
              specialtyArea.workRoles &&
                specialtyArea.workRoles.forEach((workRole) => {
                  console.log('workRole:', workRole);
                  if (workRole.id === item.id) {
                    itemDetails = {
                      ...workRole,
                      skills: workRole.skills
                        ? formatSkillsForWorkRole(workRole.skills)
                        : {
                            unmatched: [],
                            matched: [],
                            missing: [],
                          },
                    };
                  }
                });
            });
        });
        break;
      case 'skill':
        itemDetails = skills
          .map((skill) => ({
            ...skill,
            label: skill.competency,
          }))
          .find((skill) => skill.id === item.id);
        break;
      case 'course':
        itemDetails = achievements.find((achievement) => achievement.id === item.id);
        break;
      default:
        break;
    }

    return itemDetails;
  },
  (itemDetails) => itemDetails,
);

export const selectResultsItemDetails = createSelector(
  (state, item) => {
    const { framework, achievements, users } = state.dashboard;
    let itemDetails = [];

    if (!item) return;

    const { type, ids } = item;

    switch (type) {
      case 'student':
        users.forEach((user) => {
          if (ids.includes(user.id)) {
            itemDetails.push({
              ...user,
              achievements: user.achievements.map((item) => {
                return achievements.find((achievement) => achievement.id === item);
              }),
            });
          }
        });
        break;
      case 'pathway':
        const roleIds = ids.map((item) => item.roleId);

        const getStudentsByRoleId = (ids, workRoleId) => {
          let students = [];

          ids
            .filter((item) => item.roleId === workRoleId)
            .forEach((role) => {
              students.push(users.find((user) => user.id === role.userId));
            });

          return students;
        };

        framework.forEach((category) => {
          category.specialtyAreas.forEach((specialtyArea) => {
            specialtyArea.workRoles.forEach((workRole) => {
              if (roleIds.includes(workRole.id)) {
                itemDetails.push({
                  ...workRole,
                  students: getStudentsByRoleId(ids, workRole.id),
                });
              }
            });
          });
        });
        break;
      case 'course':
        achievements.forEach((achievement) => {
          if (ids.includes(achievement.id)) {
            itemDetails.push(achievement);
          }
        });
        break;
      case 'role':
        framework.forEach((category) => {
          category.specialtyAreas.forEach((specialtyArea) => {
            specialtyArea.workRoles.forEach((workRole) => {
              if (ids.includes(workRole.id)) {
                itemDetails.push(workRole);
              }
            });
          });
        });
        break;
      default:
        break;
    }

    return itemDetails;
  },
  (itemDetails) => itemDetails,
);

export const selectResultsSkills = createSelector([selectResults], (results) => {
  const { skills } = results;

  return skills.map((skill) => ({
    id: skill.id,
    type: skill.type,
    skill: `${skill.competency} (${skill.specialtyArea})`,
    students: skill.students,
    pathways: skill.pathways,
    courses: skill.courses,
    roles: skill.roles,
  }));
});

export const selectFiltersMapping = createSelector(
  [selectFramework, selectUsers, selectAchievements, selectSkills],
  (framework, students, achievements, skills) => {
    let mappings = {
      categories: {},
      specialtyAreas: {},
      workRoles: {},
      students: {},
      achievements: {},
      skills: {},
    };

    if (!framework.length || !students.length || !achievements.length || !skills.length)
      return mappings;

    // get the frameworks mappings
    framework.forEach((category) => {
      if (!mappings.categories[category.id])
        mappings.categories[category.id] = {
          id: category.id,
          label: category.label,
          associatedSpecialtyAreas: {},
          associatedWorkRoles: {},
        };

      category.specialtyAreas.forEach((specialtyArea) => {
        if (!mappings.specialtyAreas[specialtyArea.id])
          mappings.specialtyAreas[specialtyArea.id] = {
            id: specialtyArea.id,
            label: specialtyArea.label,
            associatedCategories: {},
            associatedWorkRoles: {},
          };

        specialtyArea.workRoles.forEach((workRole) => {
          if (!mappings.workRoles[workRole.id])
            mappings.workRoles[workRole.id] = {
              id: workRole.id,
              label: workRole.label,
              associatedSpecialtyAreas: {},
              associatedCategories: {},
            };

          // setup workRoles associations
          mappings.workRoles[workRole.id] = {
            ...mappings.workRoles[workRole.id],
            associatedCategories: {
              ...mappings.workRoles[workRole.id].associatedCategories,
              [category.id]: { id: category.id, label: category.label },
            },
            associatedSpecialtyAreas: {
              ...mappings.workRoles[workRole.id].associatedSpecialtyAreas,
              [specialtyArea.id]: { id: specialtyArea.id, label: specialtyArea.label },
            },
          };

          // set category associations
          mappings.categories[category.id] = {
            ...mappings.categories[category.id],
            associatedSpecialtyAreas: {
              ...mappings.categories[category.id].associatedSpecialtyAreas,
              [specialtyArea.id]: { id: specialtyArea.id, label: specialtyArea.label },
            },
            associatedWorkRoles: {
              ...mappings.categories[category.id].associatedWorkRoles,
              [workRole.id]: { id: workRole.id, label: workRole.label },
            },
          };

          // set specialtyAreas associations
          mappings.specialtyAreas[specialtyArea.id] = {
            ...mappings.specialtyAreas[specialtyArea.id],
            associatedCategories: {
              ...mappings.specialtyAreas[specialtyArea.id].associatedCategories,
              [category.id]: { id: category.id, label: category.label },
            },
            associatedWorkRoles: {
              ...mappings.specialtyAreas[specialtyArea.id].associatedWorkRoles,
              [workRole.id]: { id: workRole.id, label: workRole.label },
            },
          };
        });
      });
    });

    // a map of achievements
    const achievementsMap = achievements.reduce(toMap(), {});
    const skillsMap = skills.reduce(toMap('competency'), {});

    // derive achievements mapping
    achievements.forEach((a) => {
      const studentsWithAchievement = students
        .filter((s) => {
          return !!s.achievements.find((aId) => aId === a.id);
        })
        .reduce(toMap(), {});

      mappings.achievements[a.id] = {
        id: a.id,
        label: a.label,
        associatedStudents: studentsWithAchievement,
        associatedSkills: a.skills.reduce((map, skill) => {
          map[skill.id] = skillsMap[skill.id];
          return map;
        }, {}),
      };
    });

    // derive students mapping
    students.forEach((student) => {
      const achievementsAssociatedWithStudent = student.achievements.reduce((map, aId) => {
        map[aId] = achievementsMap[aId];
        return map;
      }, {});

      const achievementsAssociatedWithStudentArr = Object.keys(
        achievementsAssociatedWithStudent,
      ).map((id) => achievementsAssociatedWithStudent[id]);

      const skillsAssociatedToStudent = skills
        .filter((skill) => {
          return !!achievementsAssociatedWithStudentArr.find((achievement) => {
            return !!mappings.achievements[achievement.id].associatedSkills[skill.id];
          });
        })
        .reduce(toMap('competency'), {});

      mappings.students[student.id] = {
        id: student.id,
        label: student.label,
        associatedAchievements: achievementsAssociatedWithStudent,
        associatedSkills: skillsAssociatedToStudent,
      };
    });

    // derive skills mapping
    skills.forEach((skill) => {
      const achievementsAssociatedWithSkill = achievements
        .filter((achievement) => {
          return !!mappings.achievements[achievement.id].associatedSkills[skill.id];
        })
        .reduce(toMap(), {});

      const studentsAssociatedWithSkill = students
        .filter((student) => {
          return !!mappings.students[student.id].associatedSkills[skill.id];
        })
        .reduce(toMap(), {});

      mappings.skills[skill.id] = {
        id: skill.id,
        label: `${skill.competency} (${skill.specialtyArea})`,
        associatedAchievements: achievementsAssociatedWithSkill,
        associatedStudents: studentsAssociatedWithSkill,
      };
    });

    return mappings;

    function toMap(itemLabelKey = 'label') {
      return function (map, item) {
        map[item.id] = {
          id: item.id,
          label:
            itemLabelKey === 'competency'
              ? `${item.competency} (${item.specialtyArea})`
              : item[itemLabelKey],
        };
        return map;
      };
    }
  },
);
