import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import {
  fetchIsLoggedInReq,
  fetchDashboardUserReq,
  getCurrentConnections,
} from 'store/features/dashboard/dashboardSlice';
import { fetchRoleProfiles } from 'store/features/search/searchSlice';
import { selectIsLoggedIn, selectIsBootstrapping } from 'store/features/dashboard/selectors';
import './App.scss';
import Router from './routes';
import content from './content';

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isBootstrapping = useSelector(selectIsBootstrapping);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchDashboardUserReq());
    };

    if (!isBootstrapping && isLoggedIn) fetchData();
  }, [isBootstrapping, isLoggedIn, dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentConnections({ type: 'counsellor' }));
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(fetchIsLoggedInReq());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(fetchRoleProfiles());
  }, []);

  return (
    <div className="iq4-30__root">
      <IntlProvider locale="en" messages={content.en}>
        <Helmet title="iQ4 - Enterprise" />
        <Router />
      </IntlProvider>
    </div>
  );
}

export default App;
