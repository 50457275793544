import React from 'react';
import { SimpleIcon } from 'common/components/SimpleIcon';
import { Select } from 'common/components/Form/fieldTypes';

const typeOptions = [
  {
    key: 'document',
    value: (
      <>
        <SimpleIcon className="iq4-select__option-icon" name="document" /> Document
      </>
    ),
  },
  {
    key: 'video',
    value: (
      <>
        <SimpleIcon className="iq4-select__option-icon" name="video" /> Video
      </>
    ),
  },
  {
    key: 'audio',
    value: (
      <>
        <SimpleIcon className="iq4-select__option-icon" name="audio" /> Audio
      </>
    ),
  },
  {
    key: 'other',
    value: (
      <>
        <SimpleIcon className="iq4-select__option-icon" name="link" /> Other
      </>
    ),
  },
];

export const usePortfolioFormDefinition = ({ onChange }) => {
  const fieldsDefinition = [
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <Select maxWidth name="type" options={typeOptions} onChange={onChange} {...formProps} />
      ),
    },
  ];

  return {
    formDefinition: {
      fieldsDefinition,
    },
  };
};
