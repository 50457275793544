import React from 'react';
import { Link } from 'react-router-dom';
import { Pill, SimpleIcon } from 'common/components';
import placeholderAvatar from '@assets/svg/placeholder-avatar.svg';

import { ROUTES } from 'routes';

import './JobReqCandidateCard.scss';

export const JobReqCandidateCard = ({
  className,
  user: { id, avatarUrl, name, status, source, grade, matchedSkills, roleSkills, meetsCriteria },
  pill,
  onAction = () => {},
  onOpen = () => {},
  ...props
}) => {
  return (
    <div className={`iq4-job-req-candidate-card ${!!className ? className : className}`} {...props}>
      <div className="iq4-job-req-candidate-card__col">
        <div>
          <img src={avatarUrl || placeholderAvatar} />
        </div>
        <p>{name}</p>
        {/* {pill && <Pill label={pill} />} */}
      </div>
      <div className="iq4-job-req-candidate-card__col">{roleSkills}</div>
      <div className="iq4-job-req-candidate-card__col">{matchedSkills}</div>
      {/*<div className="iq4-job-req-candidate-card__col">{source}</div>
      <div className="iq4-job-req-candidate-card__col">{grade}</div>
      
      <div className="iq4-job-req-candidate-card__col">{meetsCriteria}</div> */}
      <div className="iq4-job-req-candidate-card__col iq4-job-req-candidate-card__col--actions">
        {/* <button onClick={() => void onAction({ type: 'email' })}>
          <SimpleIcon name="email" />
        </button>
        <button onClick={() => void onAction({ type: 'share' })}>
          <SimpleIcon name="share" />
        </button>
        <button onClick={() => void onAction({ type: 'delete' })}>
          <SimpleIcon name="bin" />
        </button> */}
        <Link
          className="iq4-search-page-table__first-col--link"
          to={`${ROUTES.USER_PROFILE}/${id}`}
        >
          <span>View</span> <SimpleIcon name="permissions" />
        </Link>
      </div>

      <button onClick={onOpen} className="iq4-job-req-candidate-card__caret-btn">
        <SimpleIcon name="caretDown" />
      </button>
    </div>
  );
};
