import React, { useState } from 'react';
import { Uploader } from 'common/components/Uploader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as UploaderIcon } from 'common/assets/svg/uploader.svg';
import { setResumeUploaded } from 'common/store/features/onboarding/onboardingSlice';
import { saveAllSkillsReq, parseResumeReq } from 'common/store/features/skills/skillsSlice';

import './UploadFileHelper.scss';

export const UploadFileHelper = ({ onUpload }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnUpload = async (attachment) => {
    let formData = new FormData();
    formData.append('resume', attachment[0]);

    onUpload({ attachment, formData });

    // set the loader active while uploading the resume
    setIsLoading(true);

    // parse resume and get relevant skills
    // await dispatch(parseResumeReq(formData));

    // hide the loader
    // setIsLoading(false);
    // dispatch(setResumeUploaded(true));
  };

  const UploadContainer = () => (
    <>
      <UploaderIcon />
      <p className="iq4-30-onboarding-uploader__info-text">
        <span className="iq4-30-onboarding-uploader__info-text--stronger">
          Upload your file <br />
        </span>
        or
        <br />
        <span className="iq4-30-onboarding-uploader__info-text--strong"> browse</span> to choose
        your file
      </p>
    </>
  );

  const LoadingContainer = () => (
    <div className="iq4-30-onboarding-uploader__loading-container">
      <CircularProgress
        size={100}
        classes={{ root: 'iq4-30-onboarding-uploader__circular-progress' }}
      />
    </div>
  );

  return (
    <Uploader handleUpload={handleOnUpload}>
      <div
        className={`iq4-30-onboarding-uploader ${
          isLoading ? '' : 'iq4-30-onboarding-uploader__hover-container'
        }`}
      >
        {!isLoading && <UploadContainer />}
        {isLoading && <LoadingContainer />}
      </div>
    </Uploader>
  );
};
