import React from 'react';
import { Button } from 'common/components/Button';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './ProfilePortfolioFormIntro.scss';

const options = [
  {
    type: 'document',
    icon: 'document',
    mainLabel: 'Document',
    subLabel: 'PDF, Word, txt file',
  },
  {
    type: 'video',
    icon: 'video',
    mainLabel: 'Video',
    subLabel: 'MP4 or link',
  },
  {
    type: 'audio',
    icon: 'audio',
    mainLabel: 'Audio',
    subLabel: 'MP3',
  },
  {
    type: 'other',
    icon: 'link',
    mainLabel: 'Other',
    subLabel: '',
  },
];

export const ProfilePortfolioFormIntro = ({ onSelect }) => {
  return (
    <div className="iq4-profile-portfolio-form-intro__container">
      <p className="iq4-profile-portfolio-form-intro__text iq4-profile-portfolio-form-intro__text-top">
        Select one of the following to add to your portfolio:
      </p>
      {options.map((option) => (
        <Button
          className="iq4-profile-portfolio-form-intro__button"
          variation="ghost"
          onClick={() => onSelect(option.type)}
          key={option.type}
        >
          <SimpleIcon className="iq4-profile-portfolio-form-intro__icon" name={option.icon} />
          <div className="iq4-profile-portfolio-form-intro__labels">
            {option.mainLabel && (
              <span className="iq4-profile-portfolio-form-intro__labels-main">
                {option.mainLabel}
              </span>
            )}
            {option.subLabel && (
              <span className="iq4-profile-portfolio-form-intro__labels-sub">
                {option.subLabel}
              </span>
            )}
          </div>
        </Button>
      ))}
      <p className="iq4-profile-portfolio-form-intro__text iq4-profile-portfolio-form-intro__text-bottom">
        You can add additional projects in your passport page once you’ve signed up.
      </p>
    </div>
  );
};
