import React from 'react';
import moment from 'moment';
import _uniqBy from 'lodash/uniqBy';
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TableCell,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import { COLUMN_TYPE, SORT_DIRECTION, TABLE_SORT_TYPE } from 'common/constants/miscellaneous';
import { SimpleIcon } from 'common/components';
import { DataTableHeaderFilter } from './DataTableHeaderFilter';

export const renderColumn = ({
  column,
  row,
  open = false,
  setOpen = () => {},
  onRowExpand = () => {},
}) => {
  const { type, prop, width, align } = column;
  const handleRowExpand = (row) => {
    onRowExpand(row);
    setOpen(!open);
  };
  switch (type) {
    case COLUMN_TYPE.EXPAND:
      return (
        <TableCell style={{ width }} component="div">
          <IconButton aria-label="expand row" size="small" onClick={() => handleRowExpand(row)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      );
    case COLUMN_TYPE.CUSTOM:
      return (
        <TableCell
          component="div"
          classes={{ root: 'iq4-datatable__cell' }}
          style={{
            textAlign: open ? 'right ' : align ? align : 'center',
            display: 'flex',
          }}
        >
          {column.renderComponent({ row, open })}
        </TableCell>
      );
    case COLUMN_TYPE.LINK:
      return (
        <TableCell component="div" classes={{ root: 'iq4-datatable__cell' }} style={{ width }}>
          <a
            href="https://google.ie"
            className="iq4-datatable__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {row[prop]}
          </a>
        </TableCell>
      );
    case COLUMN_TYPE.DATE:
      return (
        <TableCell component="div" classes={{ root: 'iq4-datatable__cell' }} style={{ width }}>
          {moment(row[prop]).format('MM/DD/YYYY')}
        </TableCell>
      );
    default:
      return (
        <TableCell component="div" classes={{ root: 'iq4-datatable__cell' }} style={{ width }}>
          {row[prop]}
        </TableCell>
      );
  }
};

export const renderColumnHeader = ({
  col,
  currentSort,
  rows,
  onFilterChange,
  currentFilter,
  searchInput,
  onChangeInput,
}) => {
  const renderSortingType = () => {
    switch (col.sortType) {
      case TABLE_SORT_TYPE.DOUBLE_ARROW:
        return (
          <span className="iq4-datatable__header-double-arrow">
            {col.columnLabel}
            {currentSort.prop !== col.prop ? (
              <SimpleIcon name="sort" />
            ) : (
              <SimpleIcon
                name={
                  currentSort.direction === SORT_DIRECTION.ASC ? 'tableSortUp' : 'tableSortDown'
                }
              />
            )}
          </span>
        );
      default:
        return (
          <>
            {col.columnLabel}
            <SimpleIcon
              name={
                currentSort.direction === SORT_DIRECTION.ASC ? 'sortAscending' : 'sortDescending'
              }
            />
          </>
        );
    }
  };

  if (col.isFilterable) {
    const filterValues = _uniqBy(rows, col.prop).map((val) => val[col.prop]);
    return (
      <>
        {col.columnLabel}
        <DataTableHeaderFilter
          columnName={col.prop}
          filterValues={filterValues}
          onFilterChange={onFilterChange}
          rows={rows}
          currentFilter={currentFilter}
        />
      </>
    );
  }

  if (col.isSearchable) {
    return (
      <div className="iq4-datatable__header-search">
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            classes={{ root: 'iq4-datatable__header-input' }}
            value={searchInput}
            onChange={onChangeInput}
            placeholder="Search"
            inputProps={{
              'aria-label': `Search ${col.prop} column`,
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    );
  }

  if (!col.isSortable) {
    return <>{col.columnLabel}</>;
  }

  return renderSortingType();
};

export const getDefaultActiveHeadFilter = (headerFilter) =>
  headerFilter
    ? headerFilter.reduce((result, _, index) => {
        result[index] = true;
        return result;
      }, {})
    : {};
