import React from 'react';
import PropTypes from 'prop-types';
import { SimpleIcon } from 'common/components';
import MUITabs from '@material-ui/core/Tabs';
import MUITab from '@material-ui/core/Tab';
import './InnerTabs.scss';

/**
 * Tabs Component that takes tabs and tabs content
 * ----------------------------------------------------------------------------------------------------
 * @param data - takes data content with title, Icon name & children content to render in the tab panel
 * @param id - takes a string name to distinguish child tabs of different tab lists
 */

export const InnerTabs = (props) => {
  const { id, data } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${id}-${index}`,
      'aria-controls': `tabpanel-${id}-${index}`,
    };
  }

  const tabsList = data.map((tab, i) => (
    <MUITab
      classes={{
        root: 'iq4-inner-tab',
        selected: 'iq4-inner-tab--selected',
        wrapper: 'iq4-inner-tab__wrapper',
        disabled: 'iq4-inner-tab--disabled',
      }}
      disableFocusRipple
      label={tab.title}
      icon={<SimpleIcon className="iq4-tab__icon" name={tab.icon} />}
      disabled={tab.disabled}
      {...a11yProps(i)}
    />
  ));

  const tabsContent = data.map((tab, i) => (
    <div
      role="tabpanel"
      className="iq4-tab__panel-wrapper"
      hidden={value !== i}
      id={`tabpanel-${id}-${i}`}
      aria-labelledby={`tab-${id}-${i}`}
    >
      {value === i && tab.content}
    </div>
  ));

  return (
    <section className="iq4-tabs-section" {...props}>
      <MUITabs
        className="iq4-inner-tabs"
        classes={{ indicator: 'iq4-tabs-indicator' }}
        value={value}
        onChange={handleChange}
        aria-label="Tabs Selection"
        selectionFollowsFocus
      >
        {tabsList}
      </MUITabs>
      <div className="iq4-tabs-section__content-wrapper">{tabsContent}</div>
    </section>
  );
};

InnerTabs.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      content: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
};

InnerTabs.defaultProps = {
  data: {
    icon: null,
    disabled: false,
  },
};
