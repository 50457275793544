import React from 'react';
import { Link } from 'react-router-dom';

import { DashboardTable, SimpleIcon, AssessmentStars, Pill } from 'common/components';
import { COLUMN_TYPE, TABLE_SORT_TYPE } from 'common/constants/miscellaneous';
import { ROUTES } from 'routes/routeNames';

import './JobReqsTable.scss';

export const JobReqsTable = ({ title, data }) => {
  const jobReqTableDefinition = {
    columns: [
      {
        prop: 'title',
        width: '32.5%',
        type: COLUMN_TYPE.CUSTOM,
        align: 'left',
        isSearchable: true,
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        renderComponent: ({ row }) => (
          <Link className="iq4-job-reqs-table__first-col" to={`${ROUTES.JOB_REQS}/${row.id}`}>
            <SimpleIcon name="portfolioCircle" />
            <div>{row.title}</div>
            <SimpleIcon className="iq4-search-page-table__first-col--icon" name="arrow" />
          </Link>
        ),
      },
      {
        prop: 'location',
        isSortable: true,
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        width: '12.5%',
        type: COLUMN_TYPE.CUSTOM,
        columnLabel: 'Location',
        renderComponent: ({ row }) => (
          <div className="iq4-job-reqs-table__general-col">{row.location}</div>
        ),
      },
      {
        prop: 'department',
        width: '12.5%',
        isSortable: true,
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        type: COLUMN_TYPE.CUSTOM,
        columnLabel: 'Department',
        renderComponent: ({ row }) => (
          <div className="iq4-job-reqs-table__general-col">{row.department}</div>
        ),
      },

      {
        prop: 'candidates',
        width: '10%',
        isSortable: true,
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        type: COLUMN_TYPE.CUSTOM,
        columnLabel: 'Candidates',
        renderComponent: ({ row }) => (
          <div className="iq4-job-reqs-table__general-col">{row.candidates}</div>
        ),
      },

      {
        prop: 'openPositions',
        width: '10%',
        isSortable: true,
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        type: COLUMN_TYPE.CUSTOM,
        columnLabel: 'Open Positions',
        renderComponent: ({ row }) => {
          return <div className="iq4-job-reqs-table__general-col">{row.openPositions || 1}</div>;
        },
      },

      {
        prop: 'priorityNum',
        width: '10%',
        isSortable: true,
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        type: COLUMN_TYPE.CUSTOM,
        columnLabel: 'Priority Level',
        renderComponent: ({ row }) => {
          return (
            <div
              className={`iq4-job-reqs-table__general-col iq4-job-reqs-table__general-col--no-text-decoration`}
            >
              {row.priority && (
                <Pill
                  className={`iq4-pill--priority iq4-pill--priority-${row.priority}`}
                  label={row.priority}
                />
              )}
              {!row.priority && '—'}
            </div>
          );
        },
      },

      {
        prop: 'date',
        width: '12.5%',
        type: COLUMN_TYPE.CUSTOM,
        isSortable: true,
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        columnLabel: 'Date',
        renderComponent: ({ row }) => (
          <div className="iq4-job-reqs-table__general-col">{row.date}</div>
        ),
      },
    ],
  };

  return (
    <DashboardTable
      className="iq4-job-reqs-table"
      tableDefinition={jobReqTableDefinition}
      showDataInfo
      tableTitle={title}
      useVirtualized
      isNextPreviousIconOnly
      rows={data}
      headerAction={() => null}
      isLoading={false}
      emptyState={
        <p className="iq4-job-reqs-table__info">
          <strong>
            No job requisitions available. Click the "Add a Job Req" button above to add a job
            requisition.
          </strong>
        </p>
      }
    />
  );
};
