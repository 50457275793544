import React, { useState } from 'react';
import { ROUTES } from 'routes/routeNames';
import { ActionCard, EmptyState, Modal, LoadingSkeleton, CredentialPill } from 'common/components';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { selectProfile } from 'common/store/features/profile/selectors';
import GraphicExperience from '@assets/svg/empty-states_experience.svg';
import { FormWrapper } from 'common/components/Form';
import { useProfileEducationFormDefinition } from 'common/hooks/forms';
import { DEFAULT_COUNTRY } from 'common/constants/countries';
import { orderEducationRecords, orderExperienceRecords } from 'common/utils/orderRecords';
import './ProfileExperienceSummary.scss';
import { useParams } from 'react-router-dom';
import { setSelectedPublicView } from 'store/features/search/searchSlice';
import { selectUserProfile } from '../../../../store/features/search/selectors';

export const ProfileExperienceSummary = () => {
  const MAX_NUM_RECORDS = 2;
  const dispatch = useDispatch();
  const { experience } = useSelector(selectUserProfile);
  const isPopulated = experience?.length > 0;
  const hasLoaded = true;

  return (
    <>
      <ActionCard
        title="Experience"
        icon="experienceCircle"
        seeMore="experience"
        isEmpty={!isPopulated}
        isPublic={true}
      >
        {hasLoaded ? (
          <>
            {!isPopulated && (
              <>
                <img
                  src={GraphicExperience}
                  className="empty-state-graphic"
                  role="presentation"
                  alt="Experience Graphic"
                />
              </>
            )}

            {isPopulated && (
              <ul className="iq4-profile-experience-summary__list">
                {orderExperienceRecords(experience)
                  .slice(0, MAX_NUM_RECORDS)
                  .map((item) => (
                    <li className="iq4-profile-experience-summary__list-item" key={item.id}>
                      <CredentialPill credential={item} />
                      <p className="iq4-profile-experience-summary__title">{item.title}</p>
                      <p className="iq4-profile-experience-summary__company">{item.company}</p>
                      <p className="iq4-profile-experience-summary__location">{item.location}</p>
                      <p className="iq4-profile-experience-summary__year">
                        {item.fromYear} {item.fromYear !== item.toYear ? `- ${item.toYear}` : ``}
                      </p>
                      <hr className="iq4-profile-experience-summary__divide" />
                    </li>
                  ))}
              </ul>
            )}
          </>
        ) : (
          <div style={{ display: 'block', padding: '10px 0px' }}>
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={24} />
          </div>
        )}
      </ActionCard>
    </>
  );
};
