import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';

import {
  selectRecommededCoursesMap,
  skillsSelector,
  selectTier4Assessments,
} from 'common/store/features/skillsModuleState/skills/selectors';
import { Dots, Person, Manager, Ribbon, RecommendedCourses, Certificate } from '../Icon/Icon';

import ProgressBarChart from './ProgressBarChart/ProgressBarChart';

const SkillsCardHeader = ({
  skillId,
  skillName,
  skills: { role = 0, additional = 0 } = {},
  assessments: { self = 0, tested = 0, certificates = 0, manager = 0 } = {},
  recommendedCourses = 0,
  cardExpanded,
  onClickRecommendedCourses = () => {},
  onClick = () => {},
}) => {
  const moduleConfigs = useSelector((state) => state.skillsModule.moduleConfigs);

  return (
    <button
      className="iq4-skills-card-header"
      onClick={onClick}
      aria-label={`Expand ${skillName} card`}
      aria-expanded={cardExpanded ? 'true' : 'false'}
    >
      <div className="iq4-skills-card-header-item">
        {/* <h1>{skillName}</h1> */}
        <h1>Skills</h1>
        {/* <ProgressBarChart total={role + additional} complete={self} /> */}
      </div>

      {false && (
        <div className="iq4-skills-card-header-item">
          <h2>Skills</h2>
          <div className="iq4-skills-card-header-icon-list">
            <div
              className={`iq4-skills-card-header-icon-container ${
                +role ? 'iq4-skills-card-header-icon-container--active' : ''
              }`}
              data-tip
              data-for="roleSkills"
            >
              {/* <div className="iq4-skills-cards-header-icon iq4-skills-cards-header-icon--without-outer iq4-skills-cards-header-icon--dots">
              <Dots />
            </div> */}

              <span
                data-testid="skills-card-header-role-count"
                className={+role ? 'iq4-skills-card-header-active-icon' : ''}
              >
                {role || 0}
              </span>

              <ReactTooltip id="roleSkills" effect="solid">
                <p>Skills</p>
              </ReactTooltip>
            </div>
            {/* <div
            className={`iq4-skills-card-header-icon-container ${
              +additional ? 'iq4-skills-card-header-icon-container--active' : ''
            }`}
            data-tip
            data-for="additionalSkills"
          >
            <div className="iq4-skills-cards-header-icon">
              <Person />
            </div>
            <span
              data-testid="skills-card-header-additional-count"
              className={+additional ? 'iq4-skills-card-header-active-icon' : ''}
            >
              {additional || 0}
            </span>
            <ReactTooltip id="additionalSkills" effect="solid">
              <p>Additional skills</p>
            </ReactTooltip>
          </div> */}
          </div>
        </div>
      )}

      {false && (
        <div className="iq4-skills-card-header-item">
          <h2>Recommended Proficiency</h2>
          <div className="iq4-skills-card-header-icon-list">
            <div
              className={`iq4-skills-card-header-icon-container ${
                +self ? 'iq4-skills-card-header-icon-container--active' : ''
              }`}
              data-tip
              data-for="selfAssessments"
            >
              {/* <div className="iq4-skills-cards-header-icon">
              <Person />
            </div> */}
              <span
                data-testid="skills-card-header-self-assessment"
                className={+self ? 'iq4-skills-card-header-active-icon' : ''}
              >
                {self || 0}
              </span>
              <ReactTooltip id="selfAssessments" effect="solid">
                <p>Recommended Proficiency</p>
              </ReactTooltip>
            </div>
            {moduleConfigs.includeManagerAssessments && (
              <div
                className={`iq4-skills-card-header-icon-container ${
                  +manager ? 'iq4-skills-card-header-icon-container--active' : ''
                }`}
                data-tip
                data-for="managerAssessment"
              >
                <div className="iq4-skills-cards-header-icon">
                  <Manager />
                </div>
                <span
                  data-testid="skills-card-header-manager-assessment"
                  className={+manager ? 'iq4-skills-card-header-active-icon' : ''}
                >
                  {manager || 0}
                </span>
                <ReactTooltip id="managerAssessment" effect="solid">
                  <p>Manager Assessment</p>
                </ReactTooltip>
              </div>
            )}
            {moduleConfigs.includeValidatedCredentials && (
              <div
                className={`iq4-skills-card-header-icon-container ${
                  +tested ? 'iq4-skills-card-header-icon-container--active' : ''
                }`}
                data-tip
                data-for="testedAssessments"
              >
                <div className="iq4-skills-cards-header-icon">
                  <Ribbon />
                </div>
                <span
                  data-testid="skills-card-header-tested-assessment"
                  className={+tested ? 'iq4-skills-card-header-active-icon' : ''}
                >
                  {tested || 0}
                </span>
                <ReactTooltip id="testedAssessments" effect="solid">
                  <p>Tested Assessment</p>
                </ReactTooltip>
              </div>
            )}
            {moduleConfigs.includeOtherCredentials && (
              <div
                className={`iq4-skills-card-header-icon-container ${
                  +certificates ? 'iq4-skills-card-header-icon-container--active' : ''
                }`}
                data-tip
                data-for="certificatesAssessment"
              >
                <div className="iq4-skills-cards-header-icon">
                  <Certificate />
                </div>
                <span
                  data-testid="skills-card-header-certificates-tested-assessment"
                  className={+certificates ? 'iq4-skills-card-header-active-icon' : ''}
                >
                  {certificates || 0}
                </span>
                <ReactTooltip id="certificatesAssessment" effect="solid">
                  <p>Certified Assessment</p>
                </ReactTooltip>
              </div>
            )}
          </div>
        </div>
      )}

      {moduleConfigs.includeRecommendedCourses && (
        <div className="iq4-skills-card-header-item">
          <h2>Recommended Courses</h2>
          <div className="iq4-skills-card-header-icon-list">
            <div
              className={`iq4-skills-card-header-icon-container ${
                +recommendedCourses ? 'iq4-skills-card-header-icon-container--active' : ''
              }`}
              data-tip
              data-for="recommendedCourses"
            >
              <div className="iq4-skills-cards-header-icon">
                <RecommendedCourses />
              </div>
              <ReactTooltip id="recommendedCourses" effect="solid">
                <p>Recommended courses</p>
              </ReactTooltip>
            </div>
          </div>
        </div>
      )}

      {/* {cardExpanded && (
        <div className="iq4-skills-card-header-close">[&ndash;]</div>
      )} */}
    </button>
  );
};

SkillsCardHeader.propTypes = {
  skillName: PropTypes.string.isRequired,

  skills: PropTypes.shape({
    role: PropTypes.number,
    additional: PropTypes.number,
  }),

  assessments: PropTypes.shape({
    self: PropTypes.number,
    tested: PropTypes.number,
    mananger: PropTypes.number,
  }),

  recommendedCourses: PropTypes.number,
};

export default SkillsCardHeader;
