import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Expand,
  SimpleIcon,
  LoadingSkeleton,
  TShapeV2,
  Button,
  EmploymentBreakdownPie,
} from 'common/components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TextField } from '@material-ui/core';
import { PATHWAYS_COS_VIDEO_CONTENT_URL } from 'common/constants/endpoints';
import {
  CoreTasks,
  SkillsAnalytics,
  OpenPositions,
  PathwayTopSkillsCourse,
  RoleSkillsInfoPanel,
  RoleIndividualSkillInfoPanel,
  PathwaysSimilarRolesPanel,
  RoleEduChart,
} from '../Panels';
import {
  addPathwayReq,
  removePathwayReq,
  removeAddedSuggestion,
  addAddedSuggestion,
} from 'common/store/features/pathways/pathwaysSlice';
import {
  addSkillFromWorkRoleReq,
  removeSkillFromWorkRoleReq,
} from 'common/store/features/skills/skillsSlice';
import {
  triggerUpdateSelectedLocation,
  setIsFetchingDemographicData,
  setIsFetchingJobs,
} from 'common/store/features/framework/frameworkSlice';
import { selectSelectedJobFamily } from 'common/store/features/pathways/selectors';

import {
  selectIsFetchingJobs,
  selectIsFetchingDemographicData,
  selectIsFetchingWorkRoles,
} from 'common/store/features/framework/selectors';

import { selectJobFamilies } from 'common/store/features/framework/selectors';
import { selectFeatures } from 'common/store/features/config/selectors';
import { usLocations } from 'common/constants/usLocations';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { PANEL_STATES } from 'common/utils/constants';
import { SelectWorkRoles } from 'common/components/SelectWorkRoles';
import { ROUTES } from 'routes';
import { removeUnneededChars } from 'common/utils';
import Skeleton from '@material-ui/lab/Skeleton';
import './PathwayDetail.scss';
import Avatar from '@material-ui/core/Avatar';
import { selectIsPublicProfile, selectProfile } from '../../../store/features/profile/selectors';
import { MiddleStepModal } from '../../PathwaysGraph/MiddleStepModal';
import moment from 'moment';
import { selectUserProfile } from '../../../../store/features/search/selectors';

export const PathwayDetail = ({
  role,
  onViewRole,
  readOnly = false,
  filterByInstitution,
  onTShapeClick,
  onBackClick,
  onPanelStateChange = null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { label } = role;

  const isFetchingJobs = useSelector(selectIsFetchingJobs);
  const isFetchingDemographicData = useSelector(selectIsFetchingDemographicData);
  const isFetchingWorkRoles = useSelector(selectIsFetchingWorkRoles);
  const jobFamilies = useSelector(selectJobFamilies);
  const selectedJobFamily = useSelector(selectSelectedJobFamily);
  const { personal } = useSelector(selectUserProfile);
  const isPublicProfile = useSelector(selectIsPublicProfile);

  // Demographics
  const [educationAttainmentLevels, setEducationAttainmentLevels] = useState(null);
  const [projections, setProjections] = useState(null);
  const [wages, setWages] = useState(null);

  const [currentPanelState, setCurrentPanelState] = useState(PANEL_STATES.DEFAULT);
  const [backPanelState, setBackPanelState] = useState(PANEL_STATES.DEFAULT);
  const [activeSkill, setActiveSkill] = useState(null);
  const [panelsConfig, setPanelsConfig] = useState(null);
  const [activeSkillsBarSegment, setActiveSkillsBarSegment] = useState(null);
  const [isStickyMainVisible, setIsStickyMainVisible] = useState(true);
  const [isJobFamilySelectionVisible, setIsJobFamilySelectionVisible] = useState(false);
  const [isRoleSelectionVisible, setIsRoleSelectionVisible] = useState(false);
  const [isLocationSelectionVisible, setIsLocationSelectionVisible] = useState(false);
  const [chosenJobFamily, setChosenJobFamily] = useState(null);
  const [isSelectingJobFamilyRole, setIsSelectingJobFamilyRole] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const stickyRef = useRef(null);
  const backRef = useRef(null);
  const cancelRef = useRef(null);

  const [selectedLocation, setSelectedLocation] = useLocalStorage('selectedLocation', '0');
  const isMobile = useIsMobile();

  const locationLabel = usLocations.find((x) => x.id === selectedLocation)?.label;

  const updateStickyVisibility = (entries) => {
    const [entry] = entries;
    setIsStickyMainVisible(entry.isIntersecting);
  };

  const intersectingOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.01,
  };

  const avatarPhoto = personal?.userProfilePhoto?.image;
  const step = role.paths?.[0]?.[0];

  useEffect(() => {
    const observer = new IntersectionObserver(updateStickyVisibility, intersectingOptions);
    if (stickyRef.current) {
      observer.observe(stickyRef.current);
    }

    return () => {
      if (stickyRef.current) {
        observer.unobserve(stickyRef.current);
      }
    };
  }, [stickyRef, intersectingOptions]);

  // const pathwayTopSkillsCourse = useSelector((state) =>
  //   selectTopSkillsCourseByPathway(state, category, specialtyArea, role, filterByInstitution),
  // );

  const pathwayTopSkillsCourse = {};

  useEffect(() => {
    if (onPanelStateChange) {
      onPanelStateChange(currentPanelState);
    }
  }, [currentPanelState]);

  useEffect(() => {
    setEducationAttainmentLevels(role?.demographics?.EducationTraining?.EducationType);
    setProjections(role?.demographics?.Projections);
    setWages(role?.demographics?.Wages);
  }, [role?.demographics]);

  const renderLoadingPanel = () => (
    <div style={{ display: 'block', padding: '10px 10px' }}>
      <LoadingSkeleton width="100%" height={30} />
      <LoadingSkeleton width="100%" height={30} />
      <LoadingSkeleton width="100%" height={24} />
    </div>
  );

  const getWagesLabel = () =>
    wages?.StateWagesList?.length ? wages?.StateWagesList[0] : wages?.NationalWagesList[0];

  const VideoRenderer = () => {
    if (isFetchingDemographicData || isFetchingWorkRoles) {
      return (
        <div className="iq4-pathway-details__video-container">
          <Skeleton variant="rect" style={{ width: '100%', height: '100%' }} />
        </div>
      );
    }

    const onetCode =
      role?.demographics?.Video && role?.demographics?.Video.length
        ? role?.demographics?.Video[0].VideoCode
        : '';

    const videoURL = `${PATHWAYS_COS_VIDEO_CONTENT_URL}/${onetCode}.mp4`;

    if (onetCode) {
      return (
        <div className="iq4-pathway-details__video-container">
          <video
            width="100%"
            height="100%"
            controls
            preload="metadata"
            controlsList="nodownload"
            crossOrigin="anonymous"
          >
            <source src={videoURL} type={`video/mp4`} />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src={`https://cdn.careeronestop.org/CaptionFiles/${onetCode}.vtt`}
              default
            />
          </video>
        </div>
      );
    }
    return null;
  };

  const renderJobFamilyLabel = (jobFamilyId) => {
    return jobFamilies.find((jobFamily) => jobFamily.id === jobFamilyId).label;
  };

  const handleSelectWorkRole = (workRole) => {
    setIsRoleSelectionVisible(false);
    setChosenJobFamily(null);
    history.push(`${ROUTES.CAREER_PATHWAYS}${workRole?.id ? `?r=${workRole.id}` : ''}`);
  };

  const handleClickSegment = (segment) => {
    setActiveSkillsBarSegment(segment.id);
    setCurrentPanelState(PANEL_STATES.WORK_ROLE_SKILLS_OVERVIEW);
  };

  const updateSelectedLocation = (value) => {
    setSelectedLocation(value);
    dispatch(setIsFetchingDemographicData(true));
    dispatch(setIsFetchingJobs(true));
    dispatch(triggerUpdateSelectedLocation({ role }));
  };

  useEffect(() => {
    const showPathway = !!role.paths?.length;
    const type = 'learning';

    const hasVideo = !!role?.demographics?.Video?.[0];

    const finalPanelConfig = {
      ...(showPathway && {
        recommendedPathway: {
          title: 'RECOMMENDED PATHWAY',
          isDefaultExpanded: true,
          content: (
            <div className="iq4-pathway-detail__suggested pathway">
              <div className="iq4-pathway__suggested-avatar">
                {avatarPhoto && <img src={avatarPhoto} alt="Avatar" />}
                {!avatarPhoto && (
                  <Avatar className="iq4-pathway__empty-avatar" alt="Avatar" src={null} />
                )}
                <div className="iq4-pathway__suggested-mid--text">
                  <div className="iq4-pathway__suggested-mid--label">
                    {personal?.userProfile?.user?.firstName} {personal?.userProfile?.user?.lastName}
                  </div>
                </div>
                <div className="iq4-pathway__suggested-line-1" />
              </div>

              <div className="iq4-pathway__suggested-mid">
                <SimpleIcon
                  name={type === 'learning' ? 'educationCircle' : 'experienceCirclePathways'}
                  className=""
                />
                <div className="iq4-pathway__suggested-mid--text">
                  <div className="iq4-pathway__suggested-mid--label">
                    {role.paths?.[0]?.[0]?.name}
                  </div>
                  <div
                    className="iq4-pathway__suggested-mid--view"
                    role="button"
                    tabIndex={0}
                    onClick={() => setShowModal(true)}
                    onKeyPress={() => setShowModal(true)}
                  >
                    View more
                  </div>
                </div>
              </div>
              <div className="iq4-pathway__suggested-line-2" />
              <div className="iq4-pathway__suggested-mid">
                <SimpleIcon name="experienceCirclePathways" />
                <div className="iq4-pathway__suggested-mid--text">
                  <div className="iq4-pathway__suggested-mid--label">{role.label}</div>
                  {!readOnly && role.isSelected && (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => removeRoleFromPathway(role)}
                      onKeyPress={() => removeRoleFromPathway(role)}
                      className="iq4-pathway__suggested-mid--view"
                    >
                      Delete from Pathway
                    </div>
                  )}
                  {!readOnly && !role.isSelected && (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => addRoleToPathway(role)}
                      onKeyPress={() => addRoleToPathway(role)}
                      className="iq4-pathway__suggested-mid--view"
                    >
                      Add to Pathway
                    </div>
                  )}
                </div>
              </div>
            </div>
          ),
        },
      }),
      ...(role.description &&
        role.description !== role.label && {
          description: {
            title: 'DESCRIPTION',
            isDefaultExpanded: true,
            content: (
              <>
                <p>{role.description}</p>
                {role?.demographics && (
                  <p>
                    <VideoRenderer />
                  </p>
                )}
              </>
            ),
          },
        }),
      ...(role.description === role.label &&
        hasVideo && {
          description: {
            title: 'DESCRIPTION',
            isDefaultExpanded: true,
            content: (
              <p>
                <VideoRenderer />
              </p>
            ),
          },
        }),
      ...(role?.jobTasks &&
        !!role?.jobTasks?.length && {
          coreTasks: {
            title: 'CORE TASKS',
            isDefaultExpanded: true,
            content: (
              <>
                {!role?.jobTasks && renderLoadingPanel()}
                {!!role?.jobTasks?.length && (
                  <>
                    <div className="iq4-pathway-detail__core-tasks__header">
                      These are some core tasks you can expect to be able to perform with this role
                    </div>
                    <ul className="iq4-core-tasks">
                      {role?.jobTasks.map((task) => (
                        <li className="iq4-core-tasks__item">{task.task}</li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            ),
          },
        }),
      skillsOverlap: {
        title: 'SKILLS OVERLAP',
        isDefaultExpanded: true,
        content: (
          <>
            {!role.skills && renderLoadingPanel()}
            {role.skills && (
              <>
                <p className="iq4-pathway-detail__expand__intro">
                  Select the bar to expand skills information and view potential courses for roles
                  where you need skills you don’t have.
                </p>
                <SkillsAnalytics
                  analytics={role.skills}
                  onClickSegment={(segment) => handleClickSegment(segment)}
                />
              </>
            )}
          </>
        ),
      },
      learningResource: {
        title: 'RECOMMENDED LEARNING RESOURCE',
        isDefaultExpanded: true,
        content: (
          <>
            {!role.skills && renderLoadingPanel()}
            {role.skills &&
              pathwayTopSkillsCourse &&
              (!pathwayTopSkillsCourse.skills || pathwayTopSkillsCourse.skills.length === 0) && (
                <p className="iq4-pathway-detail__empty_state">
                  No recommended learning resources found.
                </p>
              )}
            {role.skills &&
              pathwayTopSkillsCourse &&
              pathwayTopSkillsCourse.skills &&
              pathwayTopSkillsCourse.skills.length > 0 && (
                <PathwayTopSkillsCourse
                  className="iq4-pathway-detail__top-skills-course"
                  achievement={pathwayTopSkillsCourse}
                  skillsCovered={pathwayTopSkillsCourse.skills}
                  onClickSkill={(skill) => {
                    setActiveSkill(skill);
                    setBackPanelState(PANEL_STATES.DEFAULT);
                    setCurrentPanelState(PANEL_STATES.INDIVIDUAL_SKILL_INFO);
                  }}
                />
              )}
          </>
        ),
      },
      tShape: {
        title: 'MY SKILLS T',
        isDefaultExpanded: true,
        content: (
          <>
            {!role.tShape && renderLoadingPanel()}
            {role.tShape && (
              <>
                <p className="iq4-pathway-detail__expand__intro">
                  Explore your T-Shaped technology, business and professional skills as a planning
                  framework for growth.
                </p>
                <div className="iq4-pathway-detail__tshape-container">
                  <TShapeV2 {...role.tShape} displayOnly />
                  <Button
                    variation="ghost"
                    className="iq4-pathway-detail__tshape-link"
                    onClick={onTShapeClick}
                  >
                    View larger version
                  </Button>
                </div>
              </>
            )}
          </>
        ),
      },
      ...(role?.demographics?.EducationTraining && {
        educationAttainmentLevels: {
          title: 'EDUCATION ATTAINMENT LEVELS',
          isDefaultExpanded: true,
          content: (
            <>
              {(!educationAttainmentLevels || isFetchingDemographicData) && renderLoadingPanel()}
              {!isFetchingDemographicData &&
                educationAttainmentLevels &&
                educationAttainmentLevels.length === 0 && (
                  <p className="iq4-pathway-detail__empty_state">
                    No Education Attainment Levels found for this workrole.
                  </p>
                )}
              {!isFetchingDemographicData &&
                educationAttainmentLevels &&
                educationAttainmentLevels.length > 0 && (
                  <>
                    <p className="iq4-pathway-detail__edu-levels-text">
                      This bar is a guide to show what others working in {locationLabel} as{' '}
                      {role.label} have attained.
                    </p>
                    <RoleEduChart levels={educationAttainmentLevels} />
                    <div className="iq4-pathway-detail__edu-levels-legend">
                      {educationAttainmentLevels
                        .filter((eduLevel) => eduLevel.Value)
                        .map((eduLevel) => {
                          return (
                            <div
                              key={eduLevel.EducationLevel}
                              className="iq4-pathway-detail__edu-levels-legend-item"
                            >
                              <span /> {eduLevel.EducationLevel}
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
            </>
          ),
        },
      }),
      ...(projections && {
        employmentBreakdownChart: {
          title: 'EMPLOYMENT PROJECTION',
          isDefaultExpanded: true,
          content: (
            <>
              {(!projections || isFetchingDemographicData) && renderLoadingPanel()}
              {!isFetchingDemographicData && projections && (
                <>
                  <p className="iq4-pathway-detail__edu-levels-text">
                    This is a projection of employment openings in {locationLabel} as {role.label}.
                  </p>
                  <EmploymentBreakdownPie
                    employmentData={[
                      {
                        number: parseInt(
                          projections.Projections[0].EstimatedEmployment.replace(',', ''),
                        ),
                        year: projections.EstimatedYear,
                      },
                      {
                        number: parseInt(
                          projections.Projections[0].ProjectedEmployment.replace(',', ''),
                        ),
                        year: projections.ProjectedYear,
                      },
                    ]}
                    xProp="year"
                    xDescription="Year"
                    yProp="number"
                    yDescription="Prospective roles"
                  />
                </>
              )}
            </>
          ),
        },
      }),
      ...(role?.demographics && {
        annualEarnings: {
          title: 'ANNUAL EARNINGS',
          isDefaultExpanded: true,
          content: (
            <>
              {(!getWagesLabel() || isFetchingDemographicData) && renderLoadingPanel()}
              {!isFetchingDemographicData && getWagesLabel() && (
                <>
                  <p className="iq4-pathway-detail__edu-levels-text">
                    This graph shows annual earnings by {role.label} in {locationLabel}.
                  </p>
                  <EmploymentBreakdownPie
                    employmentData={[
                      {
                        percentile: 10,
                        earnings: removeUnneededChars(getWagesLabel()['Pct10']),
                      },
                      {
                        percentile: 25,
                        earnings: removeUnneededChars(getWagesLabel()['Pct25']),
                      },
                      {
                        percentile: 'Median',
                        earnings: removeUnneededChars(getWagesLabel()['Median']),
                      },
                      {
                        percentile: 75,
                        earnings: removeUnneededChars(getWagesLabel()['Pct75']),
                      },
                      {
                        percentile: 90,
                        earnings: removeUnneededChars(getWagesLabel()['Pct90']),
                      },
                    ]}
                    xProp="percentile"
                    xDescription="Percentile"
                    yProp="earnings"
                    yDescription="Earnings (USD per annum)"
                  />
                </>
              )}
            </>
          ),
        },
      }),
      liveRoles: {
        title: 'LIVE ROLES',
        isDefaultExpanded: true,
        content: (
          <>
            {isFetchingJobs && renderLoadingPanel()}
            {!role.liveRoles && !isFetchingJobs && (
              <p className="iq4-pathway-detail__empty_state">
                No Live Roles found for this workrole.
              </p>
            )}
            {!isFetchingJobs && role.liveRoles && role.liveRoles.length > 0 && (
              <OpenPositions role={role} />
            )}
          </>
        ),
      },

      similarRoles: {
        title: 'SIMILAR ROLES',
        isDefaultExpanded: true,
        content: (
          <>
            {isFetchingJobs && renderLoadingPanel()}
            {(!role.similarRoles || (role.similarRoles && role.similarRoles.length === 0)) && (
              <p className="iq4-pathway-detail__empty_state">
                No Similar Roles found for this workrole.
              </p>
            )}
            {role.similarRoles && role.similarRoles.length > 0 && (
              <PathwaysSimilarRolesPanel
                roles={role.similarRoles}
                onClick={(role) => onViewRole(role)}
              />
            )}
          </>
        ),
      },
    };

    setPanelsConfig(finalPanelConfig);
  }, [role, activeSkillsBarSegment, onViewRole]); // eslint-disable-line react-hooks/exhaustive-deps
  // role, activeSkillsBarSegment, onViewRole, pathwayTopSkillsCourse

  const addRoleToPathway = (role) => {
    dispatch(addPathwayReq(role));
    dispatch(addAddedSuggestion(role));
  };

  const removeRoleFromPathway = (role) => {
    dispatch(removePathwayReq(role));
    dispatch(removeAddedSuggestion(role));
  };

  const handleAddSkill = (skill) => {
    dispatch(addSkillFromWorkRoleReq({ skill, roleId: role.id }));
  };

  const handleRemoveSkill = (skill) => {
    dispatch(removeSkillFromWorkRoleReq({ id: skill.id, roleId: role.id }));
  };

  const prepareLocationVisible = () => {
    setIsLocationSelectionVisible(true);
    setTimeout(() => scrollToSelectionTop(), 1);
  };

  const prepareRoleSelectionVisible = () => {
    setIsRoleSelectionVisible(true);
  };

  const getStickyContent = () => {
    return (
      <>
        <div className="iq4-pathway-detail__selection-container--row">
          <div className="iq4-pathway-detail__selection-container--row-item-1">JOB FAMILY</div>
          <div className="iq4-pathway-detail__selection-container--row-item-2">
            {renderJobFamilyLabel(role.jobFamilyId)}
          </div>
          <div
            className="iq4-pathway-detail__selection-container--row-item-3"
            role="button"
            tabIndex={0}
            onClick={() => setIsJobFamilySelectionVisible(true)}
            onKeyPress={() => setIsJobFamilySelectionVisible(true)}
          >
            Change
          </div>
        </div>
        <div className="iq4-pathway-detail__selection-container--row">
          <div className="iq4-pathway-detail__selection-container--row-item-1">ROLE</div>
          <div className="iq4-pathway-detail__selection-container--row-item-2">{role.label}</div>
          <div
            className="iq4-pathway-detail__selection-container--row-item-3"
            role="button"
            tabIndex={0}
            onClick={prepareRoleSelectionVisible}
            onKeyPress={prepareRoleSelectionVisible}
          >
            Change
          </div>
        </div>
        <div className="iq4-pathway-detail__selection-container--row">
          <div className="iq4-pathway-detail__selection-container--row-item-1">LOCATION</div>
          <div className="iq4-pathway-detail__selection-container--row-item-2">{locationLabel}</div>
          <div
            role="button"
            tabIndex={0}
            onClick={prepareLocationVisible}
            onKeyPress={prepareLocationVisible}
            className="iq4-pathway-detail__selection-container--row-item-3"
          >
            Change
          </div>
        </div>
      </>
    );
  };

  const scrollToTop = () => {
    backRef &&
      backRef.current &&
      backRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const scrollToSelectionTop = () => {
    cancelRef && cancelRef.current && cancelRef.current.scrollIntoView();
  };

  const StickyContainer = () => (
    <div className="iq4-pathway-detail__selection-container-sticky">
      <>
        <div
          className="iq4-pathway-detail__selection-container-sticky--back"
          role="button"
          tabIndex={0}
          onClick={scrollToTop}
          onKeyPress={scrollToTop}
        >
          Back to top
        </div>
        {getStickyContent()}
      </>
    </div>
  );

  const renderJobFamilySelection = () => {
    const updateJobFamily = (jf) => {
      setChosenJobFamily(jf);
      setIsJobFamilySelectionVisible(false);
      setIsRoleSelectionVisible(true);
      setIsSelectingJobFamilyRole(true);
    };

    const selectedJobFamilyLabel = jobFamilies.find((x) => x.id === role.jobFamilyId).label;

    return (
      <div className="iq4-pathway-detail__selection-container">
        <button
          className="iq4-pathway-detail__selection-container--cancel"
          onClick={() => setIsJobFamilySelectionVisible(false)}
        >
          Cancel
        </button>
        <div className="iq4-pathway-detail__selection-container--title">JOB FAMILY</div>
        <TextField
          value={selectedJobFamilyLabel}
          classes={{
            root: 'iq4-pathway-detail__location-input--field-input-root',
          }}
          InputProps={{
            readOnly: true,
            'aria-label': 'Select job family',
            classes: {
              input: 'iq4-pathway-detail__location-input--field-input',
            },
          }}
          variant="outlined"
        />
        <div className="iq4-pathway-detail__selection-container--root">
          {jobFamilies.map((jf) => {
            const isSelectedJobFamily =
              selectedJobFamily.id === jf.id || role.jobFamilyId === jf.id;

            return (
              <button
                className={`iq4-pathway-detail__selection-container--item ${
                  isSelectedJobFamily ? 'iq4-pathway-detail__selection-container--selected' : ''
                }`}
                key={jf.id}
                onClick={isSelectedJobFamily ? undefined : () => updateJobFamily(jf)}
                aria-label={`Select ${jf.label}`}
              >
                <span className="iq4-pathway-detail__selection-container--label">{jf.label}</span>
                {isSelectedJobFamily && <CheckCircleIcon />}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderLocationSelection = () => {
    const updateSelection = (value) => {
      updateSelectedLocation(value);
      setIsLocationSelectionVisible(false);
    };

    return (
      <div className="iq4-pathway-detail__selection-container">
        <button
          ref={cancelRef}
          className="iq4-pathway-detail__selection-container--cancel"
          onClick={() => setIsLocationSelectionVisible(false)}
        >
          Cancel
        </button>
        <div className="iq4-pathway-detail__selection-container--title">LOCATION</div>
        <TextField
          value={locationLabel}
          classes={{
            root: 'iq4-pathway-detail__location-input--field-input-root',
          }}
          InputProps={{
            readOnly: true,
            classes: {
              input: 'iq4-pathway-detail__location-input--field-input',
            },
          }}
          variant="outlined"
        />
        <div className="iq4-pathway-detail__selection-container--root">
          {usLocations.map((location) => {
            const isSelectedLocation = selectedLocation === location.id;
            return (
              <button
                className={`iq4-pathway-detail__selection-container--item ${
                  isSelectedLocation ? 'iq4-pathway-detail__selection-container--selected' : ''
                }`}
                key={location.id}
                onClick={isSelectedLocation ? undefined : () => updateSelection(location.id)}
              >
                <span className="iq4-pathway-detail__selection-container--label">
                  {location.label}
                </span>
                {isSelectedLocation && <CheckCircleIcon />}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const isMakingAnySelection =
    isJobFamilySelectionVisible || isRoleSelectionVisible || isLocationSelectionVisible;

  const renderDefaultState = () => {
    if (isJobFamilySelectionVisible) {
      return renderJobFamilySelection();
    }

    if (isRoleSelectionVisible) {
      const handleCloseRoleSelection = () => {
        setIsRoleSelectionVisible(false);
        setChosenJobFamily(null);
        setIsSelectingJobFamilyRole(false);
      };

      return (
        <SelectWorkRoles
          jobFamily={chosenJobFamily ? chosenJobFamily : selectedJobFamily}
          onSelectWorkRole={handleSelectWorkRole}
          onClose={handleCloseRoleSelection}
          selectedWorkRole={isSelectingJobFamilyRole ? undefined : role}
        />
      );
    }

    if (isLocationSelectionVisible) {
      return renderLocationSelection();
    }

    return (
      <>
        <div className="iq4-pathway-detail__header">
          <button
            ref={backRef}
            className="iq4-pathway-detail__header-title iq4-pathway-detail__header-title--small-font"
            onClick={onBackClick}
          >
            <SimpleIcon name="arrow" /> Back to Career Pathways
          </button>

          <h2 className="iq4-pathway-detail__header-name">{role.label}</h2>
          {!role.isSelected && !readOnly && (
            <Button
              variation="ghost"
              onClick={() => addRoleToPathway(role)}
              className="iq4-pathway-detail__header__add-to-pathways"
            >
              <SimpleIcon
                className="iq4-pathway-detail__header__add-to-pathways__icon"
                name="add"
                role="presentation"
              />
              Add to Pathway
            </Button>
          )}

          <div ref={stickyRef} className="iq4-pathway-detail__sticky-placeholder" />
        </div>
        <div
          className={`${
            !isStickyMainVisible && !isMobile && !isPublicProfile
              ? 'iq4-pathway-detail__selection-container-sticky'
              : 'iq4-pathway-detail__selection-container'
          }`}
        >
          {!isPublicProfile && (
            <>
              {!isStickyMainVisible && !isMobile && (
                <div
                  className="iq4-pathway-detail__selection-container-sticky--back"
                  role="button"
                  tabIndex={0}
                  onClick={scrollToTop}
                  onKeyPress={scrollToTop}
                >
                  Back to top
                </div>
              )}
              {isStickyMainVisible && (
                <div className="iq4-pathway-detail__selection-container-sticky--reference" />
              )}
              {getStickyContent()}
            </>
          )}
        </div>
        {panelsConfig &&
          Object.entries(panelsConfig).map(([id, index]) => {
            const panel = panelsConfig[id];
            return (
              <Expand
                classes={{
                  root: 'iq4-pathway-detail__expand',
                }}
                key={index}
                title={panel.title}
                defaultExpanded={panel.isDefaultExpanded}
                expandByDefault={true}
              >
                {panel.content}
              </Expand>
            );
          })}
      </>
    );
  };

  const renderWorkRoleSkillsState = () => {
    const renderOutcomeType = (outcomeType) => {
      return outcomeType?.includes('Emsi Hard Skill')
        ? 'hard'
        : outcomeType?.includes('Emsi Common Skill')
        ? 'common'
        : null;
    };

    const PANEL_PROPS = {
      userSkills: !role.skills
        ? []
        : role.skills.userSkills
            .map((skill, i) => ({
              id: skill.competenceId,
              value: skill.label,
              description: skill.description,
              outcomeDescriptionUrl: skill.outcomeDescriptionUrl,
              category: skill.competenceGroup,
              type: renderOutcomeType(skill?.outcomeType),
              achievements: filterByInstitution
                ? skill.achievements
                  ? skill.achievements.filter(
                      (achievement) => achievement?.school?.name === filterByInstitution,
                    )
                  : []
                : skill.achievements,
              tier1: skill.tier1,
              tier2: skill.tier2,
              sources: skill.sources,
            }))
            .sort((a, b) => {
              var valueA = a.value.toUpperCase();
              var valueB = b.value.toUpperCase();
              return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            }),
      overlapSkills: !role.skills
        ? []
        : role.skills.overlapSkills
            .map((skill, i) => ({
              id: skill.competenceId,
              value: skill.label,
              description: skill.description,
              outcomeDescriptionUrl: skill.outcomeDescriptionUrl,
              category: skill.competenceGroup,
              type: renderOutcomeType(skill?.outcomeType),
              achievements: filterByInstitution
                ? skill.achievements
                  ? skill.achievements.filter(
                      (achievement) => achievement?.school?.name === filterByInstitution,
                    )
                  : []
                : skill.achievements,
              tier1: skill.tier1,
              tier2: skill.tier2,
              sources: skill.sources,
            }))
            .sort((a, b) => {
              var valueA = a.value.toUpperCase();
              var valueB = b.value.toUpperCase();
              return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            }),
      roleSkills: !role.skills
        ? []
        : role.skills.roleSkills
            .map((skill, i) => ({
              id: skill.competenceId,
              value: skill.label,
              description: skill.description,
              outcomeDescriptionUrl: skill.outcomeDescriptionUrl,
              category: skill.competenceGroup.toLowerCase(),
              type: renderOutcomeType(skill?.outcomeType),
              achievements: filterByInstitution
                ? skill.achievements
                  ? skill.achievements.filter(
                      (achievement) => achievement?.school?.name === filterByInstitution,
                    )
                  : []
                : skill.achievements,
              tier1: skill.tier1,
              tier2: skill.tier2,
              sources: skill.sources,
            }))
            .sort((a, b) => {
              var valueA = a.value.toUpperCase();
              var valueB = b.value.toUpperCase();
              return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            }),
    };

    return (
      <div className="iq4-pathway-detail__work-role-skills">
        <RoleSkillsInfoPanel
          {...PANEL_PROPS}
          roleTitle={label}
          activeBar={activeSkillsBarSegment}
          onBackClick={() => {
            setBackPanelState(PANEL_STATES.DEFAULT);
            setCurrentPanelState(backPanelState);
          }}
          onActiveBarChange={setActiveSkillsBarSegment}
          onSkillClick={(skill) => {
            setActiveSkill(skill);
            setBackPanelState(PANEL_STATES.WORK_ROLE_SKILLS_OVERVIEW);
            setCurrentPanelState(PANEL_STATES.INDIVIDUAL_SKILL_INFO);
          }}
          onSkillToggleClick={(skill) => {
            const isSkillAlreadyAdded = activeSkillsBarSegment !== 'MISSING_SKILLS';
            if (isSkillAlreadyAdded) {
              dispatch(removeSkillFromWorkRoleReq({ id: skill.id, roleId: role.id }));
            } else {
              dispatch(addSkillFromWorkRoleReq({ skill, roleId: role.id }));
            }
          }}
        />
      </div>
    );
  };

  const renderIndividualSkillState = () => (
    <div className="iq4-pathway-detail__individual-skill-info">
      <RoleIndividualSkillInfoPanel
        achievements={activeSkill.achievements}
        skill={activeSkill}
        onBackClick={() => {
          setBackPanelState(PANEL_STATES.DEFAULT);
          setCurrentPanelState(backPanelState);
        }}
        onAdd={(skill) => handleAddSkill(skill)}
        onRemove={(skill) => handleRemoveSkill(skill)}
        readOnly={readOnly}
      />
    </div>
  );

  const renderCoreTasksState = () => (
    <div className="iq4-pathway-detail__core-tasks-info">
      <CoreTasks
        tasks={role['jobTasks']}
        onBackClick={() => {
          setBackPanelState(PANEL_STATES.DEFAULT);
          setCurrentPanelState(backPanelState);
        }}
      />
    </div>
  );

  console.log('currentPanelState', currentPanelState);

  return (
    <>
      {currentPanelState === PANEL_STATES.DEFAULT &&
        !isMakingAnySelection &&
        !isStickyMainVisible &&
        isMobile && <StickyContainer />}
      <div className="iq4-pathway-detail">
        {currentPanelState === PANEL_STATES.DEFAULT && renderDefaultState()}
        {currentPanelState === PANEL_STATES.WORK_ROLE_SKILLS_OVERVIEW &&
          renderWorkRoleSkillsState()}
        {currentPanelState === PANEL_STATES.INDIVIDUAL_SKILL_INFO && renderIndividualSkillState()}
        {currentPanelState === PANEL_STATES.CORE_TASKS && renderCoreTasksState()}
      </div>
      <MiddleStepModal step={step} showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
