import React from 'react';
import { LearningResourcesCard } from 'common/components';
import './SkillLearningResources.scss';

export const SkillLearningResources = ({ skill }) => {
  const { achievements } = skill;

  return (
    <div className="iq4-skill-learning-resources">
      {achievements &&
        achievements.map(function (achievement, i) {
          return <LearningResourcesCard achievement={achievement} />;
        })}
    </div>
  );
};
