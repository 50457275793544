import React from 'react';
import { FormWrapper } from 'common/components/Form';
import { TriggerModal, ImageUpload, ImageUploadPlaceholder } from 'common/components';
import './ProfileThumbnailUpload.scss';

export const ProfileThumbnailUpload = ({
  item,
  type,
  formDefinition,
  imageFormDefinition,
  isLarge,
}) => {
  const isImage = item.image || item.type === 'image';
  const imageUrl = item.image
    ? item.image
    : isImage
    ? item?.resources?.find((x) => x.type === 'file')?.thumbnailS3Url
    : item?.resources?.find((x) => x.type === 'coverImage')?.fileS3Url;

  return React.useMemo(
    () => (
      <TriggerModal
        isTextTriggered
        isReadOnly
        title={`${isImage ? `Edit ${type}` : `Add Image`}`}
        form={
          isImage ? (
            <FormWrapper values={item} useFormDefinition={formDefinition} />
          ) : (
            <ImageUpload values={item} useFormDefinition={imageFormDefinition} />
          )
        }
        label={
          imageUrl ? (
            <img
              src={imageUrl}
              alt={`${type} Item`}
              className="iq4-profile-thumbnail-upload__image"
            />
          ) : (
            <ImageUploadPlaceholder isLarge={isLarge} />
          )
        }
      />
    ),
    [type, formDefinition, imageFormDefinition, isLarge, item],
  );
};
