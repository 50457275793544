import _uniqBy from 'lodash/uniqBy';

export const selectCategories = (state) => {
  return state.pathways.framework;
};

export const selectAddedSuggestions = (state) => {
  return state.pathways.addedSuggestions;
};

export const selectUserSkills = (state) => {
  return state.skills.skills;
};

export const selectIsFetchingFramework = (state) => state.pathways.isFetchingFramework;

export const selectSelectedJobFamily = (state) => state.pathways.selectedJobFamily;
export const selectSelectedJobFamilyWorkroles = (state) =>
  state.pathways?.selectedJobFamily?.workRoles;

export const selectWorkRolesFromJobFamily = (jobFamilyId) => (state) => {
  // TODO: remove framework[0] --> we should always be dealing with one framework - Response from API shouldn't be an array
  const jobFamily = state.pathways.framework[0].specialtyAreas.find((x) => x.id === jobFamilyId);
  return jobFamily.workRoles;
};

export const selectIsSessionExpired = (state) =>
  !!(state.dashboard.isLoggedIn && state.pathways.isSessionExpired);

export const selectSelectedPathwayRoles = (state) => state.pathways.userSelectedWorkRoles;

export const selectSelectedPathwaysSpokes = (state) => {
  const selectedPathwaysSpokes = [];

  state.pathways.userSelectedWorkRoles.forEach((workRole) => {
    selectedPathwaysSpokes.push({
      ...workRole,
      overlapSkills: workRole.analytics ? workRole.analytics.overlapSkills : null, // TODO: Refactor this (Ref: https://iq4projects.atlassian.net/browse/IQ430-515)
      roleSkills: workRole.analytics ? workRole.analytics.roleSkills : null,
      mySkills: workRole.analytics ? workRole.analytics.userSkills : null,
      distance: workRole.analytics ? workRole.analytics.distance : null,
      steps: !!workRole?.steps?.length ? workRole.steps : [],
    });
  });

  return selectedPathwaysSpokes;
};

export const selectRecommendedWorkRoles = (state) => {
  return state.pathways.recommended.map((item) => ({
    id: item.id,
    label: item.label,
    isSelected: item.isSelected,
    overlapSkills: item.analytics.overlapSkills,
    roleSkills: item.analytics.roleSkills,
    mySkills: item.analytics.userSkills,
    distance: item.analytics.distance,
    steps: [], // TODO: Update this with actual steps
    category: item.category,
    specialtyArea: item.specialtyArea,
    jobFamilyId: item.jobFamilyId,
  }));
};

export const selectUnselectedWorkRoles = (state) => {
  const unselectedWorkRoles = [];

  // state.pathways.framework.forEach((category) => {
  //   category.specialtyAreas &&
  //     category.specialtyAreas.forEach((specialtyArea) => {
  //       specialtyArea.workRoles &&
  //         specialtyArea.workRoles.forEach((workRole) => {
  //           if (!workRole.isSelected) {
  //             unselectedWorkRoles.push({
  //               id: workRole.id,
  //               label: workRole.label,
  //             });
  //           }
  //         });
  //     });
  // });

  return unselectedWorkRoles;
};

export const selectTopSkillsCourseByPathway = (
  state,
  selectedCategory,
  selectedSpecialtyArea,
  selectedWorkRole,
  institution,
) => {
  const role = state.pathways.framework
    .find((category) => category.id === selectedCategory.id)
    .specialtyAreas.find((specialtyArea) => specialtyArea.id === selectedSpecialtyArea.id)
    .workRoles.find((workRole) => workRole.id === selectedWorkRole.id);

  const achievements = [];

  const skillsKey =
    role.skills && role.skills.hasOwnProperty('roleSkills') ? 'roleSkills' : 'missing';

  role.skills &&
    role.skills[skillsKey] &&
    role.skills[skillsKey].forEach((skill) => {
      const skillAchievements = institution
        ? skill.achievements.filter(
            (achievement) =>
              achievement?.school?.name?.toLowerCase() === institution?.name?.toLowerCase(),
          )
        : skill.achievements;

      (skillAchievements || []).forEach((achievement) => {
        const achievementIndex = achievements.findIndex(
          (item) => item.achievementId === achievement.achievementId,
        );
        if (achievementIndex === -1) {
          achievements.push({
            ...achievement,
            skills: [
              {
                competenceId: skill.competenceId,
                value: skill.label,
                achievements: skill.achievements,
                tier1: skill.tier1,
                tier2: skill.tier2,
              },
            ],
          });
        } else {
          achievements[achievementIndex].skills.push({
            competenceId: skill.competenceId,
            value: skill.label,
            achievements: skill.achievements,
            tier1: skill.tier1,
            tier2: skill.tier2,
          });
        }
      });
    });

  const selectedAchievement = achievements.reduce(
    (max, achievement) => (achievement?.skills?.length > max?.skills?.length ? achievement : max),
    {
      skills: [],
    },
  );

  const filteredSelectedCourseSkills = institution
    ? selectedAchievement.skills.map((skill) => {
        skill.achievements = skill.achievements.filter(
          (achievement) => achievement?.school?.name === institution,
        );
        return skill;
      })
    : selectedAchievement.skills;

  const uniqueSkills = _uniqBy(filteredSelectedCourseSkills, 'competenceId');

  return {
    ...selectedAchievement,
    skills: uniqueSkills.sort((a, b) => {
      var valueA = a.value.toUpperCase();
      var valueB = b.value.toUpperCase();
      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    }),
  };
};

export const selectSelectedPathwaysSpokesCounsellor = (state) => {
  const selectedPathwaysSpokes = [];

  // state.pathways.framework.forEach((category) => {
  //   category.specialtyAreas &&
  //     category.specialtyAreas.forEach((specialtyArea) => {
  //       specialtyArea.workRoles &&
  //         specialtyArea.workRoles.forEach((workRole) => {
  //           if (workRole.isSelected) {
  //             selectedPathwaysSpokes.push({
  //               id: workRole.id,
  //               label: workRole.label,
  //               isSelected: workRole.isSelected,
  //               overlapSkills: workRole.analytics ? workRole.analytics.overlapSkills : null, // TODO: Refactor this (Ref: https://iq4projects.atlassian.net/browse/IQ430-515)
  //               roleSkills: workRole.analytics ? workRole.analytics.roleSkills : null,
  //               mySkills: workRole.analytics ? workRole.analytics.userSkills : null,
  //               distance: workRole.analytics ? workRole.analytics.distance : null,
  //               steps: [], // TODO: Update this with actual steps
  //               category: category,
  //               specialtyArea: specialtyArea,
  //             });
  //           }
  //         });
  //     });
  // });

  return selectedPathwaysSpokes;
};

export const selectRecommendedWorkRolesCounsellor = (state) => {
  return state.pathways.recommended.map((item) => ({
    id: item.id,
    label: item.label,
    isSelected: item.isSelected,
    overlapSkills: item.analytics.matchedSkills,
    roleSkills: item.analytics.missingSkills,
    mySkills: item.analytics.unmatchedSkills,
    distance: item.analytics.distance,
    steps: [], // TODO: Update this with actual steps
    category: item.category,
    specialtyArea: item.specialtyArea,
  }));
};

export const selectSelectedLocation = (state) => state.pathways.selectedLocation;
