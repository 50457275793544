import React, { useState, useEffect, useMemo } from 'react';
import Modal from '../Modal/Modal';
import { CertRequestInfoModal } from '../CertRequestInfoModal/CertRequestInfoModal';
import Button from '../Button/Button';
import { Close } from '../Icon/Icon';
import { Search as SearchIcon } from '../Icon/Icon';
import { Checkbox } from '../Checkbox/Checkbox';

const AddCertsModal = ({
  isReadyOnly = false,
  isOpen,
  toggle,
  certsList = [],
  onSave,
  saving = {},
  onDeleteCert,
  assessmentWriteType = 'SELF'
}) => {
  const [searchedForCerts, setSearchedForCerts] = useState([]);
  const [suggestions, updateSuggestions] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [selectedCerts, setSelectedCerts] = useState({});
  const [currentTab, changeCurrentTab] = useState(
    isReadyOnly
      ? 'myCerts'
      : assessmentWriteType === 'SELF'
      ? 'search'
      : 'myCerts'
  );

  const memoAddedCerts = useMemo(
    () => computeAddedCerts(certsList),
    [certsList]
  );
  const [certReqInfo, setCertReqInfo] = useState({ open: false, status: null });

  useEffect(() => {
    if (saving.success) {
      setCertReqInfo({ open: true, status: 'success' });
    }

    if (saving.error) {
      setCertReqInfo({ open: true, status: 'error' });
    }
  }, [saving]);

  useEffect(() => {
    setSelectedCerts(deriveInitialSelectedCerts(certsList));
  }, [certsList]);

  useEffect(() => {
    const selected = certsList.reduce((map, item) => {
      map[`${item.cert}__${item.id}`] = item.added;
      return map;
    }, {});

    const updatedSearchForCerts = searchedForCerts.map((item) => {
      return {
        ...item,
        added: selected[`${item.cert}__${item.id}`]
      };
    });

    setSearchedForCerts(updatedSearchForCerts);
  }, [certsList]);

  function deriveInitialSelectedCerts(certsList) {
    return certsList.reduce((map, item) => {
      map[`${item.cert}__${item.id}`] = false;
      return map;
    }, {});
  }

  function computeAddedCerts(certsList) {
    return certsList.filter((cert) => cert.added);
  }

  // on search box change, used to get suggestions
  const onChange = (keyword, onEnter = false) => {
    setKeyword(keyword);

    keyword = keyword.toLowerCase();
    const suggestions = certsList.filter((item) => {
      const { cert } = item;
      return cert.toLowerCase().indexOf(keyword) !== -1;
    });
    // get all the certs that match the keyword

    if (onEnter) {
      updateSuggestions([]);
      setSearchedForCerts(suggestions);
      setSelectedCerts(deriveInitialSelectedCerts(certsList));
    } else {
      // get suggestions
      updateSuggestions(suggestions);
    }
  };

  const onClick = (cert) => {
    setSelectedCerts(deriveInitialSelectedCerts(certsList));
    setKeyword(cert.name);
    updateSuggestions([]);
    setSearchedForCerts([cert]);
  };

  const onCertChange = (checked, cert) => {
    setSelectedCerts((currentState) => ({
      ...currentState,
      [`${cert.cert}__${cert.id}`]: checked
    }));
  };

  const handleSave = () => {
    const certsToArr = Object.keys(selectedCerts).reduce((arr, item) => {
      const [cert, id] = item.split('__');
      if (selectedCerts[item]) {
        const selectedCert = certsList.find((c) => c.id == id);
        arr.push(selectedCert);
      }
      return arr;
    }, []);

    onSave(certsToArr);
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`Add SANS certifications`}
      className="iq4-skills-add-certs-modal iq4-skills-add-more-skills"
      onRequestClose={() => void toggle(false)}
      id="iq4-skills-add-certs-modal"
    >
      {() => (
        <section className="iq4-skills-search-container">
          {certReqInfo.open && (
            <CertRequestInfoModal
              status={certReqInfo.status}
              isOpen={certReqInfo.open}
              toggle={() => {
                // remove success/error from req info
                setCertReqInfo({ open: false, status: null });
              }}
            />
          )}
          <div className="iq4-skills-search-header">
            <p className="iq4-skills-search-header-title">Certifications</p>
            <Button
              className="iq4-skills-search-close"
              type="link"
              onClick={() => void toggle(false)}
            >
              <Close />
            </Button>
          </div>

          <div className="iq4-skills-search-main">
            {assessmentWriteType === 'SELF' && (
              <div
                className="iq4-skills-search-tabs"
                style={{
                  marginBottom: '20px'
                }}
              >
                {!isReadyOnly && assessmentWriteType === 'SELF' && (
                  <Button
                    type="link"
                    className={
                      currentTab === 'search'
                        ? 'iq4-skills-search-tab-active'
                        : null
                    }
                    onClick={() => void changeCurrentTab('search')}
                  >
                    Add Certfications
                  </Button>
                )}
                <Button
                  type="link"
                  className={
                    currentTab !== 'search'
                      ? 'iq4-skills-search-tab-active'
                      : null
                  }
                  onClick={() => void changeCurrentTab('myCerts')}
                >
                  My Certifications
                </Button>
              </div>
            )}
            {currentTab === 'myCerts' && (
              <React.Fragment>
                {assessmentWriteType === 'SELF' && (
                  <p
                    style={{
                      fontSize: '14px',
                      marginBottom: '24px',
                      marginTop: '0'
                    }}
                  >
                    Certifications listed here come from Workday, please go to
                    Workday to manage or add new certifications
                  </p>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '10px',
                    marginTop: '10px'
                  }}
                >
                  <div style={{ flex: 1, fontWeight: 'bold' }}>
                    Certifications
                  </div>
                  {assessmentWriteType === 'SELF' && (
                    <div style={{ flex: 1 }} />
                  )}
                </div>

                {memoAddedCerts.length === 0 && (
                  <small>No certifications added</small>
                )}

                {memoAddedCerts.map((cert) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '10px'
                    }}
                    key={`${cert.cert}__${cert.id}`}
                  >
                    <div style={{ flex: 1 }}>{cert.cert}</div>
                    {!isReadyOnly && assessmentWriteType === 'SELF' && (
                      <Button
                        type="link"
                        onClick={() => {
                          onDeleteCert(cert);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                ))}
              </React.Fragment>
            )}
            {assessmentWriteType === 'SELF' && currentTab === 'search' && (
              <React.Fragment>
                <div className="iq4-skills-search-bar">
                  <div className="iq4-skills-search-bar__searchbox">
                    <SearchIcon />
                    <input
                      placeholder="Search for Certifications"
                      type="text"
                      value={keyword}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          onChange(e.target.value, true);
                        }
                      }}
                      onFocus={() => onChange(keyword)}
                    />
                  </div>
                  {!!suggestions.length && (
                    <ul className="iq4-skills-search-bar-suggestions">
                      {suggestions.map((item) => (
                        <li
                          key={`${item.cert}__${item.id}`}
                          onClick={() => onClick(item)}
                        >
                          <Button
                            type="link"
                            style={{
                              color: 'black',
                              width: '100%',
                              textAlign: 'left'
                            }}
                          >
                            <span style={{ marginLeft: '14px' }}>
                              {item.cert}
                            </span>
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {!!searchedForCerts.length && (
                  <div
                    className="iq4-skills-module-search-results"
                    style={{ marginTop: '24px' }}
                  >
                    <div className="iq4-skills-search-heading">
                      <div>
                        <h2>
                          Search results for <strong>{keyword}</strong>
                        </h2>
                      </div>
                      <p>
                        Select Certifications to add to your Skills Passport{' '}
                      </p>

                      <hr />
                    </div>

                    <div className="iq4-skills-search-result-list">
                      <ul>
                        {searchedForCerts.map((item) => (
                          <li key={`${item.cert}__${item.id}`}>
                            <label
                              className="iq4-skills-module-results__li"
                              style={{ marginLeft: 0 }}
                            >
                              <Checkbox
                                type="checkbox"
                                disabled={item.added}
                                checked={
                                  item.added
                                    ? item.added
                                    : selectedCerts[`${item.cert}__${item.id}`]
                                }
                                onChange={(e) => {
                                  onCertChange(e.target.checked, item);
                                }}
                              />
                              <span className="iq4-skills-module-results__li-label">
                                {item.name}
                              </span>
                              <span
                                className="iq4-skills-module-results__li-label"
                                style={{ marginLeft: '14px' }}
                              >
                                {item.cert}
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="iq4-skills-module-search-results-footer">
                      <p>Add the selected certifications to your passport.</p>
                      <Button
                        type="secondary"
                        onClick={handleSave}
                        disabled={saving.loading}
                      >
                        Add to Passport {saving.loading && '...'}
                      </Button>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </section>
      )}
    </Modal>
  );
};

export default AddCertsModal;
