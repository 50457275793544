import React, { useState } from 'react';
import { ROUTES } from 'routes/routeNames';
import { ActionCard, EmptyState, LoadingSkeleton, Modal } from 'common/components';
import PortfolioItem from '../PortfolioItem';
import { OnboardingHeader } from 'common/components/Onboarding';
import { useSelector } from 'react-redux';
import {
  selectFeaturedPortfolioItems,
  selectIsPublicProfile,
} from 'common/store/features/profile/selectors';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import { selectFeatures } from 'common/store/features/config/selectors';
import GraphicPortfolio from '@assets/svg/empty-states_portfolio.svg';
import { ProfilePortfolioForm } from 'common/components/Forms';
import './ProfilePortfolioSummary.scss';
import { useParams } from 'react-router-dom';
import {
  selectPortfolioItems,
  selectUserProfile,
} from '../../../../store/features/search/selectors';

const PortfolioSummarySkeleton = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      border: '1px solid #eee',
      borderRadius: '4px',
    }}
  >
    <div style={{ padding: '15px' }}>
      <LoadingSkeleton variant="rect" width={400} height={225} />
    </div>
    <div style={{ padding: '15px', width: '100%' }}>
      <LoadingSkeleton variant="rect" width={30} height={30} />
      <LoadingSkeleton variant="rect" width={220} height={30} style={{ marginTop: '8px' }} />
      <LoadingSkeleton variant="rect" width="100%" height={18} style={{ marginTop: '22px' }} />
      <LoadingSkeleton variant="rect" width="100%" height={18} style={{ marginTop: '10px' }} />
      <LoadingSkeleton variant="rect" width="75%" height={18} style={{ marginTop: '10px' }} />
      <LoadingSkeleton variant="rect" width="100%" height={18} style={{ marginTop: '10px' }} />
      <LoadingSkeleton variant="rect" width="30%" height={18} style={{ marginTop: '10px' }} />
    </div>
  </div>
);

export const ProfilePortfolioSummary = () => {
  const portfolioItems = useSelector(selectPortfolioItems);
  const [isOpen, setIsOpen] = useState(false);
  const isPopulated = portfolioItems?.length > 0;
  const isBootstrapping = false;
  const isPublicProfile = true;
  const hasLoaded = !isBootstrapping;
  const { id } = useParams();

  const context = process.env.REACT_APP_CONTEXT;

  const onAdd = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ActionCard
        title="Portfolio"
        seeMore="portfolio"
        icon="portfolioCircle"
        isDivideVisible={false}
        isEmpty={!isPopulated}
        isPublic={true}
      >
        {hasLoaded ? (
          <>
            {!isPopulated && (
              <div className="iq4-profile-portfolio-summary__empty-state-section">
                <img
                  src={GraphicPortfolio}
                  alt="Graphic for Portfolio"
                  aria-hidden="true"
                  className="iq4-profile-portfolio-summary___empty-state-graphic"
                />
                {!isPublicProfile && (
                  <div className="iq4-profile-portfolio-summary___empty-state-wrapper">
                    <EmptyState
                      text="A fully filled out portfolio looks more attractive."
                      description="You can add any media here that you want. This is your portfolio. You can highlight featured projects and associate any projects with your education or experience!"
                      action={onAdd}
                      actionLabel="Add a Portfolio Item"
                    />
                  </div>
                )}
              </div>
            )}
            {isPopulated && (
              <ul className="iq4-profile-portfolio-summary__list">
                {portfolioItems.map((item) => (
                  <li className="iq4-profile-portfolio-summary__list-item" key={item.id}>
                    <PortfolioItem item={item} associatedRecord={item?.associatedRecord} />
                  </li>
                ))}
              </ul>
            )}
          </>
        ) : (
          <PortfolioSummarySkeleton />
        )}
      </ActionCard>
    </>
  );
};
