import React from 'react';
import './UserProfileSkillsAnalysisResources.scss';

export const UserProfileSkillsAnalysisResources = ({
  profile,
  topResource,
  onPreviewClick = () => {},
  isPreviewEnabled,
}) => {
  return (
    <div className="iq4-user-profile-skills-analysis-resources">
      <p className="iq4-user-profile-skills-analysis-resources__intro">
        Preview {profile.firstName}’s skills overlap if they completed{' '}
        <strong>{topResource.label}</strong> applied learning project, a top recommended development
        resource for this role.
      </p>
      <p className="iq4-user-profile-skills-analysis-resources__preview">
        <button onClick={() => onPreviewClick()}>{isPreviewEnabled ? 'Clear' : 'Preview'}</button>
      </p>
    </div>
  );
};
