import React from 'react';
import OnboardingInfoPanel from './OnboardingInfoPanel';
import CustomBulletList from 'common/components/Onboarding/CustomBulletList';
import Testimonial from 'common/components/Onboarding/Testimonial';
import DilworthPaxton from 'common/assets/png/dilworth-paxton.png';
import GraphicStudent from 'common/assets/svg/graphic-student.svg';
import GraphicAcademic from 'common/assets/svg/graphic-academic.svg';
import GraphicKey from 'common/assets/svg/graphic-key.svg';

const listItems = [
  {
    bulletImage: GraphicStudent,
    bulletCopy: '25% of students acquire employment in cybersecurity after using this platform',
  },
  {
    bulletImage: GraphicAcademic,
    bulletCopy: 'Integrate and stay informed with emerging technologies',
  },
  {
    bulletImage: GraphicKey,
    bulletCopy: 'Use iQ4’s universal skills passport to bridge the skills gap.',
  },
];

export const ResumeInfoPanel = () => (
  <OnboardingInfoPanel>
    <h1>Welcome</h1>
    <p style={{ marginBottom: '30px' }}>
      Hi Robert,&nbsp;
      <br />
      <br />
      We are iQ4. We are transforming the learning economy and we are going to transform your
      career.
    </p>
    <CustomBulletList listItems={listItems} />
    <Testimonial
      imageUrl={DilworthPaxton}
      testimonial="iQ4 has harnessed industry engagement and developed a scalable applied learning approach.
    Dilworth Paxson, LLP"
      attestent="Dilworth Paxton, LLP"
    />
  </OnboardingInfoPanel>
);
