import {
  usePortfolioDocumentFormDefinition,
  usePortfolioAudioFormDefinition,
  usePortfolioVideoFormDefinition,
  usePortfolioOtherFormDefinition,
} from 'common/hooks/forms';

export const PROFILE_TYPE = {
  PERSONAL: 'personal',
  EDUCATION: 'education',
  EXPERIENCE: 'experience',
  PORTFOLIO: 'portfolio',
};

export const PORTFOLIO_TYPE = {
  DOCUMENT: 'document',
  OTHER: 'other',
  VIDEO: 'video',
  AUDIO: 'audio',
};

export const portfolioFormDefinitions = {
  document: usePortfolioDocumentFormDefinition,
  other: usePortfolioOtherFormDefinition,
  video: usePortfolioVideoFormDefinition,
  audio: usePortfolioAudioFormDefinition,
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const OVERLY_GENERIC_TERMS = ['specialist', 'professional'];

export const JOB_TITLE_EXCLUSION = ['security officer'];

export const STUDENT_INVITE_STATUS = {
  1: 'pending',
  2: 'active',
  3: 'rejected',
  4: 'learnerRevoked',
  5: 'counsellorRevoked',
};

export const STUDENT_INVITE_STATUS_LABEL = {
  pending: 'Pending',
  active: 'Active',
  rejected: 'Rejected',
  learnerRevoked: 'Learner Revoked',
  counsellorRevoked: 'Counsellor Revoked',
};

export const PANEL_STATES = {
  DEFAULT: 'DEFAULT',
  WORK_ROLE_SKILLS_OVERVIEW: 'WORK_ROLE_SKILLS_OVERVIEW',
  INDIVIDUAL_SKILL_INFO: 'INDIVIDUAL_SKILL_INFO',
  CORE_TASKS: 'CORE_TASKS',
};
