import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn, selectIsBootstrapping } from 'store/features/dashboard/selectors';
import { ROUTES } from 'routes/routeNames';

/**
 * Functional HOC to manage protected routes
 * @param Component
 * @param outerProps
 * @returns {*}
 * @constructor
 */
export const ProtectedRoute = ({ component: Component, ...outerProps }) => {
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Route
      {...outerProps}
      render={(props) => {
        // check if user is authenticated, if not set 'redirect' to true
        const redirect = !isLoggedIn;

        // don't render child component, just redirect if not authenticated
        return isBootstrapping ? (
          ''
        ) : redirect ? (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};
