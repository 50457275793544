import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './TruncateText.scss';

const PUNCTUATION_LIST = ['.', ',', '!', '?', "'", '{', '}', '(', ')', '[', ']', '/'];

const trimText = (text, min, ideal, max) => {
  if (max < min || ideal > max || ideal < min) {
    throw new Error(
      'The minimum length must be less than the maximum, and the ideal must be between the minimum and maximum.',
    );
  }

  if (text.length < ideal) {
    return [text, ''];
  }

  let pointerOne = ideal;
  let pointerTwo = ideal;
  let firstSpace, resultIdx;

  const setSpace = (idx) => {
    if (spaceMatch(text[idx])) {
      firstSpace = firstSpace || idx;
    }
  };

  while (pointerOne < max || pointerTwo > min) {
    if (checkMatch(pointerOne, text, max, min)) {
      resultIdx = pointerOne + 1;
      break;
    } else if (checkMatch(pointerTwo, text, max, min)) {
      resultIdx = pointerTwo + 1;
      break;
    } else {
      setSpace(pointerOne);
      setSpace(pointerTwo);
    }

    pointerOne++;
    pointerTwo--;
  }

  if (resultIdx === undefined) {
    if (firstSpace && firstSpace >= min && firstSpace <= max) {
      resultIdx = firstSpace;
    } else if (ideal - min < max - ideal) {
      resultIdx = min;
    } else {
      resultIdx = max;
    }
  }

  return [text.slice(0, resultIdx), text.slice(resultIdx).trim()];
};

const spaceMatch = (character) => {
  if (character === ' ') {
    return true;
  }
};

const punctuationMatch = (idx, text) => {
  let punctuationIdx = PUNCTUATION_LIST.indexOf(text[idx]);
  if (punctuationIdx >= 0 && spaceMatch(text[idx + 1])) {
    return true;
  }
};

const checkMatch = (idx, text, max, min) => {
  if (idx < max && idx > min && punctuationMatch(idx, text)) {
    return true;
  }
};

export const TruncateText = ({ text, min, ideal, max }) => {
  const trimmedText = trimText(text, min, ideal, max);
  const [expanded, setExpanded] = useState(false);

  let showReadMore = false;
  if (trimmedText[1].length) {
    showReadMore = true;
  }

  return (
    <div className="iq4-truncate-text">
      <p className="iq4-truncate-text__text">
        {trimmedText[0]}
        {expanded && <>&nbsp;{trimmedText[1]}</>}

        {showReadMore && (
          <>
            {!expanded && '...'}
            &nbsp;
            <button
              aria-expanded={expanded}
              className="iq4-truncate-text__button"
              onClick={() => setExpanded(!expanded)}
            >
              Read {expanded ? 'less' : 'more'}
            </button>
          </>
        )}
      </p>
    </div>
  );
};

TruncateText.propTypes = {
  text: PropTypes.string,
  min: PropTypes.number,
  ideal: PropTypes.number,
  max: PropTypes.number,
};

TruncateText.defaultProps = {
  text: '',
  min: 120,
  ideal: 140,
  max: 160,
};
