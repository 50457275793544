import React from 'react';
import { SimpleIcon } from 'common/components';
import './PathwaysPanelHeader.scss';

/**
 * Pathway Panels Header
 * @param {*} [title] - A title for the header
 * @param {*} [leftIcon] - The icon to display on the right side of the header
 * @param {*} [rightIcon] - The icon to display on the right side of the header
 * @param {function} [onLeftClick] - Handle left icon click
 * @param {function} [onRightClick] - Handle right icon click
 * @param {string} [className] - Custom className
 */
export const PathwaysPanelHeader = ({
  title,
  leftIcon = <SimpleIcon name="arrow" />,
  rightIcon,
  className,
  onLeftClick = () => {},
  onRightClick = () => {},
  ...props
}) => {
  return (
    <div className={`iq4-pathways-panel-header ${className ? className : ''}`} {...props}>
      <button
        className="iq4-pathways-panel-header__left"
        onClick={onLeftClick}
        aria-label="Go back"
      >
        {leftIcon && <div className="iq4-pathways-panel-header__left-icon">{leftIcon}</div>}
        {title && <div className="iq4-pathway-detail__header-title">{title}</div>}
      </button>

      {rightIcon && (
        <button className="iq4-pathways-panel-header__right" onClick={onRightClick}>
          <div className="iq4-pathways-panel-header__right-icon">{rightIcon}</div>
        </button>
      )}
    </div>
  );
};
