import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sortedSkillsSummary,
  skillsSelector,
  nonEmptyTier1sSelector,
  selectTier4Assessments,
  selectSkillsMetaData,
} from 'common/store/features/skillsModuleState/skills/selectors';
import {
  broadcastUpdateProf,
  broadcastRemoveAdditionalSkill,
  broadcastAddCerts,
  broadcastRemoveCert,
  broadcastUpdateTools,
  broadcastRequestRecommendedCourses,
} from 'common/store/features/skillsModuleState/broadcaster/actions';
import { toolsMapSelector } from 'common/store/features/skillsModuleState/tools/selectors';

import { toggleSkillsUpdater } from 'common/store/features/skillsModuleState/loaders/actions';

import SkillsCard from '../SkillsCard/SkillsCard';
import SkillsCardHeader from '../SkillsCardHeader/SkillsCardHeader';
import SkillsCardBody from '../SkillsCardBody/SkillsCardBody';
import Skill from '../Skill/Skill';
import SkillHeader from '../Skill/SkillHeader/SkillHeader';
import AddMoreSkillsModal from '../AddMoreSkillsModal/AddMoreSkillsModal';
import AddCertsModal from '../AddCertsModal/AddCertsModal';
import RecommendedCoursesModal from '../RecommendedCoursesModal/RecommendedCoursesModal';
import { Person } from '../Icon/Icon';
import Button from '../Button/Button';
import { Loader } from '../Loader/Loader';
import { selectModuleConfigs } from 'common/store/features/skillsModuleState/moduleConfigs/selectors';

const SkillsList = () => {
  const dispatch = useDispatch();
  const tier4Assessments = useSelector(selectTier4Assessments);
  const skillsSummary = useSelector(sortedSkillsSummary);
  const certsList = useSelector((state) => state.skillsModule.certs.list);
  const certsReqInfo = useSelector((state) => state.skillsModule.loaders.certificates);
  const skillsReqInfo = useSelector((state) => state.skillsModule.loaders.skills);
  const skillsMetaData = useSelector(selectSkillsMetaData);
  const [resetUpdateInfoIDS, setResetUpdateInfoIDS] = useState();
  const { roleSkills, additionalSkills } = useSelector(skillsSelector);
  const [activeDropdowns, setActiveDropdowns] = useState({});
  const nonEmptyTier1s = useSelector(nonEmptyTier1sSelector);
  const [addGlobalSkills, toggleAddGlobalSkills] = useState(false);
  const [addCerts, toggleAddCerts] = useState(false);
  const [showRecommendedCoursesModal, changeRecommendedCoursesModal] = useState(false);
  const moduleConfigs = useSelector(selectModuleConfigs);

  const toolsList = useSelector(toolsMapSelector);
  const recommendedCourses = useSelector((state) => state.skillsModule.courses.recommendedCourses);

  const totalSkills = useMemo(() => getTotalSkills(skillsSummary), [skillsSummary]);

  function getTotalSkills(skills) {
    return skills.reduce((sum, current) => {
      sum += current.roleskillsCount + current.additionalSkillsCount;
      return sum;
    }, 0);
  }

  // reset request info on updated card
  useEffect(() => {
    Object.keys(skillsReqInfo).forEach((id) => {
      if (skillsReqInfo[id].success) {
        setResetUpdateInfoIDS(true);
        window.setTimeout(() => {
          dispatch(
            toggleSkillsUpdater({
              t1ID: id,
              loading: false,
              success: null,
              error: null,
            }),
          );

          setResetUpdateInfoIDS((state) => ({ ...state, [id]: false }));
        }, 4000);
      }
    });
  }, [skillsReqInfo, resetUpdateInfoIDS]);

  // get skills for card that is open
  const handleOnCardOpen = ({ tier1Id, isOpen }) => {
    if (isOpen && !nonEmptyTier1s[tier1Id]) {
      // get skills under the tier1Id
      // console.log('get skills under tier1Id', tier1Id);
    }
  }; // end handleOnCardOpen

  const handleSkillProfUpdate = (payload) => {
    dispatch(broadcastUpdateProf(payload));
  };

  // broadcast to remove skill
  const handleRemoveSkill = (skill) => {
    dispatch(broadcastRemoveAdditionalSkill(skill));
  };

  const handleToolsSave = (payload) => {
    dispatch(dispatch(broadcastUpdateTools(payload)));
  };

  const handleRequestRecommendedCourses = (payload, e) => {
    e.stopPropagation();
    dispatch(broadcastRequestRecommendedCourses(payload));
    changeRecommendedCoursesModal(true);
  };

  const renderTopButtons = () => {
    return (
      <React.Fragment>
        <AddMoreSkillsModal
          isOpen={!!addGlobalSkills}
          toggle={(val) => void toggleAddGlobalSkills(val)}
        />

        {moduleConfigs.includeCerts && (
          <AddCertsModal
            isReadyOnly={moduleConfigs.isReadOnlyCerts}
            assessmentWriteType={moduleConfigs.assessmentType}
            isOpen={!!addCerts}
            toggle={(val) => void toggleAddCerts(val)}
            certsList={certsList}
            onSave={(certs) => void dispatch(broadcastAddCerts(certs))}
            saving={certsReqInfo}
            onDeleteCert={(cert) => void dispatch(broadcastRemoveCert(cert))}
          />
        )}

        {showRecommendedCoursesModal && recommendedCourses && recommendedCourses.length > 0 && (
          <RecommendedCoursesModal
            isOpen={
              showRecommendedCoursesModal && recommendedCourses && recommendedCourses.length > 0
            }
            toggle={() => void changeRecommendedCoursesModal(false)}
            courses={recommendedCourses}
          />
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {moduleConfigs.assessmentType === 'SELF' && (
            <Button
              type="outline"
              style={{ marginRight: '8px' }}
              onClick={() => void toggleAddGlobalSkills(true)}
            >
              Add Additional Skills
            </Button>
          )}

          {moduleConfigs.includeCerts && (
            <Button type="outline" onClick={() => void toggleAddCerts(true)}>
              {moduleConfigs.assessmentType === 'MANAGER'
                ? 'View Certifications'
                : 'Manage Certifications'}
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  };

  const renderCardHeader = (tier1, toggleCardBody, cardExpanded) => {
    return (
      <SkillsCardHeader
        cardExpanded={cardExpanded}
        skillId={tier1.skill_tier1_id}
        skillName={tier1.label}
        skills={{
          role: tier1.roleskillsCount,
          additional: tier1.additionalSkillsCount,
        }}
        onClick={() => {
          toggleCardBody((state) => !state);
        }}
        assessments={{
          self: tier1.selfAssessments,
          tested: tier1.thirdPartyAssessment,
          manager: tier1.managerAssessment,
          certificates: tier1.certificatesAssessment,
        }}
        recommendedCourses={tier1.recommendedCourses}
        onClickRecommendedCourses={(e, tier1Id) =>
          handleRequestRecommendedCourses({ tier: 1, id: tier1Id }, e)
        }
      />
    );
  };

  const renderCardSkillsLoader = () => {
    return (
      <Loader>
        <small style={{ marginTop: '8px' }}>Loading skills &hellip;</small>
      </Loader>
    );
  };

  const showTier2Title = (assessmentType, tier1, tier2, skills) => {
    if (assessmentType === 'TESTED' && !skills[tier1.skill_tier1_id].isTestedMap[tier2.id]) {
      return false;
    }

    if (assessmentType === 'CERTIFIED' && !skills[tier1.skill_tier1_id].isCertifiedMap[tier2.id]) {
      return false;
    }

    return true;
  };

  const showAdditionalSkillsDivider = (t1, assessmentType) => {
    const t1Id = t1.skill_tier1_id;
    const metaData = skillsMetaData[t1Id].additional;

    if (
      ((assessmentType === 'SELF' || assessmentType === 'AVERAGE') && !metaData.skillsCount) ||
      (assessmentType === 'CERTIFIED' && !metaData.certifiedSkillsCount) ||
      (assessmentType === 'MANAGER' &&
        moduleConfigs.assessmentType !== 'MANAGER' &&
        !metaData.managerSkillsCount) ||
      (assessmentType === 'MANAGER' &&
        moduleConfigs.assessmentType === 'MANAGER' &&
        !metaData.skillsCount)
    ) {
      return false;
    }

    return true;
  };

  const renderNoSkillsFallback = (s, assessmentType) => {
    const t1Id = s.skill_tier1_id;
    const metaData = skillsMetaData[t1Id];

    if (
      assessmentType === 'CERTIFIED' &&
      !metaData.role.certifiedSkillsCount &&
      !metaData.additional.certifiedSkillsCount
    ) {
      return (
        <small style={{ marginTop: '12px', paddingLeft: '30px' }}>
          No certified assessments available
        </small>
      );
    }

    if (
      assessmentType === 'MANAGER' &&
      !metaData.role.managerSkillsCount &&
      !metaData.additional.managerSkillsCount
    ) {
      return (
        <small style={{ marginTop: '12px', paddingLeft: '30px' }}>
          No manager assessments available
        </small>
      );
    }

    return null;
  };

  const renderNoRoleSkills = (tier1, assessmentType) => {
    if (renderNoSkillsFallback(tier1, assessmentType)) return null;

    const t1Id = tier1.skill_tier1_id;
    const metaData = skillsMetaData[t1Id].role;

    if (
      ((assessmentType === 'SELF' || assessmentType === 'AVERAGE') && !metaData.skillsCount) ||
      (assessmentType === 'MANAGER' && !metaData.skillsCount) ||
      (assessmentType === 'CERTIFIED' && !metaData.skillsCount)
    ) {
      return (
        <small
          style={{
            marginTop: '12px',
            marginBottom: '12px',
            paddingLeft: '30px',
          }}
        >
          You have no role skills assessments
        </small>
      );
    }

    return null;
  };

  const renderReqUpdates = (skillData, show) => {
    if (!show) return null;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        {skillsReqInfo[skillData.skill_tier1_id] &&
          skillsReqInfo[skillData.skill_tier1_id].loading && (
            <small style={{ marginBottom: '10px' }}>Updating &hellip;</small>
          )}

        {skillsReqInfo[skillData.skill_tier1_id] &&
          skillsReqInfo[skillData.skill_tier1_id].success && (
            <small style={{ color: '#47988f', marginBottom: '10px' }}>Updated</small>
          )}

        {skillsReqInfo[skillData.skill_tier1_id] &&
          skillsReqInfo[skillData.skill_tier1_id].error && (
            <small style={{ color: '#d9534f', marginBottom: '10px' }}>
              Something went wrong updating your skills
            </small>
          )}
      </div>
    );
  };

  return (
    <section className="iq4-skills-list">
      {/* Additional Skills & Sans Certs Buttons & Modals*/}
      {renderTopButtons()}

      <div className="iq4-skill-cards-container">
        <p className="iq4-skills-cards-title">
          Skills <span>{totalSkills}</span>
        </p>
        {skillsSummary.map((s, summaryIndex) => (
          <SkillsCard
            assessmentWriteType={moduleConfigs.assessmentType}
            tier1={s}
            key={s.skill_tier1_id}
            onCardOpen={(val) => void handleOnCardOpen({ tier1Id: s.skill_tier1_id, isOpen: val })}
          >
            {({ showCardBody, addMoreSkills, toggleAddMoreSkills, toggleCardBody }) => (
              <React.Fragment>
                {renderReqUpdates(s, false)}
                {renderCardHeader(s, toggleCardBody, showCardBody)}
                {showCardBody && (
                  <SkillsCardBody
                    t1Id={s.skill_tier1_id}
                    assessmentWriteType={moduleConfigs.assessmentType}
                  >
                    {({ expandAll, assessmentType, activePriorityFilter }) => (
                      <React.Fragment>
                        {renderNoRoleSkills(s, assessmentType)}
                        {/* loading state for retreiving skills */}
                        {!nonEmptyTier1s[s.skill_tier1_id] && renderCardSkillsLoader()}
                        {/* if the tested assessment dropdown is active & there are no teststed skills*/}
                        {renderNoSkillsFallback(s, assessmentType)}
                        {/* if we have data run this*/}
                        {nonEmptyTier1s[s.skill_tier1_id] && (
                          <React.Fragment>
                            {roleSkills[s.skill_tier1_id] &&
                              roleSkills[s.skill_tier1_id].tier2s.map((sk2) => (
                                <React.Fragment key={sk2.id}>
                                  {/* tier2 header, always show if not tested assessment */}
                                  {moduleConfigs.includeTier2Skills &&
                                    showTier2Title(assessmentType, s, sk2, roleSkills) && (
                                      <SkillHeader>{sk2.value}</SkillHeader>
                                    )}
                                  {roleSkills[s.skill_tier1_id].tier3s[sk2.id].map((sk3, i) => (
                                    <Skill
                                      writeType={moduleConfigs.assessmentType}
                                      highPriorityOnly={activePriorityFilter}
                                      canRemoveT3={true}
                                      onRemove={(tier4s) =>
                                        void handleRemoveSkill({
                                          skillLevelToRemove: tier4s ? 4 : 3,
                                          sk1: s,
                                          sk2,
                                          sk3,
                                          sk4:
                                            tier4s ||
                                            additionalSkills[s.skill_tier1_id].tier4s[sk3.id],
                                        })
                                      }
                                      featureConfig={moduleConfigs}
                                      tier4Assessments={tier4Assessments}
                                      tools={toolsList.role[sk3.value]}
                                      key={sk3.id}
                                      onProfChangeTier4={(value, t4) => {
                                        handleSkillProfUpdate({
                                          isRoleSkill: true,
                                          value,
                                          sk1: s.skill_tier1_id,
                                          sk2: sk2.id,
                                          sk3: sk3.id,
                                          sk4: t4,
                                          meta: { compId: sk3.compId },
                                        });
                                      }}
                                      handleSkillProfUpdate={(value) =>
                                        void handleSkillProfUpdate({
                                          isRoleSkill: true,
                                          value,
                                          sk1: s.skill_tier1_id,
                                          sk2: sk2.id,
                                          sk3: sk3.id,
                                          sk4: roleSkills[s.skill_tier1_id].tier4s[sk3.id],
                                          meta: { compId: sk3.compId },
                                        })
                                      }
                                      onChangeAssessmentDropdown={(value) =>
                                        void setActiveDropdowns({
                                          [`${s.skill_tier1_id}-${sk2.id}-${sk3.id}`]: value,
                                        })
                                      }
                                      activeDropdown={
                                        activeDropdowns[`${s.skill_tier1_id}-${sk2.id}-${sk3.id}`]
                                      }
                                      skill={{
                                        ...sk3,
                                        meta: {
                                          t1Id: s.skill_tier1_id,
                                          t2Id: sk2.id,
                                          t3Id: sk3.id,
                                        },
                                      }}
                                      tier4s={roleSkills[s.skill_tier1_id].tier4s[sk3.id]}
                                      showKSAs={expandAll}
                                      assessmentType={assessmentType}
                                      onToolsSave={(tools) =>
                                        void handleToolsSave({
                                          sk3,
                                          isRoleSkill: true,
                                          tools,
                                        })
                                      }
                                      onRequestRecommendedCourses={(payload, e) => {
                                        return void handleRequestRecommendedCourses(payload, e);
                                      }}
                                    />
                                  ))}
                                </React.Fragment>
                              ))}

                            {!activePriorityFilter &&
                              showAdditionalSkillsDivider(s, assessmentType) && (
                                <React.Fragment>
                                  <div
                                    className="iq4-skill-card-skill iq4-skills-card-body-row"
                                    style={{
                                      minHeight: '56px',
                                      backgroundColor: '#f7f8f7',
                                    }}
                                  >
                                    <div
                                      className="iq4-skills-card-body-col"
                                      style={{
                                        padding: '10px',
                                        borderRight: 0,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                        className="iq4-skills-card-body__title iq4-skills-card-body__title-additional"
                                      >
                                        <span className="iq4-skills-card-body__title-additional-icon">
                                          <Person viewBox={null} />
                                        </span>
                                        <span
                                          style={{
                                            lineHeight: '1',
                                            marginLeft: '7px',
                                            color: '#444444',
                                          }}
                                        >
                                          Additional Skills
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="iq4-skills-card-body-col"
                                      style={{ borderRight: 0 }}
                                    />
                                    <div
                                      className="iq4-skills-card-body-col"
                                      style={{ borderRight: 0 }}
                                    />
                                    <div
                                      className="iq4-skills-card-body-col"
                                      style={{ borderRight: 0 }}
                                    />
                                  </div>
                                  {!Object.keys(additionalSkills).length && (
                                    <div
                                      className="iq4-skill-card-skill iq4-skills-card-body-row"
                                      style={{
                                        minHeight: 'initial',
                                      }}
                                    >
                                      <div
                                        className="iq4-skills-card-body-col"
                                        style={{
                                          flexFlow: 'column nowrap',
                                          alignItems: 'flex-start',
                                          fontSize: '14px',
                                          paddingTop: '7px',
                                          paddingBottom: '7px',
                                        }}
                                      >
                                        <p>No additional skills available</p>
                                        <Button
                                          onClick={toggleAddMoreSkills}
                                          type="link"
                                          style={{
                                            textAlign: 'left',
                                            marginLeft: 0,
                                            marginTop: '2px',
                                          }}
                                        >
                                          Click here to add more skills to {s.label}.
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              )}

                            {additionalSkills[s.skill_tier1_id] &&
                              additionalSkills[s.skill_tier1_id].tier2s.map((sk2) => (
                                <React.Fragment key={sk2.id}>
                                  {moduleConfigs.includeTier2Skills &&
                                    showTier2Title(assessmentType, s, sk2, additionalSkills) && (
                                      <SkillHeader>{sk2.value}</SkillHeader>
                                    )}

                                  {additionalSkills[s.skill_tier1_id].tier3s[sk2.id].map(
                                    (sk3, i) => (
                                      <Skill
                                        writeType={moduleConfigs.assessmentType}
                                        highPriorityOnly={activePriorityFilter}
                                        canRemoveT3={true}
                                        featureConfig={moduleConfigs}
                                        tier4Assessments={tier4Assessments}
                                        tools={toolsList.additional[sk3.value]}
                                        key={sk3.id}
                                        onProfChangeTier4={(value, t4) => {
                                          handleSkillProfUpdate({
                                            isRoleSkill: false,
                                            value,
                                            sk1: s.skill_tier1_id,
                                            sk2: sk2.id,
                                            sk3: sk3.id,
                                            sk4: t4,

                                            meta: { compId: sk3.compId },
                                          });
                                        }}
                                        handleSkillProfUpdate={(value) =>
                                          void handleSkillProfUpdate({
                                            isRoleSkill: false,
                                            value,
                                            sk1: s.skill_tier1_id,
                                            sk2: sk2.id,
                                            sk3: sk3.id,
                                            sk4: additionalSkills[s.skill_tier1_id].tier4s[sk3.id],

                                            meta: { compId: sk3.compId },
                                          })
                                        }
                                        onChangeAssessmentDropdown={(value) =>
                                          void setActiveDropdowns({
                                            [`${s.skill_tier1_id}-${sk2.id}-${sk3.id}`]: value,
                                          })
                                        }
                                        onRemove={(tier4s) =>
                                          void handleRemoveSkill({
                                            skillLevelToRemove: tier4s ? 4 : 3,
                                            sk1: s,
                                            sk2,
                                            sk3,
                                            sk4:
                                              tier4s ||
                                              additionalSkills[s.skill_tier1_id].tier4s[sk3.id],
                                          })
                                        }
                                        onRequestRecommendedCourses={(payload, e) => {
                                          return void handleRequestRecommendedCourses(payload, e);
                                        }}
                                        activeDropdown={
                                          activeDropdowns[`${s.skill_tier1_id}-${sk2.id}-${sk3.id}`]
                                        }
                                        skill={{
                                          ...sk3,
                                          meta: {
                                            t1Id: s.skill_tier1_id,
                                            t2Id: sk2.id,
                                            t3Id: sk3.id,
                                          },
                                        }}
                                        tier4s={additionalSkills[s.skill_tier1_id].tier4s[sk3.id]}
                                        showKSAs={expandAll}
                                        assessmentType={assessmentType}
                                        onToolsSave={(tools) =>
                                          void handleToolsSave({
                                            sk3,
                                            isRoleSkill: false,
                                            tools,
                                          })
                                        }
                                      />
                                    ),
                                  )}
                                </React.Fragment>
                              ))}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </SkillsCardBody>
                )}
                {addMoreSkills && (
                  <AddMoreSkillsModal
                    isOpen={!!addMoreSkills}
                    toggle={toggleAddMoreSkills}
                    skillTier1={{ id: s.skill_tier1_id, value: s.label }}
                  />
                )}
              </React.Fragment>
            )}
          </SkillsCard>
        ))}
      </div>
    </section>
  );
};

export default SkillsList;
