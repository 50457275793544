import React, { useState } from 'react';
import moment from 'moment';
import { SimpleIcon } from 'common/components/SimpleIcon';
import { PROFILE_EXPERIENCE_URL } from 'common/constants/endpoints';
import './OnboardingExperienceList.scss';
import { removeProfileSection } from 'common/store/features/profile/profileSlice';
import { selectProfile } from 'common/store/features/profile/selectors';
import { PROFILE_TYPE } from 'common/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { PromptModal } from '../../../PromptModal';
import { sendRequest } from '../../../../utils';

export const OnboardingExperienceList = ({ onEdit }) => {
  const dispatch = useDispatch();
  const state = useSelector(selectProfile);
  const [isPromptActive, setIsPromptActive] = useState(false);

  const handleDeleteItem = (id) => {
    sendRequest({ url: `${PROFILE_EXPERIENCE_URL}/${id}`, verb: 'DELETE' })
      .then(() => {
        dispatch(removeProfileSection({ section: PROFILE_TYPE.EXPERIENCE, value: id }));
        setIsPromptActive(false);
      })
      .catch((err) => {
        // TODO: John to confirm error display
        console.log(err);
      });
  };

  if (state.experience.length === 0) {
    return null;
  }

  return state.experience.map((item, index) => (
    <>
      <PromptModal
        hideDismiss
        isOpen={isPromptActive}
        handleClose={() => setIsPromptActive(false)}
        title="Are you sure you want to delete this?"
        buttonLabel="Delete"
        buttonAction={() => handleDeleteItem(item.id)}
      />
      <div className="iq4-onboarding__experience-list-container">
        <div className="iq4-onboarding__experience-field-container">
          <span className="iq4-onboarding__experience-list-strong">{item.title}</span>
          <span className="iq4-onboarding__experience-list-strong">{item.company}</span>
          {item.position && <span>{item.position}</span>}
          {item.currentlyWorkHere && <span>I currently work here</span>}
          <span className="iq4-onboarding__experience-period-container">
            {item.fromMonth && item.fromYear && (
              <span>
                <span>
                  {moment(item.fromMonth, 'MM').format('MMMM')} {item.fromYear}
                </span>
                {item.toMonth && item.toYear && (
                  <span>
                    <span> - </span>
                    {moment(item.toMonth, 'MM').format('MMMM')} {item.toYear}
                  </span>
                )}
              </span>
            )}
          </span>
        </div>
        <div className="iq4-onboarding__experience-icon-container">
          <span>
            <SimpleIcon name="edit" color="#296AFB" onClick={() => onEdit(item)} />
          </span>
          <span className="iq4-onboarding__experience-icon">
            <SimpleIcon name="bin" color="#296AFB" onClick={() => setIsPromptActive(true)} />
          </span>
        </div>
      </div>
      <span className="iq4-onboarding__experience-separator" />
    </>
  ));
};
