import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './LinkIcon.scss';

export const LinkIcon = ({
  className,
  to,
  label,
  iconName,
  isAriaHidden,
  size,
  ...linkIconProps
}) => (
  <Link className={`iq4-link-icon ${className}`} aria-label={label} to={to} {...linkIconProps}>
    <SimpleIcon
      className="iq4-link-icon__icon"
      name={iconName}
      role="presentation"
      aria-hidden={isAriaHidden}
      size={size}
    />
  </Link>
);

LinkIcon.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  size: PropTypes.string,
};

LinkIcon.defaultProps = {
  className: '',
  isAriaHidden: true,
};
