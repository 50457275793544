import React from 'react';
import { useDispatch } from 'react-redux';
import { TextInput, TextArea, Select } from 'common/components/Form/fieldTypes';
import { validations } from 'common/components/Form/validations';
import { CREATE_JOB_REQ_URL, EDIT_JOB_REQ_URL } from 'common/constants/endpoints';
import {
  setNewJobReq,
  setNewParsedJobReq,
  fetchJobReq,
} from 'store/features/jobReqs/jobReqsSlice.js';
import { sendRequest } from 'common/utils';

export const useJobReqFormDefinition = ({ values, onComplete }) => {
  const dispatch = useDispatch();

  const fieldsDefinition = [
    {
      label: 'Role',
      placeholder: 'Role',
      name: 'role',
      component: TextInput,
      isDisabled: !!values.firstName,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Location',
      placeholder: 'Location',
      name: 'location',
      component: TextInput,
      isDisabled: !!values.lastName,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Owner',
      placeholder: 'Owner',
      name: 'owner',
      component: TextInput,
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Department',
      placeholder: 'Department',
      name: 'department',
      component: TextInput,
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Position Type',
      placeholder: 'Position Type',
      name: 'positionType',

      isCustom: true,
      component: ({ ...formProps }) => (
        <div className="iq4-portfolio-source-select">
          <Select
            maxWidth
            label="Position Type"
            name="positionType"
            placeholder="Select Position Type"
            options={[
              { key: 'fulltime', value: 'Full Time' },
              { key: 'internship', value: 'Internship' },
              { key: 'contractor', value: 'Contractor' },
              // { key: 'missing', value: 'Missing' },
            ]}
            {...formProps}
          />
        </div>
      ),
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },

    {
      label: 'Date of Job Req Creation',
      placeholder: 'YYYY-MM-DD',
      name: 'createdDate',
      component: TextInput,
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Role Description',
      placeholder: 'Role Description',
      name: 'description',
      component: TextArea,
      validators: {
        // maxLength: validations.hasMaxLength(140),
      },
      rows: 10,
    },
    {
      label: 'Qualifications',
      placeholder: 'Qualifications',
      name: 'qualification',
      component: TextInput,
    },
    {
      label: 'Education',
      placeholder: 'Education',
      name: 'education',
      component: TextInput,
    },
    {
      label: 'Priority Level',
      placeholder: 'Priority Level',
      name: 'priority',

      isCustom: true,
      component: ({ ...formProps }) => (
        <div className="iq4-portfolio-source-select">
          <Select
            maxWidth
            label="Priority Level"
            name="priority"
            placeholder="Select priority level"
            options={[
              { key: 'low', value: 'Low' },
              { key: 'medium', value: 'Medium' },
              { key: 'high', value: 'High' },
              { key: 'urgent', value: 'Urgent' },
            ]}
            {...formProps}
          />
        </div>
      ),
    },

    {
      label: 'Open positions',
      placeholder: 'Number of open positions for this role',
      name: 'openPositions',
      component: TextInput,
    },
  ];

  const onEdit = async (data) => {
    await sendRequest({
      url: `${EDIT_JOB_REQ_URL}/${values.id}`,
      verb: 'PUT',
      data,
    }).then((resp) => {
      return dispatch(setNewJobReq(resp));
    });

    await dispatch(fetchJobReq(values.id));

    return;
  };

  const onCreate = (data) => {
    return sendRequest({
      url: CREATE_JOB_REQ_URL,
      verb: 'POST',
      data,
    }).then((resp) => {
      dispatch(setNewJobReq(resp));
      return resp;
    });
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};
export const useParsedJobReqFormDefinition = ({ values, onComplete }) => {
  const dispatch = useDispatch();

  const fieldsDefinition = [
    {
      label: 'Role',
      placeholder: 'Role',
      name: 'role',
      component: TextInput,
      isDisabled: !!values.firstName,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Location',
      placeholder: 'Location',
      name: 'location',
      component: TextInput,
      isDisabled: !!values.lastName,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Owner',
      placeholder: 'Owner',
      name: 'owner',
      component: TextInput,
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Department',
      placeholder: 'Department',
      name: 'department',
      component: TextInput,
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Position Type',
      placeholder: 'Position Type',
      name: 'positionType',

      isCustom: true,
      component: ({ ...formProps }) => (
        <div className="iq4-portfolio-source-select">
          <Select
            maxWidth
            label="Position Type"
            name="positionType"
            placeholder="Select Position Type"
            options={[
              { key: 'fulltime', value: 'Full Time' },
              { key: 'internship', value: 'Internship' },
              { key: 'contractor', value: 'Contractor' },
              // { key: 'missing', value: 'Missing' },
            ]}
            {...formProps}
          />
        </div>
      ),
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Date of Job Req Creation',
      placeholder: 'YYYY-MM-DD',
      name: 'createdDate',
      component: TextInput,
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Role Description',
      placeholder: 'Role Description',
      name: 'description',
      component: TextArea,
      validators: {
        // maxLength: validations.hasMaxLength(140),
      },
      rows: 10,
    },
    {
      label: 'Qualifications',
      placeholder: 'Qualifications',
      name: 'qualification',
      component: TextInput,
    },
    {
      label: 'Education',
      placeholder: 'Education',
      name: 'education',
      component: TextInput,
    },
    {
      label: 'Priority Level',
      placeholder: 'Priority Level',
      name: 'priority',

      isCustom: true,
      component: ({ ...formProps }) => (
        <div className="iq4-portfolio-source-select">
          <Select
            maxWidth
            label="Priority Level"
            name="priority"
            placeholder="Select priority level"
            options={[
              { key: 'low', value: 'Low' },
              { key: 'medium', value: 'Medium' },
              { key: 'high', value: 'High' },
              { key: 'urgent', value: 'Urgent' },
            ]}
            {...formProps}
          />
        </div>
      ),
    },

    {
      label: 'Open positions',
      placeholder: 'Number of open positions for this role',
      name: 'openPositions',
      component: TextInput,
    },
  ];

  const onEdit = (data) => {
    return sendRequest({
      url: `${EDIT_JOB_REQ_URL}/${values.id}`,
      verb: 'PUT',
      data,
    }).then((resp) => {
      return dispatch(setNewJobReq(resp));
    });
  };

  const onCreate = async (data) => {
    return dispatch(setNewParsedJobReq(data));
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};
