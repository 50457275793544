import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  LCN_GET_COUNTS_URL,
  LCN_GET_CONNECTION_REQUEST_BY_STATUS_URL,
  LCN_GET_CONNECTION_DETAIL_BY_ID_URL,
  LCN_CREATE_NEW_CONNECTION_REQUEST_URL,
  LCN_APPROVE_REJECT_CONNECTION_REQUEST_URL,
  LCN_UPDATE_PERMISSIONS_URL,
  LCN_GET_CREDENTIAL_BY_ID_URL,
  LCN_GET_CREDENTIALS_BY_STATUS_URL,
  LCN_UPDATE_CREDENTIAL_STATUS_URL,
  LCN_GET_VERIFIER_ORGANIZATIONS_URL,
  NSC_STUDENT_PROFILE_URL,
  LCN_GET_VERIFY_CREDENTIALS_URL,
} from 'common/constants/endpoints';

import { LCN_RECORD_STATES, LCN_RECORD_STATUSES } from 'common/constants/lcnRecords';

export const fetchCountsReq = createAsyncThunk(
  'records/fetchCountsReq',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(LCN_GET_COUNTS_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchAcceptedCredentialsReq = createAsyncThunk(
  'records/fetchAcceptedCredentialsReq',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(`${LCN_GET_CREDENTIALS_BY_STATUS_URL}/${LCN_RECORD_STATES.ACCEPTED}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchNewCredentialsReq = createAsyncThunk(
  'records/fetchNewCredentialsReq',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(`${LCN_GET_CREDENTIALS_BY_STATUS_URL}/${LCN_RECORD_STATES.NEW}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchCredentialsDetailsByIdReq = createAsyncThunk(
  'records/fetchCredentialsDetailsByIdReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${LCN_GET_CREDENTIAL_BY_ID_URL}/${id}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchVerifierOrganizationsReq = createAsyncThunk(
  'records/fetchVerifierOrganizationsReq',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(LCN_GET_VERIFIER_ORGANIZATIONS_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchConnectionRequestsReq = createAsyncThunk(
  'records/fetchConnectionRequestsReq',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(`${LCN_GET_CONNECTION_REQUEST_BY_STATUS_URL}/${LCN_RECORD_STATES.NEW}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchConnectionDetailsByIdReq = createAsyncThunk(
  'records/fetchConnectionDetailsByIdReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${LCN_GET_CONNECTION_DETAIL_BY_ID_URL}/${id}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchSharedConnectionsReq = createAsyncThunk(
  'records/fetchSharedConnectionsReq',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(`${LCN_GET_CONNECTION_REQUEST_BY_STATUS_URL}/${LCN_RECORD_STATES.ACCEPTED}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const approveRejectConnectionReq = createAsyncThunk(
  'records/approveRejectConnectionReq',
  async (payload, { dispatch, rejectWithValue }) => {
    return fetch(LCN_APPROVE_REJECT_CONNECTION_REQUEST_URL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchCountsReq());
        return payload;
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const updatePermissionsReq = createAsyncThunk(
  'records/updatePermissionsReq',
  async (payload, { rejectWithValue }) => {
    return fetch(LCN_UPDATE_PERMISSIONS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        return payload;
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const updateCredentialStatusReq = createAsyncThunk(
  'records/updateCredentialStatusReq',
  async (data, { dispatch, rejectWithValue }) => {
    const payload = data.map((item) => ({
      id: item.id,
      status: LCN_RECORD_STATES.ACCEPTED,
    }));

    return fetch(LCN_UPDATE_CREDENTIAL_STATUS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchCountsReq());
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const createNewConnectionReq = createAsyncThunk(
  'records/createNewConnectionReq',
  async (payload, { dispatch, rejectWithValue }) => {
    return fetch(LCN_CREATE_NEW_CONNECTION_REQUEST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchSharedConnectionsReq());
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchPersonalInformation = createAsyncThunk(
  'records/fetchPersonalInformation',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(NSC_STUDENT_PROFILE_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchVerifyCredentialsURL = createAsyncThunk(
  'records/fetchVerifyCredentialsURL',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(LCN_GET_VERIFY_CREDENTIALS_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const recordsSlice = createSlice({
  name: 'records',
  initialState: {
    counts: {
      connectionsCountNew: 0,
      credentailsCountNew: 0,
    },
    credentials: [],
    connections: [],
    verifierOrganizations: [],
    personalInformation: {},
    verifyCredentialsURL: null,
    errors: [],
  },
  reducers: {
    dummyReducer: (state, action) => {
      state.dummy = action.payload;
    },
  },
  extraReducers: {
    [fetchCountsReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchCountsReq.fulfilled]: (state, action) => {
      state.counts = action.payload;
    },
    [fetchAcceptedCredentialsReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchAcceptedCredentialsReq.fulfilled]: (state, action) => {
      Object.keys(action.payload) &&
        Object.keys(action.payload).forEach((key) => {
          action.payload[key] &&
            action.payload[key].forEach((credential) => {
              const index = state.credentials.findIndex((item) => item.id === credential.id);
              if (index > -1) {
                state.credentials[index] = credential;
              } else {
                state.credentials.push(credential);
              }
            });
        });
    },
    [fetchNewCredentialsReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchNewCredentialsReq.fulfilled]: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        action.payload[key] &&
          action.payload[key].forEach((credential) => {
            const index = state.credentials.findIndex((item) => item.id === credential.id);
            if (index > -1) {
              state.credentials[index] = credential;
            } else {
              state.credentials.push(credential);
            }
          });
      });
    },
    [fetchCredentialsDetailsByIdReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchCredentialsDetailsByIdReq.fulfilled]: (state, action) => {
      const index = state.credentials.findIndex(
        (credential) => credential.id === action.payload.id,
      );
      const credential = state.credentials[index];
      const credentialType =
        credential.type === 'microcredential' ? 'microCredential' : credential.type; // TODO: Remove this when fixed on API

      state.credentials[index] = {
        ...credential,
        [credential.type]: action.payload[credentialType],
      };
    },
    [fetchVerifierOrganizationsReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchVerifierOrganizationsReq.fulfilled]: (state, action) => {
      state.verifierOrganizations = action.payload;
    },
    [fetchConnectionRequestsReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchConnectionRequestsReq.fulfilled]: (state, action) => {
      action.payload.listOfconnections &&
        action.payload.listOfconnections.forEach((connection) => {
          const index = state.connections.findIndex(
            (item) => item.connectionId === connection.connectionId,
          );
          if (index > -1) {
            state.connections[index] = {
              ...connection,
              status: LCN_RECORD_STATUSES.REQUESTED,
            };
          } else {
            state.connections.push({
              ...connection,
              status: LCN_RECORD_STATUSES.REQUESTED,
            });
          }
        });
    },
    [fetchSharedConnectionsReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchSharedConnectionsReq.fulfilled]: (state, action) => {
      action.payload.listOfconnections &&
        action.payload.listOfconnections.forEach((connection) => {
          const index = state.connections.findIndex(
            (item) => item.connectionId === connection.connectionId,
          );
          if (index > -1) {
            state.connections[index] = {
              ...connection,
              status: LCN_RECORD_STATUSES.SHARED,
            };
          } else {
            state.connections.push({
              ...connection,
              status: LCN_RECORD_STATUSES.SHARED,
            });
          }
        });
    },
    [updatePermissionsReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [updatePermissionsReq.fulfilled]: (state, action) => {
      action.payload.forEach((credential) => {
        const index = state.credentials.findIndex((item) => item.id === credential.credentialId);
        const original = state.credentials[index];

        state.credentials[index] = {
          ...original,
          permission: credential.permissioned,
        };
      });
    },
    [updateCredentialStatusReq.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [updateCredentialStatusReq.fulfilled]: (state, action) => {
      action.payload.forEach((credential) => {
        const index = state.credentials.findIndex((item) => item.id === credential.id);
        if (index > -1) {
          state.credentials[index] = credential;
        } else {
          state.credentials.push(credential);
        }
      });
    },
    [fetchPersonalInformation.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchPersonalInformation.fulfilled]: (state, action) => {
      state.personalInformation = action.payload;
    },
    [fetchVerifyCredentialsURL.rejected]: (state, action) => {
      state.errors.push(action);
    },
    [fetchVerifyCredentialsURL.fulfilled]: (state, action) => {
      state.verifyCredentialsURL = action.payload.verifyCredentialsURL;
    },
  },
});

// action creators
// eslint-disable-next-line no-empty-pattern
export const {} = recordsSlice.actions;

export default recordsSlice.reducer;
