import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const selectJobReqPositions = createDraftSafeSelector(
  (state) => state.jobReqs,
  (jobReqs) => jobReqs.positions,
);

export const selectSelectedPosition = createDraftSafeSelector(
  (state) => state.jobReqs,
  (jobReqs) => jobReqs.selectedPosition,
);

export const selectJobValueFormStructure = createDraftSafeSelector(
  (state) => state.jobReqs?.selectedPosition,
  (selectedPosition) => {
    if (!selectedPosition) return null;
    return {
      id: selectedPosition.id,
      role: selectedPosition.title,
      location: selectedPosition.location,
      owner: selectedPosition.openedBy,
      department: selectedPosition.department,
      positionType: selectedPosition.positionType,
      createdDate: selectedPosition.date,
      description: selectedPosition.description,
      qualification: selectedPosition.qualification,
      education: selectedPosition.education,
      priority: selectedPosition.priority,
      openPositions: selectedPosition.openPositions,
    };
  },
);

export const selectSelectedPositionCandidates = createDraftSafeSelector(
  (state) => state.jobReqs,
  (jobReqs) => jobReqs.selectedPositionCandidates,
);

export const selectParsedJobReq = createDraftSafeSelector(
  (state) => state.jobReqs,
  (jobReqsStore) => jobReqsStore.parsedJobReq,
);

export const selectSelectedJobSkills = createDraftSafeSelector(
  (state) => state.jobReqs,
  (jobReqsStore) => jobReqsStore.selectedPositionSkills,
);
