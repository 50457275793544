import React from 'react';

export const Loader = ({ children }) => {
  return (
    <div className="skill-module-loader-container">
      <div className="skills-module-loader" />
      {children}
    </div>
  );
};
