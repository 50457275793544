import React, { useEffect, useState } from 'react';
import { fetchPublicData, setSelectedPublicView } from '../../store/features/search/searchSlice';
import { useIsMobile } from 'common/hooks/useIsMobile';
import Grid from '@material-ui/core/Grid';
import { Container, ManageSkillsCard } from 'common/components';
import { useDispatch, useSelector } from 'react-redux';
import './UserPublicProfile.scss';
import { ProfilePersonal } from './PublicProfile/ProfilePersonal';
import { ProfileEducationSummary } from './PublicProfile/ProfileEducationSummary';
import { ProfileExperienceSummary } from './PublicProfile/ProfileExperienceSummary';
import {
  selectPublicCredentials,
  selectPublicSkills,
  selectSelectedPublicView,
} from '../../store/features/search/selectors';
import { ProfileEducationDetail } from './PublicProfile/ProfileEducationDetail';
import { ProfilePortfolioSummary } from './PublicProfile/ProfilePortfolioSummary';
import { ProfileSkillsSummary } from './PublicProfile/ProfileSkillsSummary';
import { selectOrderedSkills, selectSkills } from '../../common/store/features/skills/selectors';
import { Card, CardContent } from '@material-ui/core';
import { CredentialRow } from './PublicProfile/CredentialRow';
import { CareerPathwaysPage } from '../CareerPathwaysPage';
import { ProfileExperienceDetail } from './PublicProfile/ProfileExperienceDetail';
import { ProfilePortfolioDetail } from './PublicProfile/ProfilePortfolioDetail';
/*import { useHistory, useParams, useLocation, useRouteMatch } from 'react-router-dom';
import {
  ProfilePersonal,
  ProfileEducationSummary,
  ProfileLCNEducationSummary,
  ProfileExperienceSummary,
  ProfilePortfolioSummary,
  ProfileSkillsSummary,
  ProfilePageLayout,
  ProfileResumeParse,
  ProfileEducationDetail,
  ProfileExperienceDetail,
} from 'components/Profile';
import {
  Button,
  ConsentBanner,
  Container,
  Footer,
  ManageSkillsCard,
  Topbar,
} from 'common/components';
import { ROUTES } from 'routes/routeNames';
import { selectFeatures } from 'common/store/features/config/selectors';
import {
  selectProfile,
  selectPublicPersonalProfile,
  selectPublicSkills,
  selectUserProfile,
} from 'common/store/features/profile/selectors';
import {
  fetchProfileReq,
  fetchPublicData,
  setIsPublicProfile,
} from 'common/store/features/profile/profileSlice';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { Helmet } from 'react-helmet';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import Grid from '@material-ui/core/Grid';
import './PublicProfilePage.scss';
import {
  selectSkills,
  selectSkillsWithLearningResourceByState,
} from 'common/store/features/skills/selectors';
import { ProfilePortfolioDetail } from '../../../components/Profile/ProfilePortfolioDetail';
import { CareerPathwaysPage } from '../../CareerPathwaysPage';
import { setSkillExpandedId } from '../../../common/store/features/ui/uiSlice';
import { CredentialsPage } from '../../CredentialsPage';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CredentialRow } from '../../CredentialsPage/components/CredentialRow';
import { VerifiedCredentialRow } from '../../CredentialsPage/components/VerifiedCredentialRow';
import { selectInnerCurrentCredentials } from '../../../common/store/features/credentials/selectors';
import { fetchAllSkillsReq } from '../../../common/store/features/skills/skillsSlice';*/

export const UserPublicProfile = ({ userId, dashboardUserId }) => {
  const isTablet = useIsMobile((b) => ['xs', 'sm', 'md'].includes(b));
  const dispatch = useDispatch();

  const selectedPublicView = useSelector(selectSelectedPublicView);
  const publicSkills = useSelector(selectPublicSkills);
  const publicCredentials = useSelector(selectPublicCredentials);
  const [hasFetchedCredentials, setHasFetchedCredentials] = useState(false);
  /*
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { applicationTitle, lcnEnabled, personalProfileSource } = useSelector(selectFeatures);
  const [showResumeParser, setShowResumeParser] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsMobile((b) => ['xs', 'sm', 'md'].includes(b));
  const personalPublicProfile = useSelector(selectPublicPersonalProfile);
  const { skills: publicSkills } = useSelector(selectSkills);
  const credentialsList = useSelector(selectInnerCurrentCredentials);
  const context = process.env.REACT_APP_CONTEXT;

  const personal = personalPublicProfile?.personal;

  const { skills } = useSelector((state) =>
    selectSkillsWithLearningResourceByState(state, personal?.school),
  );
*/

  useEffect(() => {
    if (!hasFetchedCredentials) {
      dispatch(fetchPublicData({ userId, dashboardUserId, section: 'credentials' }));
      setHasFetchedCredentials(true);
    }
  }, [hasFetchedCredentials]);

  useEffect(() => {
    switch (selectedPublicView) {
      case 'profile':
        dispatch(fetchPublicData({ userId, dashboardUserId, section: 'personal' }));
        dispatch(fetchPublicData({ userId, dashboardUserId, section: 'skills' }));
        dispatch(fetchPublicData({ userId, dashboardUserId, section: 'pathways' }));
        break;
    }

    // dispatch(setSkillExpandedId(-1));
    window.scrollTo(0, 0);
  }, [selectedPublicView]);

  function renderLeftComponents() {
    switch (selectedPublicView) {
      case 'profile2':
      case 'skills':
      case 'credentials':
      case 'pathways':
        return (
          <>
            <ProfilePersonal />
          </>
        );
      default:
        return (
          <>
            <ProfilePersonal />
            <ProfileEducationSummary />
            <ProfileExperienceSummary />
          </>
        );
    }
  }

  function renderRightComponents() {
    switch (selectedPublicView) {
      case 'education':
        return (
          <>
            <ProfileEducationDetail />
          </>
        );
      case 'experience':
        return (
          <>
            <ProfileExperienceDetail />
          </>
        );
      case 'portfolio':
        return (
          <>
            <ProfilePortfolioDetail />
          </>
        );
      case 'skills':
        return (
          <>
            <ManageSkillsCard skills={publicSkills} userId={userId} />
          </>
        );
      case 'credentials':
        return (
          <>
            <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
              <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                <h2 className="iq4-credentials__sub-header">Manage</h2>
                <div className="iq4-credentials__card-container-header">
                  <div className="iq4-credentials__card-container--header">Name</div>
                  <div className="iq4-credentials__card-container--header">Award Date</div>
                  <div className="iq4-credentials__card-container--header">Issuer</div>
                </div>
                {publicCredentials.map((credential) => (
                  <CredentialRow
                    row={credential}
                    key={credential?.id}
                    setCredentialToBeDeleted={() => {}}
                    setIsDeletingModal={() => {}}
                    setIsEdit={() => {}}
                  />
                ))}
              </CardContent>
            </Card>
          </>
        );
      case 'pathways':
        return (
          <>
            <CareerPathwaysPage />
          </>
        );
      default:
        return (
          <>
            <ProfileSkillsSummary />
            <ProfilePortfolioSummary />
          </>
        );
    }
  }

  return (
    <>
      {!isTablet && (
        <Grid container classes={{ root: 'iq4-profile__grid-container' }}>
          <Grid
            item
            md={3}
            classes={{
              root: 'iq4-profile-page-item iq4-profile-page-item--left iq4-profile-page-item--top',
            }}
          >
            <h1 className="iq4-profile-header__text">
              <span className="iq4-proifle-header__label">Profile</span>
            </h1>
          </Grid>
          <Grid
            item
            md={9}
            classes={{
              root: 'iq4-public-profile-page-item iq4-public-profile-page-item--right iq4-public-profile-page-item--top',
            }}
          >
            <div className="iq4-public-profile__link-container">
              <div
                className={`iq4-public-profile__link-item ${
                  ['profile', 'experience', 'education', 'portfolio'].includes(selectedPublicView)
                    ? 'iq4-public-profile__link-item--active'
                    : ''
                }`}
                onClick={() => dispatch(setSelectedPublicView('profile'))}
              >
                Profile
              </div>
              <div
                className={`iq4-public-profile__link-item ${
                  selectedPublicView === 'skills' ? 'iq4-public-profile__link-item--active' : ''
                }`}
                onClick={() => dispatch(setSelectedPublicView('skills'))}
              >
                Skills
              </div>
              <div
                className={`iq4-public-profile__link-item ${
                  selectedPublicView === 'credentials'
                    ? 'iq4-public-profile__link-item--active'
                    : ''
                }`}
                onClick={() => dispatch(setSelectedPublicView('credentials'))}
              >
                Credentials
              </div>
              <div
                className={`iq4-public-profile__link-item ${
                  selectedPublicView === 'pathways' ? 'iq4-public-profile__link-item--active' : ''
                }`}
                onClick={() => dispatch(setSelectedPublicView('pathways'))}
              >
                Career Pathways
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container>
        {!isTablet && (
          <Grid
            item
            md={3}
            sm={6}
            classes={{ root: 'iq4-profile-page-item iq4-profile-page-item--left' }}
          >
            <div>{renderLeftComponents()}</div>
          </Grid>
        )}
        <Grid
          item
          md={isTablet ? 12 : 9}
          sm={isTablet ? 12 : 6}
          classes={{
            root: `iq4-profile-page-item iq4-profile-page-item--right ${
              isTablet ? 'iq4-profile-page-item--mobile' : ''
            }`,
          }}
        >
          <div>{renderRightComponents()}</div>
        </Grid>
      </Grid>
    </>
  );
};
