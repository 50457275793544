import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ROLE_PROFILES_URL,
  MANAGERS_URL,
  PATHWAYS_URL,
  LOCATIONS_URL,
  CREDENTIALS_URL,
  SCHOOLS_URL,
  DASHBOARD_QUERY_URL,
  DASHBOARD_USER_URL,
  USER_PROFILE_URL,
  INVITE_USER_URL,
  WALLET_SHARING_SETTINGS_URL,
  PUBLIC_PROFILE_URL,
  PUBLIC_CREDENTIALS_URL,
} from 'constants/endpoints';
import {
  setIsLoadingFilterResults,
  setSkillExpandedId,
  setSkillExpandingId,
} from 'common/store/features/ui/uiSlice';
import { setProfileFromPublic } from '../../../common/store/features/profile/profileSlice';
import { fetchSkillDetails, setSkills } from '../../../common/store/features/skills/skillsSlice';
import { setUserSelectedWorkRoles } from '../../../common/store/features/pathways/pathwaysSlice';
import { SKILL_DETAILS_URL } from '../../../common/constants/endpoints';

export const fetchRoleProfiles = createAsyncThunk('dashboard/fetchRoleProfiles', async () => {
  const res = await fetch(ROLE_PROFILES_URL, {
    method: 'GET',
  });

  const json = await res.json();

  const derivedRoles = json.map((r) => {
    return {
      id: r.id,
      label: r.name,
    };
  });

  return derivedRoles;
});

export const fetchRoleProfile = createAsyncThunk(
  'dashboard/fetchRoleProfile',
  async ({ roleProfileId, userId }, { dispatch }) => {
    dispatch(setIsFetchingRoleProfile(true));
    const res = await fetch(`${DASHBOARD_USER_URL}/${userId}/roleProfile/${roleProfileId}`, {
      method: 'GET',
    });

    return res.json();
  },
);

export const fetchManagers = createAsyncThunk('dashboard/fetchManagers', async () => {
  const res = await fetch(MANAGERS_URL, {
    method: 'GET',
  });

  return res.json();
});

export const fetchPathways = createAsyncThunk('dashboard/fetchPathways', async () => {
  const res = await fetch(PATHWAYS_URL, {
    method: 'GET',
  });

  const data = await res.json();

  return updateNamePropToLabel(data);
});

export const fetchLocations = createAsyncThunk('dashboard/fetchLocations', async () => {
  const res = await fetch(LOCATIONS_URL, {
    method: 'GET',
  });

  const data = await res.json();

  return updateNamePropToLabel(data);
});

export const fetchCredentials = createAsyncThunk('dashboard/fetchCredentials', async () => {
  const res = await fetch(CREDENTIALS_URL, {
    method: 'GET',
  });

  return res.json();
});

export const fetchSchools = createAsyncThunk('dashboard/fetchSchools', async () => {
  const res = await fetch(SCHOOLS_URL, {
    method: 'GET',
  });

  const data = await res.json();

  return updateNamePropToLabel(data);
});

function updateNamePropToLabel(arr) {
  return arr.map((v) => {
    return { ...v, label: v.name };
  });
}

export const fetchFilterQuery = createAsyncThunk(
  'search/fetchFilterQuery',
  async ({ allFilters, query, selectedSource }, { dispatch }) => {
    dispatch(setIsLoadingFilterResults(true));
    const generateQueryString = () => allFilters.map((key) => `${key}=ALL`).join('&');
    const url = `${DASHBOARD_QUERY_URL}${allFilters.length ? `?${generateQueryString()}` : ''}${
      allFilters.length ? `&source=${selectedSource}` : `?source=${selectedSource}`
    }`;

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });

    dispatch(setIsLoadingFilterResults(false));

    return res.json();
  },
);

export const fetchUserProfile = createAsyncThunk('search/fetchUserProfile', async (id) => {
  const res = await fetch(`${USER_PROFILE_URL}/${id}`, {
    method: 'GET',
  });

  return res.json();
});

export const fetchInvites = createAsyncThunk('search/fetchInvites', async () => {
  const res = await fetch(`${INVITE_USER_URL}?type=counsellor`, {
    method: 'GET',
  });

  return res.json();
});

export const createConnection = createAsyncThunk(
  'search/createConnection',
  async ({ message, studentId }, { rejectWithValue, dispatch }) => {
    dispatch(setIsSendingMessage(true));
    return fetch(INVITE_USER_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        studentId: studentId,
        msg: message,
      }),
    }).then((response) => {
      dispatch(setIsSendingMessage(false));
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response.json());
      }
    });
  },
);

export const fetchWalletSharingSettings = createAsyncThunk(
  'search/fetchWalletSharingSettings',
  async ({ studentId, dashboardId }, { rejectWithValue, dispatch }) => {
    return fetch(`${WALLET_SHARING_SETTINGS_URL}/${studentId}/${dashboardId}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response.json());
      }
    });
  },
);

export const fetchPublicSkillDetails = createAsyncThunk(
  'search/fetchPublicSkillDetails',
  async ({ competenceId, isPublic = false, userId }, { rejectWithValue, dispatch }) => {
    return fetch(`${SKILL_DETAILS_URL}${isPublic ? '?userId=' + userId : ''}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([{ competenceId }]),
    }).then((response) => {
      if (response.ok) {
        dispatch(setSkillExpandedId(competenceId));
        dispatch(setSkillExpandingId(-1));
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchPublicData = createAsyncThunk(
  'search/fetchPublicData',
  async ({ userId, dashboardUserId, section }, { dispatch, rejectWithValue }) => {
    const urlToUse = section === 'credentials' ? PUBLIC_CREDENTIALS_URL : PUBLIC_PROFILE_URL;
    return fetch(
      `${urlToUse}/${userId}/${dashboardUserId}${
        section === 'credentials' ? '' : '?filter=' + section
      }`,
      {
        method: 'GET',
      },
    ).then(async (response) => {
      if (response.ok) {
        const result = await response.json();

        if (section === 'skills') {
          dispatch(setSkills(result));
        }

        if (section === 'personal') {
          dispatch(
            setProfileFromPublic({
              personal: result?.personal,
              experience: result?.experience,
              education: result?.education,
              portfolio: result?.portfolio,
            }),
          );
        }

        if (section === 'pathways') {
          dispatch(setUserSelectedWorkRoles(result));
        }

        return result;
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

const filterProps = {
  selectedOptions: [],
};

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    roleProfiles: [],
    managers: [],
    pathways: [],
    locations: [],
    credentials: [],
    schools: [],
    invites: [],
    currentConnections: [],
    filteredUsers: undefined,
    userProfile: undefined,
    searchedRoleProfiles: [],
    performedInitialSearch: false,
    selectedRoleProfiles: [],
    selectedDropdownFilters: {
      roleProfiles: {
        ...filterProps,
      },
      managers: {
        ...filterProps,
      },
      pathways: {
        ...filterProps,
      },
      locations: {
        ...filterProps,
      },
      skillStatuses: {
        ...filterProps,
      },
      credentials: {
        ...filterProps,
      },
      schools: {
        ...filterProps,
      },
    },
    isFetchingRoleProfile: false,
    isSendingMessage: false,
    publicProfile: {
      personal: {},
      education: [],
      experience: [],
      portfolio: [],
      credentials: [],
      pathways: [],
      skills: [],
    },
    selectedPublicView: 'profile',
  },
  reducers: {
    setSearchedRoleProfiles: (state, action) => {
      state.searchedRoleProfiles = action.payload;
    },
    toggleIsLoggingIn: (state) => {
      state.isLoggingIn = !state.isLoggingIn;
    },
    setPerformedInitialSearch: (state, action) => {
      state.performedInitialSearch = action.payload;
    },
    setSelectedRoleProfiles: (state, action) => {
      state.selectedRoleProfiles = action.payload;
    },
    setSelectedDropdownFilters: (state, action) => {
      state.selectedDropdownFilters = action.payload;
    },
    setIsFetchingRoleProfile: (state, action) => {
      state.isFetchingRoleProfile = action.payload;
    },
    setIsSendingMessage: (state, action) => {
      state.isSendingMessage = action.payload;
    },
    setSelectedPublicView: (state, action) => {
      state.selectedPublicView = action.payload;
    },
  },
  extraReducers: {
    [fetchRoleProfiles.fulfilled]: (state, action) => {
      state.roleProfiles = action.payload;
    },
    [fetchRoleProfile.fulfilled]: (state, action) => {
      const objIndex = state.roleProfiles.findIndex((obj) => obj.id === action.payload.id);
      state.roleProfiles[objIndex] = action.payload;
      state.isFetchingRoleProfile = false;
    },
    [fetchManagers.fulfilled]: (state, action) => {
      state.managers = action.payload;
    },
    [fetchPathways.fulfilled]: (state, action) => {
      state.pathways = action.payload;
    },
    [fetchLocations.fulfilled]: (state, action) => {
      state.locations = action.payload;
    },
    [fetchCredentials.fulfilled]: (state, action) => {
      state.credentials = action.payload;
    },
    [fetchSchools.fulfilled]: (state, action) => {
      state.schools = action.payload;
    },
    [fetchFilterQuery.fulfilled]: (state, action) => {
      state.filteredUsers = action.payload;
    },
    [fetchUserProfile.fulfilled]: (state, action) => {
      state.userProfile = action.payload;
    },
    [fetchInvites.fulfilled]: (state, action) => {
      state.invites = action.payload;
    },
    [fetchWalletSharingSettings.fulfilled]: (state, action) => {
      state.walletSharingSettings = action.payload;
    },
    [createConnection.fulfilled]: (state, action) => {
      state.currentConnections = [...state.currentConnections, action.payload];
      state.invites = [...state.invites, action.payload];
    },

    [fetchPublicData.fulfilled]: (state, action) => {
      state.publicProfile[action.meta.arg.section] = action.payload;
    },
    [fetchPublicSkillDetails.fulfilled]: (state, action) => {
      const updatedSkill = action.payload[0];
      const skillIndex = state.publicProfile.skills.findIndex(
        (skill) => skill.competenceId === updatedSkill?.competenceId,
      );

      if (skillIndex > -1) {
        state.publicProfile.skills[skillIndex] = {
          ...state.publicProfile.skills[skillIndex],
          ...updatedSkill,
        };
      }
    },
  },
});

export const {
  toggleIsLoggingIn,
  setSearchedRoleProfiles,
  setPerformedInitialSearch,
  setSelectedRoleProfiles,
  setSelectedDropdownFilters,
  setIsFetchingRoleProfile,
  setIsSendingMessage,
  setSelectedPublicView,
} = searchSlice.actions;

export default searchSlice.reducer;
