import React from 'react';
import { SimpleIcon } from 'common/components';
import './Pill.scss';

export const Pill = ({ className, label, type, icon }) => {
  return (
    <span
      className={`iq4-pill ${className ? className : ''} ${
        type ? `iq4-pill--${type.toString().replace(' ', '_')}` : ''
      }`}
    >
      {icon && <SimpleIcon name={icon} role="presentation" aria-hidden="true" />}
      {label}
    </span>
  );
};
