import React from 'react';
import { useSelector } from 'react-redux';

const SkillHeader = ({ children }) => {
  const moduleConfigs = useSelector(
    (state) => state.skillsModule.moduleConfigs
  );

  return (
    <div
      className="iq4-skill-card-skill iq4-skills-card-body-row"
      style={{
        borderBottom: '1px solid #cfcfcf',
      }}
    >
      <div
        className="iq4-skills-card-body-col iq4-skills-card-body-col-skills"
        style={{
          fontSize: '14px',
          textDecoration: 'underline',
          paddingTop: '16px',
        }}
      >
        {children}
      </div>
      {moduleConfigs.includeTools && (
        <div className="iq4-skills-card-body-col iq4-skills-card-body-col-tools" />
      )}

      <div className="iq4-skills-card-body-col iq4-skills-card-body-col-assessments" />

      {moduleConfigs.includeRecommendedCourses && (
        <div className="iq4-skills-card-body-col iq4-skills-card-body-col-recommended-courses" />
      )}
    </div>
  );
};

export default SkillHeader;
