import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  DashboardTable,
  DashboardTableSkillCell,
  DashboardTableDataCell,
  SimpleIcon,
  UserProfileCredentialsSimple,
  DashboardModal,
  DashboardList,
  Pill,
} from 'common/components';
import {
  selectUserPersonal,
  selectUserCredentials,
  selectUserPathways,
  selectUserSkills,
} from 'store/features/search/selectors';
import { fetchUserProfile } from 'store/features/search/searchSlice';
import { COLUMN_TYPE, TABLE_SORT_TYPE, SOURCE_TYPES } from 'common/constants/miscellaneous';
import { ROUTES } from 'routes';
import './SearchPageTable.scss';

export const SearchPageTable = ({ users, isLoading }) => {
  const dispatch = useDispatch();

  const userPersonal = useSelector(selectUserPersonal);
  const userCredentials = useSelector(selectUserCredentials);
  const userPathways = useSelector(selectUserPathways);
  const userSkills = useSelector(selectUserSkills);

  const [currentTableModalIndex, setCurrentTableModalIndex] = useState(0);
  const [selectedResultsItem, setSelectedResultsItem] = useState(null);

  const tableDefinition = {
    columns: [
      {
        prop: 'name',
        width: '32%',
        type: COLUMN_TYPE.CUSTOM,
        align: 'left',
        isSearchable: true,
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        columnLabel: (
          <div className="iq4-dashboard-filter-results__table-header-cell">
            <span>Skills</span>
          </div>
        ),
        renderComponent: ({ row }) => (
          <div className="iq4-search-page-table__first-col">
            <Link
              className="iq4-search-page-table__first-col--link"
              to={`${ROUTES.USER_PROFILE}/${row.id}`}
            >
              <div className="iq4-search-page-table__first-col--left">
                <DashboardTableSkillCell label={row.name} />
                {row.origin === SOURCE_TYPES.EXTERNAL && <Pill label={row.origin} />}
              </div>
              <SimpleIcon className="iq4-search-page-table__first-col--icon" name="arrow" />
            </Link>
          </div>
        ),
      },
      {
        prop: 'topRoleProfile',
        width: '17%',
        type: COLUMN_TYPE.CUSTOM,
        isSortable: true,
        sortFunc: (row) => {},
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        columnLabel: 'Role',
        renderComponent: ({ row }) => (
          <span style={{ textAlign: 'left', width: '100%' }}>{row.topRoleProfile}</span>
        ),
      },
      {
        prop: 'credentialsCount',
        width: '17%',
        type: COLUMN_TYPE.CUSTOM,
        isSortable: true,
        sortFunc: (row) => (row.credentialsCount ? row.credentialsCount : 0),
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        columnLabel: 'Credentials',
        renderComponent: ({ row }) => (
          <DashboardTableDataCell
            content={row.credentialsCount ? row.credentialsCount : 0}
            onClick={() =>
              handleClickResultsItem({
                ...row,
                type: 'credentials',
                title: 'Credentials',
                count: row.credentialsCount,
              })
            }
          />
        ),
      },
      {
        prop: 'pathwaysCount',
        width: '17%',
        type: COLUMN_TYPE.CUSTOM,
        isSortable: true,
        sortFunc: (row) => (row.pathwaysCount ? row.pathwaysCount : 0),
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        columnLabel: 'Pathways',
        renderComponent: ({ row }) => (
          <DashboardTableDataCell
            content={row.pathwaysCount ? row.pathwaysCount : 0}
            onClick={() =>
              handleClickResultsItem({
                ...row,
                title: 'Pathways',
                type: 'pathways',
                count: row.pathwaysCount,
              })
            }
          />
        ),
      },
      {
        prop: 'skillsCount',
        width: '17%',
        type: COLUMN_TYPE.CUSTOM,
        isSortable: true,
        sortFunc: (row) => (row.skillsCount ? row.skillsCount : 0),
        sortType: TABLE_SORT_TYPE.DOUBLE_ARROW,
        columnLabel: 'Skills',
        renderComponent: ({ row }) => (
          <DashboardTableDataCell
            content={row.skillsCount ? row.skillsCount : 0}
            onClick={() =>
              handleClickResultsItem({
                ...row,
                title: 'Skills',
                type: 'skills',
                count: row.skillsCount,
              })
            }
          />
        ),
      },
    ],
  };

  const modalNavItems = [
    { id: 1, label: 'Credentials' },
    { id: 2, label: 'Pathways' },
    { id: 3, label: 'Skills' },
  ];

  const handleCloseResultsItemModal = () => {
    setSelectedResultsItem(null);
  };

  const handleTableModalNavChange = (type) => {
    const newIndex = type === 'next' ? currentTableModalIndex + 1 : currentTableModalIndex - 1;
    setCurrentTableModalIndex(newIndex);

    const modalDetailsMap = {
      0: {
        type: 'credentials',
        title: 'Credentials',
        count: userCredentials.length,
      },

      1: {
        title: 'Pathways',
        type: 'pathways',
        count: userPathways.length,
      },

      2: {
        title: 'Skills',
        type: 'skills',
        count: userSkills.length,
      },
    };

    setSelectedResultsItem({
      ...selectedResultsItem,
      ...modalDetailsMap[newIndex],
    });
  };

  const handleClickResultsItem = (item) => {
    dispatch(fetchUserProfile(item.id));

    const itemToModalNavIndex = {
      credentials: 0,
      pathways: 1,
      skills: 2,
    };
    setCurrentTableModalIndex(itemToModalNavIndex[item.type]);
    setSelectedResultsItem(item);
  };

  const getCurrentModalContent = () => {
    switch (currentTableModalIndex) {
      case 0:
        return <UserProfileCredentialsSimple noLink credentials={userCredentials} />;
      case 1:
        return (
          <DashboardList
            isExpandable={true}
            list={userPathways}
            type="pathway"
            institution={userPersonal ? `${userPersonal.firstName} ${userPersonal.lastName}` : ''}
          />
        );
      case 2:
        return <DashboardList isExpandable={false} list={userSkills} type="skill" />;
      default:
        return null;
    }
  };

  const renderHeaderActions = (includeHeaderActions = true) => {
    if (!includeHeaderActions) return null;

    return (
      <div className="iq4-search-page__header-action">
        <Button className="iq4-search-page__header-action--disabled iq4-search-page__header-action--buttons iq4-search-page__header-action--filter">
          <SimpleIcon name="filter" className="iq4-search-page__header-action--icons" /> Save this
          Filter
        </Button>
        <Button className="iq4-search-page__header-action--disabled iq4-search-page__header-action--buttons iq4-search-page__header-action--export">
          <SimpleIcon name="fileExport" className="iq4-search-page__header-action--icons" /> Export
        </Button>
      </div>
    );
  };

  return (
    <>
      <DashboardTable
        tableDefinition={tableDefinition}
        showDataInfo
        tableTitle="Talent"
        useVirtualized
        isNextPreviousIconOnly
        rows={users}
        headerAction={() => renderHeaderActions()}
        isLoading={isLoading}
        emptyState={<p>No results found.</p>}
      />
      <DashboardModal
        title={selectedResultsItem && selectedResultsItem.title}
        titleCount={selectedResultsItem && selectedResultsItem.count}
        isOpen={!!selectedResultsItem}
        navList={modalNavItems}
        currentNavState={currentTableModalIndex}
        onNavChange={handleTableModalNavChange}
        onClose={() => handleCloseResultsItemModal()}
      >
        {getCurrentModalContent()}
      </DashboardModal>
    </>
  );
};
