import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import './Uploader.scss';

/**
 * This component uploads a binary file
 * @param handleUpload
 * @param fileTypes
 * @param children
 * @returns {*}
 * @constructor
 */
export function Uploader({ handleUpload, fileTypes, children }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleUpload(acceptedFiles);
    },
    [handleUpload],
  );
  const { getRootProps, getInputProps } = useDropzone({
    noDrag: true,
    onDrop,
    accept: fileTypes,
  });

  return (
    <>
      <div className="iq4-uploader" {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
      </div>
    </>
  );
}

Uploader.propTypes = {
  handleUpload: PropTypes.func.isRequired,
};
