import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import { selectSkillExpandingId, selectSkillExpandedId } from 'common/store/features/ui/selectors';
import { setSkillExpandingId, setSkillExpandedId } from 'common/store/features/ui/uiSlice';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { orderBy } from 'lodash';
import { SORT_DIRECTION } from 'common/utils/constants';
import './ManageSkillsCard.scss';
import {
  SimpleIcon,
  SkillCard,
  LoadingSkeleton,
  Button,
  Toast,
  Paginator,
} from 'common/components';
import { ManageSkillsCardFilter } from './ManageSkillsCardFilter';
import { ManageSkillsCardSort } from './ManageSkillsCardSort';
import { FilterBanner } from './FilterBanner';
import EmptyStateImage from '@assets/svg/empty-states_skills.svg';
import {
  DEFAULT_SKILLS_FILTER,
  DEFAULT_SKILLS_SORT,
  DEFAULT_TABLE_PAGE_SIZE,
  SKILLS_SORT_FUNCS,
} from 'common/constants/miscellaneous';
import { runGenericFilter } from 'common/utils';
import { removeNewlyAddedFlag, fetchSkillDetails } from 'common/store/features/skills/skillsSlice';
import { selectFeatures } from 'common/store/features/config/selectors';
import { SelectPageSize } from '../DataTable/SelectPageSize';
import _orderBy from 'lodash/orderBy';
import { selectIsPublicProfile } from '../../store/features/profile/selectors';
import { useParams } from 'react-router-dom';
import { fetchPublicSkillDetails } from '../../../store/features/search/searchSlice';

/**
 * Manage Skills Card - holds control of skills filtering & sorting
 * @param skills
 * @returns {*}
 * @constructor
 */
export const ManageSkillsCard = ({ skills, userId }) => {
  const { selfAttestedEnabled } = useSelector(selectFeatures);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const [currentSort, setCurrentSort] = useState(() => getCurrentSort(selfAttestedEnabled));
  const [currentFilter, setCurrentFilter] = useState(DEFAULT_SKILLS_FILTER);
  const [skillsRefs, setSkillsRefs] = useState([]);
  const [scrollToElement, setScrollToElement] = useState(null);
  const [paginationData, setPaginationData] = useState({ currentPage: 1, pageLimit: rowsPerPage });
  const [currentSkills, setCurrentSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState(
    orderBy(skills, (s) => s.tier3Label, SORT_DIRECTION.ASC),
  );

  const dispatch = useDispatch();

  const expandedId = useSelector(selectSkillExpandedId);
  const expandingId = useSelector(selectSkillExpandingId);
  const isBootstrapping = false;
  const isPublicProfile = true;
  const hasLoaded = !isBootstrapping;
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));
  const { id } = useParams();

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);

  useEffect(() => {
    const hasAnyFilter = Object.keys(currentFilter).some((k) => currentFilter[k]);

    if (hasAnyFilter) {
      applyFilters(currentFilter, true);
    } else {
      const activeSort = getActiveSort(currentSort);
      const newFilteredSkills = SKILLS_SORT_FUNCS[activeSort](skills);
      setFilteredSkills(newFilteredSkills);

      const refs = newFilteredSkills.reduce((acc, value) => {
        acc[value.competenceId] = React.createRef();
        return acc;
      }, {});

      setSkillsRefs(refs);
    }
  }, [skills, dispatch, currentSort]);

  useEffect(() => {
    if (scrollToElement && skillsRefs[scrollToElement].current) {
      skillsRefs[scrollToElement].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScrollToElement(null);
    }
  }, [scrollToElement, skillsRefs]);

  const expandSkill = (skill) => {
    // check if it's currently expanded, and collapse
    if (skill.competenceId === expandedId) {
      dispatch(setSkillExpandedId(-1));
    } else if (skill.tier1) {
      dispatch(setSkillExpandedId(skill.competenceId));
    } else {
      dispatch(setSkillExpandingId(skill.competenceId));
      dispatch(
        fetchPublicSkillDetails({
          competenceId: skill.competenceId,
          isPublic: !!isPublicProfile,
          userId: userId,
        }),
      );
    }
  };

  const updateSkillsData = (data) => {
    const { currentPage, pageLimit } = data;
    const activeSort = getActiveSort(currentSort);
    const newOrderedRows = _orderBy(filteredSkills, [activeSort], [currentSort.direction]);
    const offset = (currentPage - 1) * pageLimit;

    const newCurrentSkills =
      pageLimit === skills.length
        ? newOrderedRows
        : newOrderedRows.slice(offset, offset + pageLimit);

    setPaginationData({
      ...paginationData,
      ...data,
    });

    setCurrentSkills(newCurrentSkills);
  };

  useEffect(() => {
    if (filteredSkills.length <= rowsPerPage) {
      updateSkillsData({ currentPage: 1, pageLimit: rowsPerPage });
    }
  }, [filteredSkills, rowsPerPage]);

  useEffect(() => {
    if (paginationData) {
      updateSkillsData(paginationData);
    }
  }, [currentSort, filteredSkills, skills]);

  const getActiveSort = (sort) => Object.keys(sort).find((key) => sort[key]);

  const manageChangeFilter = (currentFilter) => {
    setCurrentFilter(currentFilter);
    applyFilters(currentFilter);
  };

  const manageChangeSort = (newCurrentSort) => {
    setCurrentSort(newCurrentSort);
    const newSort = getActiveSort(newCurrentSort);
    applySort(newSort);
  };

  const clearAllFilters = () => {
    setCurrentFilter(DEFAULT_SKILLS_FILTER);
    const newFilteredSkills = orderBy(skills, (s) => s.tier3Label, SORT_DIRECTION.ASC);
    setFilteredSkills(newFilteredSkills);
  };

  const handlePageSizeChange = (value) => setRowsPerPage(value);

  const applySort = (newSort, target = filteredSkills) =>
    setFilteredSkills(SKILLS_SORT_FUNCS[newSort](target));

  function filterCategory(inputSkills, inputFilter) {
    const categoryProps = [
      'technologyCategory',
      'professionalSkillsCategory',
      'businessDevelopmentCategory',
    ];
    const hasSomeFilter =
      inputFilter.technologyCategory ||
      inputFilter.professionalSkillsCategory ||
      inputFilter.businessDevelopmentCategory;

    // if no filter is applied just return input
    if (!hasSomeFilter) return inputSkills;
    return runGenericFilter({ categoryProps, inputFilter, inputSkills });
  }

  function filterOutcomeType(inputSkills, inputFilter) {
    const categoryProps = ['outcomeTypeCommon', 'outcomeTypeHard'];
    const hasSomeFilter = inputFilter.outcomeTypeCommon || inputFilter.outcomeTypeHard;

    // if no filter is applied just return input
    if (!hasSomeFilter) return inputSkills;

    return runGenericFilter({ categoryProps, inputFilter, inputSkills });
  }

  function filterProficiencies(inputSkills, inputFilter) {
    const categoryProps = [
      'proficiencyOne',
      'proficiencyTwo',
      'proficiencyThree',
      'proficiencyFour',
    ];
    const hasSomeFilter =
      inputFilter.proficiencyOne ||
      inputFilter.proficiencyTwo ||
      inputFilter.proficiencyThree ||
      inputFilter.proficiencyFour;

    // if no filter is applied just return input
    if (!hasSomeFilter) return inputSkills;
    return runGenericFilter({ categoryProps, inputFilter, inputSkills });
  }

  const applyFilters = (currentFilter, refreshRefs) => {
    const filteredByCategory = filterCategory(skills, currentFilter);
    const filteredByOutcomeTypes = filterOutcomeType(filteredByCategory, currentFilter);
    const filteredByProficiencies = filterProficiencies(filteredByOutcomeTypes, currentFilter);

    if (refreshRefs) {
      const refs = filteredByProficiencies.reduce((acc, value) => {
        acc[value.competenceId] = React.createRef();
        return acc;
      }, {});

      setSkillsRefs(refs);
    }

    const activeSort = getActiveSort(currentSort);
    applySort(activeSort, filteredByProficiencies);
  };

  const onRemoveFilter = (prop) => {
    const newFilter = {
      ...currentFilter,
      [prop]: false,
    };
    setCurrentFilter(newFilter);
    applyFilters(newFilter);
  };

  const goToPageAndScroll = (page, skill) => {
    updateSkillsData({ currentPage: page, pageLimit: rowsPerPage });

    if (skill.tier1) {
      dispatch(setSkillExpandedId(skill.competenceId));
    } else {
      dispatch(setSkillExpandingId(skill.competenceId));
      dispatch(fetchSkillDetails({ competenceId: skill.competenceId }));
    }

    dispatch(removeNewlyAddedFlag(skill.competenceId));
    setScrollToElement(skill.competenceId);
  };

  const newlyAddedSkills = filteredSkills.filter((skill) => skill.newlyAdded);

  const getToastContent = () => {
    return newlyAddedSkills.map((skill) => {
      const skillIndex = filteredSkills.findIndex((s) => s.competenceId === skill.competenceId);
      const whichPageIsSkillIn = Math.floor(skillIndex / paginationData.pageLimit) + 1;

      return (
        <div className="iq4-manage-skills__toast-content" key={skill.competenceId}>
          You have added <strong>{skill.tier3Label}</strong> to your Manage Skills list <br />
          <span
            className="iq4-manage-skills__toast-view"
            onClick={() => goToPageAndScroll(whichPageIsSkillIn, skill)}
          >
            View it now
          </span>{' '}
          or edit later
        </div>
      );
    });
  };

  const dismissAllNewlyAddedSkills = () => {
    newlyAddedSkills.forEach((skill) => {
      dispatch(removeNewlyAddedFlag(skill.competenceId));
    });
  };

  function getIsMobile(breakpoint) {
    return breakpoint === 'xs';
  }

  function getCurrentSort(selfAttestedEnabled) {
    if (selfAttestedEnabled) return DEFAULT_SKILLS_SORT;

    const { lowToHigh: _, highToLow: __, ...allExProf } = DEFAULT_SKILLS_SORT;

    return allExProf;
  }

  return (
    <div
      className={`iq4-manage-skills-card ${
        hasLoaded && skills.length === 0 && 'iq4-manage-skills-card--empty'
      }`}
    >
      {!!newlyAddedSkills.length && (
        <Toast title="new skill added" onClose={dismissAllNewlyAddedSkills}>
          {getToastContent()}
        </Toast>
      )}
      <div className="iq4-manage-skills-card__header">
        <div className="iq4-manage-skills-card__header--left">
          <div className="iq4-manage-skills-card__header-container">
            {!isMobile && (
              <>
                <SimpleIcon
                  className="iq4-manage-skills-card__header-icon"
                  name="skillsCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-manage-skills-card__header-title">Skills</h2>
                {hasLoaded ? (
                  <>
                    <span className="iq4-manage-skills-card__length">{skills.length}</span>
                  </>
                ) : (
                  <LoadingSkeleton
                    variant="circle"
                    width={20}
                    height={20}
                    style={{ display: 'inline-flex', justifyContent: 'center' }}
                  />
                )}
              </>
            )}
            {skills.length > 0 && (
              <div className="iq4-manage-skills__pagination">
                <ManageSkillsCardSort
                  currentSort={currentSort}
                  onChangeSort={manageChangeSort}
                  hasProfFilter={false}
                />
                {!isMobile && (
                  <div className="iq4-datatable__paginator-container">
                    <SelectPageSize
                      handlePageSizeChange={handlePageSizeChange}
                      rows={filteredSkills}
                      rowsPerPage={rowsPerPage}
                    />
                    <span
                      className="iq4-datatable__view-all"
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => handlePageSizeChange(filteredSkills.length)}
                      onClick={() => handlePageSizeChange(filteredSkills.length)}
                    >
                      View All
                    </span>
                    {filteredSkills.length > rowsPerPage && (
                      <Paginator
                        totalRecords={filteredSkills.length}
                        onPageChanged={updateSkillsData}
                        pageLimit={rowsPerPage}
                        outCurrentPage={paginationData ? paginationData.currentPage : null}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {skills.length > 0 && !isPublicProfile && (
          <ManageSkillsCardFilter
            currentFilter={currentFilter}
            onChangeFilter={manageChangeFilter}
            hasProfFilter={false}
          />
        )}
      </div>
      <div className="iq4-manage-skills-card__content">
        <FilterBanner currentFilter={currentFilter} onRemoveFilter={onRemoveFilter} />
        {hasLoaded ? (
          <>
            {(isMobile ? filteredSkills : currentSkills).map((skill, index) => (
              <div
                className="iq4-manage-skills-card__skill-card"
                key={skill.competenceId}
                ref={skillsRefs[skill.competenceId]}
              >
                <SkillCard
                  skill={skill}
                  handleClick={() => expandSkill(skill)}
                  isExpanding={skill.competenceId === expandingId}
                  isExpanded={skill.competenceId === expandedId}
                  key={skill.competenceId}
                  index={index}
                  readOnly={!selfAttestedEnabled}
                />
              </div>
            ))}
            {skills.length === 0 && (
              <div className="iq4-manage-skills-card__empty-state">
                <p>You currently have no skills</p>
                <img
                  className="iq4-manage-skills-card__empty-state-image"
                  src={EmptyStateImage}
                  alt=""
                />
              </div>
            )}
            {!!(!filteredSkills.length && skills.length) && (
              <div className="iq4-manage-skills-card__no-results-container">
                <div className="iq4-manage-skills-card__no-results">no results returned!</div>
                <div className="iq4-manage-skills-card__no-results">
                  TRY DISMISSING SOME OF THE FILTER TERMS ABOVE OR TRY AGAIN
                </div>
                <div className="iq4-manage-skills-card__no-results-normal">or</div>
                <div className="iq4-manage-skills-card__no-results-normal">Clear filters</div>
                <div className="iq4-manage-skills-card__no-results-normal">
                  <Button variation="ghost" onClick={clearAllFilters}>
                    Clear
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
          </>
        )}
      </div>
    </div>
  );
};
