import React from 'react';

import { JobReqCandidateCard } from 'common/components';

import './JobReqCandidatesTable.scss';

export const JobReqCandidatesTable = ({ candidates }) => {
  return (
    <div className="iq4-job-req-candidate-table">
      {!candidates?.length && (
        <p>
          At this time, we do not have any recommended candidates. To improve your chances of
          finding suitable candidates, please add additional skills to your search criteria.
        </p>
      )}
      {!!candidates?.length && (
        <>
          <div className="iq4-job-req-candidate-table__header">
            <div className="iq4-job-req-candidate-table__header-col">Name</div>
            {/* <div className="iq4-job-req-candidate-table__header-col">Status</div>
            <div className="iq4-job-req-candidate-table__header-col">Source</div>
            <div className="iq4-job-req-candidate-table__header-col">Grade</div> */}
            <div className="iq4-job-req-candidate-table__header-col">Role Skills</div>
            <div className="iq4-job-req-candidate-table__header-col">Matched Skills</div>
            <div className="iq4-job-req-candidate-table__header-col">Actions</div>
          </div>

          {candidates.map((c) => {
            return <JobReqCandidateCard key={c.id} user={c} pill="BEST FIT" />;
          })}
        </>
      )}
    </div>
  );
};
