import React from 'react';
import { RatingStars, SimpleIcon } from 'common/components';
import { SELF_ASSESSMENT_ASSESSEE_ID } from 'common/constants/identifiers';
import './SkillProficiency.scss';

/**
 * Proficiency Tab: contains self assessment + additional contributions (if any)
 * @param skill
 * @returns {*}
 * @constructor
 */
export const SkillProficiency = ({ skill, onSelfAssessment, readOnly = false }) => {
  const assessments = skill.assessments || [];
  const additionalContributions = assessments.filter(
    (s) => s.assesseeId !== SELF_ASSESSMENT_ASSESSEE_ID,
  );
  const selfAssessment = assessments.find((s) => s.assesseeId === SELF_ASSESSMENT_ASSESSEE_ID);

  const handleSelfAssessment = (e) => {
    if (readOnly) return;

    onSelfAssessment({
      competenceId: skill.competenceId,
      assessment: parseInt(e.target.value),
    });
  };

  const getOverallAverage = (arr) => {
    const sum = arr.reduce((total, currentValue) => total + currentValue, 0);
    return sum / arr.length;
  };

  return (
    <>
      <div className="iq4-skill-card__proficiency--container">
        <div className="iq4-skill-card__proficiency--content">
          <span className="iq4-skill-card__proficiency--label">your self assessment</span>
          <span className="iq4-skill-card__proficiency--rating">
            <SimpleIcon name="personCircle" />
            <span className="iq4-skill-card__proficiency--rating-star">
              <RatingStars
                readOnly={readOnly}
                value={(selfAssessment && selfAssessment.assessment) || 0}
                isSelf
                onValueSelect={handleSelfAssessment}
              />
            </span>
          </span>
        </div>
        <div className="iq4-skill-card__proficiency--content">
          <span className="iq4-skill-card__proficiency--label">overall skill proficiency</span>
          <span className="iq4-skill-card__proficiency--rating">
            <RatingStars value={getOverallAverage(assessments.map((s) => s.assessment))} readOnly />
          </span>
        </div>
      </div>
      {!!additionalContributions.length && (
        <div className="iq4-skill-card__proficiency--additional">
          <div className="iq4-skill-card__proficiency--label">additional contributions</div>
          <div className="iq4-skill-card__proficiency--item-container">
            {additionalContributions.map((a) => (
              <div className="iq4-skill-card__proficiency--additional-item">
                <div className="iq4-skill-card__proficiency--content">
                  <span className="iq4-skill-card__proficiency--additional-label">
                    {a.assesseeName}
                  </span>
                  <span className="iq4-skill-card__proficiency--rating">
                    <RatingStars value={a.assessment} readOnly />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
