import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';

const SkillsCard = ({
  children,
  className = null,
  onCardOpen = null,
  tier1,
  assessmentWriteType = 'SELF',
  ...props
}) => {
  const [showCardBody, toggleCardBody] = useState(true);
  const [addMoreSkills, toggleAddMoreSkills] = useState(false);

  // logic for closing the card.
  const handleToggleCardBody = () => {
    // close modal if modal is active && user is closing card body
    if (showCardBody && addMoreSkills) {
      toggleAddMoreSkills(false);
    }

    toggleCardBody(!showCardBody);

    // let parent know if handler is mentioned in props
    if (onCardOpen) {
      onCardOpen(!showCardBody);
    }
  }; // end handleToggleCardBody

  return (
    <div className={`iq4-skills-card${className ? ' ' + className : ''}`} {...props}>
      {children({
        showCardBody,
        addMoreSkills,
        toggleAddMoreSkills,
        toggleCardBody,
      })}

      <div className="iq4-skills-card-footer">
        {false && assessmentWriteType === 'SELF' && showCardBody && (
          <Button
            type="link"
            style={{ textDecoration: 'underline', padding: '20px' }}
            onClick={() => void toggleAddMoreSkills((addMoreSkills) => !addMoreSkills)}
          >
            Add more skills to {tier1.label}
          </Button>
        )}
        <Button
          type="link"
          aria-label={`Expand ${tier1.label} card`}
          aria-expanded={showCardBody ? 'true' : 'false'}
          className="iq4-skills-card-toggle-body"
          onClick={handleToggleCardBody}
          style={{
            textDecoration: 'underline',
            bottom: showCardBody ? '20px' : '10px',
          }}
        >
          {showCardBody ? 'Close' : 'Expand'}
        </Button>
      </div>
    </div>
  );
};

SkillsCard.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SkillsCard;
