import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import { X } from '../Icon/Icon';

const Modal = ({
  renderHeader = null,
  renderFooter = null,
  handleClose = null,
  children,
  ...props
}) => (
  <ReactModal {...props}>
    {(renderHeader || handleClose) && (
      <div className="iq4-skills-modal-header">
        {renderHeader && renderHeader()}

        {handleClose && (
          <Button
            aria-label="Close modal"
            className="iq4-skills-modal-close-btn"
            type="link"
            onClick={handleClose}
          >
            <X />
          </Button>
        )}
      </div>
    )}

    <div className="iq4-skills-modal-body">{children()}</div>

    {renderFooter && (
      <div className="iq4-skills-modal-footer">{renderFooter()}</div>
    )}
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  renderHeader: PropTypes.func,
  renderHeader: PropTypes.func
};

export default Modal;
