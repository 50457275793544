import {
  SET_SKILLS_LOADER,
  SET_DEFAULT_LOADER,
  SET_SKILLS_UPDATE
} from '../../actionTypes';

const initialState = {
  savingSkills: null, // 'LOADING' || 'SUCCESS' || 'ERROR'
  certificates: {
    loading: false,
    success: null,
    error: null
  },

  additionalSkillsRole: {
    loading: false,
    success: null,
    error: null
  },

  skills: {} // [{ t1ID: Number, loading: Boolean, success: dynamic, error: dynamic }, ...]
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SKILLS_LOADER:
      return { ...state, [action.payload.key]: action.payload.value };
    case SET_SKILLS_UPDATE:
      return {
        ...state,
        skills: {
          ...state.skills,
          [action.payload.t1ID]: action.payload
        }
      };
    case SET_DEFAULT_LOADER:
      if (action.payload.success) {
        return {
          ...state,
          [action.payload.key]: {
            loading: false,
            success: action.payload.success,
            error: false
          }
        };
      }

      if (action.payload.error) {
        return {
          ...state,
          [action.payload.key]: {
            loading: false,
            success: false,
            error: action.payload.error
          }
        };
      }

      if (action.payload.loading) {
        return {
          ...state,
          [action.payload.key]: {
            loading: action.payload.loading,
            success: null,
            error: null
          }
        };
      }

      return state;
    default:
      return state;
  }
};
