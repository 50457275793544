import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserProfile } from 'store/features/search/selectors';
import Skeleton from '@material-ui/lab/Skeleton';
import placeholderAvatar from '@assets/svg/placeholder-avatar.svg';
import './ProfileAvatar.scss';

export const ProfileAvatar = ({ onAvatarClick, label = '' }) => {
  const { personal } = useSelector(selectUserProfile);

  const hasPhoto = !!(personal && personal.userProfilePhoto && personal.userProfilePhoto.image);
  // const userAvatar = useSelector(selectAvatar);
  const userAvatar = hasPhoto ? personal?.userProfilePhoto?.image : placeholderAvatar;

  return (
    <div className="iq4-profile-avatar">
      <button className={`iq4-profile-avatar__image-wrapper`} onClick={onAvatarClick}>
        {!userAvatar && <Skeleton variant="circle" style={{ width: '100%', height: '100%' }} />}

        {userAvatar && (
          <>
            {hasPhoto && (
              <img className="iq4-profile-avatar__image" src={userAvatar} alt="Avatar" />
            )}

            {!hasPhoto && (
              <img
                className="iq4-profile-avatar__image-empty"
                src={userAvatar}
                alt="Empty avatar"
              />
            )}
          </>
        )}
        {label && <p className="iq4-profile-avatar__label">{label}</p>}
      </button>
    </div>
  );
};
