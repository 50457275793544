import React, { useState } from 'react';
import {
  InnerTabs,
  SkillsStatsChart,
  SkillsLegendTile,
  DashboardList,
  TalentList,
} from 'common/components';
import { CoreTasks, OpenPositions } from 'common/components/Pathways/Panels';
import './DashboardRole.scss';

export const DashboardRole = ({
  role,
  institution,
  showStudents = false,
  onItemClick = () => {},
}) => {
  const [selectedSkillType, setSelectedSkillType] = useState('matched');

  const getSkillsMetrics = (role) => {
    return [
      {
        type: 'matched',
        count:
          role.skills && role.skills.overlapSkills
            ? role.skills.overlapSkills.length
            : role.skills
            ? role.skills.filter((skill) => skill.type === 'matched').length
            : 0,
        label: (
          <div className="iq4-dashboard-role__skill-legend-item">
            <SkillsLegendTile type="matched" /> Matched
          </div>
        ),
        description: 'This is the description for matched skills',
      },
      {
        type: 'missing',
        count:
          role.skills && role.skills.roleSkills
            ? role.skills.roleSkills.length
            : role.skills
            ? role.skills.filter((skill) => skill.type === 'missing').length
            : 0,
        label: (
          <div className="iq4-dashboard-role__skill-legend-item">
            <SkillsLegendTile type="missing" /> Missing
          </div>
        ),
        description: 'This is the description for missing skills',
      },
    ];
  };

  return (
    <>
      <div className="iq4-dashboard-role">
        <div className="iq4-dashboard-role__item">
          <h4 className="iq4-dashboard-role__item-heading">Description</h4>
          <p>{role.description}</p>
        </div>
        <InnerTabs
          className="iq4-dashboard-role__tabs"
          data={[
            ...(role.talent
              ? [
                  {
                    title: 'Talent',
                    icon: 'student',
                    content: (
                      <TalentList
                        list={role.talent}
                        onClick={(item) => {
                          onItemClick({
                            type: 'talent',
                            meta: { id: 'talent_list_item_click' },
                            item,
                          });
                        }}
                      />
                    ),
                  },
                ]
              : []),
            ...(role.jobTasks
              ? [
                  {
                    title: 'Core Tasks',
                    icon: 'portfolioCircle',
                    content: <CoreTasks tasks={role.jobTasks} />,
                    disabled: !role.jobTasks || role.jobTasks.length === 0,
                  },
                ]
              : []),
            ...(role.requiredSkills
              ? [
                  {
                    title: 'Required Skills',
                    icon: 'skillsCircle',
                    content: (
                      <div className="iq4-dashboard-role__required-skills">
                        {role.requiredSkills && role.requiredSkills.length > 0 && (
                          <div className="iq4-dashboard-role__required-skills__list">
                            <DashboardList list={role.requiredSkills} type="skill" />
                          </div>
                        )}
                      </div>
                    ),
                  },
                ]
              : []),
            ...(role.skills
              ? [
                  {
                    title: 'Required Skills',
                    icon: 'skillsCircle',
                    content: (
                      <div className="iq4-dashboard-role__required-skills">
                        <SkillsStatsChart
                          skillsMetrics={getSkillsMetrics(role)}
                          onClick={(bar) => setSelectedSkillType(bar.type)}
                          institution={institution}
                        />
                        {role.skills && role.skills.length > 0 && (
                          <div className="iq4-dashboard-role__required-skills__list">
                            <DashboardList
                              isExpandable={false}
                              list={role.skills.filter((skill) => skill.type === selectedSkillType)}
                              type="skill"
                            />
                          </div>
                        )}
                      </div>
                    ),
                    disabled: !role.skills || role.skills.length === 0,
                  },
                ]
              : []),
            ...(role.liveRoles
              ? [
                  {
                    title: 'Open Positions',
                    icon: 'experienceCircle',
                    content: <OpenPositions positions={role.liveRoles} />,
                    disabled: !role.liveRoles || role.liveRoles.length === 0,
                  },
                ]
              : []),
            ...(showStudents && role.students
              ? [
                  {
                    title: 'Students',
                    icon: 'educationCircle',
                    content: (
                      <DashboardList isExpandable={false} list={role.students} type="student" />
                    ),
                    disabled: !role.students || role.students.length === 0,
                  },
                ]
              : []),
          ]}
        />
      </div>
    </>
  );
};
