import React from 'react';
import { SimpleIcon } from '../SimpleIcon';
import './TalentList.scss';

export const TalentList = ({ className, list, onClick }) => {
  return (
    <ul className={`iq4-talent-list ${className ? className : ''}`}>
      {list.map((item) => (
        <li key={item.label} className="iq4-talent-list__item-container">
          <button className="iq4-talent-list__item" onClick={() => onClick(item)}>
            <div className="iq4-talent-list__item-label-container">
              <SimpleIcon className="iq4-talent-list__item-icon-left" name="student" />
              <span className="iq4-talent-list__item-label">{item.label}</span>
            </div>

            <SimpleIcon className="iq4-talent-list__item-icon-right" name="arrow" />
          </button>
        </li>
      ))}
    </ul>
  );
};
