import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { LCN_CREDENTIAL_PROVIDERS } from 'common/constants/lcnRecords';
import { getBase64ImageUrl } from 'common/utils';
import { IconButton } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, PrivatePublicToggle } from 'common/components';
import { ReactComponent as Verified } from 'common/components/SimpleIcon/svg/verified.svg';

export function CredentialRow({ row, setCredentialToBeDeleted, setIsDeletingModal, setIsEdit }) {
  const dispatch = useDispatch();

  const imageURL =
    row.credentialProvider === LCN_CREDENTIAL_PROVIDERS.BADGR
      ? row.imageURL
      : row.base64schoollogo
      ? getBase64ImageUrl(row.base64schoollogo)
      : row.imageUrl || row.resources?.[0]?.fileS3Url;

  const date = moment(`${row.startYear}-${row.startMonth}-${row.startDay}`).format('MM/DD/YYYY');
  const [isExpanded, setIsExpanded] = useState(false);
  const isPublic = window.location.href.indexOf('/profile/') >= 0;

  function prepareCredentialToBeDeleted(cred) {
    setCredentialToBeDeleted(cred);
    setIsDeletingModal(true);
  }

  return (
    <div
      className={`iq4-credentials__existing-card ${
        isExpanded ? 'iq4-credentials-auto-height' : ''
      }`}
      key={row?.id}
    >
      <IconButton
        classes={{ root: 'iq4-credentials__expand' }}
        aria-label="expand row"
        size="small"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <div
        className={`iq4-credentials__card-container ${
          isExpanded ? 'iq4-credentials-auto-height' : ''
        }`}
      >
        <div className="iq4-credentials__card-container--row iq4-credentials__card-container--with-verified">
          <div className="iq4-record-image-and-label">
            {imageURL && (
              <img className="iq4-record-image-and-label__image" src={imageURL} alt="Logo" />
            )}
            <span className="iq4-record-image-and-label__label">
              {row.label}
              {row.isVerified && (
                <div className="iq4-credentials__verified-container">
                  <Verified />
                  <span></span>
                </div>
              )}
            </span>
          </div>
        </div>
        <div className="iq4-credentials__card-container--row">
          <span>{date}</span>
        </div>
        <div className="iq4-credentials__card-container--row">{row?.issuerName}</div>
        {!isPublic && (
          <div className="iq4-credentials__card-container--row">
            <PrivatePublicToggle
              displayScope={true}
              isConsentGiven={true}
              record={row}
              onSelecting={() => {}}
            />
          </div>
        )}
      </div>
      {isExpanded && (
        <div className="iq4-credential-expanded__container">
          <div className="iq4-credential-expanded__container--line iq4-credential-expanded__container--line-no-margin" />
          <div className="iq4-credential-expanded__split">
            <div>
              <div className="iq4-credential-expanded-item" style={{ marginRight: 50 }}>
                <div className="iq4-credential-expanded-item__label">Description</div>
                <div className="iq4-credential-expanded-item__content">{row.description}</div>
              </div>
              <div className="iq4-credential-expanded-item">
                <div className="iq4-credential-expanded-item__label">Issuer</div>
                <div className="iq4-credential-expanded-item__content">{row.issuerName}</div>
              </div>
              <div className="iq4-credential-expanded-item">
                <div className="iq4-credential-expanded-item__label">Award Date</div>
                <div className="iq4-credential-expanded-item__content">
                  {moment(`${row.startYear}-${row.startMonth}-${row.startDay}`).format(
                    'MM/DD/YYYY',
                  )}
                </div>
              </div>
            </div>
            {imageURL && (
              <div className="iq4-credential-expanded__split-img">
                <img
                  className="iq4-credentials__expanded-image-desktop"
                  src={imageURL}
                  alt="Logo"
                />
              </div>
            )}
          </div>
          <div className="iq4-credential-expanded__container--line iq4-credential-expanded__container--line-no-margin" />
          {!!row?.competences?.length && (
            <div className="iq4-credential-expanded-item">
              <div className="iq4-credential-expanded-item__label">Skills</div>
              <div className="iq4-credential-expanded-item__content">
                Recipients demonstrated these job skills:
              </div>
            </div>
          )}
          <div className="iq4-credential__skills-list">
            {row?.competences?.map((c) => (
              <div className="iq4-credential-expanded-item__content">{c?.tier3?.label}</div>
            ))}
          </div>
          {!row.isVerified && !isPublic && (
            <Button className="iq4-credentials__edit" onClick={() => setIsEdit(row)}>
              Edit
            </Button>
          )}
          {!!row.isVerified && (
            <div className="iq4-credential-expanded-item__content">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="iq4-credentials__manual-add"
                style={{ cursor: 'pointer' }}
                href={row.achievementUrl}
              >
                View details
              </a>
            </div>
          )}
          <div className="iq4-credential-expanded__container--line iq4-credential-expanded__container--line-no-margin" />
          {!row.isVerified && !isPublic && (
            <div className="iq4-credential-expanded__split" style={{ marginTop: 45 }}>
              <div className="iq4-credential-expanded-item__content">
                Do you want to delete this credential?
              </div>
              <Button
                className="iq4-credentials__delete"
                onClick={() => prepareCredentialToBeDeleted(row)}
                loading={false}
              >
                Delete this credential
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
