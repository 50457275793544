import React from 'react';
import PropTypes from 'prop-types';
import { SimpleIcon } from 'common/components';
import { useFormContext } from 'react-hook-form';
import './Checkbox.scss';

export const Checkbox = (props) => {
  const { label, isChecked, handleChange, id, name, validators, checkboxClass, labelAfter } = props;
  const formContext = useFormContext();
  let register;

  const isWithinForm = formContext !== null;

  if (isWithinForm) {
    register = formContext.register;
  }

  return (
    <label className={`iq4-checkbox ${checkboxClass}`}>
      <input
        ref={isWithinForm ? register({ ...validators }) : null}
        type="checkbox"
        className="iq4-checkbox__input"
        id={id || name}
        name={name || label}
        checked={isChecked}
        onChange={isWithinForm ? formContext.triggerValidation : handleChange}
      />
      {!labelAfter && label}
      <span className="iq4-checkbox__checkmark">
        <SimpleIcon className="iq4-checkbox__checkmark-icon" name="tick" />
      </span>
      {labelAfter && <span className="iq4-checkbox__label-after">{label}</span>}
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
};

Checkbox.defaultProps = {
  handleChange: null,
};
