import { deriveJPMCSkills } from 'common/components/TShape/lib/t-shape';
import userSkills from './tShapeMockData/usersSkills';
import { createSelector } from '@reduxjs/toolkit';

export const selectMySkills = (state) => {
  return state.skills.skills.map((skill) => {
    return {
      id: skill.competenceId,
      tier1: skill.tier1Label,
      tier2: skill.tier2Label,
      value: skill.tier3Label,
      t2: {
        id: skill.tier2Id,
        label: skill.tier2Label,
      },
      label: skill.tier3Label,
      competenceGroup: skill.competenceGroup,
      assessment: getSelfAssessment(skill),
    };
  });
};

export const selectOnboardingSkills = (state) => {
  return state.skills.skills
    .map((skill) => {
      return {
        id: skill.competenceId,
        tier1: skill.tier1Label,
        tier2: skill.tier2Label,
        value: skill.tier3Label,
        assessment: getSelfAssessment(skill),
        selected: skill.hasOwnProperty('selected') ? skill.selected : true,
        addedManually: skill.addedManually,
      };
    })
    .slice(0, 9);
};

export const selectSkills = (state) => state.skills.skills;

export const selectSkillsWithLearningResourceByState = (state, school) => {
  return {
    ...state.skills,
    skills: state.skills.skills.map((skill) => {
      const educationalItems = skill?.sources
        ?.filter((source) => source.type === 'education')
        .map((i) => {
          return {
            ...i,
            isVerified: !!state.profile.education.find((x) => x.major === i.title)?.verifiedBy,
          };
        });

      return {
        ...skill,
        achievements: skill?.achievements?.filter(
          (achievement) => achievement?.school?.id === school?.id,
        ),
        educationalItems,
      };
    }),
  };
};

export const selectSelectedSkills = (state) => {
  return state.skills.skills.map((s) => {
    return { id: s.competenceId, value: s.tier3Label };
  });
};

export const isProfileSkillsComplete = (state) => {
  const skills = state.skills.mySkills;

  // if we want a minimum amount of skills, update here.
  if (!skills.length) return false;

  // only retrive selected skills, the selected skills are all the skills that have been checked during onboarding.
  const filteredSkills = selectSelectedSkills(state);

  const numAssessed = filteredSkills.reduce((sum, skill) => {
    return skill.assessment ? ++sum : sum;
  }, 0);

  return numAssessed === filteredSkills.length;
};

export const selectSkillsBreakUp = (state) => {
  const skills = state.skills.skills;

  function competenceGroupIdToSection(competenceGroupId) {
    switch (competenceGroupId) {
      case 'CG01':
        return 'it skills';
      case 'CG02':
        return 'soft skills';
      case 'CG03':
        return 'professional skills';
      case 'CG04':
        return 'professional skills';
      default:
        return '';
    }
  }

  const result = skills.reduce(
    (map, skill) => {
      map[competenceGroupIdToSection(skill.competenceGroupId)]++;
      return map;
    },
    { 'it skills': 0, 'soft skills': 0, 'professional skills': 0 },
  );

  return result;
};

export const selectSkillSources = (state, skill) => {
  const { education, experience, portfolio } = state.profile;
  const { credentialsList } = state.credentials;

  const addAssociations = (items, type) =>
    items.map((item) => ({
      ...item,
      associated: !!skill.sources.find((x) => x.type === type && item.id === x.id),
    }));

  return {
    ...(education &&
      education.length > 0 && { education: addAssociations(education, 'education') }),
    ...(experience &&
      experience.length > 0 && { experience: addAssociations(experience, 'experience') }),
    ...(portfolio &&
      portfolio.length > 0 && { portfolio: addAssociations(portfolio, 'portfolio') }),
    ...(credentialsList?.length && {
      credentials: addAssociations(credentialsList, 'credentials'),
    }),
  };
};

export const selectIsSkillSelectedById = (state, skillId) => {
  return state.skills.skills.filter((s) => s.competenceId === skillId).length > 0;
};

const derivedSkills = deriveJPMCSkills({
  userSkills,
  roleSkills: [],
  hiddenSkillsIds: [],
});
export const selectTShapeSkills = (state) => {
  return derivedSkills;
};

export const selectOrderedSkills = createSelector(
  (state) => state.skills.skills,
  (skills) => {
    if (!skills.length) return [];

    const copySkillsArr = [...skills];
    const ranked = copySkillsArr.filter((skill) => skill.rank !== null);
    const skillsOrderedByRank = ranked.sort((a, b) => a.rank - b.rank);

    const remainingSkills = copySkillsArr.filter((skill) => skill.rank === null);
    const skillsOrderedByProf = remainingSkills.sort((a, b) => {
      const profA = a.proficiency ? a.proficiency : 0;
      const profB = b.proficiency ? b.proficiency : 0;
      return profA - profB;
    });

    return deriveSkills([...skillsOrderedByRank, ...skillsOrderedByProf]);

    function deriveSkills(skills) {
      return skills.map((skill) => ({
        id: skill.competenceId,
        value: skill.tier3Label,
        assessment: skill.proficiency,
        tier1: skill.tier1Label,
        tier2: skill.tier2Label,
      }));
    }
  },
);

export const selectRecommendedSkills = (state) =>
  state.skills.recommendedSkills.map((skill) => ({
    id: skill.competenceId,
    value: skill.skillTier3,
  }));

// get the skills that were used to decide what recommended skills the user gets
export const selectRecommendedDecisionSkills = (state) => [
  'Problem Solving',
  'Presenting Effectively',
];

function getSelfAssessment(skill) {
  if (skill.assessments) {
    const assesmentInfo = skill.assessments.find((assessment) => assessment.assesseeId === 1); // assesseeId => 1 = self assessment
    return assesmentInfo ? assesmentInfo.assessment : null;
  }
  return skill.proficiency || 0;
}

export const selectResumeParsedSkills = createSelector(
  (state) => state.skills.resumeParser,
  (skills) => skills,
);

export const selectResumeParserOnboardingSkills = createSelector(
  (state) => state.skills.resumeParser,
  (skills) => {
    return skills.map((skill) => ({
      id: skill.competenceId,
      value: skill.label,
      tier1: skill.tier1.label,
      tier2: skill.tier2.label,
      selected: skill.hasOwnProperty('checked') ? skill.checked : true, // selected by default
      assessment: skill.assessment,
      addedManually: skill.addedManually,
    }));
  },
);

export const selectTShapeData = () => {
  return {
    top: [
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '1',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED_LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '1',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED_LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '1',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '1',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED_LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '1',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED_LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
        ],
      },
    ],

    bottomLeft: [
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED_LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
        ],
      },
      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED_LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
        ],
      },
      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'LINES' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
        ],
      },

      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'LINES' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED_LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED_LINES' },
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
        ],
      },
      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
        ],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'LINES' },
        ],
      },

      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
        ],
      },
      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
        ],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'FILLED' },
        ],
      },
    ],

    bottomRight: [
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
        ],
      },
      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
        ],
      },
      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
        ],
      },
      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '2',
        label: 'Tier 2 Label',
        stack: [
          { id: '1', label: 'Tier 3 Label (1)', type: 'FILLED' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED_LINES' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
          { id: '2', label: 'Tier 3 Label (2)', type: 'FILLED_LINES' },
          { id: '3', label: 'Tier 3 Label (3)', type: 'FILLED' },
          { id: '4', label: 'Tier 3 Label (4)', type: 'LINES' },
        ],
      },
      {
        id: '4',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
      {
        id: '3',
        label: 'Tier 2 Label',
        stack: [{ id: '1', label: 'Tier 3 Label (1)', type: 'LINES' }],
      },
    ],
  };
};
