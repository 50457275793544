const initialState = {
  includeCerts: false,
  isReadOnlyCerts: true,
  includeRecommendedCourses: false,
  includeTools: false,
  includeValidatedCredentials: false,
  includeOtherCredentials: false,
  includeManagerAssessments: false,
  assessmentType: 'SELF',
  skillAssessmentTier: 'TIER_4',
  includeTier2Skills: false,
  defaultHighSkillsPriority: false,
};

export const reducer = (state = initialState, actions) => {
  return state;
};
