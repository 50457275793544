import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { SimpleIcon, AssessmentStars } from 'common/components';
import { EmptyState } from 'common/components/EmptyState';
import GraphicSkillsGroup from '@assets/svg/empty-states_skills.svg';
import { ROUTES } from 'routes/routeNames';
import './TopSkillsCard.scss';

/**
 * @param {Object[]} topSkills - An array of objects containing skills
 * @param {bool} withoutWrapper - If this is true, the "Top x Skills" text, padding and border is removed
 * @param {string} emptyStateMessage - String to display within empty state
 * @param {bool} showManageYourSkills - If this is true, the "Manage your skills" button will be displayed
 * @param {string} seeMoreLink - Route / URL to use for See More link
 *
 */
export const TopSkillsCard = ({
  topSkills = [],
  withoutWrapper = false,
  emptyStateMessage = '',
  showManageYourSkills = true,
  seeMoreLink = null,
}) => {
  const history = useHistory();
  const renderSkillsList = (skills, withNumbers = false) => {
    return (
      <ul className="iq4-top-skills-card__skill-list">
        {skills.map((skill, index) => {
          return (
            <li key={skill.id} className="iq4-top-skills-card__skill-list-item">
              <div className="iq4-top-skills-card__checkmark">
                {withNumbers ? <span>{index + 1}</span> : <SimpleIcon name="tick" />}
              </div>
              <div className="iq4-top-skills-card__skill-name">
                <span className="iq4-top-skills-card__skill-name--tier3">{skill.value}</span>
                <span className="iq4-top-skills-card__skill-name--tier1-tier2">
                  {skill.tier1} &gt; {skill.tier2}
                </span>
              </div>
              <div className="iq4-top-skills-card__assessment">
                <AssessmentStars assessment={skill.assessment || skill.proficiency} displayOnly />
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div
      className={`iq4-top-skills-card ${
        withoutWrapper ? 'iq4-top-skills-card--without-wrapper' : ''
      }`}
    >
      {!withoutWrapper && (
        <h3 className="iq4-top-skills-card__title">TOP {topSkills.length} SKILLS</h3>
      )}

      {!topSkills.length && (
        <div className="iq4-top-skills-card__empty-state-section">
          <div className="iq4-top-skills-card__empty-state-wrapper">
            <EmptyState
              text={emptyStateMessage}
              action={() => void history.push(ROUTES.MANAGE_SKILLS)}
              actionLabel={showManageYourSkills && `Manage your Skills`}
            />
          </div>
          <img
            src={GraphicSkillsGroup}
            alt="Empty State Graphic for Top 10 Skills"
            className="iq4-top-skills-card__empty-state-graphic"
          />
        </div>
      )}

      {topSkills.length > 0 && (
        <div className="iq4-top-skills-card__list-container">
          {renderSkillsList(topSkills, true)}
        </div>
      )}
      {seeMoreLink && (
        <div className="iq4-top-skills-card__see-more">
          <Link to={seeMoreLink}>
            See more <SimpleIcon name="arrow" />
          </Link>
        </div>
      )}
    </div>
  );
};

TopSkillsCard.propTypes = {
  topSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
        .isRequired,
      value: PropTypes.string.isRequired,
      assessment: PropTypes.number.isRequired,
    }),
  ).isRequired,
  withoutWrapper: PropTypes.bool,
  emptyStateMessage: PropTypes.string,
  showManageYourSkills: PropTypes.bool,
  seeMoreLink: PropTypes.string,
};
