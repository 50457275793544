import React from 'react';
import { TableRow } from '@material-ui/core';
import { renderColumn } from './tableUtils';

/**
 * Datatable row component
 * @param props
 * @returns {*}
 * @constructor
 */
export const DataTableRow = ({ row, tableDefinition, onRowExpandOpen }) => {
  const [open, setOpen] = React.useState(false);

  const onRowExpand = (row) => {
    if (!open && onRowExpandOpen) {
      onRowExpandOpen(row);
    }
  };

  return (
    <>
      <TableRow classes={{ root: 'iq4-datatable__row-width' }}>
        {tableDefinition.columns.map((column) =>
          renderColumn({ column, row, open, setOpen, onRowExpand }),
        )}
      </TableRow>
      {tableDefinition.expanded && tableDefinition.expanded({ row, open })}
    </>
  );
};
