const context = process.env.REACT_APP_CONTEXT;

export const DASHBOARD_USER_URL = `${context}/api/v2/dashboard/user`;
export const DASHBOARD_USERS_URL = `${context}/api/v2/dashboard/all/users`;
export const DASHBOARD_ACHIEVEMENTS_URL = `${context}/api/v2/dashboard/achievements`;
export const DASHBOARD_ACHIEVEMENT_URL = `${context}/api/v2/dashboard/achievement`;
export const DASHBOARD_SKILLS_URL = `${context}/api/v2/dashboard/skills`;
export const DASHBOARD_SKILL_URL = `${context}/api/v2/dashboard/skill`;
export const DASHBOARD_FRAMEWORK_URL = `${context}/api/v2/dashboard/framework`;
export const DASHBOARD_QUERY_URL = `${context}/api/v2/dashboard/enterpriseQuery`;
export const DASHBOARD_WORK_ROLE_URL = `${context}/api/v2/dashboard/workrole`;
export const DASHBOARD_TOP_CARDS_URL = `${context}/api/v2/dashboard/topCard`;
export const DASHBOARD_METRICS_URL = `${context}/api/v2/dashboard/metrics`;
export const AUTHENTICATION_URL = `${context}/login/authenticate`;
export const ROLE_PROFILES_URL = `${context}/api/v2/dashboard/roleProfiles`;
export const MANAGERS_URL = `${context}/api/v2/dashboard/managers`;
export const PATHWAYS_URL = `${context}/api/v2/dashboard/pathways`;
export const LOCATIONS_URL = `${context}/api/v2/dashboard/locations`;
export const CREDENTIALS_URL = `${context}/api/v2/dashboard/credentials`;
export const SCHOOLS_URL = `${context}/api/v2/dashboard/schools`;
export const USER_PROFILE_URL = `${context}/api/v2/dashboard/user`;

export const INVITE_USER_URL = `${context}/api/invite/student`;

export const WALLET_SHARING_SETTINGS_URL = `${context}/api/v2/user/profile/share/setting`;

export const PUBLIC_PROFILE_URL = `${context}/api/v2/user/profile/info`;
export const PUBLIC_CREDENTIALS_URL = `${context}/api/v2/user/profile/credentials`;
