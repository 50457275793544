import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import './TabbedCard.scss';

/**
 * Tabbed Card component
 * @param {func} children
 * @param {object[]} tabs - array of the tabs, [{label}, ...]
 * @param {bool} [activeIndex] - index of the active tab panel
 * @param {func} [onChange] - notify parent on change
 * @param {string} [className] - custom className
 * @param {object} [tabsProps] - custom props to apply to the Tabs component
 * @param {object} [tabProps] - custom props to apply to the Tab component
 * @param {object} [props] - custom props
 */

export const TabbedCard = ({
  children,
  tabs,
  activeIndex = 0,
  onChange,
  className,
  tabsProps = {},
  tabProps = {},
  props,
}) => {
  const [value, setValue] = useState(activeIndex);

  // update the current tab, if the activeIndex prop is updated
  useEffect(() => {
    handleChange(activeIndex);
  }, [activeIndex]);

  // handle tab change
  const handleChange = (newValue) => {
    setValue(newValue);

    // notify parent if handler available
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={`iq4-tabbed-card ${className ? className : ''}`} {...props}>
      <Tabs
        className="iq4-tabbed-card__tabs"
        classes={{
          indicator: 'iq4-tabbed-card__tab-indicator',
        }}
        value={value}
        onChange={(_, newVal) => handleChange(newVal)}
        {...tabsProps}
      >
        {tabs.map((tab) => {
          return (
            <Tab
              className="iq4-tabbed-card__tab"
              key={tab.label}
              label={tab.label}
              disableRipple
              {...tabProps}
            />
          );
        })}
      </Tabs>

      <div className="iq4-tabbed-card__active-panel">
        {children({ activeIndex: value, onChange: handleChange })}
      </div>
    </div>
  );
};
