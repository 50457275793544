import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'common/components/Modal';
import { Button } from 'common/components/Button';
import './TriggerModal.scss';

export const TriggerModal = ({
  title,
  form,
  label,
  buttonVariation,
  className,
  isTextTriggered,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onTrigger = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const TextTrigger = ({ children }) => (
    <span
      role="button"
      onClick={onTrigger}
      onKeyDown={onTrigger}
      className="iq4-trigger-modal__text"
    >
      {children}
    </span>
  );

  const ButtonTrigger = ({ children }) => (
    <Button variation={buttonVariation} onClick={onTrigger}>
      {children}
    </Button>
  );

  return (
    <div className={`iq4-trigger-modal ${className}`}>
      {isTextTriggered ? (
        <TextTrigger>{label}</TextTrigger>
      ) : (
        <ButtonTrigger>{label}</ButtonTrigger>
      )}
      <Modal isOpen={isOpen} handleClose={handleClose} title={title}>
        {React.cloneElement(form, { onComplete: handleClose })}
      </Modal>
    </div>
  );
};

TriggerModal.propTypes = {
  title: PropTypes.string,
  form: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  buttonVariation: PropTypes.string,
  className: PropTypes.string,
  isTextTriggered: PropTypes.bool,
};

TriggerModal.defaultProps = {
  title: '',
  label: '',
  buttonVariation: 'ghost',
  className: '',
  isTextTriggered: false,
};
