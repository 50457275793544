import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { MainNavOptions } from 'routes/MainNavOptions';
import {
  MainNavbar,
  SimpleIcon,
  JobReqCandidatesTable,
  JobReqDetails,
  Button,
  ManageSkillsModule,
  Modal,
  Pill,
} from 'common/components';

import { ManageSkillsModuleWithMocks } from 'common/components/ManageSkillsModule/ManageSkillsModuleWithMocks';

import { useJobReqFormDefinition } from 'common/hooks/forms';

import { FormWrapper } from 'common/components/Form';

import {
  fetchJobReq,
  fetchJobReqRecommendedCandidates,
  fetchKeywordSearchJobReq,
} from 'store/features/jobReqs/jobReqsSlice';
import {
  selectSelectedPosition,
  selectSelectedPositionCandidates,
  selectJobValueFormStructure,
} from 'store/features/jobReqs/selectors';

import { setSearchedRoleProfiles, fetchRoleProfiles } from 'store/features/search/searchSlice';

import { ROUTES } from 'routes/routeNames';

import './SingleJobReqPage.scss';

export const SingleJobReqPage = () => {
  const dispatch = useDispatch();
  const { jobReqId } = useParams();
  const [tabValue, setTabValue] = useState(0);

  const job = useSelector(selectSelectedPosition);
  const candidates = useSelector(selectSelectedPositionCandidates);
  const jobFormStucture = useSelector(selectJobValueFormStructure);

  const [editFormActive, setEditFormActive] = useState(false);

  // get job details on initial render
  useEffect(() => {
    dispatch(fetchJobReq(jobReqId));

    dispatch(fetchKeywordSearchJobReq(jobReqId));
  }, []);

  useEffect(() => {
    if (job) {
      dispatch(setSearchedRoleProfiles([{ id: job.id, label: job.title }]));
    }
  }, [job]);

  useEffect(() => {
    dispatch(fetchRoleProfiles());
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMarkAsClosed = () => {
    console.log('handle mark as closed');
  };

  const handleMarkHighPriority = () => {
    console.log('high priority mark');
  };

  const renderJobReqHeader = () => {
    return (
      <div className="iq4-individual-job-req-page__job-header">
        <Link
          to={`${ROUTES.JOB_REQS}`}
          className="iq4-individual-job-req-page__job-header-back-btn"
        >
          <SimpleIcon name="arrow" />
        </Link>

        <div className="iq4-individual-job-req-page__job-header-title">{job?.title}</div>
        <div className="iq4-individual-job-req-page__job-header-desc">
          These are the candidates currently shortlisted for the above role.
        </div>
      </div>
    );
  };

  return (
    <div className="iq4-individual-job-req-page">
      <MainNavbar className="iq4-individual-job-req-page__navbar" MainNavOptions={MainNavOptions} />

      <div className="iq4-individual-job-req-page__breadcrumb-header">
        <div>
          <div
            className={`iq4-individual-job-req-page__breadcrumb ${
              (job?.positionType === 'fulltime' &&
                'iq4-individual-job-req-page__breadcrumb--active') ||
              ''
            }`}
          >
            Full Time open positions
          </div>
          <div
            className={`iq4-individual-job-req-page__breadcrumb ${
              (job?.positionType === 'internship' &&
                'iq4-individual-job-req-page__breadcrumb--active') ||
              ''
            }`}
          >
            Internship open positions
          </div>
          <div
            className={`iq4-individual-job-req-page__breadcrumb ${
              (job?.positionType === 'contractor' &&
                'iq4-individual-job-req-page__breadcrumb--active') ||
              ''
            }`}
          >
            Contractor open positions
          </div>
          {/* <div
            className={`iq4-individual-job-req-page__breadcrumb ${
              (job?.positionType === 'missing' &&
                'iq4-individual-job-req-page__breadcrumb--active') ||
              ''
            }`}
          >
            Missing job reqs
          </div> */}
        </div>
      </div>

      <div className="iq4-individual-job-req-page__container">
        {renderJobReqHeader()}

        <div className="iq4-individual-job-req-page__tabs-container">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="simple tabs example"
            className="iq4-individual-job-req-page__tabs"
          >
            <Tab label="Job Requisition" {...a11yProps(0)} />
            <Tab label="Manage Skills" {...a11yProps(1)} />
            <Tab label="Candidates" {...a11yProps(2)} />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <div className="iq4-individual-job-req-page__tabs-panel-1">
              <JobReqDetails
                className="iq4-individual-job-req-page__req-details"
                onEdit={() => {
                  setEditFormActive(true);
                }}
                isLoading={!job?.title}
                reqProps={[
                  { title: 'Role', description: job?.title || '—' },
                  { title: 'Location', description: job?.location || '—' },
                  { title: 'Type', description: job?.positionType || '—' },
                  { title: 'Department', description: job?.department || '—' },
                  { title: 'Opened by', description: job?.openedBy || '—' },
                  { title: 'Date', description: job?.date || '—' },
                  {
                    title: 'Priority Level',
                    description: job?.priority ? (
                      <Pill
                        label={job?.priority}
                        className={`iq4-pill--priority iq4-pill--priority-${job?.priority}`}
                      />
                    ) : (
                      '—'
                    ),
                  },
                  { title: 'Open Positions', description: job?.openPositions || '—' },
                  {
                    title: 'Role Description',
                    description: job?.description,
                  },
                ]}
              />

              {/* <div className="iq4-individual-job-req-page__tabs-panel-1-btn-container">
                <Button
                  onClick={handleMarkAsClosed}
                  className="iq4-individual-job-req-page__tabs-panel-1-btn"
                  variation="ghost"
                >
                  Mark as closed
                </Button>
                <Button
                  onClick={handleMarkHighPriority}
                  className="iq4-individual-job-req-page__tabs-panel-1-btn"
                  variation="ghost"
                >
                  Mark as High priority
                </Button>
              </div> */}
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ManageSkillsModuleWithMocks />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <JobReqCandidatesTable candidates={candidates} />
          </TabPanel>
        </div>
      </div>

      <Modal
        isOpen={editFormActive}
        handleClose={() => void setEditFormActive(false)}
        title="Edit Job Req"
      >
        {jobFormStucture && jobFormStucture.id && (
          <FormWrapper
            submitLabel="Save"
            showButtons
            values={jobFormStucture}
            useFormDefinition={useJobReqFormDefinition}
            onComplete={({ error }) => {
              if (!error) {
                setEditFormActive(false);
              }
            }}
          />
        )}
      </Modal>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`iq4-individual-job-req-pagepanel-${index}`}
      aria-labelledby={`iq4-individual-job-req-page-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `iq4-individual-job-req-page-${index}`,
    'aria-controls': `iq4-individual-job-req-pagepanel-${index}`,
  };
}
