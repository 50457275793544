import React from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

export const CertRequestInfoModal = ({ isOpen, toggle, status }) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`Adding certifications feedback.`}
      onRequestClose={() => void toggle(false)}
      handleClose={() => toggle(false)}
      renderHeader={() => (
        <React.Fragment>
          <h3>{status === 'success' ? 'Success' : 'Error'}</h3>
        </React.Fragment>
      )}
    >
      {() => (
        <div>
          {status === 'success' && (
            <p>You have successfully added the certifications.</p>
          )}

          {status === 'error' && <p>Something went wrong, please try again.</p>}
        </div>
      )}
    </Modal>
  );
};
