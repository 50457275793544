import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from 'common/store/features/skillsModuleState/actions.js';

import {
  selectSelectedJobSkills,
  selectSelectedPosition,
} from 'store/features/jobReqs/selectors.js';
import { fetchJobReq } from 'store/features/jobReqs/jobReqsSlice';
import { selectSkillsModuleBroadcaster } from 'common/store/features/skillsModuleState/selectors.js';
import { sendRequest } from 'common/utils/requests.js';

import ManageSkillsModule from './ManageSkillsModule';

import {
  JOB_REQ_SKILL_KEYWORD_SEARCH_API_URL,
  JOB_REQ_SKILLS_API_URL,
} from 'common/constants/endpoints.js';

import './ManageSkillsModuleWithMocks.scss';

export const ManageSkillsModuleWithMocks = () => {
  const dispatch = useDispatch();
  const skills = useSelector(selectSelectedJobSkills);
  const broadcaster = useSelector(selectSkillsModuleBroadcaster);
  const jobReq = useSelector(selectSelectedPosition);

  useEffect(() => {
    async function handleBroadcasterEvents() {
      if (broadcaster) {
        // reset broadcaster
        dispatch(actions.broadcasters.resetBroadcaster());

        // perform skills search
        if (broadcaster.type === 'SKILLS_SEARCH') {
          // get new skills
          const searchedForSkills = await fetch(
            JOB_REQ_SKILL_KEYWORD_SEARCH_API_URL(broadcaster.searchTerm, jobReq.id),
          );
          const skillsParsed = await searchedForSkills.json();

          const deriveInValidFormat = skillsParsed.map((t1) => {
            t1.skillTier2List = t1.skillTier2List.map((t2) => {
              t2.skillTier3List = t2.skillTier3List.map((t3) => {
                t3.compId = t3.competence.id;
                t3.skillTier4List = [
                  {
                    scId: t3.skillTier4List[0].skillCategory.id,
                    skillAdded: t3.skillAdded,
                    skillCategory: { id: t3.skillTier4List[0].skillCategory.id },
                    skillTier4: { id: t3.skillTier3.id, label: t3.skillTier3.label },
                  },
                ];

                return t3;
              });
              return t2;
            });
            return t1;
          });

          // pass new skills to module
          dispatch(actions.skills.updateSearchResults(deriveInValidFormat));
        }

        // save new skills
        if (broadcaster.type === 'SAVE_ADDITIONAL_SKILLS') {
          const newSkills = broadcaster.payload;

          const skillsPayload = newSkills.map((s) => {
            return {
              competence: s.meta.compId,
              jobId: jobReq.id,
              isDelete: false,
            };
          });

          // try saving new skills
          try {
            dispatch(actions.loaders.toggleSkillsLoader('savingSkills', 'LOADING'));

            const savedJobReqSkills = await sendRequest({
              url: JOB_REQ_SKILLS_API_URL,
              verb: 'POST',
              data: skillsPayload,
            });

            await dispatch(fetchJobReq(jobReq.id));

            dispatch(actions.loaders.toggleSkillsLoader('savingSkills', 'SUCCESS'));
          } catch (e) {}
        }

        if (broadcaster.type === 'REMOVE_ADDITIONAL_SKILLS') {
          const skillsPayload = [
            {
              competence: broadcaster.payload.sk4[0].id,
              jobId: jobReq.id,
              isDelete: true,
            },
          ];

          try {
            // remove skill
            await sendRequest({
              url: JOB_REQ_SKILLS_API_URL,
              verb: 'POST',
              data: skillsPayload,
            });

            // get updated data
            await dispatch(fetchJobReq(jobReq.id));
          } catch (e) {}
        }
      }
    }

    handleBroadcasterEvents();
  }, [broadcaster]);

  useEffect(() => {
    const tier1s = skills?.length
      ? [
          {
            skillTier1: {
              id: 80,
              label: 'Text Kernal',
            },
          },
        ]
      : [];

    const summary = skills?.length
      ? [
          {
            skill_tier1_id: 80,
            label: 'Text Kernel',
            roleskillsCount: skills.length,
            additionalSkillsCount: null,
            selfAssessments: null,
            recommendedCourses: 15,
            certificatesAssessment: 0,
            managerAssessment: 0,
          },
        ]
      : [];

    dispatch(actions.skills.setJPMCTier1s(tier1s));
    dispatch(actions.skills.setJPMCSkillsData(getMockSkills(skills)));
    dispatch(actions.skills.setJPMCSkillsSummary(summary));
  }, [skills]);

  return <ManageSkillsModule />;
};

const TK_MOCK_SKILLS = [
  {
    competenceId: 44058,
    foundIn: null,
    label: 'Applicant Tracking Systems',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 55685,
    foundIn: null,
    label: 'Communication Skills',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 48139,
    foundIn: null,
    label: 'Microsoft Office',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 51111,
    foundIn: null,
    label: 'Presentations',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 46991,
    foundIn: null,
    label: 'Recruitment',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 51463,
    foundIn: null,
    label: 'Service Orientation',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 50295,
    foundIn: null,
    label: 'Stakeholder Management',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 56024,
    foundIn: null,
    label: 'Brand Management',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 46385,
    foundIn: null,
    label: 'Fair Labor Standards Act',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 55751,
    foundIn: null,
    label: 'Forecasting Skills',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 55900,
    foundIn: null,
    label: 'Human Resource Marketing',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 43550,
    foundIn: null,
    label: 'Innovation',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 51150,
    foundIn: null,
    label: 'Interviewing',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 55799,
    foundIn: null,
    label: 'Knowledge of Laws',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 55691,
    foundIn: null,
    label: 'Management of Contingent Workforce',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 47928,
    foundIn: null,
    label: 'Market Research',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 56181,
    foundIn: null,
    label: 'Mentoring',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 55845,
    foundIn: null,
    label: 'Networking Skills',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 54364,
    foundIn: null,
    label: 'Offshore Work',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 51311,
    foundIn: null,
    label: 'Passionate',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 49310,
    foundIn: null,
    label: 'Recruitment Process Outsourcing',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 45138,
    foundIn: null,
    label: 'Regulatory Compliance',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 49779,
    foundIn: null,
    label: 'Risk Analysis',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 50178,
    foundIn: null,
    label: 'Social Media',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 56178,
    foundIn: null,
    label: 'Team Working',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
  {
    competenceId: 56056,
    foundIn: null,
    label: 'Trustworthiness',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  },
];

function getMockSkills(skills = TK_MOCK_SKILLS) {
  const tk = {
    competenceId: 56056,
    foundIn: null,
    label: 'Trustworthiness',
    tier1: { id: 80, label: 'TK' },
    tier2: { id: 464, label: 'TK' },
  };
  return {
    skillAssessmentList: [
      {
        skillTier1: { id: tk.tier1.id, label: tk.tier1.label },
        skillTier2: { id: null, label: null },
        skillTier3: {
          assessments: [],
          context: false,
          lmsLinks: { url: null },
          skillTier3: { id: tk.competenceId, label: tk.tier2.label },
          course: {
            advanced: false,
            beginner: true,
            everyone: true,
            intermediate: true,
          },
          courseCount: { advanced: [], entry: [], intermediate: [] },
          compId: tk.competenceId,
          skillTier4List: skills.map((tkSkill) => {
            return {
              includes: ['role'],
              requiredProficiency: 3,
              skillCategory: { id: 36714 },
              skillLevel: 'required',
              priority: null,
              skillTier4: { id: tkSkill.competenceId, label: tkSkill.label },
              ups: { id: 216578, jobSkillPayGrade: { id: 216578 } },
              assessments: [{ type: 'self', assessment: null }],
              courseCount: { advanced: [], entry: [], intermediate: [] },
              course: {
                advanced: false,
                beginner: true,
                intermediate: false,
              },
              scId: 36714,
            };
          }),
        },
      },
    ],
    labInfo: {
      labDetails: [],
    },
  };
}
