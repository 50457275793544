import React, { useState, useEffect, useRef } from 'react';
import { SimpleIcon } from 'common/components/SimpleIcon';
import { debounce } from 'lodash';
import { useClickOutside } from 'common/hooks/useClickOutside';
import { useSelector } from 'react-redux';
import { selectFeatures } from 'common/store/features/config/selectors';
import './RolesSearchBar.scss';

const MINIMUM_SEARCH_LENGTH = 3;
const SEARCH_DEBOUNCE_DELAY = 250;
const EVENT_KEYS = {
  UP: 38,
  DOWN: 40,
  TAB: 9,
  ESCAPE: 27,
};

/**
 * Search bar component
 * @param {Object} results - The results after a search has been complete
 * @param {function} onSkillClick - Callback to let the parent know an item has been clicked from the search suggestions
 * @param {function} onChange - Callback when the search bar value has changed
 * @param {number} [minSearchLength] - Minimum number of characters before search can be performed
 * @param {number} [debounceDelay] - Delay before performing search
 * @param {string} [className] - Extend component className
 * @param {boolean} [noSearchIcon] - Removes the search icon from the input if enabled
 * @param {boolean} [noResultsMessage] - Message to display when there's no results
 * @param {Object} props - Remaining props that the user
 */
export const RolesSearchBar = ({
  results,
  onSkillClick,
  onChange,
  className,
  minSearchLength = MINIMUM_SEARCH_LENGTH,
  debounceDelay = SEARCH_DEBOUNCE_DELAY,
  noSearchIcon = false,
  noResultsMessage = null,
  ...props
}) => {
  const [inputVal, setInputVal] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsBoxRef = useRef(null);
  const [clickedOutside, setClickedOutside] = useClickOutside(suggestionsBoxRef);
  const debounceSearch = debounce((newVal) => {
    onChange(newVal);
  }, debounceDelay);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { displayUpperTiers } = useSelector(selectFeatures);

  let suggestionsListRef = useRef({ suggestions: [], focusedIndex: null });

  // show/hide suggestions based on input
  useEffect(() => {
    if (inputVal.length >= minSearchLength || isInputFocused) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [inputVal, minSearchLength, isInputFocused]);

  // hide suggestions if the user click ouside the container
  useEffect(() => {
    if (clickedOutside) {
      setShowSuggestions(false);
      setClickedOutside(false);
    }
  }, [clickedOutside, setClickedOutside]);

  const renderResults = () => {
    suggestionsListRef = { suggestions: [], focusedIndex: null };

    return (
      <div className="iq4-roles-search-bar__suggestions-list">
        {results.map((skill, i) => {
          return (
            <button
              key={skill.id}
              ref={(btnNode) => {
                if (!skill.alreadyAdded) {
                  suggestionsListRef.suggestions.push(btnNode);
                }
              }}
              tabIndex={skill.alreadyAdded ? '-1' : '0'}
              onClick={() => void handleSkillClick(skill)}
              className={`iq4-roles-search-bar__suggestions-list-item ${
                skill.alreadyAdded ? 'iq4-roles-search-bar__suggestions-list-item--disabled' : ''
              }`}
            >
              <div className="iq4-roles-search-bar__tier3">
                {getHighlightedText(skill.label, inputVal)}
              </div>
              {displayUpperTiers && skill.tier1 && skill.tier2 && (
                <div className="iq4-roles-search-bar__tier1-and-2">
                  {skill.tier1} / {skill.tier2}
                </div>
              )}
            </button>
          );
        })}
      </div>
    );
  };

  const handleArrowNavigation = (e) => {
    // handle escape
    if (e.which === EVENT_KEYS.ESCAPE) {
      document.activeElement.blur();
      setShowSuggestions(false);

      if (suggestionsListRef.focusedIndex !== undefined) {
        suggestionsListRef.focusedIndex = null;
      }
      return;
    }

    // do nothing if not down, up, or tab
    if (e.which !== EVENT_KEYS.DOWN && e.which !== EVENT_KEYS.UP && e.which !== EVENT_KEYS.TAB)
      return;

    // do nothing if are no suggestions
    if (
      (suggestionsListRef.current &&
        suggestionsListRef.current.suggestions &&
        !suggestionsListRef.current.suggestions.length) ||
      (suggestionsListRef.suggestions && !suggestionsListRef.suggestions.length)
    ) {
      return;
    }

    if (e.which === EVENT_KEYS.UP || e.which === EVENT_KEYS.DOWN) {
      e.preventDefault();
    }

    // keep track of the current focused node
    if (e.which === EVENT_KEYS.DOWN || e.which === EVENT_KEYS.TAB) {
      const increment = suggestionsListRef.focusedIndex === null ? 0 : 1;
      suggestionsListRef.focusedIndex =
        (suggestionsListRef.focusedIndex + increment) % suggestionsListRef.suggestions.length;
    }

    if (e.which === EVENT_KEYS.UP || (e.shiftKey && e.which === EVENT_KEYS.TAB)) {
      const increment = !suggestionsListRef.focusedIndex
        ? suggestionsListRef.suggestions.length - 1
        : -1;
      suggestionsListRef.focusedIndex =
        (suggestionsListRef.focusedIndex + increment) % suggestionsListRef.suggestions.length;
    }

    // only focus on node if up/down arrow since tab already works by default
    if (e.which === EVENT_KEYS.DOWN || e.which === EVENT_KEYS.UP) focusOnNode();

    function focusOnNode() {
      suggestionsListRef.suggestions[suggestionsListRef.focusedIndex].focus();
    }
  };

  const handleChange = (e) => {
    const newVal = e.target.value;
    setInputVal(newVal);

    if (newVal.length >= minSearchLength) {
      debounceSearch(newVal);
    }
  };

  const handleFocus = () => {
    if (inputVal.length >= minSearchLength) {
      setShowSuggestions(true);
    }

    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const handleSkillClick = (skill) => {
    if (skill.alreadyAdded) return;
    // reset search
    setInputVal('');
    onSkillClick({
      id: skill.id,
      label: skill.label,
      jobFamilyId: skill.jobFamilyId,
      meta: { ...skill },
    });
  };

  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, 'gi')) || [];
    return parts.map((part, i) => (
      <span
        key={i}
        className={
          part.toLowerCase() === highlight.toLowerCase() ? 'iq4-roles-search-bar__highlighted' : ''
        }
      >
        {part}
      </span>
    ));
  };

  return (
    <div
      ref={suggestionsBoxRef}
      onKeyDown={handleArrowNavigation}
      className={`iq4-roles-search-bar ${className ? className : ''}`}
    >
      <div className="iq4-roles-search-bar__input-container">
        <input
          className={`iq4-roles-search-bar__input ${
            noSearchIcon ? 'iq4-roles-search-bar__input--no-icon' : ''
          }`}
          type="text"
          value={inputVal}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Search for roles"
          aria-label="Search for roles"
          {...props}
        />

        {!noSearchIcon && (
          <SimpleIcon name="search" className="iq4-roles-search-bar__search-icon" />
        )}
      </div>

      {showSuggestions && (
        <div className="iq4-roles-search-bar__suggestions-container">
          {inputVal.length < minSearchLength && (
            <div className="iq4-roles-search-bar__user-alert">
              Please enter at least {minSearchLength} characters to search...
            </div>
          )}

          {inputVal.length >= minSearchLength && (
            <>
              {!!results.length && renderResults()}

              {!results.length && (
                <div className="iq4-roles-search-bar__user-alert">
                  {!noResultsMessage && (
                    <>
                      No results found for <strong>"{inputVal}"</strong>
                    </>
                  )}

                  {noResultsMessage && noResultsMessage}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
