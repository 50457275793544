import React from 'react';

const LEVELS_MAP = {
  entry: 0,
  intermediate: 1,
  advanced: 2,
  expert: 3
};
export const RecommendedCoursesCount = ({
  courseCount,
  level,
  tier,
  id,
  label,
  onClick = () => {}
}) => {
  const currentLvl = LEVELS_MAP[level] || 0;
  const coursesAvailabelAtLevelOrAbove = level
    ? !!Object.keys(courseCount).find((key) => {
        return (LEVELS_MAP[key] || 0) >= currentLvl && courseCount[key];
      })
    : !!Object.keys(courseCount).find((key) => courseCount[key]);

  if (!coursesAvailabelAtLevelOrAbove) return null;

  return (
    <div
      className="iq4-recommended-courses-count"
      onClick={(e) => {
        onClick({ tier, id, level, courseCount, label }, e);
      }}
      aria-label={`Open Learning Resources for ${label}`}
    >
      <span className="iq4-recommended-courses-count__count">View Courses</span>
    </div>
  );
};

export default RecommendedCoursesCount;
