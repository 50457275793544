import React from 'react';
import moment from 'moment';
import { Modal, Button, SimpleIcon } from 'common/components';

export const MiddleStepModal = ({ step = {}, showModal, setShowModal }) => {
  const hasProperDescription = !!step.description && step.description !== step.name;
  const formatMonth = (month) => (month ? moment(month, 'MM').format('MMM') : '');

  return (
    <Modal
      title={
        <div className="iq4-pathways__mid-step-popover--modal-title">
          <div className="iq4-pathways__mid-step-popover--modal-title-container">
            <SimpleIcon
              name={step?.type === 'role' ? 'experienceCirclePathways' : 'educationCircle'}
            />
            <div className="iq4-pathways__mid-step-popover--modal-title-label">{step.name}</div>
          </div>
          {step.url && (
            <Button className="iq4-pathways__mid-step-popover--modal-button">
              <a
                href="https://www.google.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Learn More"
              >
                Learn More
              </a>
            </Button>
          )}
        </div>
      }
      isOpen={showModal}
      handleClose={() => setShowModal(false)}
      variation="2"
    >
      {hasProperDescription && (
        <>
          <strong>Description</strong>
          <div className="iq4-pathways__mid-step-popover--modal-text">{step.description}</div>
        </>
      )}
      {(!!step.schoolId || !!step.startYear || !!step.durationUnit) && (
        <div className="iq4-pathways__mid-step-popover--modal-details">
          <div className="iq4-pathways__mid-step-popover--modal-items">
            {!!step.schoolId && (
              <>
                <strong>School/Offered by</strong>
                <div>{step.schoolName}</div>
              </>
            )}
          </div>
          <div className="iq4-pathways__mid-step-popover--modal-items">
            {!!step.startYear && (
              <>
                <strong>Commencement Date</strong>
                <div>
                  {formatMonth(step.startMonth)} {step.startYear}
                </div>
              </>
            )}
          </div>
          <div className="iq4-pathways__mid-step-popover--modal-items">
            {!!step.durationUnit && (
              <>
                <strong>Duration</strong>
                <div>
                  {step.duration} {step.durationUnit}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div className="iq4-pathways__mid-step-popover--modal-sub-title">
        {step.competencies?.length} Skills for {step.name}
      </div>
      <div className="iq4-pathways__mid-step-popover--modal-list">
        {step.competencies?.map((competency) => (
          <div key={competency.id} className="iq4-pathways__mid-step-popover--modal-list-item">
            <div className="iq4-pathways__mid-step-popover--modal-list-item-icon" />
            {competency.name}
          </div>
        ))}
      </div>
    </Modal>
  );
};
