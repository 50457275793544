import { combineReducers } from 'redux';
import { reducer as moduleConfigs } from './moduleConfigs/reducers';
import skills from './skills/reducers';
import { reducer as broadcaster } from './broadcaster/reducers';
import { reducer as loaders } from './loaders/reducers';
import certs from './certs/reducers';
import tools from './tools/reducers';
import courses from './courses/reducers';

export const moduleReducers = {
  moduleConfigs,
  skills,
  broadcaster,
  loaders,
  certs,
  tools,
  courses,
};

export default combineReducers({
  moduleConfigs,
  skills,
  broadcaster,
  loaders,
  certs,
  tools,
  courses,
});
