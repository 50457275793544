import React from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';

export const RemoveSkillConfirmModal = ({
  isOpen,
  toggle,
  onRemove,
  skill
}) => {
  const isTier4 = skill.hasOwnProperty('scId');

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`Remove skill from your additional skills`}
      onRequestClose={() => void toggle(false)}
      handleClose={() => toggle(false)}
      renderHeader={() => (
        <React.Fragment>
          <h3>Warning</h3>
        </React.Fragment>
      )}
      renderFooter={() => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => toggle(false)}
            type="secondary"
            style={{ marginRight: '7px' }}
          >
            Cancel
          </Button>
          <Button
            className="iq4-skills-module-btn-danger"
            onClick={() => (isTier4 ? onRemove(skill) : onRemove())}
          >
            Remove
          </Button>
        </div>
      )}
    >
      {() => (
        <div>
          {!isTier4 && (
            <p>
              Are you sure you want to delete <strong>{skill.value}</strong> and
              all the associated Knowledge, Skills and Abilities from your
              Passport?
            </p>
          )}

          {isTier4 && <p>Are you sure that you want to delete this skill?</p>}
        </div>
      )}
    </Modal>
  );
};
