import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import { Button, Pill } from 'common/components';

import './JobReqDetails.scss';

export const JobReqDetails = ({ className, isLoading, reqProps, onEdit, ...props }) => {
  return (
    <div className={`iq4-job-req-details ${!!className ? className : ''}`}>
      {isLoading && <Skeleton variant="rect" width="100%" height={800} />}

      {!isLoading && (
        <>
          {!!onEdit && (
            <Button variation="ghost" className="iq4-job-req-details__edit-btn" onClick={onEdit}>
              Edit
            </Button>
          )}

          {!isLoading && (
            <div className="iq4-job-req-details__req-props">
              {reqProps.map((prop) => {
                return (
                  <div className="iq4-job-req-details__req-prop" key={prop.title}>
                    <p className="iq4-job-req-details__req-prop-attr">{prop.title}</p>
                    {prop.description && (
                      <>
                        {prop.title === 'Role Description' && (
                          <pre className="iq4-job-req-details__req-prop-attr">
                            {prop.description}
                          </pre>
                        )}
                        {prop.title !== 'Role Description' && (
                          <p className="iq4-job-req-details__req-prop-attr">{prop.description}</p>
                        )}
                      </>
                    )}

                    {prop.values && !!prop.values.length && (
                      <div className="iq4-job-req-details__req-prop-attr--values">
                        {prop.values.map((val) => {
                          return (
                            <Pill
                              key={val}
                              className="iq4-job-req-details__req-prop-attr-val"
                              label={val}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};
