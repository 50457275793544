import React from 'react';
import PropTypes from 'prop-types';
import { ValidationMessage } from 'common/components/ValidationMessage';
import { Tooltip } from 'common/components/Tooltip';
import './TextArea.scss';
import { useFormContext } from 'react-hook-form';

/**
 * TextArea form input, connected to RHF form context
 * @param validators
 * @param name
 * @param label
 * @param placeholder
 * @param inputProps
 * @param tooltip
 * @param tooltipVariation
 * @returns {*}
 * @constructor
 */
export const TextArea = ({
  validators,
  id,
  name,
  label,
  placeholder,
  tooltip,
  tooltipVariation,
  rows,
  exposeFormContext,
  triggerExternalValidation,
  ...props
}) => {
  const { register, triggerValidation, formState, errors } = useFormContext();
  const touched = formState.touched[name];
  const dirty = formState.dirty;
  const hasError = errors[name];
  const isSubmitted = formState.isSubmitted;
  const showError = () => {
    if (exposeFormContext && !dirty) return false;

    return (touched || isSubmitted || triggerExternalValidation) && hasError;
  };

  return (
    <div className="iq4-textarea__wrapper">
      <label className="iq4-textarea__label" htmlFor={name}>
        {label}
        {tooltip && <Tooltip message={tooltip} variation={tooltipVariation} />}
      </label>
      <textarea
        id={id || name}
        name={name}
        ref={register({ ...validators })}
        placeholder={placeholder}
        type="text"
        className={`iq4-textarea__textarea ${
          hasError && showError() ? 'iq4-textarea__textarea--error' : ''
        }`}
        onChange={triggerValidation}
        aria-invalid={hasError ? 'true' : 'false'}
        aria-describedby={`${name}Error`}
        rows={rows}
        {...props}
      />
      {showError() && <ValidationMessage id={`${name}Error`} message={hasError.message} />}
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  tooltip: PropTypes.node,
  exposeFormContext: PropTypes.bool,
  triggerExternalValidation: PropTypes.bool,
};

TextArea.defaultProps = {
  label: '',
  rows: 4,
};
