import { UPDATE_BROADCASTER } from '../../actionTypes';

export const resetBroadcaster = () => {
  return {
    type: UPDATE_BROADCASTER,
    payload: null,
  };
};

export const broadcastUpdateProf = (payload) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'UPDATE_SKILL_PROFICIENCY',
      ...payload,
    },
  };
};

export const broadcastSearch = ({ skillTier1, searchTerm }) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'SKILLS_SEARCH',
      skillTier1,
      searchTerm,
    },
  };
};

export const broadcastGetBrowseCategories = ({ skillTier1 }) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'SKILLS_BROWSE_CATEGORIES',
      skillTier1,
    },
  };
};

export const boradcastSearchSkillsByRole = (data) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'SKILLS_SEARCH_BY_ROLE',
      ...data,
    },
  };
};

export const broadcastGetBrowseKsas = ({ skillTier1, skillTier2, skillTier3 }) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'SKILLS_BROWSE_KSAS',
      skillTier1,
      skillTier2,
      skillTier3,
    },
  };
};

export const broadcastSaveAdditionalSkills = (payload) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'SAVE_ADDITIONAL_SKILLS',
      payload,
    },
  };
};

export const broadcastRemoveAdditionalSkill = (payload) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'REMOVE_ADDITIONAL_SKILLS',
      payload,
    },
  };
};

export const broadcastAddCerts = (payload) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'ADD_CERTIFICATES',
      payload,
    },
  };
};

export const broadcastRemoveCert = (payload) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'REMOVE_CERTIFICATE',
      payload,
    },
  };
};

export const broadcastUpdateTools = (payload) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'UPDATE_TOOLS',
      payload,
    },
  };
};

export const broadcastRequestRecommendedCourses = (payload) => {
  return {
    type: UPDATE_BROADCASTER,
    payload: {
      type: 'REQUEST_RECOMMENDED_COURSES',
      payload,
    },
  };
};
