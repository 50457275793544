import { SET_TOOLS, SET_ADDED_TOOLS } from '../../actionTypes';

const initialState = {
  all: [],
  added: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TOOLS:
      return {
        ...state,
        all: action.payload
      };
    case SET_ADDED_TOOLS:
      return {
        ...state,
        added: action.payload
      };
    default:
      return state;
  }
}
