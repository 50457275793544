import React from 'react';
import { Check } from '../Icon/Icon';

export const Checkbox = ({ className, ...props }) => {
  return (
    <label className="iq4-skills-module-checkbox">
      <input type="checkbox" {...props} />
      <span className="iq4-skills-module-checkbox__visual"></span>
      <Check />
    </label>
  );
};
