export const UPDATE_COUNTER = 'UPDATE_COUNTER';

// SKILL ACTION TYPES
export const SET_RAW_SKILL = 'SET_RAW_SKILL';
export const SET_RAW_SKILL_V2 = 'SET_RAW_SKILL_V2';
export const SET_SKILLS_SUMMARY = 'SET_SKILLS_SUMMARY';
export const UPDATE_SKILL_PROFICIENCY_T4 = 'UPDATE_SKILL_PROFICIENCY_T4';
export const UPDATE_SKILL_PROFICIENCY = 'UPDATE_SKILL_PROFICIENCY';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const UPDATE_BROWSE_CATEGORIES = 'UPDATE_BROWSE_CATEGORIES';
export const CLEAR_BROWSE_RESULTS = 'CLEAR_BROWSE_RESULTS';
export const UPDATE_BROWSE_KSAS = 'UPDATE_BROWSE_KSAS';
export const REMOVE_BROWSE_RESULT = 'REMOVE_BROWSE_RESULT';
export const REMOVE_ADDTIONAL_SKILLS = 'REMOVE_ADDTIONAL_SKILLS';
export const UPDATE_SAVED_ADDITIONAL_SKILLS = 'UPDATE_SAVED_ADDITIONAL_SKILLS';
export const SET_BROWSE_BY_ROLE_LIST = 'SET_BROWSE_BY_ROLE_LIST';
export const SET_GLOBAL_TIER1S = 'SET_GLOBAL_TIER1S';
export const SET_SKILLS_LOADER = 'SET_SKILLS_LOADER';
export const SET_DEFAULT_LOADER = 'SET_DEFAULT_LOADER';
export const SET_SKILLS_UPDATE = 'SET_SKILLS_UPDATE';
export const SET_CERTS_LIST = 'SET_CERTS_LIST';
export const SET_MY_CERTS = 'SET_MY_CERTS';
export const SET_TOOLS = 'SET_TOOLS';
export const SET_ADDED_TOOLS = 'SET_ADDED_TOOLS';
export const SET_RECOMMENDED_COURSES = 'SET_RECOMMENDED_COURSES';

// BORADCASTER ACTION TYPES
export const UPDATE_BROADCASTER = 'UPDATE_BROADCASTER';
