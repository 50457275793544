import React from 'react';
import PropTypes from 'prop-types';
import './CircleNumber.scss';

export const CircleNumber = ({ variant, value, circleClass }) => (
  <div className={`iq4-circle-number ${circleClass ? circleClass : ''}`}>
    <span>{value}</span>
  </div>
);

CircleNumber.propTypes = {
  value: PropTypes.number.isRequired,
  variant: PropTypes.number.isRequired,
  circleClass: PropTypes.string,
};
