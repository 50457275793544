export const transformProfilePersonal = (personal) => {
  const userProfile = {
    ...personal.userProfile,
    userId: personal.userProfile && personal.userProfile.user && personal.userProfile.user.id,
    firstName:
      personal.userProfile && personal.userProfile.user && personal.userProfile.user.firstName,
    lastName:
      personal.userProfile && personal.userProfile.user && personal.userProfile.user.lastName,
  };

  return {
    userProfile,
    userProfilePhoto: personal.userProfilePhoto,
  };
};

export const transformProfileRecord = (record) => {
  if (!record.resources && !record.competencies) return record;

  const image = getResourceByType(record.resources, 'coverImage', 'fileS3Url');
  const file = getResourceByType(record.resources, 'file', 'fileS3Url');
  const competencies = transformCompetencies(record.competencies);

  const { resources, ...transformed } = {
    ...record,
    ...(image && { image }),
    ...(file && { file }),
    ...(competencies && { competencies }),
  };

  return transformed;
};

export const transformCompetencies = (competencies) => {
  if (!competencies) return;
  return competencies.map((competency) => ({
    id: competency.id,
    value: competency.skillTier3.label,
  }));
};

export const getResourceByType = (resources, type, key) => {
  if (!resources) return;
  const resourcesByType = resources.filter((item) => item.type === type).map((item) => item[key]);
  return resourcesByType.length > 0 ? resourcesByType[0] : null;
};
