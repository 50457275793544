import React from 'react';
import { useSelector } from 'react-redux';
import { SimpleIcon } from 'common/components';
import { ROUTES } from 'routes/routeNames';
import { selectAllUsers } from 'common/store/features/dashboard/selectors';
import './DashboardStudent.scss';

export const DashboardStudent = ({ student, index }) => {
  const allUsers = useSelector(selectAllUsers);
  const studentData = allUsers.find((user) => user.id === student.id);

  const renderPathways = (index, pathways) => {
    return (
      <table className="iq4-dashboard-student__table">
        <tr>
          <th>Pathways Selected</th>
        </tr>
        {pathways &&
          pathways.map((pathway) => (
            <tr>
              <td>{pathway.job.name.toLowerCase()}</td>
            </tr>
          ))}
      </table>
    );
  };

  const renderAchievements = (index, achievements) => {
    return (
      <table className="iq4-dashboard-student__table">
        <tr>
          <th>Achievements</th>
        </tr>

        {achievements &&
          achievements.map((achievement) => (
            <tr>
              <td>{achievement.label.toLowerCase()}</td>
            </tr>
          ))}
      </table>
    );
  };

  return (
    <>
      <div className="iq4-compass-student">
        {studentData.credentials &&
          studentData.credentials.courses &&
          renderAchievements(index, studentData.credentials.courses)}
        {studentData.pathways && renderPathways(index, studentData.pathways)}
      </div>

      <div className="iq4-compass-student__actions">
        <a
          className="iq4-compass-student__actions-button"
          href={`${ROUTES.PATHWAYS}/${student.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SimpleIcon className="iq4-compass-student__actions-button-graph" name="pathwaysGraph" />
          View Student Profile
          <SimpleIcon className="iq4-compass-student__actions-button-arrow" name="arrow" />
        </a>
      </div>
    </>
  );
};
