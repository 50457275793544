import React, { useEffect, useRef, useState } from 'react';
import { SimpleIcon } from '../SimpleIcon';
import { Modal, Button } from 'common/components';
import { MiddleStepModal } from './MiddleStepModal';
import moment from 'moment';

export const MiddleStep = ({ stepIndex, step }) => {
  const middleStepRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const hasProperDescription = !!step.description && step.description !== step.name;

  useEffect(() => {
    const handler = (e) => {
      const isOutside = !(middleStepRef.current && middleStepRef.current.contains(e.target));
      if (isOutside && showPopover) {
        setShowPopover(false);
      }
    };

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [middleStepRef, showPopover]);

  return (
    <>
      <div
        onClick={() => setShowPopover(true)}
        className={`iq4-pathway__mid-step iq4-pathway__mid-step--small-${stepIndex}`}
      >
        <SimpleIcon
          name={step?.type === 'learning' ? 'educationCircle' : 'experienceCirclePathways'}
        />
        <div className="iq4-pathways__mid-step-popover-container" ref={middleStepRef}>
          {!!showPopover && (
            <div className="iq4-pathways__mid-step-popover">
              <div className="iq4-pathways__mid-step-popover--title">
                <SimpleIcon
                  name={step?.type === 'learning' ? 'educationCircle' : 'experienceCirclePathways'}
                  className="iq4-pathways__mid-step-popover--title-icon"
                />
                <div className="iq4-pathways__mid-step-popover--title-label">{step.name}</div>
              </div>
              <div className="iq4-pathways__mid-step-popover--footer">
                <div className="iq4-pathways__mid-step-popover--footer-left">
                  <div className="iq4-pathways__mid-step-popover--footer-count">
                    {step.competencies?.length}
                  </div>
                  <div className="iq4-pathways__mid-step-popover--title-label">Role Skills</div>
                </div>
                <div
                  className="iq4-pathways__mid-step-popover--footer-label-right"
                  onClick={() => setShowModal(true)}
                >
                  View more details
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MiddleStepModal step={step} showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
