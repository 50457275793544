import React from 'react';
import { Button } from 'common/components';
import './Logout.scss';

export const Logout = () => {
  const logoutUrl = process.env.REACT_APP_LOGOUT_URL;

  return (
    <Button
      variation="ghost"
      className="iq4-logout__button"
      onClick={() => window.open(logoutUrl, '_self')}
    >
      Logout
    </Button>
  );
};
