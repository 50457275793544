import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link, Switch, Route, useHistory } from 'react-router-dom';
import { MainNavOptions } from 'routes/MainNavOptions';
import { MainNavbar, Button, SimpleIcon, Modal } from 'common/components';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'common/components/Form/fieldTypes/Checkbox';
import { useJobReqFormDefinition, useParsedJobReqFormDefinition } from 'common/hooks/forms';
import { parseJobReq, saveNewJobReqWithSkills } from 'store/features/jobReqs/jobReqsSlice.js';
import { selectParsedJobReq } from 'store/features/jobReqs/selectors';
import { FormWrapper } from 'common/components/Form';
import { ROUTES } from 'routes/routeNames';
import { UploadFileHelper } from 'common/components/UploadFileHelper';
import './NewJobReqPage.scss';
const PARSE_JOB_REQ_TITLE = [null, '— Details', '— Skills'];

export const NewJobReqPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [creatingJobReqError, setCreatingJobReqError] = useState(null);

  const [modalWizardConfig, setModalWizardConfig] = useState({
    parseModal: { maxSteps: 3, currentStep: 1 },
    manualModal: { maxSteps: 3, currentStep: 1 },
  });

  const [values, setValues] = useState({});

  const parsedJobReq = useSelector(selectParsedJobReq);
  const [confirmedSkills, setConfirmedSkills] = useState(
    getInitialConfirmedSkills(parsedJobReq?.skills),
  );

  const handleEditComplete = ({ data, error }) => {
    if (creatingJobReqError) setCreatingJobReqError(null);

    if (error) {
      setCreatingJobReqError(
        'Something went wrong saving your job requisition. Please try again later',
      );
    }

    // if saved successfully navigate to individual req page.
    if (data?.role?.id) {
      history.push(`${ROUTES.JOB_REQS}/${data.role.id}`);
    }
  };

  // handle route changes for new req modals
  useEffect(() => {
    const newJobReqTypeFromRoute = history.location.pathname
      .split('/')
      .find((param) => param === 'parse' || param === 'manual');

    if (isFirstRender.current) {
      isFirstRender.current = false;

      // if user refreshes on opened create job req modal navigate to landing page for new job req
      if (newJobReqTypeFromRoute) {
        history.push(`${ROUTES.JOB_REQS}/new`);
      }
      return;
    }

    // don't do anything if it's on landing page
    if (
      history.location.pathname === `${ROUTES.JOB_REQS}/new` ||
      history.location.pathname === `${ROUTES.JOB_REQS}/new/`
    ) {
      return;
    }

    // invalid url relating to modals
    if (!newJobReqTypeFromRoute) return;

    history.push(
      `${ROUTES.JOB_REQS}/new/${newJobReqTypeFromRoute}/${
        modalWizardConfig[`${newJobReqTypeFromRoute}Modal`].currentStep
      }`,
    );
  }, [modalWizardConfig, history]);

  useEffect(() => {
    if (parsedJobReq?.skills) {
      const newParsedSkills = getInitialConfirmedSkills(parsedJobReq.skills);
      setConfirmedSkills(newParsedSkills);
    }
  }, [parsedJobReq]);

  function getInitialConfirmedSkills(skills = []) {
    return skills.reduce((map, skill) => {
      map[skill.competenceId] = true;
      return map;
    }, {});
  }

  return (
    <div className="iq4-new-job-reqs-page">
      <MainNavbar className="iq4-new-job-reqs-page__navbar" MainNavOptions={MainNavOptions} />

      <main className="iq4-default-page-container iq4-new-job-reqs-page__main">
        <header className="iq4-new-job-reqs-page__header">
          <Link to={ROUTES.JOB_REQS} className="iq4-new-job-reqs-page__header-back-icon">
            <SimpleIcon name="arrow" />
          </Link>
          <h1 className="iq4-new-job-reqs-page__header-title">Create a Job Requisition</h1>
        </header>

        <div className="iq4-new-job-reqs-page__main-card">
          <p className="iq4-new-job-reqs-page__card-title">
            Choose an options to help you start creating your Job Req
          </p>

          <div className="iq4-new-job-reqs-page__action-cards-container">
            <div className="iq4-new-job-reqs-page__action-card">
              <span className="iq4-new-job-reqs-page__action-card-icon">
                <SimpleIcon name="experience" />
              </span>
              <h2 className="iq4-new-job-reqs-page__action-card-title">Add Job Req</h2>
              <p className="iq4-new-job-reqs-page__action-card-desc">
                Would you prefer to fill out the Job Req manually?
              </p>
              <Button
                onClick={() => void history.push(`${ROUTES.JOB_REQS}/new/manual`)}
                variation="ghost"
                className="iq4-new-job-reqs-page__action-card-btn"
              >
                Add Job Req
              </Button>
            </div>

            <div className="iq4-new-job-reqs-page__action-card">
              <span className="iq4-new-job-reqs-page__action-card-icon">
                <SimpleIcon name="upload" />
              </span>
              <h2 className="iq4-new-job-reqs-page__action-card-title">Parse a Job Req Document</h2>
              <p className="iq4-new-job-reqs-page__action-card-desc">
                Use a pre filled out document
              </p>
              <Button
                onClick={() => void history.push(`${ROUTES.JOB_REQS}/new/parse`)}
                variation="ghost"
                className="iq4-new-job-reqs-page__action-card-btn"
              >
                Parse document
              </Button>
            </div>
          </div>
        </div>

        <Route path={`${ROUTES.JOB_REQS}/new/parse`}>
          <Modal
            title={
              <div className="iq4-new-job-reqs-page__modal-header-container">
                {modalWizardConfig.parseModal.currentStep > 1 && (
                  <button
                    className="iq4-new-job-reqs-page__modal-back-btn"
                    onClick={() => {
                      setModalWizardConfig((current) => ({
                        ...current,
                        parseModal: {
                          ...current.parseModal,
                          currentStep: current.parseModal.currentStep - 1,
                        },
                      }));
                    }}
                  >
                    <SimpleIcon name="arrow" />
                  </button>
                )}
                <span>
                  Parse Your Job Req{' '}
                  {PARSE_JOB_REQ_TITLE[modalWizardConfig.parseModal.currentStep - 1]}
                </span>
              </div>
            }
            isOpen={true}
            handleClose={() => {
              history.push(`${ROUTES.JOB_REQS}/new`);

              setModalWizardConfig((current) => ({
                ...current,

                parseModal: {
                  ...current.parseModal,
                  currentStep: 1,
                },
              }));
            }}
          >
            <Switch>
              <Route
                exact
                path={[`${ROUTES.JOB_REQS}/new/parse/1`, `${ROUTES.JOB_REQS}/new/parse`]}
              >
                <UploadFileHelper
                  onUpload={async (data) => {
                    try {
                      await dispatch(parseJobReq(data.formData));

                      setModalWizardConfig((current) => ({
                        ...current,
                        parseModal: {
                          ...current.parseModal,
                          currentStep: 2,
                        },
                      }));
                    } catch (e) {
                      console.log('something went wrong parsing your file', e);
                    }
                  }}
                />
              </Route>
              <Route exact path={`${ROUTES.JOB_REQS}/new/parse/2`}>
                <FormWrapper
                  submitLabel="Next"
                  showButtons
                  values={parsedJobReq?.jobReqMeta}
                  useFormDefinition={useParsedJobReqFormDefinition}
                  onComplete={({ error }) => {
                    if (!error) {
                      setModalWizardConfig((current) => ({
                        ...current,
                        parseModal: {
                          ...current.parseModal,
                          currentStep: current.parseModal.currentStep + 1,
                        },
                      }));
                    }
                  }}
                />
              </Route>
              <Route exact path={`${ROUTES.JOB_REQS}/new/parse/3`}>
                {parsedJobReq?.skills &&
                  parsedJobReq.skills.map((skill) => {
                    return (
                      <label
                        className="iq4-new-job-reqs-page__parsed-skill-checkbox"
                        key={skill.competenceId}
                      >
                        <span>{skill.label}</span>
                        <Checkbox
                          handleChange={(e) => {
                            setConfirmedSkills((current) => ({
                              ...current,
                              [skill.competenceId]: !current[skill.competenceId],
                            }));
                          }}
                          isChecked={confirmedSkills[skill.competenceId]}
                        />
                      </label>
                    );
                  })}
              </Route>
            </Switch>

            <div className="iq4-new-job-reqs-page__modal_footer">
              {creatingJobReqError && (
                <p className="iq4-new-job-reqs-page__modal_footer-err-msg">{creatingJobReqError}</p>
              )}
              {modalWizardConfig.parseModal.currentStep === 3 && (
                <Button
                  onClick={async () => {
                    // reset error
                    if (creatingJobReqError) setCreatingJobReqError(null);

                    const selectedSkills = parsedJobReq.skills.filter(
                      (s) => confirmedSkills[s.competenceId],
                    );

                    try {
                      const savedJobReq = await dispatch(
                        saveNewJobReqWithSkills({
                          skills: selectedSkills,
                          jobReq: parsedJobReq.jobReqMeta,
                        }),
                      );

                      history.push(`${ROUTES.JOB_REQS}/${savedJobReq.payload.role.id}`);
                    } catch (e) {
                      setCreatingJobReqError(
                        'Something went wrong saving your job requisition. Please try again later.',
                      );
                      console.log('something went wrong saving your job req', e);
                    }
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Modal>
        </Route>

        <Route path={`${ROUTES.JOB_REQS}/new/manual`}>
          <Modal
            isOpen={true}
            handleClose={() => {
              history.push(`${ROUTES.JOB_REQS}/new`);
              setModalWizardConfig((current) => ({
                ...current,
                manualModal: {
                  ...current.manualModal,
                  currentStep: 1,
                },
              }));
            }}
            title={
              <div className="iq4-new-job-reqs-page__modal-header-container">
                {modalWizardConfig.parseModal.currentStep > 1 && (
                  <button
                    className="iq4-new-job-reqs-page__modal-back-btn"
                    onClick={() => {
                      setModalWizardConfig((current) => ({
                        ...current,
                        manualModal: {
                          ...current.manualModal,
                          currentStep: current.manualModal.currentStep - 1,
                        },
                      }));
                    }}
                  >
                    <SimpleIcon name="arrow" />
                  </button>
                )}
                <span>Add Job Req</span>
              </div>
            }
          >
            <Switch>
              <Route
                exact
                path={[`${ROUTES.JOB_REQS}/new/manual/1`, `${ROUTES.JOB_REQS}/new/manual`]}
              >
                <FormWrapper
                  submitLabel="Save"
                  showButtons
                  values={values}
                  useFormDefinition={useJobReqFormDefinition}
                  onComplete={handleEditComplete}
                />
              </Route>
            </Switch>

            <div className="iq4-new-job-reqs-page__modal_footer">
              {creatingJobReqError && (
                <p className="iq4-new-job-reqs-page__modal_footer-err-msg">{creatingJobReqError}</p>
              )}

              {modalWizardConfig.manualModal.currentStep !== 1 &&
                modalWizardConfig.manualModal.currentStep <
                  modalWizardConfig.manualModal.maxSteps && (
                  <Button
                    onClick={() => {
                      setModalWizardConfig((current) => ({
                        ...current,
                        manualModal: {
                          ...current.manualModal,
                          currentStep: current.manualModal.currentStep + 1,
                        },
                      }));
                    }}
                  >
                    Next
                  </Button>
                )}
            </div>
          </Modal>
        </Route>
      </main>
    </div>
  );
};
