import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import { SORT_DIRECTION } from 'common/utils/constants';
import './TabbedSkillList.scss';
import {
  SimpleIcon,
  SkillCard,
  LoadingSkeleton,
  Paginator,
  TabbedCard,
  CountIndicator,
} from 'common/components';
import { ManageSkillsCardSort } from '../ManageSkillsCard/ManageSkillsCardSort';
import EmptyStateImage from '@assets/svg/empty-states_skills.svg';
import {
  DEFAULT_SKILLS_FILTER,
  DEFAULT_SKILLS_SORT,
  DEFAULT_TABLE_PAGE_SIZE,
  SKILLS_SORT_FUNCS,
} from 'common/constants/miscellaneous';
import { runGenericFilter } from 'common/utils';
import { SelectPageSize } from '../DataTable/SelectPageSize';
import _orderBy from 'lodash/orderBy';

/**
 * Manage Skills Card - holds control of skills filtering & sorting
 * @param skills
 * @returns {*}
 * @constructor
 */
export const TabbedSkillList = ({
  skills,
  expandedId = null,
  expandingId,
  loading = false,
  onExpanded = null,
  activeTabIndex = 0,
  onTabChange,
  onBackClick,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const [currentSort, setCurrentSort] = useState(DEFAULT_SKILLS_SORT);
  const [paginationData, setPaginationData] = useState({ currentPage: 1, pageLimit: rowsPerPage });
  const [currentSkills, setCurrentSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState(
    orderBy(skills, (s) => s.tier3Label, SORT_DIRECTION.ASC),
  );
  const [expanded, setExpanded] = useState(expandedId);
  const [expanding, setExpanding] = useState(expandingId);
  const [skillTypeCount, setSkillTypeCount] = useState({
    ALL: 0,
    technologyCategory: 0,
    professionalSkillsCategory: 0,
    businessDevelopmentCategory: 0,
  });

  // keep skill types count up to date
  useEffect(() => {
    const newSkillTypeCount = getSkillTypeCount(skills);
    setSkillTypeCount(newSkillTypeCount);
  }, [skills]);

  useEffect(() => {
    setExpanded(expandedId);
  }, [expandedId]);

  useEffect(() => {
    setExpanding(expandingId);
  }, [expandingId]);

  const expandSkill = (skill) => {
    const newExpandedId = skill.competenceId === expanded ? null : skill.competenceId;
    setExpanded(newExpandedId);

    // notify parent if available
    if (onExpanded) {
      onExpanded(newExpandedId);
    }
  };

  const updateSkillsData = (data) => {
    const { currentPage, pageLimit } = data;
    const activeSort = getActiveSort(currentSort);
    const newOrderedRows = _orderBy(filteredSkills, [activeSort], [currentSort.direction]);
    const offset = (currentPage - 1) * pageLimit;

    const newCurrentSkills =
      pageLimit === skills.length
        ? newOrderedRows
        : newOrderedRows.slice(offset, offset + pageLimit);

    setPaginationData({
      ...paginationData,
      ...data,
    });

    setCurrentSkills(newCurrentSkills);
  };

  useEffect(() => {
    if (filteredSkills.length <= rowsPerPage) {
      updateSkillsData({ currentPage: 1, pageLimit: rowsPerPage });
    }
  }, [filteredSkills, rowsPerPage]);

  useEffect(() => {
    if (paginationData) {
      updateSkillsData(paginationData);
    }
  }, [currentSort]);

  useEffect(() => {
    updateSkillsData({ currentPage: 1, pageLimit: rowsPerPage });
  }, [activeTabIndex]);

  const getActiveSort = (sort) => Object.keys(sort).find((key) => sort[key]);

  const manageChangeFilter = (tabIndex) => {
    if (onTabChange) {
      onTabChange(tabIndex);
    }

    const indexToKeys = [
      '_',
      'technologyCategory',
      'professionalSkillsCategory',
      'businessDevelopmentCategory',
    ];
    let newFilters = { ...DEFAULT_SKILLS_FILTER };
    if (tabIndex !== 0) {
      newFilters = { ...newFilters, [indexToKeys[tabIndex]]: true };
    }

    applyFilters(newFilters);
    setPaginationData({ currentPage: 1, pageLimit: rowsPerPage });
  };

  const manageChangeSort = (newCurrentSort) => {
    setCurrentSort(newCurrentSort);
    const newSort = getActiveSort(newCurrentSort);
    applySort(newSort);
  };

  const handlePageSizeChange = (value) => setRowsPerPage(value);

  const applySort = (newSort, target = filteredSkills) => {
    setFilteredSkills(SKILLS_SORT_FUNCS[newSort](target));
  };

  function filterCategory(inputSkills, inputFilter) {
    const categoryProps = [
      'technologyCategory',
      'professionalSkillsCategory',
      'businessDevelopmentCategory',
    ];
    const hasSomeFilter =
      inputFilter.technologyCategory ||
      inputFilter.professionalSkillsCategory ||
      inputFilter.businessDevelopmentCategory;

    // if no filter is applied just return input
    if (!hasSomeFilter) return inputSkills;

    return runGenericFilter({ categoryProps, inputFilter, inputSkills });
  }

  function filterProficiencies(inputSkills, inputFilter) {
    const categoryProps = [
      'proficiencyOne',
      'proficiencyTwo',
      'proficiencyThree',
      'proficiencyFour',
    ];
    const hasSomeFilter =
      inputFilter.proficiencyOne ||
      inputFilter.proficiencyTwo ||
      inputFilter.proficiencyThree ||
      inputFilter.proficiencyFour;

    // if no filter is applied just return input
    if (!hasSomeFilter) return inputSkills;
    return runGenericFilter({ categoryProps, inputFilter, inputSkills });
  }

  function getSkillTypeCount(skills) {
    return ['technologyCategory', 'professionalSkillsCategory', 'businessDevelopmentCategory']
      .map((type) => {
        return {
          type,
          skills: runGenericFilter({
            categoryProps: [type],
            inputFilter: { [type]: true },
            inputSkills: skills,
          }),
        };
      })
      .reduce(
        (map, current) => {
          map[current.type] = current.skills.length;
          return map;
        },
        { ALL: skills.length },
      );
  }

  const applyFilters = (currentFilter) => {
    const filteredByCategory = filterCategory(skills, currentFilter);
    const filteredByProficiencies = filterProficiencies(filteredByCategory, currentFilter);

    const activeSort = getActiveSort(currentSort);

    if (!activeSort) return;
    applySort(activeSort, filteredByProficiencies);
  };

  const renderTab = ({ label, variation, count }) => {
    return (
      <div className="iq4-tabbed-skill-list__tab">
        <div className="iq4-tabbed-skill-list__tab-label">
          {label}
          <div className="iq4-tabbed-skill-list_tab-counter">
            <CountIndicator variation={variation} count={count} />
          </div>
        </div>

        <div className="iq4-tabbed-skill-list__tab-sort">
          <ManageSkillsCardSort currentSort={currentSort} onChangeSort={manageChangeSort} />
        </div>
      </div>
    );
  };

  const getTabs = () => {
    return [
      { label: renderTab({ label: 'All', count: skillTypeCount['ALL'], variation: 'dark' }) },
      {
        label: renderTab({
          label: 'Technology',
          count: skillTypeCount['technologyCategory'],
          variation: 'tech',
        }),
      },
      {
        label: renderTab({
          label: 'Professional',
          count: skillTypeCount['professionalSkillsCategory'],
          variation: 'professional',
        }),
      },
      {
        label: renderTab({
          label: 'Business Development',
          count: skillTypeCount['businessDevelopmentCategory'],
          variation: 'business',
        }),
      },
    ];
  };

  const renderLoadingSkeleton = () => {
    return (
      <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    );
  };

  const renderLoadingList = () => [
    renderLoadingSkeleton(),
    renderLoadingSkeleton(),
    renderLoadingSkeleton(),
    renderLoadingSkeleton(),
  ];

  return (
    <div
      className={`iq4-tabbed-skill-list ${
        !loading && skills.length === 0 && 'iq4-tabbed-skill-list--empty'
      }`}
    >
      <div className="iq4-tabbed-skill-list__header">
        <div className="iq4-tabbed-skill-list__header--left">
          <div className="iq4-tabbed-skill-list__header-container">
            {onBackClick && (
              <button className="iq4-tabbed-skill-list__bact-btn" onClick={onBackClick}>
                <SimpleIcon name="arrow" />
              </button>
            )}
            <SimpleIcon
              className="iq4-tabbed-skill-list__header-icon"
              name="skillsCircle"
              role="presentation"
              hidden
            />
            <h2 className="iq4-tabbed-skill-list__header-title">Skills</h2>
          </div>
        </div>
      </div>

      <TabbedCard
        className="iq4-tabbed-skill-list__tabs"
        onChange={manageChangeFilter}
        tabs={getTabs()}
        activeIndex={activeTabIndex}
      >
        {() => (
          <>
            {filteredSkills.length >= 10 && (
              <div className="iq4-tabbed-skill-list__tabs-header">
                <div className="iq4-manage-skills__pagination">
                  <div className="iq4-datatable__paginator-container">
                    <>
                      <span className="iq4-datatable__paginator-label">Display per page</span>
                      <SelectPageSize
                        handlePageSizeChange={handlePageSizeChange}
                        rows={filteredSkills}
                        rowsPerPage={rowsPerPage}
                      />
                      <span
                        className="iq4-datatable__view-all"
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => handlePageSizeChange(filteredSkills.length)}
                        onClick={() => handlePageSizeChange(filteredSkills.length)}
                      >
                        View All
                      </span>
                      {filteredSkills.length > rowsPerPage && (
                        <Paginator
                          totalRecords={filteredSkills.length}
                          onPageChanged={updateSkillsData}
                          pageLimit={rowsPerPage}
                          outCurrentPage={paginationData ? paginationData.currentPage : null}
                        />
                      )}
                    </>
                  </div>
                </div>
              </div>
            )}

            <div className="iq4-tabbed-skill-list__content">
              {!loading ? (
                <>
                  {/* render the skills list here */}
                  {currentSkills.map((skill, index) => (
                    <div className="iq4-tabbed-skill-list__skill-card" key={skill.competenceId}>
                      <SkillCard
                        readOnly
                        skill={skill}
                        handleClick={() => expandSkill(skill)}
                        isExpanding={skill.competenceId === expanding}
                        isExpanded={skill.competenceId === expanded}
                        key={skill.competenceId}
                        index={index}
                      />
                    </div>
                  ))}

                  {skills.length === 0 && (
                    <div className="iq4-tabbed-skill-list__empty-state">
                      <p>No skills available</p>
                      <img
                        className="iq4-tabbed-skill-list__empty-state-image"
                        src={EmptyStateImage}
                        alt=""
                      />
                    </div>
                  )}

                  {/* no filter results available */}
                  {!!(!filteredSkills.length && skills.length) && (
                    <div className="iq4-tabbed-skill-list__no-results-container">
                      <div className="iq4-tabbed-skill-list__no-results">No results returned!</div>
                    </div>
                  )}
                </>
              ) : (
                renderLoadingList()
              )}
            </div>
          </>
        )}
      </TabbedCard>
    </div>
  );
};
