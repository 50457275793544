/**
 * Main abstracter function to manage HTTP requests
 * @param url
 * @param data
 * @param verb
 * @returns {Promise<Response | never>}
 */
export function sendRequest({ url, data, verb }) {
  const requestData = {
    method: verb,
  };

  if (!['DELETE'].includes(verb)) {
    requestData.body = JSON.stringify(data);
  }

  const headersForRequest = new Headers({
    'Content-Type': 'application/json',
  });

  requestData.headers = headersForRequest;

  const request = new Request(url, requestData);

  return fetch(request)
    .then(parseResponse)
    .catch((err) => {
      throw err;
    });
}

export function sendFormDataRequest({ url, data, verb }) {
  const formData = new FormData();
  const info = {};

  for (var key in data) {
    if (data[key] instanceof File) {
      formData.append(key, data[key]);
    } else {
      info[key] = data[key];
    }
  }

  if (Object.keys(info).length > 0) {
    formData.append('info', JSON.stringify(info));
  }

  const requestData = {
    body: formData,
    method: verb,
  };

  return fetch(url, requestData)
    .then(parseResponse)
    .catch((err) => {
      throw err;
    });
}

export function transformProfileFormsPutData(data, values) {
  const competenciesToDelete =
    values.competencies &&
    values.competencies.filter((valueCompetency) =>
      data.competencies
        ? data.competencies
            .map((dataCompetency) => dataCompetency.id)
            .indexOf(valueCompetency.id) === -1
        : true,
    );

  const competenciesToAdd =
    data.competencies &&
    data.competencies.filter((dataComptency) =>
      values.competencies
        ? values.competencies
            .map((valueCompetency) => valueCompetency.id)
            .indexOf(dataComptency.id) === -1
        : true,
    );

  return {
    ...data,
    ...(data.image === null && { deleteCoverImage: true }),
    ...(competenciesToDelete && competenciesToDelete.length > 0 && { competenciesToDelete }),
    ...(competenciesToAdd && competenciesToAdd.length > 0 && { competenciesToAdd }),
  };
}

/**
 * Parses the response object and returns it
 * It returns a resolved promise
 *
 * @param result
 * @returns {Promise}
 */
function parseResponse(result) {
  return new Promise((resolve, reject) => {
    return result
      .json()
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.warn('ERROR while convering to JSON: ', error);
        return reject({ result, error });
      });
  });
}
