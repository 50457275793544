import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { SimpleIcon, RatingStars } from 'common/components';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { Checkbox } from 'common/components/Form/fieldTypes/Checkbox';
import { RATING_STARS_SIZE } from 'common/constants/miscellaneous';
import { useSelector } from 'react-redux';
import { selectFeatures } from 'common/store/features/config/selectors';
import { ClickAwayListener } from '@material-ui/core';
import './ManageSkillsCardFilter.scss';

/**
 * Main skills filter component
 * @param currentFilter
 * @param onChangeFilter
 * @param {object[]} [customFilters] - provide custom filters in the format: [ { filterGroupTitle: '', filters: [{ label: '', key: '' }] }, ...]
 * @returns {*}
 * @constructor
 */
export const ManageSkillsCardFilter = ({
  currentFilter,
  onChangeFilter,
  customFilters,
  hasProfFilter = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const filterRef = useRef(null);
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));
  const [showFilterDescription, setShowFilterDescription] = useState(false);
  const lcnEnabled = false;

  const FILTER_DESCRIPTIONS = [
    {
      title: 'IT Skills',
      label:
        'IT skills are the abilities and knowledge needed to perform specific tasks. They are practical, and often relate to mechanical, information technology, mathematical, or scientific tasks.',
    },
    {
      title: 'Professional Skills',
      label: `Professionals skills are abilities that can help you succeed in your
job. A professional skill describes a habit, personality trait or ability that positively
affects your performance in the workplace.`,
    },
    {
      title: 'Soft Skills',
      label: `Soft skills are skills that help people understand the
consumer and organizational behavior and use this information to promote the success of the
company.`,
    },
    {
      ...(lcnEnabled
        ? {
            title: 'Common Skills',
            label: `Common skills can be self-taught and usually do not necessitate a certain
completed level of education, such as a Master’s degree, or other
certifications/credentials. They are essential in many industries and occupations (e.g.
problem-solving, project management).`,
          }
        : {}),
    },
    {
      ...(lcnEnabled
        ? {
            title: 'Hard Skills',
            label: `Hard skills are specific, learnable, measurable, often industry- or
occupation-specific abilities related to a position. A hard skill for nurses might be CPR,
and a hard skill for a data analyst might be JavaScript.`,
          }
        : {}),
    },
  ];

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);
  const toggleVisibility = () => setIsVisible(!isVisible);

  function handleClickOutside(event) {
    if (!isVisible) return;
    const isClickOutside = filterRef.current && !filterRef.current.contains(event.target);
    setIsVisible(!isClickOutside);
  }

  useEffect(() => {
    if (isMobile) return;
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile]);

  const updateFilter = (evt, change) => {
    onChangeFilter({
      ...currentFilter,
      [change]: evt.target.checked,
    });
  };

  const clearAllFilters = () => {
    const clearedFilters = Object.keys(currentFilter).reduce((map, filterKey) => {
      map[filterKey] = false;
      return map;
    }, {});

    onChangeFilter(clearedFilters);
  };

  const renderCustomFilters = (filters) => {
    return filters.map((filterData, i) => {
      if (!lcnEnabled && filterData.filterGroupTitle === 'type') {
        return null;
      }
      return (
        <>
          <div className="iq4-manage-skills__filter-section--title">
            {filterData.filterGroupTitle}
          </div>
          <div className="iq4-manage-skills__filter-section--options-container">
            {filterData.filters.map((filterItem) => {
              return (
                <label
                  className={`iq4-manage-skills__filter-section--options ${
                    currentFilter[filterItem.key]
                      ? 'iq4-manage-skills__filter-section--options-active'
                      : ''
                  }`}
                >
                  <span>{filterItem.label}</span>
                  <Checkbox
                    handleChange={(e) => updateFilter(e, filterItem.key)}
                    isChecked={currentFilter[filterItem.key]}
                    key={currentFilter[filterItem.key]}
                  />
                </label>
              );
            })}
            {!isMobile && i !== filters.length - 1 && (
              <div className="iq4-manage-skills__filter-section--separator" />
            )}
          </div>
        </>
      );
    });
  };

  const renderFilterDescriptions = ({ isModal } = {}) => {
    return (
      <div
        className={`iq4-manage-skills__filter-descriptions-container ${
          isModal ? 'iq4-manage-skills__filter-descriptions-container--modal' : ''
        }`}
      >
        <button
          className="iq4-manage-skills__filter-descriptions-back-btn"
          onClick={() => void setShowFilterDescription(false)}
        >
          {!isModal && (
            <>
              <SimpleIcon name="arrow" />
              Back
            </>
          )}
          {isModal && <SimpleIcon name="close" />}
        </button>
        <h2 className="iq4-manage-skills__filter-descriptions-header">Filter Definitions</h2>

        {FILTER_DESCRIPTIONS.map((filterDescription) => {
          return (
            <div
              className="iq4-manage-skills__filter-descriptions-labels"
              key={filterDescription.title}
            >
              <p className="iq4-manage-skills__filter-descriptions-labels-title">
                {filterDescription.title}
              </p>
              <p className="iq4-manage-skills__filter-descriptions-label">
                {filterDescription.label}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  function getIsMobile(breakpoint) {
    return breakpoint === 'xs';
  }

  return (
    <div className="iq4-manage-skills__filter-container" ref={filterRef}>
      <div
        className="iq4-manage-skills__filter-trigger"
        role="button"
        tabIndex={0}
        onClick={toggleVisibility}
        onKeyPress={toggleVisibility}
      >
        <SimpleIcon name="filter" />
        <span className="iq4-manage-skills__filter-trigger--label">Filter</span>
      </div>

      {!isMobile && isVisible && (
        <ClickAwayListener onClickAway={() => setIsVisible(false)}>
          <div className="iq4-manage-skills__filter-section">
            {customFilters ? (
              renderCustomFilters(customFilters)
            ) : (
              <>
                <div className="iq4-manage-skills__filter-section--title">category</div>
                <div className="iq4-manage-skills__filter-section--options-container">
                  <div className="iq4-manage-skills__filter-section--options">
                    <span>IT Skills</span>
                    <Checkbox
                      handleChange={(e) => updateFilter(e, 'technologyCategory')}
                      isChecked={currentFilter.technologyCategory}
                      key={currentFilter.technologyCategory}
                    />
                  </div>
                  <div className="iq4-manage-skills__filter-section--options">
                    <span>Professional Skills</span>
                    <Checkbox
                      handleChange={(e) => updateFilter(e, 'professionalSkillsCategory')}
                      isChecked={currentFilter.professionalSkillsCategory}
                    />
                  </div>
                  <div className="iq4-manage-skills__filter-section--options">
                    <span>Soft Skills</span>
                    <Checkbox
                      handleChange={(e) => updateFilter(e, 'businessDevelopmentCategory')}
                      isChecked={currentFilter.businessDevelopmentCategory}
                    />
                  </div>
                  {lcnEnabled && (
                    <>
                      <div className="iq4-manage-skills__filter-section--separator" />
                      <div className="iq4-manage-skills__filter-section--title">outcome type</div>
                      <div className="iq4-manage-skills__filter-section--options">
                        <span>Common</span>
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'outcomeTypeCommon')}
                          isChecked={currentFilter.outcomeTypeCommon}
                        />
                      </div>
                      <div className="iq4-manage-skills__filter-section--options">
                        <span>Hard</span>
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'outcomeTypeHard')}
                          isChecked={currentFilter.outcomeTypeHard}
                        />
                      </div>
                    </>
                  )}
                  {hasProfFilter && (
                    <>
                      <div className="iq4-manage-skills__filter-section--separator" />
                      <div className="iq4-manage-skills__filter-section--title">proficiency</div>
                      <div className="iq4-manage-skills__filter-section--options">
                        <RatingStars value={1} max={1} size={RATING_STARS_SIZE.SMALL} readOnly />
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'proficiencyOne')}
                          isChecked={currentFilter.proficiencyOne}
                        />
                      </div>
                      <div className="iq4-manage-skills__filter-section--options">
                        <RatingStars value={2} max={2} size={RATING_STARS_SIZE.SMALL} readOnly />
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'proficiencyTwo')}
                          isChecked={currentFilter.proficiencyTwo}
                        />
                      </div>
                      <div className="iq4-manage-skills__filter-section--options">
                        <RatingStars value={3} max={3} size={RATING_STARS_SIZE.SMALL} readOnly />
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'proficiencyThree')}
                          isChecked={currentFilter.proficiencyThree}
                        />
                      </div>
                      <div className="iq4-manage-skills__filter-section--options">
                        <RatingStars value={4} max={4} size={RATING_STARS_SIZE.SMALL} readOnly />
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'proficiencyFour')}
                          isChecked={currentFilter.proficiencyFour}
                        />
                      </div>
                    </>
                  )}
                </div>

                <button
                  className="iq4-manage-skills__filter-section-description-btn iq4-manage-skills__filter-section-description-btn--desktop"
                  onClick={() => void setShowFilterDescription(true)}
                >
                  What do these filters mean?
                </button>
              </>
            )}{' '}
          </div>
        </ClickAwayListener>
      )}

      {isMobile &&
        isVisible &&
        ReactDOM.createPortal(
          <div className="iq4-manage-skills__filter-section--mobile">
            {showFilterDescription ? (
              renderFilterDescriptions()
            ) : (
              <>
                <div className="iq4-manage-skills__filter-section__header">
                  <button
                    className="iq4-manage-skills__filter-section__header-back-btn"
                    onClick={toggleVisibility}
                  >
                    <SimpleIcon name="arrow" />
                    Back
                  </button>
                </div>

                <div className="iq4-manage-skills__filter-section__filter-header">
                  <h2>Filters</h2>
                  <button
                    className="iq4-manage-skills__filter-section__header-clear-btn"
                    onClick={clearAllFilters}
                  >
                    Clear
                  </button>
                </div>

                {customFilters ? (
                  renderCustomFilters(customFilters)
                ) : (
                  <>
                    <div className="iq4-manage-skills__filter-section--title">category</div>
                    <div className="iq4-manage-skills__filter-section--options-container">
                      <label
                        className={`iq4-manage-skills__filter-section--options ${
                          currentFilter.technologyCategory
                            ? 'iq4-manage-skills__filter-section--options-active'
                            : ''
                        }`}
                      >
                        <span>IT Skills</span>
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'technologyCategory')}
                          isChecked={currentFilter.technologyCategory}
                          key={currentFilter.technologyCategory}
                        />
                      </label>
                      <label
                        className={`iq4-manage-skills__filter-section--options ${
                          currentFilter.professionalSkillsCategory
                            ? 'iq4-manage-skills__filter-section--options-active'
                            : ''
                        }`}
                      >
                        <span>Professional Skills</span>
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'professionalSkillsCategory')}
                          isChecked={currentFilter.professionalSkillsCategory}
                        />
                      </label>
                      <label
                        className={`iq4-manage-skills__filter-section--options ${
                          currentFilter.businessDevelopmentCategory
                            ? 'iq4-manage-skills__filter-section--options-active'
                            : ''
                        }`}
                      >
                        <span>Soft Skills</span>
                        <Checkbox
                          handleChange={(e) => updateFilter(e, 'businessDevelopmentCategory')}
                          isChecked={currentFilter.businessDevelopmentCategory}
                        />
                      </label>

                      {hasProfFilter && (
                        <>
                          <div className="iq4-manage-skills__filter-section--title">
                            proficiency
                          </div>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.proficiencyOne
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <RatingStars
                              value={1}
                              max={1}
                              size={RATING_STARS_SIZE.SMALL}
                              readOnly
                            />
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'proficiencyOne')}
                              isChecked={currentFilter.proficiencyOne}
                            />
                          </label>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.proficiencyTwo
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <RatingStars
                              value={2}
                              max={2}
                              size={RATING_STARS_SIZE.SMALL}
                              readOnly
                            />
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'proficiencyTwo')}
                              isChecked={currentFilter.proficiencyTwo}
                            />
                          </label>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.proficiencyThree
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <RatingStars
                              value={3}
                              max={3}
                              size={RATING_STARS_SIZE.SMALL}
                              readOnly
                            />
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'proficiencyThree')}
                              isChecked={currentFilter.proficiencyThree}
                            />
                          </label>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.proficiencyFour
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <RatingStars
                              value={4}
                              max={4}
                              size={RATING_STARS_SIZE.SMALL}
                              readOnly
                            />
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'proficiencyFour')}
                              isChecked={currentFilter.proficiencyFour}
                            />
                          </label>
                        </>
                      )}
                      {lcnEnabled && (
                        <>
                          <div className="iq4-manage-skills__filter-section--title">
                            outcome type
                          </div>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.outcomeTypeCommon
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <span>Common</span>
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'outcomeTypeCommon')}
                              isChecked={currentFilter.outcomeTypeCommon}
                              key={currentFilter.outcomeTypeCommon}
                            />
                          </label>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.outcomeTypeHard
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <span>Hard</span>
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'outcomeTypeHard')}
                              isChecked={currentFilter.outcomeTypeHard}
                            />
                          </label>
                        </>
                      )}
                    </div>
                  </>
                )}

                <button
                  className="iq4-manage-skills__filter-section-description-btn"
                  onClick={() => void setShowFilterDescription(true)}
                >
                  What do these filters mean?
                </button>

                <div className="iq4-manage-skills__filter-section__footer">
                  <button onClick={toggleVisibility}>Submit</button>
                </div>
              </>
            )}
          </div>,
          document.getElementsByTagName('BODY')[0],
        )}
    </div>
  );
};
