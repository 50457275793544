import React, { useState, useEffect } from 'react';
import { Star, PadLock } from '../Icon/Icon';

/**
 * Assessment/display assessment component
 * @param {number} value - The assessment value
 * @param {function} handleSkillProfUpdate - Get's called when the proficiency has been updated
 * @param {boolean} displayOnly - Specify wether to render this component is assessment/display mode
 * @param {string} className - custom classname
 */
export const Assessment = ({
  value = null,
  handleSkillProfUpdate,
  displayOnly = false,
  className,
  ...props
}) => {
  const [proficiency, setProficiency] = useState(Math.floor(value));

  useEffect(() => {
    setProficiency(Math.floor(value));
  }, [value]);

  const setProficiencyHandler = (prof) => {
    setProficiency(prof);
    handleSkillProfUpdate(prof);
  };

  const renderAssessmentStars = (proficiency) => {
    return [1, 2, 3, 4].map((v, i) => (
      <button
        tabIndex={displayOnly ? '-1' : '0'}
        key={v}
        className={`iq4-skills-module-assessment__btn ${
          displayOnly ? 'iq4-skills-module-assessment__btn--display-only' : ''
        }`}
        onClick={() => {
          if (displayOnly) return;
          setProficiencyHandler(i + 1);
        }}
        aria-label={`Your assessment is ${proficiency}. Click to set your assessment to ${
          i + 1
        }`}
      >
        {i < proficiency ? <Star /> : <Star outline />}
      </button>
    ));
  };

  return (
    <div
      className={`iq4-skills-module-assessment ${className ? className : ''}`}
      {...props}
    >
      { displayOnly && <span className="iq4-skills-module-assessment__read-only">
        <PadLock />
      </span> }
      {renderAssessmentStars(proficiency)}
    </div>
  );
};
