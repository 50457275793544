import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  RESUME_PARSER_URL,
  GET_JOB_REQS_URL,
  CREATE_JOB_REQ_URL,
  JOB_REQ_SKILLS_API_URL,
  JOB_REQ_GET_SKILLS_API_URL,
  JOB_REQ_RECOMMENDED_CANDIDATES_API_URL,
  JOB_REQ_SKILL_KEYWORD_SEARCH_API_URL,
  REMOVE_JOB_REQ_URL,
} from 'common/constants/endpoints';
import { sendRequest } from 'common/utils/requests.js';

const POSITION_TYPE_KEY_TO_LABEL = {
  fulltime: 'Full Time',
  contractor: 'Contractor',
  internship: 'Internship',
  missing: 'Missing',
};
export const parseJobReq = createAsyncThunk(
  'jobReqsSlice/parseJobReq',
  async (attachment, { getState }) => {
    const res = await fetch(RESUME_PARSER_URL, {
      method: 'POST',
      body: attachment,
    });

    const { skills, jobData } = await res.json();

    const jobReqMeta = {
      role: jobData?.JobTitles?.JobTitle?.[0] || jobData?.JobTitles?.MainJobTitle || '',
      location: '',
      owner: jobData?.EmployerNames?.MainEmployerName || '',
      department: '',
      positionType: 'fulltime',
      createdDate: getCurrentDate(),
      description: jobData?.JobMetadata?.PlainText?.trim() || '',
      education:
        jobData?.Degrees?.reduce(
          (qString, d, i) => `${qString}${i > 0 ? ', ' : ''}${d.Name}`,
          '',
        ) || '',
      qualification: '',
    };

    return { skills, jobReqMeta };
  },
);

export const saveNewJobReqWithSkills = createAsyncThunk(
  'jobReqsSlice/saveNewJobReqWithSkills',
  async ({ jobReq, skills }, { getState }) => {
    let newJobReqId;
    try {
      // save job req
      const savedJobReq = await sendRequest({
        url: CREATE_JOB_REQ_URL,
        verb: 'POST',
        data: jobReq,
      });

      newJobReqId = savedJobReq.role.id;

      // TODO: save skills to job req
      const skillsPayload = skills.map((s) => {
        return {
          competence: s.competenceId,
          jobId: savedJobReq.role.id,
          isDelete: false,
        };
      });

      const savedJobReqSkills = await sendRequest({
        url: JOB_REQ_SKILLS_API_URL,
        verb: 'POST',
        data: skillsPayload,
      });

      return savedJobReq;
    } catch (e) {
      // remove job req if there was an issue
      if (newJobReqId) {
        const removedJobReq = await sendRequest({
          url: `${REMOVE_JOB_REQ_URL}/${newJobReqId}`,
          verb: 'DELETE',
        });
      }
    }
  },
);

export const fetchJobReqs = createAsyncThunk('jobReqsSlice/fetchJobReqs', async () => {
  // TODO: implement api to get positions

  const jobreqs = await fetch(GET_JOB_REQS_URL);
  const json = await jobreqs.json();

  const priorityLabelToNum = {
    low: 1,
    medium: 2,
    high: 3,
    urgent: 4,
  };
  const data = json.reduce(
    (map, req) => {
      const reqData = {
        id: req.role.id,
        title: req.role.name,
        description: req.role.description,
        responsibilities: req.jobResponsibilities.join(', '),
        proficiency: Math.floor(Math.random() * 4) + 1,
        credentialType: POSITION_TYPE_KEY_TO_LABEL[req.positionType],
        candidates: req.totalCandidates,
        openPositions: req.openPositions,
        department: req.department,
        priority: req.priority,
        priorityNum: priorityLabelToNum[req.priority] || 0,
        location: req.location,
        openedBy: req.owner,
        type: POSITION_TYPE_KEY_TO_LABEL[req.positionType],
        positionType: req.positionType,
        date: getCurrentDate(new Date(req.createdDate)),
        qualification: req.qualification,
        education: req.education,
        isAvailable: true,
      };

      if (map[req.positionType]) {
        map[req.positionType].push(reqData);
      }

      return map;
    },
    {
      fulltime: [],
      internship: [],
      contractor: [],
      missing: [],
    },
  );

  return data;
});

export const fetchJobReq = createAsyncThunk(
  'dashboard/fetchJobReq',
  async (jobId, { getState, dispatch }) => {
    const jobreqs = await fetch(`${GET_JOB_REQS_URL}/${jobId}`);
    const jobReqParsed = await jobreqs.json();

    // get skills in background
    dispatch(fetchJobReqSkills(jobId));

    // fetch recommended candidates in the background
    dispatch(fetchJobReqRecommendedCandidates(jobId));

    return {
      job: {
        id: jobReqParsed.role.id,
        title: jobReqParsed.role.name,
        description: jobReqParsed.role.description,
        responsibilities: jobReqParsed.jobResponsibilities.join(', '),
        proficiency: Math.floor(Math.random() * 4) + 1,
        credentialType: POSITION_TYPE_KEY_TO_LABEL[jobReqParsed.positionType],
        candidates: jobReqParsed.totalCandidates,
        openPositions: jobReqParsed.openPositions,
        department: jobReqParsed.department,
        priority: jobReqParsed.priority,
        location: jobReqParsed.location,
        openedBy: jobReqParsed.owner,
        type: POSITION_TYPE_KEY_TO_LABEL[jobReqParsed.positionType],
        positionType: jobReqParsed.positionType,
        date: getCurrentDate(new Date(jobReqParsed.createdDate)),
        qualification: jobReqParsed.qualification,
        education: jobReqParsed.education,
        isAvailable: true,
      },
      // skills: derivedParsedSkills,
      // candidates: candidates,
    };
  },
);

export const fetchJobReqSkills = createAsyncThunk('dashboard/fetchJobReqSkills', async (jobId) => {
  const jobReqSkillsRes = await fetch(`${JOB_REQ_GET_SKILLS_API_URL}${jobId}`);
  let jobReqSkillsParsed = await jobReqSkillsRes.json();

  const derivedParsedSkills = jobReqSkillsParsed.map((s) => {
    return {
      competenceId: s.competence.id,
      foundIn: null,
      label: s.competence.skillTier3.label,
      tier1: { id: 80, label: 'TK' },
      tier2: { id: 464, label: 'TK' },
    };
  });

  return derivedParsedSkills;
});

export const fetchJobReqRecommendedCandidates = createAsyncThunk(
  'dashboard/fetchJobReqRecommendedCandidates',
  async (jobId) => {
    const recommendedCandidates = await fetch(JOB_REQ_RECOMMENDED_CANDIDATES_API_URL(jobId));
    let recommendedCandidatesParsed = await recommendedCandidates.json();

    const candidates = recommendedCandidatesParsed
      .map((c) => {
        return {
          id: c.user.id,
          avatarUrl: c.userProfilePhoto.thumbnail,
          name: `${c.user.firstName} ${c.user.lastName}`,
          status: 'Employed',
          source: 'External',
          grade: 'Senior',
          roleSkills: c.roleSkills,
          matchedSkills: c.matchedSkills,
          meetsCriteria: 'Credential',
        };
      })
      .sort((a, b) => (a.matchedSkills <= b.matchedSkills ? 1 : -1));

    return candidates;
  },
);

export const fetchKeywordSearchJobReq = createAsyncThunk(
  'dashboard/fetchKeywordSearchJobReq',
  async (jobId) => {
    const skillsSearchResults = await fetch(JOB_REQ_SKILL_KEYWORD_SEARCH_API_URL('soft', jobId));
    const skillsSearchResultsParsed = await skillsSearchResults.json();
    // debugger;

    return {};
  },
);

export const jobReqsSlice = createSlice({
  name: 'jobReqsSlice',
  initialState: getInitialState(),
  reducers: {
    setNewJobReq: (state, action) => {
      state.newJobReq = action.payload;
    },

    setNewParsedJobReq: (state, action) => {
      state.parsedJobReq.jobReqMeta = action.payload;
    },
  },
  extraReducers: {
    [fetchJobReqs.fulfilled]: (state, action) => {
      state.positions = action.payload;
    },

    [fetchJobReq.fulfilled]: (state, action) => {
      state.selectedPosition = action.payload.job;
      // state.selectedPositionSkills = action.payload.skills;
      // state.selectedPositionCandidates = action.payload.candidates;
    },

    [parseJobReq.fulfilled]: (state, action) => {
      state.parsedJobReq = action.payload;
    },

    [fetchJobReqSkills.fulfilled]: (state, action) => {
      state.selectedPositionSkills = action.payload;
    },

    [fetchJobReqRecommendedCandidates.fulfilled]: (state, action) => {
      state.selectedPositionCandidates = action.payload;
    },
  },
});

export const { setNewJobReq, setNewParsedJobReq } = jobReqsSlice.actions;

export default jobReqsSlice.reducer;

function getInitialState() {
  return {
    // job reqs list
    positions: {
      fulltime: null,
      internship: null,
      contractor: null,
      missing: null,
    },

    selectedPosition: null,
    selectedPositionSkills: [],
    selectedPositionCandidates: [],

    // create a new job req
    newJobReq: {},

    newParsedJobReq: {
      meta: {
        rolelocation: '',
        owner: '',
        department: '',
        positionType: '',
        createdDate: '',
        description: '',
        qualification: '',
        education: '',
      },
      skills: [],
    },

    parsedJobReq: null, // || { skills: Object[], jobReqMeta: { rolelocation  owner  department  positionType  createdDate  description  qualification education};
  };
}

function getCurrentDate(date = new Date()) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const joined = [year, month, day].join('-');
  return joined;
}
