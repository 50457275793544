import React, { useState, useEffect } from 'react';
import { ROUTES } from 'routes/routeNames';
import {
  ActionCard,
  MenuButton,
  TriggerModal,
  FormWrapper,
  PromptModal,
  SimpleIcon,
  CredentialPill,
} from 'common/components';
import { useDispatch, useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { selectIsPublicProfile, selectProfile } from 'common/store/features/profile/selectors';
import CompetenciesList from '../CompetenciesList';
import PortfolioItemSummary from '../PortfolioItemSummary/PortfolioItemSummary';
import { ProfileThumbnailUpload } from '../ProfileThumbnailUpload';
import { ProfileAssociatePortfolioItems } from '../ProfileAssociatePortfolioItems';
import { Modal, EmptyState, MultilineDisplayer } from 'common/components';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import {
  useProfileEducationFormDefinition,
  useProfileEducationImageFormDefinition,
} from 'common/hooks/forms';
import { PROFILE_TYPE, sendRequest } from 'common/utils';
import { PROFILE_EDUCATION_URL } from 'common/constants/endpoints';
import { removeProfileSection } from 'common/store/features/profile/profileSlice';
import GraphicEducation from '@assets/svg/empty-states_education.svg';
import { DEFAULT_COUNTRY } from 'common/constants/countries';
import { orderEducationRecords } from 'common/utils/orderRecords';
import './ProfileEducationDetail.scss';
import { useParams } from 'react-router-dom';
import { selectUserProfile } from '../../../../store/features/search/selectors';

export const ProfileEducationDetail = () => {
  const isMobile = useIsMobile();
  const { education } = useSelector(selectUserProfile);
  const isPublicProfile = true;
  const [isPromptActive, setIsPromptActive] = useState(false);
  const dispatch = useDispatch();
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [promptedId, setPromptedId] = useState(-1);
  const [orderedEducation, setOrderedEducation] = useState(orderEducationRecords(education || []));
  const [expandedMap, setExpandedMap] = useState(getInitialExpandedMap(orderedEducation));
  const isPopulated = education?.length > 0;
  const { id } = useParams();

  const defaultValues = {
    country: DEFAULT_COUNTRY,
    isCurrentStudent: true,
  };

  const myhubBasicUrl = process.env.REACT_APP_MYHUB_BASIC_URL;

  useEffect(() => {
    setOrderedEducation(orderEducationRecords(education || []));
  }, [education]);

  useEffect(() => {
    setExpandedMap(getInitialExpandedMap(orderedEducation));
  }, [orderedEducation]);

  const defaultNewFormValues = {
    country: DEFAULT_COUNTRY,
    isCurrentStudent: false,
  };

  const changePromptedId = (id) => {
    setPromptedId(id);
    setIsPromptActive(true);
  };

  const handleDeleteItem = (id) => {
    sendRequest({ url: `${PROFILE_EDUCATION_URL}/${id}`, verb: 'DELETE' })
      .then(() => {
        dispatch(removeProfileSection({ section: PROFILE_TYPE.EDUCATION, value: id }));
        setIsPromptActive(false);
      })
      .catch((err) => {
        // TODO: John to confirm error display
        console.log(err);
      });
  };

  function getInitialExpandedMap(items) {
    return items.reduce((map, item, i) => {
      map[item.id] = i === 0 ? true : false;
      return map;
    }, {});
  }

  function generatePublicCompetencies(competencies) {
    return competencies.map((competency, i) => ({
      id: competency.id,
      value: competency.skillTier3?.label,
    }));
  }

  const renderEduMobileCard = (item, menuItems) => {
    return (
      <div
        className={`iq4-profile-experience-detail__mobile-card ${
          !expandedMap[item.id] ? 'iq4-profile-experience-detail__mobile-card--closed' : ''
        }`}
      >
        <div className="iq4-profile-experience-detail__mobile-card-section iq4-profile-experience-detail__mobile-card-header">
          <div className="iq4-profile-experience-detail__mobile-card-header-col">
            <p className="iq4-profile-experience-detail__mobile-card-p iq4-profile-experience-detail__mobile-card-p--bold">
              {item.title}
            </p>
            <p className="iq4-profile-experience-detail__mobile-card-p iq4-profile-experience-detail__mobile-card-p--bold">
              {item.major}
            </p>
            <p className="iq4-profile-experience-detail__mobile-card-p">{item.school}</p>
            <p className="iq4-profile-experience-detail__mobile-card-p">
              {item.startMonth} {item.startYear} -{' '}
              {item.isCurrentStudent ? 'Present' : `${item.endMonth} ${item.endYear}`}
            </p>
          </div>

          <div className="iq4-profile-experience-detail__mobile-card-header-col">
            <button
              className="iq4-profile-experience-detail__mobile-card-expand-btn"
              style={{ transform: `rotate(${expandedMap[item.id] ? 0 : 180}deg)` }}
              onClick={() =>
                void setExpandedMap((state) => ({ ...state, [item.id]: !state[item.id] }))
              }
            >
              <KeyboardArrowUpIcon />
            </button>
            {expandedMap[item.id] && (
              <MenuButton
                className="iq4-profile-experience-detail__options-button"
                items={menuItems}
              />
            )}
          </div>
        </div>
        {expandedMap[item.id] && (
          <>
            {item.description && (
              <div className="iq4-profile-experience-detail__mobile-card-section iq4-profile-experience-detail__mobile-card-section--description">
                <p className="iq4-profile-experience-detail__mobile-card-p iq4-profile-experience-detail__mobile-card-p--bold">
                  Description
                </p>
                <p className="iq4-profile-experience-detail__mobile-card-p">{item.description}</p>
              </div>
            )}

            {!!item.competencies?.length && (
              <div className="iq4-profile-experience-detail__mobile-card-section iq4-profile-experience-detail__mobile-card-section--competencies">
                <p className="iq4-profile-experience-detail__mobile-card-p iq4-profile-experience-detail__mobile-card-p--bold">
                  Competencies
                </p>
                <CompetenciesList
                  competencies={
                    isPublicProfile
                      ? generatePublicCompetencies(item.competencies)
                      : item.competencies
                  }
                />
              </div>
            )}

            {!!item.portfolio?.length && (
              <div className="iq4-profile-experience-detail__mobile-card-section">
                <p className="iq4-profile-experience-detail__section-title">Related Projects</p>

                <ul className="iq4-provide-experience-detail__portfolio-items-list">
                  {item.portfolio.map((portfolioItem, i) => (
                    <li
                      className="iq4-provide-experience-detail__portfolio-items-list-item"
                      key={i}
                    >
                      <SimpleIcon
                        className="iq4-portfolio-item-summary__type-icon"
                        name={portfolioItem.type !== 'other' ? portfolioItem.type : 'link'}
                      />
                      <p className="iq4-portfolio-item-summary__title">{portfolioItem.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <ActionCard
        title={<div className="iq4-profile-education-detail__card-title">EDUCATION</div>}
        icon="educationCircle"
        backLink={isPublicProfile ? 'profile' : ROUTES.PROFILE_ROOT}
        isHeaderVisible={!isMobile}
        isDivideVisible={!isMobile}
        isPublic={isPublicProfile}
      >
        {!isPopulated && (
          <>
            <img
              src={GraphicEducation}
              className="empty-state-graphic"
              role="presentation"
              alt="Education Graphic"
            />
          </>
        )}

        {isPopulated && (
          <div className="iq4-profile-education-detail__list">
            {orderedEducation.map((item, i) => {
              if (isMobile) return renderEduMobileCard(item, []);

              const verifiedCredentialDetails = item.verifiedCredentialDetails
                ? JSON.parse(item.verifiedCredentialDetails)
                : null;

              const renderDate = (year, month) => {
                let date = '';
                date += `${year ? year : ''}`;
                date += `${month && month ? '-' : ''}`;
                date += `${month ? month : ''}`;

                return date;
              };

              return (
                <>
                  {i !== 0 && <hr className="iq4-profile-education-detail__divide" />}
                  <div className="iq4-profile-education-detail__list-item" key={item.id}>
                    <div className="iq4-profile-education-detail__company-logo-wrapper">
                      {verifiedCredentialDetails?.imageURL && (
                        <img
                          src={verifiedCredentialDetails.imageURL}
                          alt="Education Item"
                          className="iq4-profile-thumbnail-upload__image"
                        />
                      )}
                      {!verifiedCredentialDetails?.imageURL && (
                        <ProfileThumbnailUpload
                          item={item}
                          type="Education"
                          formDefinition={useProfileEducationFormDefinition}
                          imageFormDefinition={useProfileEducationImageFormDefinition}
                        />
                      )}
                    </div>
                    <div className="iq4-profile-education-detail__content">
                      <CredentialPill credential={item} displayVerifier={true} />
                      <p className="iq4-profile-education-detail__degree">{item.title}</p>
                      <p className="iq4-profile-education-detail__school">{item.school}</p>

                      {(item.startYear || item.startMonth) && (
                        <div className="iq4-profile-education-detail__section">
                          <p className="iq4-profile-education-detail__section-title">Start Date</p>
                          <p className="iq4-profile-education-detail__section-value">
                            {renderDate(item.startYear, item.startMonth)}
                          </p>
                        </div>
                      )}

                      {(item.endYear || item.endMonth) && (
                        <div className="iq4-profile-education-detail__section">
                          <p className="iq4-profile-education-detail__section-title">End Date</p>
                          <p className="iq4-profile-education-detail__section-value">
                            {renderDate(item.endYear, item.endMonth)}
                          </p>
                        </div>
                      )}

                      {verifiedCredentialDetails?.awardDate && (
                        <div className="iq4-profile-education-detail__section">
                          <p className="iq4-profile-education-detail__section-title">Award Date</p>
                          <p className="iq4-profile-education-detail__section-value">
                            {verifiedCredentialDetails.awardDate}
                          </p>
                        </div>
                      )}

                      {verifiedCredentialDetails?.expiryDate && (
                        <div className="iq4-profile-education-detail__section">
                          <p className="iq4-profile-education-detail__section-title">Expiry Date</p>
                          <p className="iq4-profile-education-detail__section-value">
                            {moment(verifiedCredentialDetails.expiryDate).format('YYYY-MM-DD')}
                          </p>
                        </div>
                      )}

                      {(item.description || verifiedCredentialDetails?.description) && (
                        <div className="iq4-profile-education-detail__section">
                          <p className="iq4-profile-education-detail__section-title">Description</p>
                          <p className="iq4-profile-education-detail__description-text">
                            <MultilineDisplayer
                              text={item.description || verifiedCredentialDetails?.description}
                            />
                          </p>
                        </div>
                      )}
                      {item.gpa && (
                        <div className="iq4-profile-education-detail__section">
                          <p className="iq4-profile-education-detail__section-title">GPA</p>
                          {item.gpa}
                        </div>
                      )}
                      {!item.verifiedBy && item.competencies && item.competencies.length > 0 && (
                        <div className="iq4-profile-education-detail__section">
                          <p className="iq4-profile-education-detail__section-title">
                            {item.verifiedBy ? 'Verified Competencies' : 'Competencies'}
                          </p>
                          <CompetenciesList
                            competencies={
                              isPublicProfile
                                ? generatePublicCompetencies(item.competencies)
                                : item.competencies
                            }
                            isVerified={item.verifiedBy}
                          />
                        </div>
                      )}
                      {item.portfolio && item.portfolio.length > 0 && (
                        <>
                          <p className="iq4-profile-education-detail__section-title">
                            Portfolio Items
                          </p>
                          <ul className="iq4-provide-education-detail__portfolio-items-list">
                            {item.portfolio.map((portfolioItem) => (
                              <li>{/*<PortfolioItemSummary item={portfolioItem} />*/}</li>
                            ))}
                          </ul>
                        </>
                      )}
                      {!item.verifiedBy && <div></div>}

                      {item.verifiedBy && verifiedCredentialDetails && (
                        <div>
                          <a
                            className="iq4-profile-education-detail__nsc-link"
                            href={
                              verifiedCredentialDetails.type === 'Badge'
                                ? `${myhubBasicUrl}` // TODO: we should differentiate with different links, but for now, it's not possible
                                : `${myhubBasicUrl}`
                            }
                          >
                            View this record on the National Student Clearinghouse{' '}
                            <SimpleIcon name="arrow" />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </ActionCard>
    </>
  );
};
