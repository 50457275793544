import {
  SET_RAW_SKILL,
  SET_SKILLS_SUMMARY,
  UPDATE_SKILL_PROFICIENCY,
  UPDATE_SEARCH_RESULTS,
  UPDATE_BROWSE_CATEGORIES,
  CLEAR_BROWSE_RESULTS,
  UPDATE_BROWSE_KSAS,
  REMOVE_BROWSE_RESULT,
  REMOVE_ADDTIONAL_SKILLS,
  UPDATE_SAVED_ADDITIONAL_SKILLS,
  SET_GLOBAL_TIER1S,
  UPDATE_SKILL_PROFICIENCY_T4,
  SET_BROWSE_BY_ROLE_LIST
} from '../../actionTypes';

// new api
export const setJPMCSkillsData = (payload) => {
  const skillsMap = payload.skillAssessmentList.reduce((map, skill) => {
    if (!map[skill.skillTier1.id]) map[skill.skillTier1.id] = [];
    map[skill.skillTier1.id].push(skill);
    return map;
  }, {});

  const comtechLabsInfo = payload.labInfo.labDetails.reduce((map, lab) => {
    map[lab.id] = lab;
    return map;
  }, {});

  return {
    type: SET_RAW_SKILL,
    payload: {
      skills: skillsMap,
      comtechLabsInfo
    }
  };
};

export const setJPMCSkillsSummary = (payload) => {
  return {
    type: SET_SKILLS_SUMMARY,
    payload
  };
};

export const updateSkillProfT4 = (payload) => ({
  type: UPDATE_SKILL_PROFICIENCY_T4,
  payload
});

export const updateJPMCSkillsProf = (payload) => ({
  type: UPDATE_SKILL_PROFICIENCY,
  payload
});

export const updateSearchResults = (payload) => ({
  type: UPDATE_SEARCH_RESULTS,
  payload
});

export const updateBrowseCategories = (payload) => ({
  type: UPDATE_BROWSE_CATEGORIES,
  payload
});

export const clearBrowseResults = () => ({
  type: CLEAR_BROWSE_RESULTS
});

export const removeBrowseResult = (payload) => ({
  type: REMOVE_BROWSE_RESULT,
  payload
});

export const removeAdditionalSkills = (payload) => ({
  type: REMOVE_ADDTIONAL_SKILLS,
  payload
});

export const updateBrowseKsas = ({
  skillTier1,
  skillTier2,
  skillTier3,
  skillTier4
}) => {
  return {
    type: UPDATE_BROWSE_KSAS,
    payload: {
      skillTier1,
      skillTier2,
      skillTier3,
      skillTier4
    }
  };
};

export const updateSavedAdditionalSkills = (payload) => {
  return {
    type: UPDATE_SAVED_ADDITIONAL_SKILLS,
    payload
  };
};

export const setJPMCTier1s = (payload) => {
  return {
    type: SET_GLOBAL_TIER1S,
    payload
  };
};

export const setSkillsByRoleList = (payload) => {
  return {
    type: SET_BROWSE_BY_ROLE_LIST,
    payload
  };
};

function deriveJPMCSkillsData(skills) {
  const roleSkills = skills.userRoleSkillsList.reduce(reduceMap, {});
  const additionalSkills = skills.userAdditionalSkillsList.reduce(
    reduceMap,
    {}
  );

  return {
    roleSkills,
    additionalSkills
  };

  function reduceMap(map, skill) {
    map[skill.usp.id] = skill;
    return map;
  }
}
