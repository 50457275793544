import React, { useState, useEffect } from 'react';
import _orderBy from 'lodash/orderBy';
import _cloneDeep from 'lodash/cloneDeep';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Checkbox,
  ClickAwayListener,
} from '@material-ui/core';

import { VirtualizedTable } from './VirtualizedTable';
import { DataTableRow } from './DataTableRow';
import { SelectPageSize } from './SelectPageSize';
import { TableLoading } from './TableLoading';
import { renderColumnHeader, getDefaultActiveHeadFilter } from './tableUtils';

import { Button, Paginator, SimpleIcon, CountIndicator } from 'common/components';
import { SORT_DIRECTION, DEFAULT_TABLE_PAGE_SIZE } from 'common/constants/miscellaneous';
import './DataTable.scss';

/**
 *
 * @param {Object[]} tableDefinition - Array of objects that define the structure of the table.
 * @param {string} tableDefinition[].columns - structure of columns (defines basic table structure)
 * @param {string} tableDefinition[].expanded - component to show in case a row is expanded.
 * @param {boolean} tableDefinition[].isSortable - apply standard sorting or not.
 * @param rows {Array} - Array of rows passed onto table
 * @param headerAction - component to be rendered on top right corner
 * @param colGroup
 * @param showDataInfo
 * @param useVirtualized
 * @param tableTitle
 * @param headerSort
 * @param headerFilter
 * @param onRowExpandOpen
 * @param context - property that can be used when using multiple renders using different contexts (e.g.: tabs)
 * @param defaultSort
 * @param isLoading - Boolean indicating if table data is loading
 * @param emptyState - component to show when no data is loaded
 * @returns {*}
 * @constructor
 */
export const DataTable = ({
  tableDefinition,
  rows,
  headerAction,
  colGroup,
  showDataInfo,
  useVirtualized,
  tableTitle,
  headerSort,
  headerFilter,
  onRowExpandOpen,
  context = 'table',
  defaultSort,
  isLoading,
  emptyState
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const [currentRows, setCurrentRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [paginationData, setPaginationData] = useState({ currentPage: 1, pageLimit: rowsPerPage });
  const [searchInput, setSearchInput] = useState('');
  const [firstRecordOnPage, setFirstRecordOnPage] = useState(1);
  const [lastRecordOnPage, setLastRecordOnPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    prop: defaultSort ? defaultSort.prop : 'name',
    direction: defaultSort && defaultSort.order ? defaultSort.order : SORT_DIRECTION.ASC,
  });
  const [currentFilter, setCurrentFilter] = useState({});
  const [headerSortExpanded, setHeaderSortExpanded] = useState(false);
  const [headerFilterExpanded, setHeaderFilterExpanded] = useState(false);

  const [activeHeaderFilter, setActiveHeaderFilter] = useState(
    getDefaultActiveHeadFilter(headerFilter),
  );

  /**
   * gets called whenever rows / current sort / filtering are changed
   * @param data
   */
  const updateTableData = (data) => {
    const { currentPage, pageLimit } = data;
    const columnBeingSorted = tableDefinition.columns.find((col) => col.prop === currentSort.prop);
    const newOrderedRows = _orderBy(
      filteredRows,
      [
        columnBeingSorted && columnBeingSorted.sortFunc
          ? columnBeingSorted.sortFunc
          : currentSort.prop,
      ],
      [currentSort.direction],
    );
    const offset = (currentPage - 1) * pageLimit;
    const newCurrentRows =
      pageLimit === rows.length ? newOrderedRows : newOrderedRows.slice(offset, offset + pageLimit);
    const last = offset + pageLimit;

    setPaginationData({
      ...paginationData,
      ...data,
    });
    setFirstRecordOnPage(offset + 1);
    setLastRecordOnPage(last > filteredRows.length ? filteredRows.length : last);
    setCurrentRows(newCurrentRows);
  };

  useEffect(() => {
    const activeFilters = Object.keys(currentFilter);
    let newFilteredRows = _cloneDeep(rows);
    activeFilters.forEach((filter) => {
      newFilteredRows = currentFilter[filter](newFilteredRows);
    });

    setFilteredRows(newFilteredRows);
  }, [currentFilter, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newFilteredRows = _cloneDeep(rows);
    setFilteredRows(newFilteredRows);
  }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newSearchedRows = _cloneDeep(rows);
    const searchableColumn = tableDefinition.columns.find((col) => col.isSearchable);
    if (searchableColumn) {
      const searchResults = newSearchedRows.filter(
        (row) => row[searchableColumn.prop].toLowerCase().indexOf(searchInput.toLowerCase()) > -1,
      );
      setFilteredRows(searchResults);
    }
  }, [searchInput]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filteredRows.length <= rowsPerPage) {
      updateTableData({ currentPage: 1, pageLimit: rowsPerPage });
    }
  }, [filteredRows, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newFilteredRows = _cloneDeep(rows);
    setFilteredRows(newFilteredRows);

    if (rowsPerPage !== DEFAULT_TABLE_PAGE_SIZE) {
      setRowsPerPage(DEFAULT_TABLE_PAGE_SIZE);
    }

    updateTableData({ currentPage: 1, pageLimit: rowsPerPage });
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (paginationData) {
      updateTableData(paginationData);
    }
  }, [currentSort, filteredRows]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (value) => setRowsPerPage(value);

  const handleSorting = (prop) => {
    if (currentSort.prop === prop) {
      setCurrentSort({
        ...currentSort,
        direction:
          currentSort.direction === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC,
      });
    } else {
      setCurrentSort({
        prop,
        direction: SORT_DIRECTION.ASC,
      });
    }
  };

  const onFilterChange = (updatedColumnFilter) => {
    if (updatedColumnFilter.toRemove) {
      const { [updatedColumnFilter.columnName]: del, ...rest } = currentFilter;
      setCurrentFilter(rest);
    } else {
      setCurrentFilter({
        ...currentFilter,
        [updatedColumnFilter.columnName]: updatedColumnFilter.applyFilter,
      });
    }
  };

  const HeaderSort = () => (
    <>
      <SimpleIcon
        className="iq4-datatable__header-sort"
        name="sort"
        onClick={() => setHeaderSortExpanded(!headerSortExpanded)}
      />
      {headerSortExpanded && (
        <ClickAwayListener onClickAway={() => void setHeaderSortExpanded(false)}>
          <div className="iq4-datatable__header-sort--expanded">
            <span className="iq4-datatable__header-sort--title">Sort by:</span>
            {headerSort.map((hs) => (
              <span className="iq4-datatable__header-sort--item">
                <span
                  className="iq4-datatable__header-sort--item-label"
                  onClick={() => setCurrentSort({ name: hs.column, direction: hs.sortDirection })}
                >
                  {hs.label}
                </span>
                {currentSort.prop === hs.column && currentSort.direction === hs.sortDirection && (
                  <SimpleIcon name="tick" />
                )}
              </span>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </>
  );

  const updateActiveHeaderFilter = (index) => {
    const newActiveHeaderFilter = {
      ...activeHeaderFilter,
      [index]: !activeHeaderFilter[index],
    };

    const activeFilterIndexes = Object.keys(newActiveHeaderFilter).filter(
      (k) => newActiveHeaderFilter[k],
    );

    let filteringResult = {};

    activeFilterIndexes.forEach((index) => {
      filteringResult[index] = rows.filter((row) => headerFilter[+index].filterFunc(row));
    });

    const newFilteredRows = Object.keys(filteringResult).reduce(function (r, k) {
      return r.concat(filteringResult[k]);
    }, []);

    setFilteredRows(newFilteredRows);
    setActiveHeaderFilter(newActiveHeaderFilter);
  };

  const HeaderFilter = () => (
    <div className="iq4-datatable__header-filter-container">
      <SimpleIcon
        className="iq4-datatable__header-filter"
        name="filter"
        onClick={() => setHeaderFilterExpanded(!headerFilterExpanded)}
      />
      {headerFilterExpanded && (
        <ClickAwayListener onClickAway={() => void setHeaderFilterExpanded(false)}>
          <div className="iq4-datatable__header-filter--expanded">
            <span className="iq4-datatable__header-filter--title">Filter</span>
            {headerFilter.map((hf, index) => (
              <span className="iq4-datatable__header-filter--item">
                <span className="iq4-datatable__header-filter--item-label" onClick={() => {}}>
                  {hf.label}
                </span>
                <Checkbox
                  checked={activeHeaderFilter[index]}
                  onChange={() => updateActiveHeaderFilter(index)}
                />
              </span>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );

  const resetCurrentFilterVirtualized = (filter) => {
    setCurrentFilter(filter);
    const newActiveHeaderFilter = Object.keys(activeHeaderFilter).map((key) => {
      return {
        [+key]: true,
      };
    });

    setActiveHeaderFilter(newActiveHeaderFilter);
  };

  const renderTableColumnHeader = (col) =>
    renderColumnHeader({
      col,
      currentSort,
      rows,
      onFilterChange,
      currentFilter,
      searchInput,
      onChangeInput,
    });

  return (
    <TableContainer
      classes={{
        root: useVirtualized ? 'iq4-datatable__virtualized-container' : 'iq4-datatable__container',
      }}
    >
      {isLoading && <TableLoading />}

      {!isLoading && rows.length === 0 && emptyState}

      {!isLoading && rows.length > 0 && (
        <>
          <div className="iq4-datatable__header-container">
            <div className="iq4-datatable__paginator-container">
              {showDataInfo && (
                <>
                  <span className="iq4-datatable__data-info-container">
                    <h1 className="iq4-datatable__data-info">{tableTitle}</h1>
                    <CountIndicator count={filteredRows.length} variation="dark" />
                  </span>
                  {headerSort && <HeaderSort />}
                  {headerFilter && <HeaderFilter />}
                </>
              )}
              <>
                <SelectPageSize
                  handlePageSizeChange={handlePageSizeChange}
                  rows={rows}
                  rowsPerPage={rowsPerPage}
                />
                <span
                  className="iq4-datatable__view-all"
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => handlePageSizeChange(filteredRows.length)}
                  onClick={() => handlePageSizeChange(filteredRows.length)}
                >
                  View All
                </span>
                {!!(rowsPerPage !== filteredRows.length && filteredRows.length) && (
                  <span className="iq4-datatable__paginator-info">
                    {firstRecordOnPage}-{lastRecordOnPage} of {filteredRows.length}
                  </span>
                )}
                {filteredRows.length > rowsPerPage && (
                  <Paginator
                    totalRecords={filteredRows.length}
                    pageLimit={rowsPerPage}
                    onPageChanged={updateTableData}
                    isNextPreviousIconOnly
                    context={context}
                  />
                )}
              </>
            </div>
            <div>{headerAction()}</div>
          </div>
          {useVirtualized ? (
            <div className="iq4-datatable__virtualized-container">
              <VirtualizedTable
                tableDefinition={tableDefinition}
                handleSorting={handleSorting}
                currentSort={currentSort}
                renderColumnHeader={renderTableColumnHeader}
                currentRows={currentRows}
                setCurrentFilter={resetCurrentFilterVirtualized}
                rows={rows}
              />
            </div>
          ) : (
            <Table aria-label="collapsible table">
              {colGroup()}
              <TableHead>
                <TableRow>
                  {tableDefinition.columns.map((col) =>
                    col.isEmpty ? (
                      <TableCell />
                    ) : (
                      <TableCell
                        classes={{ root: 'iq4-datatable__cell-header' }}
                        onClick={col.isSortable ? () => handleSorting(col.prop) : null}
                      >
                        <span
                          className={`iq4-datatable__cell-header--inner ${
                            col.isSortable && currentSort.prop !== col.prop
                              ? 'iq4-datatable__cell-header--disabled'
                              : ''
                          }`}
                        >
                          {renderColumnHeader({
                            col,
                            currentSort,
                            rows,
                            onFilterChange,
                            currentFilter,
                            searchInput,
                            onChangeInput,
                          })}
                        </span>
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRows.length ? (
                  currentRows.map((row, index) => (
                    <DataTableRow
                      key={`${row.id}-${index}`}
                      row={row}
                      tableDefinition={tableDefinition}
                      onRowExpandOpen={() => onRowExpandOpen && onRowExpandOpen(row)}
                    />
                  ))
                ) : (
                  <tr>
                    <td colspan={tableDefinition.columns.length}>
                      <div className="iq4-datatable__no-results-container">
                        <div className="iq4-datatable__no-results">no results returned!</div>
                        <div className="iq4-datatable__no-results">
                          try changing the filters above
                        </div>
                        <div className="iq4-datatable__no-results-normal">or</div>
                        <div className="iq4-datatable__no-results-normal">
                          <Button variation="ghost" onClick={() => setCurrentFilter({})}>
                            Clear
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          )}
        </>
      )}
    </TableContainer>
  );
};
