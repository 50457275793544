import React, { useState } from 'react';
import './ProfilePortfolioForm.scss';
import { FormWrapper } from 'common/components/Form';
import { ProfilePortfolioFormIntro } from './';
import {
  usePortfolioFormDefinition,
  usePortfolioOtherFormDefinition,
  usePortfolioDocumentFormDefinition,
  usePortfolioVideoFormDefinition,
  usePortfolioAudioFormDefinition,
} from 'common/hooks/forms';

export const ProfilePortfolioForm = ({ values, onComplete, exposeFormContext }) => {
  const [showIntro, setShowIntro] = useState(true);
  const [type, setType] = useState('document');

  const onFormComplete = () => {
    onComplete && onComplete();
  };

  const onTypeChange = (type) => {
    setType(type);
  };

  const PORTFOLIO_FORMS = {
    other: (
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioOtherFormDefinition}
        {...(exposeFormContext ? { exposeFormContext, showButtons: false } : {})}
      />
    ),
    document: (
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioDocumentFormDefinition}
        {...(exposeFormContext ? { exposeFormContext, showButtons: false } : {})}
      />
    ),
    video: (
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioVideoFormDefinition}
        {...(exposeFormContext ? { exposeFormContext, showButtons: false } : {})}
      />
    ),
    audio: (
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioAudioFormDefinition}
        {...(exposeFormContext ? { exposeFormContext, showButtons: false } : {})}
      />
    ),
  };

  const onTypeSelected = (type) => {
    setType(type);
    setShowIntro(false);
  };

  return (
    <>
      {showIntro && <ProfilePortfolioFormIntro onSelect={onTypeSelected} />}
      {!showIntro && (
        <>
          <p>This project is a</p>
          <div className="iq4-onboarding-porfolio__container">
            <FormWrapper
              onChange={onTypeChange}
              useFormDefinition={usePortfolioFormDefinition}
              values={{ type }}
            />
            {PORTFOLIO_FORMS[type]}
          </div>
        </>
      )}
    </>
  );
};
