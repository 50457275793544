import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { MainNavbar, DropdownFilter, Button } from 'common/components';
import { selectAllWorkRoles } from 'store/features/dashboard/selectors';
import {
  selectAllRoleProfiles,
  selectAllManagers,
  selectAllPathways,
  selectAllLocations,
  selectAllCredentials,
  selectAllSchools,
  selectSearchUsers,
  selectPerformedInitialSearch,
  selectSelectedRoleProfiles,
  selectSelectedDropdownFilters,
} from 'store/features/search/selectors';
import {
  fetchRoleProfiles,
  fetchManagers,
  fetchPathways,
  fetchLocations,
  fetchCredentials,
  fetchSchools,
  fetchFilterQuery,
  setSearchedRoleProfiles,
  setPerformedInitialSearch,
  setSelectedRoleProfiles,
  setSelectedDropdownFilters,
} from 'store/features/search/searchSlice';

import { MainNavOptions } from 'routes/MainNavOptions';
import { SearchPageTable } from './SearchPageTable';
import './SearchPage.scss';
import { SOURCE_TYPES } from 'common/constants/miscellaneous';
import { selectIsLoadingFilterResults } from 'common/store/features/ui/selectors';

export const SearchPage = () => {
  const dispatch = useDispatch();

  const [searchError, setSearchError] = useState(null);

  // keeps track of the disabled dropdowns
  const [disabledDropdowns, setDisabledDropdowns] = useState({
    roleProfiles: false,
    managers: false,
    pathways: false,
    locations: false,
    skillStatuses: false,
    credentials: false,
    schools: false,
  });

  const skillStatuses = useSelector(selectAllWorkRoles);
  const roleProfiles = useSelector(selectAllRoleProfiles);
  const managers = useSelector(selectAllManagers);
  const pathways = useSelector(selectAllPathways);
  const locations = useSelector(selectAllLocations);
  const credentials = useSelector(selectAllCredentials);
  const schools = useSelector(selectAllSchools);
  const users = useSelector(selectSearchUsers);
  const performedInitialSearch = useSelector(selectPerformedInitialSearch);
  const isLoadingFilterResults = useSelector(selectIsLoadingFilterResults);
  const selectedRoleProfiles = useSelector(selectSelectedRoleProfiles);
  const selectedDropdownFilters = useSelector(selectSelectedDropdownFilters);

  const allFilterValues = {
    roleProfiles,
    managers,
    pathways,
    locations,
    skillStatuses,
    credentials,
    schools,
  };

  const [isInternalSelected, setIsInternalSelected] = useState(true);
  const [isExternalSelected, setIsExternalSelected] = useState(true);

  useEffect(() => {
    dispatch(fetchRoleProfiles());
    dispatch(fetchManagers());
    dispatch(fetchPathways());
    dispatch(fetchLocations());
    dispatch(fetchCredentials());
    dispatch(fetchSchools());
    dispatch(setSearchedRoleProfiles([]));
  }, []);

  // disable/enable dropdown filters based on internal/external checkboxes
  useEffect(() => {
    // reset all
    let newDisabledDropdowns = Object.keys(disabledDropdowns).reduce((obj, itemKey) => {
      obj[itemKey] = false;
      return obj;
    }, {});

    // external only
    if (isExternalSelected && !isInternalSelected) {
      newDisabledDropdowns.managers = true;
    }

    // internal only
    if (isInternalSelected && !isExternalSelected) {
      newDisabledDropdowns.schools = true;
    }

    // update the disabled dropdowns map
    setDisabledDropdowns(newDisabledDropdowns);

    // reset disabled dropdowns
    for (let key in newDisabledDropdowns) {
      if (newDisabledDropdowns[key]) {
        dispatch(
          setSelectedDropdownFilters({
            ...selectedDropdownFilters,
            [key]: {
              ...selectedDropdownFilters[key],
              selectedOptions: [],
            },
          }),
        );
      }
    }
  }, [isExternalSelected, isInternalSelected]);

  useEffect(() => {
    dispatch(setSearchedRoleProfiles(selectedRoleProfiles));
  }, []);

  const onDropdownFilterChange = ({ itemKey, isChecked, filterItem, isAll }) => {
    if (isAll) {
      const isChecked =
        allFilterValues[itemKey].length !== selectedDropdownFilters[itemKey].selectedOptions.length;
      const newSelectedOptions = isChecked ? allFilterValues[itemKey] : [];

      dispatch(
        setSelectedDropdownFilters({
          ...selectedDropdownFilters,
          [itemKey]: {
            ...selectedDropdownFilters[itemKey],
            selectedOptions: [...newSelectedOptions],
          },
        }),
      );

      return;
    }

    if (isChecked) {
      dispatch(
        setSelectedDropdownFilters({
          ...selectedDropdownFilters,
          [itemKey]: {
            ...selectedDropdownFilters[itemKey],
            selectedOptions: [...selectedDropdownFilters[itemKey].selectedOptions, filterItem],
          },
        }),
      );
    } else {
      const selectedOptionsWithoutCurrent = selectedDropdownFilters[itemKey].selectedOptions.filter(
        (i) => i.id !== filterItem.id,
      );
      dispatch(
        setSelectedDropdownFilters({
          ...selectedDropdownFilters,
          [itemKey]: {
            ...selectedDropdownFilters[itemKey],
            selectedOptions: [...selectedOptionsWithoutCurrent],
          },
        }),
      );
    }
  };

  const submitFilters = () => {
    if (selectedDropdownFilters?.roleProfiles?.selectedOptions?.length === 0) {
      setSearchError('Please select a role profile');
      return;
    }

    if (!performedInitialSearch) {
      dispatch(setPerformedInitialSearch(true));
    }

    dispatch(
      setSearchedRoleProfiles(
        selectedDropdownFilters.roleProfiles.selectedOptions.map((roleProfile) => ({
          id: roleProfile.id,
          label: roleProfile.label,
        })),
      ),
    );

    const allSelectedFilters = Object.keys(selectedDropdownFilters).filter(
      (k) =>
        allFilterValues[k].length &&
        allFilterValues[k].length === selectedDropdownFilters[k].selectedOptions.length,
    );

    let query = {};

    Object.keys(selectedDropdownFilters).forEach((k) => {
      if (
        selectedDropdownFilters[k].selectedOptions.length > 0 &&
        !allSelectedFilters.includes(k)
      ) {
        query[k] = selectedDropdownFilters[k].selectedOptions.map((f) => f.id);
      }
    });

    const selectedSource =
      isInternalSelected && isExternalSelected
        ? SOURCE_TYPES.ALL
        : isInternalSelected
        ? SOURCE_TYPES.INTERNAL
        : SOURCE_TYPES.EXTERNAL;

    dispatch(fetchFilterQuery({ allFilters: allSelectedFilters, query, selectedSource }));
  };

  const toggleExternalFilter = () => {
    if (isExternalSelected && !isInternalSelected) {
      setIsExternalSelected(!isExternalSelected);
      setIsInternalSelected(!isInternalSelected);
    } else {
      setIsExternalSelected(!isExternalSelected);
    }
  };

  const toggleInternalFilter = () => {
    if (isInternalSelected && !isExternalSelected) {
      setIsExternalSelected(!isExternalSelected);
      setIsInternalSelected(!isInternalSelected);
    } else {
      setIsInternalSelected(!isInternalSelected);
    }
  };

  const onChangeRoleProfiles = (selectedRoleProfiles) => {
    dispatch(
      setSelectedDropdownFilters({
        ...selectedDropdownFilters,
        roleProfiles: {
          ...selectedDropdownFilters[roleProfiles],
          selectedOptions: selectedRoleProfiles,
        },
      }),
    );

    dispatch(setSelectedRoleProfiles(selectedRoleProfiles));
    setSearchError(null);
  };

  return (
    <div className="iq4-search-page">
      <MainNavbar className="iq4-search-page__navbar" MainNavOptions={MainNavOptions} />
      <div className="iq4-search-page__container">
        <div className="iq4-search-page__top-container">
          <h1 className="iq4-search-page__top-container--title">Search for talent</h1>

          <div className="iq4-search-page__top-container--role-profiles">
            <Autocomplete
              multiple
              classes={{
                root: 'iq4-search-role-profiles__autocomplete',
              }}
              loading={!roleProfiles.length}
              loadingText="Please wait, loading your data..."
              fullWidth
              defaultValue={selectedRoleProfiles}
              autoSelect={true}
              closeIcon={null}
              popupIcon={null}
              options={roleProfiles}
              renderOption={(option) => option.label}
              getOptionLabel={(option) => option.label}
              aria-label="Search roles"
              onChange={(e, value) => onChangeRoleProfiles(value)}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <SearchIcon
                            classes={{
                              root: 'iq4-search-role-profiles__autocomplete-input-icon',
                            }}
                          />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  placeholder={
                    params.InputProps.startAdornment && params.InputProps.startAdornment.length > 0
                      ? ''
                      : 'Search Roles & Job Reqs e.g. Cyber Defense Analyst'
                  }
                />
              )}
            />
            {searchError && <p className="iq4-search-role-profiles__error">{searchError}</p>}
          </div>

          <div className="iq4-search-page__top-container--content">
            Use the filters below to find talent for your Organization
          </div>
        </div>
        <div className="iq4-search-page__filter-container">
          <div className="iq4-search-page__filter-container--title">Filter by</div>
          <div className="iq4-search-page__filter-container--checkboxes">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isInternalSelected}
                  onChange={toggleInternalFilter}
                  color="primary"
                />
              }
              label="Internal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isExternalSelected}
                  onChange={toggleExternalFilter}
                  color="primary"
                />
              }
              label="External"
            />
          </div>
          <div className="iq4-search-page__filter-container--filters">
            <DropdownFilter
              disabled={disabledDropdowns.managers}
              useVirtualized
              filterList={managers}
              className="iq4-search-page__filter-container--checkbox"
              filterLabel="Managers"
              selected={selectedDropdownFilters.managers.selectedOptions}
              partiallySelected={[]}
              onChange={(checked, filterItem) =>
                void onDropdownFilterChange({ itemKey: 'managers', isChecked: checked, filterItem })
              }
              onSelectAll={() => void onDropdownFilterChange({ itemKey: 'managers', isAll: true })}
            />
            <DropdownFilter
              disabled={disabledDropdowns.pathways}
              useVirtualized
              filterList={pathways}
              className="iq4-search-page__filter-container--checkbox"
              filterLabel="User Pathways"
              selected={selectedDropdownFilters.pathways.selectedOptions}
              partiallySelected={[]}
              onChange={(checked, filterItem) =>
                void onDropdownFilterChange({ itemKey: 'pathways', isChecked: checked, filterItem })
              }
              onSelectAll={() => void onDropdownFilterChange({ itemKey: 'pathways', isAll: true })}
            />
            <DropdownFilter
              disabled={disabledDropdowns.locations}
              useVirtualized
              filterList={locations}
              className="iq4-search-page__filter-container--checkbox"
              filterLabel="Location"
              selected={selectedDropdownFilters.locations.selectedOptions}
              partiallySelected={[]}
              onChange={(checked, filterItem) =>
                void onDropdownFilterChange({
                  itemKey: 'locations',
                  isChecked: checked,
                  filterItem,
                })
              }
              onSelectAll={() => void onDropdownFilterChange({ itemKey: 'locations', isAll: true })}
            />
          </div>
          <div className="iq4-search-page__filter-container--filters">
            <DropdownFilter
              disabled={true}
              useVirtualized
              filterList={skillStatuses}
              className="iq4-search-page__filter-container--checkbox"
              filterLabel="Skills Status"
              selected={selectedDropdownFilters.skillStatuses.selectedOptions}
              partiallySelected={[]}
              onChange={(checked, filterItem) =>
                void onDropdownFilterChange({
                  itemKey: 'skillStatuses',
                  isChecked: checked,
                  filterItem,
                })
              }
              onSelectAll={() =>
                void onDropdownFilterChange({ itemKey: 'skillStatuses', isAll: true })
              }
            />
            <DropdownFilter
              disabled={disabledDropdowns.credentials}
              useVirtualized
              filterList={credentials}
              className="iq4-search-page__filter-container--checkbox"
              filterLabel="All Credentials"
              selected={selectedDropdownFilters.credentials.selectedOptions}
              partiallySelected={[]}
              onChange={(checked, filterItem) =>
                void onDropdownFilterChange({
                  itemKey: 'credentials',
                  isChecked: checked,
                  filterItem,
                })
              }
              onSelectAll={() =>
                void onDropdownFilterChange({ itemKey: 'credentials', isAll: true })
              }
            />
            <DropdownFilter
              disabled={disabledDropdowns.schools}
              useVirtualized
              filterList={schools}
              className="iq4-search-page__filter-container--checkbox"
              filterLabel="Schools"
              selected={selectedDropdownFilters.schools.selectedOptions}
              partiallySelected={[]}
              onChange={(checked, filterItem) =>
                void onDropdownFilterChange({ itemKey: 'schools', isChecked: checked, filterItem })
              }
              onSelectAll={() => void onDropdownFilterChange({ itemKey: 'schools', isAll: true })}
            />
          </div>
          <div>
            <Button variation="ghost" className="iq4-search-page__button" onClick={submitFilters}>
              Submit
            </Button>
          </div>
        </div>
        {performedInitialSearch && users && (
          <div className="iq4-search-page__table-container">
            <SearchPageTable users={users} isLoading={isLoadingFilterResults} />
          </div>
        )}
      </div>
    </div>
  );
};
