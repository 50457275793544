import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  DashboardPage,
  SearchPage,
  UserProfilePage,
  LoginPage,
  JobReqsPage,
  SingleJobReqPage,
  NewJobReqPage,
} from 'pages';
import { ROUTES } from './routeNames';
import { ProtectedRoute } from './ProtectedRoute';

const context = process.env.REACT_APP_CONTEXT;

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <ProtectedRoute component={DashboardPage} exact path={[context, ROUTES.DASHBOARD]} /> */}
        <ProtectedRoute component={SearchPage} exact path={ROUTES.SEARCH} />
        <ProtectedRoute component={UserProfilePage} path={`${ROUTES.USER_PROFILE}/:id`} />
        <ProtectedRoute component={JobReqsPage} exact path={[context, ROUTES.JOB_REQS]} />
        <ProtectedRoute component={NewJobReqPage} path={`${ROUTES.JOB_REQS}/new`} />
        <ProtectedRoute component={SingleJobReqPage} exact path={`${ROUTES.JOB_REQS}/:jobReqId`} />

        <Route component={LoginPage} exact path={`${ROUTES.LOGIN}`} />
        <Redirect exact from="/enterprise" to={ROUTES.DASHBOARD} />
        <Redirect from="*" to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
