import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import './Paginator.scss';

const LEFT = 'LEFT';
const RIGHT = 'RIGHT';
const BACK_ICON = 'BACK_ICON';
const NEXT_ICON = 'NEXT_ICON';

/**
 * Component that paginates collections
 * @param totalRecords
 * @param pageLimit
 * @param onPageChanged
 * @param isNextPreviousIconOnly
 * @returns {null|*}
 * @constructor
 */
export function Paginator({
  totalRecords = 0,
  pageLimit = 5,
  onPageChanged = () => {},
  isNextPreviousIconOnly,
  context,
  outCurrentPage,
}) {
  // declare total number of pages
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * creates the proper pages to be rendered
   * @param from
   * @param to
   * @param step
   * @returns {Array}
   */
  function pageCollection(from, to, step = 1) {
    let i = from;
    const pages = [];

    while (i <= to) {
      pages.push(i);
      i += step;
    }

    return pages;
  }

  /**
   * main function for manipulating current page
   * @param page
   */
  const gotoPage = (page) => {
    const newCurrentPage = Math.max(1, Math.min(page, totalPages));
    setCurrentPage(newCurrentPage);
  };

  useEffect(() => {
    gotoPage(1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    gotoPage(1);
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const totalPages = Math.ceil(totalRecords / pageLimit);
    setTotalPages(totalPages);
    gotoPage(1);
  }, [totalRecords, pageLimit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (outCurrentPage && outCurrentPage !== currentPage) {
      gotoPage(outCurrentPage);
    }
  }, [outCurrentPage]);

  /**
   * Listen to changes on current page
   */
  useEffect(() => {
    const paginationData = {
      currentPage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    onPageChanged(paginationData);
  }, [currentPage, pageLimit]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * click specific page event
   * @param page
   * @param evt
   */
  const handleClick = (evt, page) => {
    evt.preventDefault();
    if (page === currentPage) return;
    gotoPage(page);
  };

  /**
   * click on back button event
   * @param evt
   */
  const handleMoveLeft = (evt, index) => {
    evt.preventDefault();
    gotoPage(currentPage - 1);
  };

  /**
   * click on next button event
   * @param evt
   */
  const handleMoveRight = (evt, index) => {
    evt.preventDefault();
    gotoPage(index + 1);
  };

  /**
   * function responsible for building collection of pages currently rendered on the paginator
   * @returns {*[]|Array}
   */
  const fetchPageNumbers = () => {
    const totalNumbers = 5;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages;

      const leftBound = currentPage - 1;
      const rightBound = currentPage + 1;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = pageCollection(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftRemaining = startPage > 2;
      const rightRemaining = endPage < beforeLastPage;

      if (leftRemaining && !rightRemaining) {
        const extraPages = pageCollection(startPage - singleSpillOffset, startPage - 1);
        pages = [LEFT, ...extraPages, ...pages];
      } else if (!leftRemaining && rightRemaining) {
        const extraPages = pageCollection(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, RIGHT];
      } else if (leftRemaining && rightRemaining) {
        pages = [LEFT, ...pages, RIGHT];
      }

      return [BACK_ICON, 1, ...pages, totalPages, NEXT_ICON];
    }

    return pageCollection(1, totalPages);
  };

  if (!totalRecords) return null;
  if (totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <Fragment>
      <nav aria-label="iq4-paginator__container">
        <ul className="iq4-paginator__section">
          {pages.map((page, index) => {
            // these refer to the inner back / next "..." sections
            if (page === LEFT || page === RIGHT) {
              return (
                <li
                  key={index}
                  className="iq4-paginator__item "
                  onClick={
                    page === LEFT
                      ? (evt) => handleMoveLeft(evt, index)
                      : (evt) => handleMoveRight(evt, index)
                  }
                >
                  <span className="page-link" aria-label="Previous">
                    <span aria-hidden="true">...</span>
                    <span className="sr-only">${page === LEFT ? 'Previous' : 'Next'}</span>
                  </span>
                </li>
              );
            }

            // these refer to the back / next icon
            if (page === BACK_ICON || page === NEXT_ICON) {
              const isBackDisabled = currentPage === 1;
              const isNextDisabled = currentPage === totalPages;
              // prettier-ignore
              const onClickHandler = page === BACK_ICON ? (evt) => handleClick(evt, currentPage === 1 ? 1 : currentPage - 1)
                : (evt) => handleClick(evt, currentPage === totalPages ? totalPages : currentPage + 1);
              return (
                <li key={index} className="iq4-paginator__item " onClick={onClickHandler}>
                  <span
                    className="iq4-paginator__prev-next"
                    aria-label={`${page === BACK_ICON ? 'Previous' : 'Next'}`}
                  >
                    {page === BACK_ICON ? (
                      isNextPreviousIconOnly ? (
                        <span className="iq4-paginator__prev-text">Previous</span>
                      ) : (
                        <BackIcon
                          classes={{
                            root: `iq4-paginator__icon ${
                              isBackDisabled ? 'iq4-paginator__icon-disabled' : ''
                            }`,
                          }}
                        />
                      )
                    ) : isNextPreviousIconOnly ? (
                      <span>Next</span>
                    ) : (
                      <NextIcon
                        classes={{
                          root: `iq4-paginator__icon ${
                            isNextDisabled ? 'iq4-paginator__icon-disabled' : ''
                          }`,
                        }}
                      />
                    )}
                    <span className="sr-only">
                      ${page === BACK_ICON ? 'Go to previous page' : 'Go to next page'}
                    </span>
                  </span>
                </li>
              );
            }

            return (
              <li
                key={index}
                className={`iq4-paginator__item ${
                  currentPage === page ? ' iq4-paginator__item-active' : ''
                }`}
                aria-label={currentPage === page ? 'Current page' : ''}
              >
                <button
                  className={`page-link page-link-${page}`}
                  onClick={(e) => handleClick(e, page)}
                >
                  {page}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
    </Fragment>
  );
}

Paginator.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
};
