import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  SkillsLegendTile,
  SimpleIcon,
  DashboardSkill,
  DashboardCourse,
  DashboardStudent,
  DashboardRole,
  DashboardDevelopmentResource,
} from 'common/components';
import { fetchWorkRoleReq } from 'common/store/features/dashboard/dashboardSlice';
import './DashboardList.scss';

export const DashboardList = ({
  list,
  type,
  isExpandable = true,
  onItemSelect,
  institution,
  onItemClick = () => {},
}) => {
  const dispatch = useDispatch();
  const [isSingleItemLoaded, setIsSingleItemLoaded] = useState(false);

  const ITEM_TYPES = {
    skill: {
      header: (item) => ({
        icon: <SkillsLegendTile type={item.type} />,
        label: `${item.label} (${item.specialtyArea})`,
      }),
      details: (item, index) => <DashboardSkill skill={item} index={index} />,
    },
    student: {
      header: (item) => ({
        icon: (
          <div className="iq4-dashboard-list__panel-header-icon">
            <SimpleIcon name="person" />
          </div>
        ),
        label: item.label,
      }),
      details: (item, index) => <DashboardStudent student={item} index={index} />,
    },
    course: {
      header: (item) => ({
        icon: (
          <div className="iq4-dashboard-list__panel-header-icon">
            <SimpleIcon name="education" />
          </div>
        ),
        label: item.label,
      }),
      details: (item, index) => <DashboardCourse course={item} index={index} />,
    },
    microcredential: {
      header: (item) => ({
        icon: (
          <div className="iq4-dashboard-list__panel-header-icon">
            <SimpleIcon name="education" />
          </div>
        ),
        label: item.label,
      }),
      details: (item, index) => <DashboardCourse course={item} index={index} />,
    },
    pathway: {
      header: (item) => ({
        icon: (
          <div className="iq4-dashboard-list__panel-header-icon">
            <SimpleIcon name="portfolio" />
          </div>
        ),
        label: item.label,
      }),
      details: (item, index) => (
        <DashboardRole role={item} index={index} institution={institution} showStudents={true} />
      ),
      action: fetchWorkRoleReq,
    },
    role: {
      header: (item) => ({
        icon: (
          <div className="iq4-dashboard-list__panel-header-icon">
            <SimpleIcon name="portfolio" />
          </div>
        ),
        label: item.label,
      }),
      details: (item, index) => (
        <DashboardRole
          role={item}
          index={index}
          institution={institution}
          onItemClick={onItemClick}
        />
      ),
      action: fetchWorkRoleReq,
    },
    'development resource': {
      header: (item) => ({
        icon: (
          <div className="iq4-dashboard-list__panel-header-icon">
            <SimpleIcon name="portfolio" />
          </div>
        ),
        label: item.label,
      }),
      details: (item) => {
        return (
          <DashboardDevelopmentResource
            description={item.description}
            topSkills={item.topSkills}
            proficiencyLevel={item.proficiencyLevel}
            roles={item.roles}
          />
        );
      },
    },
  };

  const itemType = ITEM_TYPES[type];

  useEffect(() => {
    if (list.length === 1 && !isSingleItemLoaded && itemType.action) {
      dispatch(itemType.action(list[0].id));
      setIsSingleItemLoaded(true);
    }
  }, [itemType, list, isSingleItemLoaded, dispatch]);

  const renderDetails = (item, index) => itemType.details(item, index);

  if (list.length === 1) {
    const item = list[0];

    return (
      <div className="iq4-dashboard-list__single">
        <div className="iq4-dashboard-list__single-title">
          {item.label}
          {item.pill && <div className="iq4-dashboard-list__single-title-pill">{item.pill}</div>}
        </div>
        <div
          className={`iq4-dashboard-list__single-content iq4-dashboard-list__single-content--${type}`}
        >
          {renderDetails(list[0], 0)}
        </div>
      </div>
    );
  }

  return (
    <div className="iq4-dashboard-list">
      {list.map((item, index) => {
        return (
          <ExpansionPanel
            disabled={!isExpandable}
            key={list.id}
            square
            classes={{
              root: 'iq4-dashboard-list__panel',
            }}
            onChange={() => onItemSelect && onItemSelect(item.id)}
          >
            <ExpansionPanelSummary
              classes={{
                root: 'iq4-dashboard-list__panel-summary',
              }}
              expandIcon={isExpandable ? <ExpandMoreIcon /> : null}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="iq4-dashboard-list__panel-header">
                {itemType.header(item).icon}
                <div className="iq4-dashboard-list__panel-header-label">
                  {itemType.header(item).label}
                </div>
              </div>
            </ExpansionPanelSummary>
            {isExpandable && (
              <ExpansionPanelDetails
                classes={{
                  root: 'iq4-dashboard-list__panel-details',
                }}
              >
                {renderDetails(item, index)}
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
        );
      })}
    </div>
  );
};
