import React from 'react';
import { ActionCard, SimpleIcon, Pill } from 'common/components';
import './UserProfileCredentials.scss';

const UserProfileCredentialsContent = ({ credentials, noLink }) => (
  <div className="iq4-user-profile-credential__content">
    <table className="iq4-user-profile-credentials__table">
      <thead>
        <tr>
          <th className="iq4-user-profile-credentials__table--credential">Credential</th>
          <th className="iq4-user-profile-credentials__table--credentialSource">Credential Source</th>
          <th className="iq4-user-profile-credentials__table--status">Status</th>
        </tr>
      </thead>
      {credentials.map((credential) => (
        <tr key={credential.id}>
          <td className="iq4-user-profile-credentials__table--credential">
            <button
              onClick={
                noLink
                  ? () => {}
                  : () => {
                      alert('do something');
                    }
              }
            >
              {credential.label}
              {!noLink && <SimpleIcon name="arrow" />}
            </button>
          </td>
          <td className="iq4-user-profile-credentials__table--credentialSource">
            {credential.source}
          </td>
          <td
            className={`iq4-user-profile-credentials__table--status iq4-pill__status--${credential.status}`}
          >
            <Pill label={credential.statusLabel} />
          </td>
        </tr>
      ))}
    </table>
  </div>
);

export const UserProfileCredentials = ({ credentials, noLink }) => (
  <ActionCard
    title="Credentials"
    icon="educationCircle"
    isDivideVisible={false}
    className="iq4-user-profile-credentials"
  >
    <UserProfileCredentialsContent credentials={credentials} noLink={noLink} />
  </ActionCard>
);

export const UserProfileCredentialsSimple = ({ credentials, noLink }) => (
  <>
    <UserProfileCredentialsContent credentials={credentials} noLink={noLink} />
  </>
);
