import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Select, FormControl, MenuItem, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import _orderBy from 'lodash/orderBy';
import './BrowseRolesInput.scss';

export const BrowseRolesInput = ({ roles = [], onSelect, selectedJobFamily }) => {
  const onSelectRole = (_, opt) => {
    const role = roles.find((item) => item.id === opt.id);
    onSelect({
      id: role.id,
      label: role.label,
    });
  };

  return (
    <div className="iq4-select__wrapper iq4-categories">
      <label className="iq4-categories__label">Browse Jobs By job family</label>
      <FormControl
        variant="outlined"
        className="iq4-select__form-control iq4-select__form-control-max-width"
      >
        <Autocomplete
          disablePortal
          options={_orderBy(roles, 'label', 'asc')}
          onChange={onSelectRole}
          popupIcon={<KeyboardArrowDownIcon />}
          classes={{
            input: 'iq4-select__input',
            root: 'iq4-search-roles__autocomplete',
          }}
          fullWidth
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" placeholder="Select Job Family" />
          )}
        />
      </FormControl>
    </div>
  );
};
