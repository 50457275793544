import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { TShapeV2Box } from '../TShapeV2Box';
import tshapeImgSrc from '../t_static.png';
import './TShapeV2Graph.scss';

export const STACK_ITEM_TYPES = {
  FILLED: 'FILLED',
  LINES: 'LINES',
  FILLED_LINES: 'FILLED_LINES',
};

/**
 * T-Shape V2 Graph component
 * @param {object[]} top - top quadrant data
 * @param {object[]} bottomRight - bottom right quadrant data
 * @param {object[]} bottomLeft - bottom left quadrant data
 * @param {object} [sectionLabels] - Each quadrant labels
 * @param {boolean} [displayOnly] - if set to true, it will show a non-interactive graph
 * @param {function} [onClick] - handle box click
 */
export const TShapeV2GraphImg = ({
  top,
  bottomRight,
  bottomLeft,
  sectionLabels,
  displayOnly,
  onClick,
}) => {
  return (
    <div className="iq4-tshape-v2-graph">
      {!displayOnly && (
        <div className="iq4-tshape-v2-graph__top-labels">
          <div className="iq4-tshape-v2-graph__section-label">{sectionLabels.top}</div>
        </div>
      )}
      <div
        className={`iq4-tshape-v2-graph__container ${
          displayOnly ? 'iq4-tshape-v2-graph__container--display-only' : ''
        }`}
      >
        <img src={tshapeImgSrc} alt="T-Shape" style={{ width: '100%' }} />
      </div>

      {!displayOnly && (
        <div className="iq4-tshape-v2-graph__bottom-labels">
          <div className="iq4-tshape-v2-graph__section-label">{sectionLabels.bottomLeft}</div>
          <div className="iq4-tshape-v2-graph__section-label">{sectionLabels.bottomRight}</div>
        </div>
      )}
    </div>
  );
};

export const TShapeV2Graph = ({
  top,
  bottomRight,
  bottomLeft,
  sectionLabels,
  displayOnly,
  onClick,
}) => {
  const topGraphElRef = useRef(null);
  const bottomGraphElRef = useRef(null);
  const bottomTStemRef = useRef(null);
  const [boxWidth, setBoxWidth] = useState(null);

  const [sortedTop, setSortedTop] = useState(getSortedTop(top));
  const [sortedBottomLeft, setSortedBottomLeft] = useState(getSortedDefault(bottomLeft, true));
  const [sortedBottomRight, setSortedBottomRight] = useState(getSortedDefault(bottomRight, false));

  // handle resizing boxes on resize of window.
  useEffect(() => {
    const handleResize = debounce(function () {
      const newBoxWidth = getBoxDimensions(
        topGraphElRef.current,
        bottomTStemRef.current,
        top,
        bottomRight,
        bottomLeft,
      );

      setBoxWidth(newBoxWidth);
    }, 24);

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [topGraphElRef, bottomGraphElRef, top, bottomRight, bottomLeft]);

  // resize boxes when new data comes in.
  useEffect(() => {
    const newBoxWidth = getBoxDimensions(
      topGraphElRef.current,
      bottomTStemRef.current,
      top,
      bottomRight,
      bottomLeft,
    );

    setBoxWidth(newBoxWidth);
  }, [topGraphElRef, bottomGraphElRef, top, bottomRight, bottomLeft]); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    const newSorted = getSortedTop(top);
    setSortedTop(newSorted);
  }, [top]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newSorted = getSortedDefault(bottomLeft, true);
    setSortedBottomLeft(newSorted);
  }, [bottomLeft]);

  useEffect(() => {
    const newSorted = getSortedDefault(bottomRight, false);
    setSortedBottomRight(newSorted);
  }, [bottomRight]);

  // determine the box width

  function getSortedTop(top) {
    const sorted = getSortedDefault(top, false, true);

    let middleSorted = [];
    sorted.forEach((item, i) => {
      if (i % 2 === 0) {
        middleSorted.push(item);
      } else {
        middleSorted.unshift(item);
      }
    });

    return middleSorted;
  }

  function getSortedDefault(list, reverse, reverseStack) {
    const sorted = list.slice().sort((a, b) => {
      if (a.stack.length === b.stack.length) {
        let countType = {
          a: { [STACK_ITEM_TYPES.FILLED]: 0, [STACK_ITEM_TYPES.FILLED_LINES]: 0 },
          b: { [STACK_ITEM_TYPES.FILLED]: 0, [STACK_ITEM_TYPES.FILLED_LINES]: 0 },
        };
        const aFilledStacksCount = a.stack.reduce((sum, item) => {
          if (
            item.type === STACK_ITEM_TYPES.FILLED ||
            item.type === STACK_ITEM_TYPES.FILLED_LINES
          ) {
            sum += 1;
            countType.a[item.type]++;
          }

          return sum;
        }, 0);

        const bFilledStacksCount = b.stack.reduce((sum, item) => {
          if (
            item.type === STACK_ITEM_TYPES.FILLED ||
            item.type === STACK_ITEM_TYPES.FILLED_LINES
          ) {
            sum += 1;
            countType.b[item.type]++;
          }

          return sum;
        }, 0);

        if (aFilledStacksCount !== bFilledStacksCount) {
          return aFilledStacksCount > bFilledStacksCount ? (reverse ? 1 : -1) : reverse ? -1 : 1;
        }

        return countType.a[STACK_ITEM_TYPES.FILLED_LINES] >
          countType.b[STACK_ITEM_TYPES.FILLED_LINES]
          ? reverse
            ? 1
            : -1
          : reverse
          ? -1
          : 1;
      } else {
        return a.stack.length > b.stack.length ? (reverse ? 1 : -1) : reverse ? -1 : 1;
      }
    });

    let sortedWithSortedStacks = [];
    const priorityMap = {
      [STACK_ITEM_TYPES.FILLED_LINES]: 1,
      [STACK_ITEM_TYPES.FILLED]: 0,
      [STACK_ITEM_TYPES.LINES]: -1,
    };
    sorted.forEach((item) => {
      const sortedStack = item.stack.slice().sort((a, b) => {
        return reverseStack
          ? priorityMap[a.type] - priorityMap[b.type]
          : priorityMap[b.type] - priorityMap[a.type];
      });

      sortedWithSortedStacks.push({ id: item.id, label: item.label, stack: sortedStack });
    });

    return sortedWithSortedStacks;
  }

  function getBoxDimensions(topEl, bottomTStem, topList, bottomRightList, bottomLeftList) {
    if (!topEl || !bottomTStem) return;

    const topElWidth = topEl.offsetWidth;
    const bottomStemWidth = bottomTStem.offsetWidth;
    const bottomElWidth = topElWidth / 2 - bottomStemWidth / 2;

    let boxWidthHeight;
    if (topList.length / 2 < bottomRightList.length || topList.length / 2 < bottomLeftList.length) {
      // bottom right has more elements, else bottom left does
      if (bottomRightList.length > bottomLeftList.length) {
        boxWidthHeight = bottomElWidth / bottomRightList.length;
      } else {
        boxWidthHeight = bottomElWidth / bottomLeftList.length;
      }
    } else {
      boxWidthHeight = topElWidth / topList.length;
    }

    return boxWidthHeight;
  }

  // render an individual stack
  const renderStack = (stack, t2) => {
    return (
      <div className="iq4-tshape-v2-graph__stack" style={{ width: `${boxWidth}px` }}>
        {stack.map((stackItem) => {
          return (
            <div
              style={{ width: `${boxWidth}px`, height: `${boxWidth}px` }}
              className={`iq4-tshape-v2-graph__stack-item-container ${
                onClick ? 'iq4-tshape-v2-graph__stack-item-container--clickable' : ''
              }`}
              onClick={() => {
                if (onClick && !displayOnly)
                  onClick({ column: { id: t2.id, label: t2.label }, ...stackItem });
              }}
            >
              <TShapeV2Box
                type={stackItem.type}
                className={`iq4-tshape-v2-graph__stack-item iq4-tshape-v2-graph__stack-item--${stackItem.type}`}
              />

              {!displayOnly && (
                <div className="iq4-tshape-v2-graph__stack-item-tooltip">
                  <div className="iq4-tshape-v2-graph__stack-item-tooltip-label">
                    {stackItem.label}
                  </div>
                  <div className="iq4-tshape-v2-graph__stack-item-tooltip-label">
                    {stackItem.t2.label}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  // renders all the stacks for a section
  const renderStacks = (stacks, quadrant) => {
    return (
      <div className="iq4-tshape-v2-graph__stacks-container">
        {stacks.map(({ stack, ...t2 }) => renderStack(stack, t2))}

        {/* Center the tallest stack always*/}
        {quadrant === 'top' && stacks.length % 2 === 0 && (
          <div className="iq4-tshape-v2-graph__stack" style={{ width: `${boxWidth}px` }}></div>
        )}
      </div>
    );
  };

  return (
    <div className="iq4-tshape-v2-graph">
      {!displayOnly && (
        <div className="iq4-tshape-v2-graph__top-labels">
          <div className="iq4-tshape-v2-graph__section-label">{sectionLabels.top}</div>
        </div>
      )}
      <div
        className={`iq4-tshape-v2-graph__container ${
          displayOnly ? 'iq4-tshape-v2-graph__container--display-only' : ''
        }`}
      >
        <div className="iq4-tshape-v2-graph__top" ref={topGraphElRef}>
          {renderStacks(sortedTop, 'top')}
        </div>
        <div className="iq4-tshape-v2-graph__stem-top"></div>
        <div className="iq4-tshape-v2-graph__bottom-section">
          <div className="iq4-tshape-v2-graph__bottom-left" ref={bottomGraphElRef}>
            {renderStacks(sortedBottomLeft)}
          </div>
          <div className="iq4-tshape-v2-graph__stem-bottom" ref={bottomTStemRef}></div>
          <div className="iq4-tshape-v2-graph__bottom-right">{renderStacks(sortedBottomRight)}</div>
          {!displayOnly && (
            <div className="iq4-tshape-v2-graph__legend-container">
              <div className="iq4-tshape-v2-graph__legend-item">
                <div className="iq4-tshape-v2-graph_legend-item-icon"></div>
                <div className="iq4-tshape-v2-graph_legend-item-icon"></div>
                <div className="iq4-tshape-v2-graph_legend-item-icon"></div>
                <div className="iq4-tshape-v2-graph_legend-item-label">My Skills</div>
              </div>
              <div className="iq4-tshape-v2-graph__legend-item">
                <div className="iq4-tshape-v2-graph_legend-item-icon">
                  <TShapeV2Box type={STACK_ITEM_TYPES.LINES} />
                </div>
                <div className="iq4-tshape-v2-graph_legend-item-icon">
                  <TShapeV2Box type={STACK_ITEM_TYPES.LINES} />
                </div>
                <div className="iq4-tshape-v2-graph_legend-item-icon">
                  <TShapeV2Box type={STACK_ITEM_TYPES.LINES} />
                </div>
                <div className="iq4-tshape-v2-graph_legend-item-label">Role Skills</div>
              </div>
            </div>
          )}
        </div>
      </div>

      {!displayOnly && (
        <div className="iq4-tshape-v2-graph__bottom-labels">
          <div className="iq4-tshape-v2-graph__section-label">{sectionLabels.bottomLeft}</div>
          <div className="iq4-tshape-v2-graph__section-label">{sectionLabels.bottomRight}</div>
        </div>
      )}
    </div>
  );
};
