import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button/Button';
import { Close, LeftArrow } from '../Icon/Icon';
import { Loader } from '../Loader/Loader';
import SkillsSearchBar from './SkillsSearchBar/SkillsSearchBar';
import SkillsBrowseBar from './SkillsBrowseBar/SkillsBrowseBar';
import { SkillsBrowseByRole } from './SkillsBrowseByRole/SkillsBrowseByRole';
import SkillsSearchResults from './SkillsSearchResults/SkillsSearchResults';
import { useDebounce } from '../hooks/useDebounce';
import {
  broadcastSearch,
  broadcastGetBrowseCategories,
  broadcastGetBrowseKsas,
  broadcastSaveAdditionalSkills,
  boradcastSearchSkillsByRole,
} from 'common/store/features/skillsModuleState/broadcaster/actions';
import actions from 'common/store/features/skillsModuleState/actions';

import {
  browseResultsSelector,
  getTier1sSelector,
  selectBrowseSkillsByRoleList,
} from 'common/store/features/skillsModuleState/skills/selectors';

const SEARCH = 'SEARCH';
const BROWSE = 'BROWSE';
const ROLES = 'ROLES';
const SEARCH_TRESHOLD = 3;

const SkillsSearch = ({ skillTier1, onClose }) => {
  const dispatch = useDispatch();
  const [searchType, toggleSearchType] = useState(SEARCH);
  const [includeKSAs, toggleIncludeKSAs] = useState(false);
  const [searchTerm, changeSearchTerm] = useState('');
  const [selectedRoleSkills, setSelectedRoleSkills] = useState(null);

  const additionalSkillsRoleLoader = useSelector(
    (state) => state.skillsModule.loaders.additionalSkillsRole,
  );
  const searchResults = useSelector((state) => state.skillsModule.skills.searchResults);
  const [currentResults, setCurrentResults] = useState([]);

  const browseCategories = useSelector((state) => state.skillsModule.skills.browseCategories);
  const browseResults = useSelector(browseResultsSelector);
  const savingSkillsLoader = useSelector((state) => state.skillsModule.loaders.savingSkills);
  const tier1s = useSelector(getTier1sSelector);
  const browseSkillsByRoleList = useSelector(selectBrowseSkillsByRoleList);

  // watch for saving of skills
  useEffect(() => {
    if (savingSkillsLoader === 'SUCCESS') {
      // reset loader
      dispatch(actions.loaders.toggleSkillsLoader('savingSkills', null));

      // reset modal
      handleToggleSearchType(SEARCH);

      // close the modal
      onClose(false);
    }
  }, [savingSkillsLoader]);

  // Search for skills
  const debouncedSearchTerm = useDebounce(searchTerm, 350);
  useEffect(() => {
    if (debouncedSearchTerm.length >= SEARCH_TRESHOLD) {
      // perform search
      dispatch(broadcastSearch({ skillTier1, searchTerm: debouncedSearchTerm }));
    }

    if (debouncedSearchTerm.length < SEARCH_TRESHOLD) {
      dispatch(actions.skills.updateSearchResults([]));
    }
  }, [debouncedSearchTerm]);

  // Get Browse Search categories
  useEffect(() => {
    dispatch(broadcastGetBrowseCategories({ skillTier1 }));
  }, [skillTier1.id]);

  // set the current results when tabs change & clear previous results
  useEffect(() => {
    if (searchType === BROWSE) {
      setCurrentResults([...browseResults]);
    } else {
      setCurrentResults([...searchResults]);
    }
  }, [searchType, searchResults, browseResults]);

  // handle browse category change
  const handleCategoryChange = ({ skillTier1, skillTier2, skillTier3, value }) => {
    if (value) {
      // get ksas
      dispatch(broadcastGetBrowseKsas({ skillTier1, skillTier2, skillTier3 }));
    } else {
      // remove from store
      dispatch(
        actions.skills.removeBrowseResult({
          skillTier1,
          skillTier2,
          skillTier3,
        }),
      );

      // remove from skills that will be saved if included.
    }
  };

  const handleToggleSearchType = (newSearchType) => {
    dispatch(actions.skills.updateSearchResults([]));
    dispatch(actions.skills.clearBrowseResults());
    if (selectedRoleSkills) {
      setSelectedRoleSkills(null);
    }

    if (searchTerm) {
      changeSearchTerm('');
    }

    toggleSearchType(newSearchType);
  };

  // save additional skills
  const handleSaveSkills = (skills) => {
    // save skills
    if (skills.length) dispatch(broadcastSaveAdditionalSkills(skills));
  };

  const handleSkillsBrowseRoleClick = ({ role, meta }) => {
    setSelectedRoleSkills({
      roleLabel: role.label,
      roleId: role.id,
      jobFamilyLabel: meta.label,
      jobFamilyId: meta.id,
    });

    // TODO: request for role skills here

    dispatch(
      boradcastSearchSkillsByRole({
        role,
        jobFamily: { id: meta.id, label: meta.label },
      }),
    );
  };

  return (
    <section className="iq4-skills-search-container">
      <div className="iq4-skills-search-header">
        <p className="iq4-skills-search-header-title">Additional Skills</p>
        <Button className="iq4-skills-search-close" type="link" onClick={() => void onClose(false)}>
          <Close />
        </Button>
      </div>
      <div className="iq4-skills-search-main">
        {skillTier1.id !== null && (
          <React.Fragment>
            <h2 className="iq4-skills-search-title">Select/Search within {skillTier1.value}</h2>
            <hr />
          </React.Fragment>
        )}

        <div className="iq4-skills-search-tabs" role="tablist">
          <Button
            aria-label="Search skills"
            role="tab"
            aria-selected={searchType === SEARCH ? 'true' : 'false'}
            type="link"
            className={searchType === SEARCH ? 'iq4-skills-search-tab-active' : null}
            onClick={() => void handleToggleSearchType(SEARCH)}
          >
            Search Skills
          </Button>
          {/* <Button
            aria-label="Browse skills"
            role="tab"
            aria-selected={searchType === BROWSE ? 'true' : 'false'}
            type="link"
            className={searchType === BROWSE ? 'iq4-skills-search-tab-active' : null}
            onClick={() => void handleToggleSearchType(BROWSE)}
          >
            Browse Skills
          </Button> */}
          {/* <Button
            aria-label="Browse Role Profiles"
            role="tab"
            aria-selected={searchType === ROLES ? 'true' : 'false'}
            type="link"
            className={searchType === ROLES ? 'iq4-skills-search-tab-active' : null}
            onClick={() => void handleToggleSearchType(ROLES)}
          >
            Browse Role Profiles
          </Button> */}
        </div>

        {searchType === SEARCH && (
          <SkillsSearchBar
            skillTier1={skillTier1}
            searchResults={searchResults}
            onChange={changeSearchTerm}
            onSearchSelect={setCurrentResults}
            includeKSAs={includeKSAs}
          />
        )}

        {searchType === BROWSE && (
          <SkillsBrowseBar
            tier1s={tier1s}
            onTier1Select={(skillTier1) => {
              dispatch(broadcastGetBrowseCategories({ skillTier1: skillTier1 }));
            }}
            skillTier1={skillTier1}
            categories={browseCategories}
            onChange={handleCategoryChange}
          />
        )}

        {searchType === ROLES && !selectedRoleSkills && (
          <SkillsBrowseByRole onClick={handleSkillsBrowseRoleClick} list={browseSkillsByRoleList} />
        )}

        {/* {searchType === SEARCH && (
          <div className="iq4-skills-search-include-ksa">
            <Checkbox
              type="checkbox"
              onChange={(e) => void toggleIncludeKSAs(e.target.checked)}
              checked={includeKSAs}
            />
            <span>Include Knowledge, Skills, and Abilities in suggestions</span>
          </div>
        )} */}

        {searchType === ROLES && selectedRoleSkills && (
          <>
            <button
              onClick={() => {
                dispatch(actions.skills.updateSearchResults([]));
                setSelectedRoleSkills(null);
              }}
              className="iq4-skills-search-selected-role-header-btn"
            >
              <LeftArrow />
              <span>
                <strong>{selectedRoleSkills.roleLabel} - </strong>
                {selectedRoleSkills.jobFamilyLabel}
              </span>
            </button>

            {!currentResults && (
              <div className="iq4-skills-search-selected-role-header-loading">
                Loading skills...
              </div>
            )}
          </>
        )}

        {!!currentResults.length && !additionalSkillsRoleLoader.loading && (
          <SkillsSearchResults
            savingLoader={savingSkillsLoader}
            skills={currentResults}
            searchTerm={debouncedSearchTerm}
            skillTier1={skillTier1}
            onSave={handleSaveSkills}
          />
        )}

        {!currentResults.length && additionalSkillsRoleLoader.loading && (
          <Loader>
            <small style={{ marginTop: '8px' }}>Loading skills &hellip;</small>
          </Loader>
        )}
      </div>
    </section>
  );
};

export default SkillsSearch;
