import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextInput,
  AutoComplete,
  Checkbox,
  TextArea,
  Select,
  DateRange,
  FileUpload,
} from 'common/components/Form/fieldTypes';
import { useSkillsSearch } from 'common/hooks/useSkillsSearch';
import { validations } from 'common/components/Form/validations';
import { PROFILE_EDUCATION_URL } from 'common/constants/endpoints';
import { THUMBNAIL_MIME_TYPES } from 'common/constants/mimeTypes';
import {
  setProfileSection,
  updateProfileSection,
} from 'common/store/features/profile/profileSlice';
import { PROFILE_TYPE, sendFormDataRequest, transformProfileFormsPutData } from 'common/utils';
import { COUNTRIES } from 'common/constants/countries';
import './useProfileEducationFormDefinition.scss';

const countryOptions = COUNTRIES.map((country) => ({
  key: country,
  value: country,
}));

export const useProfileEducationFormDefinition = ({ values, onComplete }) => {
  const dispatch = useDispatch();
  const [competenciesSearchTerm, setCompetenciesSearchTerm] = useState('');
  const competenciesData = useSkillsSearch(competenciesSearchTerm);

  const fieldsDefinition = [
    {
      label: 'School',
      name: 'school',
      component: TextInput,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <Select maxWidth label="Country" name="country" options={countryOptions} {...formProps} />
      ),
    },
    {
      label: 'Course Title',
      name: 'title',
      component: TextInput,
    },
    {
      label: 'Major',
      name: 'major',
      component: TextInput,
    },
    {
      label: 'Location',
      name: 'location',
      component: TextInput,
    },
    {
      isCustom: true,
      component: ({ key, getValues, ...formProps }) => (
        <div>
          <Checkbox
            labelAfter
            name="isCurrentStudent"
            label="I'm currently enrolled here"
            checkboxClass="iq4-onboarding-education__checkbox"
            {...formProps}
          />
        </div>
      ),
    },
    {
      isCustom: true,
      component: ({ key, getValues, ...formProps }) => {
        const { isCurrentStudent } = getValues();
        return (
          <DateRange isPresent={isCurrentStudent} key={key} {...formProps} getValues={getValues} />
        );
      },
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <div className="iq4-profile-education__gpa">
          <span>
            <TextInput {...formProps} label="GPA" name="gpa" />
          </span>
          <span>
            <TextInput {...formProps} label="GPA in Major" name="gpaInMajor" />
          </span>
        </div>
      ),
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <AutoComplete
          name="competencies"
          label="Competencies"
          placeholder="List your skills"
          options={competenciesData}
          optionLabel="value"
          onInputChange={(e, value) => setCompetenciesSearchTerm(value)}
          {...formProps}
        />
      ),
    },
    {
      label: 'Description (140 characters)',
      name: 'description',
      component: TextArea,
      validators: {
        maxLength: validations.hasMaxLength(140),
      },
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <FileUpload
          triggerLabel="Upload a cover image"
          name="image"
          type="thumbnail"
          fileTypes={THUMBNAIL_MIME_TYPES}
          {...formProps}
        />
      ),
    },
  ];

  const onEdit = (data) => {
    return sendFormDataRequest({
      url: `${PROFILE_EDUCATION_URL}/${values.id}`,
      verb: 'PUT',
      data: transformProfileFormsPutData(data, values),
    }).then((resp) =>
      dispatch(updateProfileSection({ section: PROFILE_TYPE.EDUCATION, value: resp })),
    );
  };

  const onCreate = (data) => {
    return sendFormDataRequest({
      url: PROFILE_EDUCATION_URL,
      verb: 'POST',
      data: {
        ...data,
        ...(data.competencies && {
          competencies: data.competencies.map((competency) => ({ id: competency.id })),
        }),
      },
    }).then((resp) => {
      return dispatch(setProfileSection({ section: PROFILE_TYPE.EDUCATION, value: resp }));
    });
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};
