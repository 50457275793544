import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { useFormContext, Controller } from 'react-hook-form';
import { Uploader } from 'common/components/Uploader';
import { Button } from 'common/components/Button';
import { SimpleIcon } from 'common/components/SimpleIcon';
import { ValidationMessage } from '../../../ValidationMessage';
import { FormattedMessage } from 'react-intl';
import { getFilenameFromPath } from 'common/utils';
import './FileUpload.scss';

/**
 * InnerFileUpload that gets enhanced by RHF's HOC
 * @param id
 * @param name
 * @param iconName
 * @param label
 * @param uploadedLabel
 * @param triggerLabel
 * @param onChange
 * @param errors
 * @param formState
 * @param value
 * @param type // 'main' || 'thumbnail' || 'image'
 * @returns {*}
 * @constructor
 */
export const InnerFileUpload = ({
  id,
  name,
  iconName,
  label,
  uploadedLabel,
  triggerLabel,
  onChange,
  errors,
  formState,
  value,
  type,
  exposeFormContext,
  triggerExternalValidation,
  fileTypes,
}) => {
  const [file, setFile] = useState(null);
  const touched = formState.touched[name];
  const dirty = formState.dirty;
  const hasError = errors[name];
  const isSubmitted = formState.isSubmitted;
  const showError = () => {
    if (exposeFormContext && !dirty) return false;
    return (touched || isSubmitted || triggerExternalValidation) && hasError;
  };

  const handleUpload = (data) => {
    if (data && data.length) {
      data[0].preview = URL.createObjectURL(data[0]);
      setFile(data[0]);
      onChange(data[0]);
    }
  };

  const handleDelete = (data) => {
    setFile(null);
    onChange(null);
  };

  useEffect(() => {
    if (value) {
      if (typeof value === 'object') {
        // from user input
        setFile(value);
      } else {
        // from API
        setFile({
          name: value,
          preview: value,
        });
      }
    } else {
      setFile(null);
    }
  }, [value]);

  const renderControlsTemplate = (handleDelete, handleUpload, fileTypes) => (
    <div>
      <div className="iq4-file-upload__control">
        <Button className="iq4-file-upload__control-button" onClick={handleDelete}>
          Delete
        </Button>
      </div>
      <div className="iq4-file-upload__control">
        <Uploader handleUpload={handleUpload} fileTypes={fileTypes}>
          <Button className="iq4-file-upload__control-button">Upload new</Button>
        </Uploader>
      </div>
    </div>
  );

  const renderMainDetailsTemplate = (file, iconName) => (
    <div className="iq4-file-upload__details">
      <div className="iq4-file-upload__details-filename">
        <SimpleIcon className="iq4-file-upload__details-icon" name={iconName} role="presentation" />
        {getFilenameFromPath(file.name)}
      </div>
      {renderControlsTemplate(handleDelete, handleUpload, fileTypes)}
    </div>
  );

  const renderThumbnailDetailsTemplate = (file) => (
    <div className="iq4-file-upload__details">
      <span className="iq4-file-upload__details-thumbnail">
        <img src={file.preview} alt="thumbnail" />
      </span>
      {renderControlsTemplate(handleDelete, handleUpload, fileTypes)}
    </div>
  );

  const renderImageDetailsTemplate = (file) => (
    <div className="iq4-file-upload__details">
      <div className="iq4-file-upload__details-filename">
        <span className="iq4-file-upload__details-filename-label">Uploaded:</span>{' '}
        {getFilenameFromPath(file.name)}
      </div>
      {renderControlsTemplate(handleDelete, handleUpload, fileTypes)}
    </div>
  );

  const renderImagePreviewTemplate = (file) => (
    <div className="iq4-image-upload__preview">
      <img className="iq4-image-upload__preview-image" src={file.preview} alt="preview" />
    </div>
  );

  const DETAILS_TEMPLATES = {
    main: renderMainDetailsTemplate,
    thumbnail: renderThumbnailDetailsTemplate,
    image: renderImageDetailsTemplate,
  };

  return (
    <div className={`iq4-file-upload__wrapper ${type ? `iq4-file-upload__wrapper--${type}` : ''}`}>
      {label && (
        <label className="iq4-file-upload__label" htmlFor={name}>
          {file ? uploadedLabel : label}
        </label>
      )}
      <FormControl
        variant="outlined"
        className={`iq4-file-upload__form-control ${
          showError() ? 'iq4-file-upload__form-control--error' : ''
        }`}
      >
        {file && (
          <>
            {DETAILS_TEMPLATES[type](file, iconName)}
            {type === 'image' && renderImagePreviewTemplate(file)}
          </>
        )}

        {!file && (
          <Uploader handleUpload={handleUpload} fileTypes={fileTypes}>
            <Button variation="ghost">{triggerLabel}</Button>
          </Uploader>
        )}
      </FormControl>
      {hasError && showError() && (
        <ValidationMessage
          id={`${name}Error`}
          message={<FormattedMessage id="form.validation.required" />}
        />
      )}
    </div>
  );
};

/**
 * FileUpload wrapper
 * This needs to use the Controller component from react-hook-form
 * see: https://react-hook-form.com/api#Controller
 * @param name
 * @param validators
 * @param type // 'main' || 'thumbnail'
 * @param props
 * @returns {*}
 * @constructor
 */
export const FileUpload = ({ name, validators, ...props }) => {
  const { control, getValues } = useFormContext();
  const values = getValues();

  return (
    <Controller
      as={<InnerFileUpload {...props} />}
      control={control}
      rules={{ ...validators }}
      onChange={([selected]) => selected}
      name={name}
      value={values[name]}
    />
  );
};
