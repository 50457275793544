import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer,
  SimpleIcon,
  RoleIndividualSkillInfoPanel,
  LoadingSkeleton,
} from 'common/components';
import { fetchTShapeSkillInfo, fetchTShapeGraphicData } from 'common/store/features/t/slice';
import { selectTShapeData, selectTShapeSelectedSkill } from 'common/store/features/t/selectors';
import { TShapeV2 } from 'common/components';
import { selectProfile } from 'common/store/features/profile/selectors';
import { getMySkillsTShapeData } from 'common/utils';
import { selectMySkills } from 'common/store/features/skills/selectors';
import {
  addSkillFromWorkRoleReq,
  removeSkillFromWorkRoleReq,
} from 'common/store/features/skills/skillsSlice';
import './TShapePage.scss';

export const TShapePage = ({
  onClose,
  title = 'My Skills T',
  renderOnlySelfSkills = false,
  selectedRole,
}) => {
  const dispatch = useDispatch();
  const tShapeGraphicData = useSelector(selectTShapeData);
  const tShapeSelectedSkill = useSelector(selectTShapeSelectedSkill);
  const { personal } = useSelector(selectProfile);
  const mySkills = useSelector(selectMySkills);
  const [selectedTShapeRole, setSelectedTShapeRole] = useState('');

  console.log('tShapeGraphicData', tShapeGraphicData);

  // get tshape data for the selected role when the role changes
  useEffect(() => {
    if (selectedTShapeRole && !selectedRole) {
      dispatch(fetchTShapeGraphicData({ roleId: selectedTShapeRole.id }));
    }
  }, [selectedTShapeRole]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddSkill = async (skill) => {
    await dispatch(addSkillFromWorkRoleReq({ skill, roleId: selectedRole?.id }));
    dispatch(fetchTShapeGraphicData({ roleId: selectedRole?.id }));
  };

  const handleRemoveSkill = async (skill) => {
    await dispatch(removeSkillFromWorkRoleReq({ id: skill.id, roleId: selectedRole?.id }));
    dispatch(fetchTShapeGraphicData({ roleId: selectedRole?.id }));
  };

  return (
    <div className="iq4-tshape-page">
      <Drawer
        variant="persistent"
        isOpen={tShapeSelectedSkill}
        classes={{
          root: 'iq4-tshape-page__drawer',
          paper: 'iq4-tshape-page__drawer-paper',
        }}
      >
        <div style={{ paddingTop: '40px' }}>
          <button
            aria-label="Close drawer"
            className="iq4-tshape-page__drawer__dismiss-button"
            onClick={() => void dispatch(fetchTShapeSkillInfo({ id: null }))}
          >
            <SimpleIcon name="close" />
          </button>
          {tShapeSelectedSkill && (
            <RoleIndividualSkillInfoPanel
              achievements={tShapeSelectedSkill.achievements.filter(
                (achievement) => achievement?.school === personal?.school?.name,
              )}
              skill={tShapeSelectedSkill}
              onBackClick={null}
              onAdd={handleAddSkill}
              onRemove={handleRemoveSkill}
              onCourseClick={() => {}}
            />
          )}
        </div>
      </Drawer>

      <div className="iq4-tshape-page__container">
        <div
          className={`iq4-tshape-page__t-container ${
            tShapeSelectedSkill ? 'iq4-tshape-page__t-container--open-drawer' : ''
          }`}
        >
          <div className="iq4-tshape-page__heading-back-btn-container">
            <button className="iq4-tshape-page__heading-back-btn" onClick={onClose}>
              Close <SimpleIcon name="close" />
            </button>
          </div>
          {(selectedTShapeRole || selectedRole) && !renderOnlySelfSkills && (
            <div className="iq4-profile-skills__container-middle iq4-profile-skills__title-parent--item">
              <div>explore role</div>
              <div className="iq4-profile-skills__title-parent--item-label">
                <div className="iq4-profile-skills__title-parent--change-label">
                  {selectedRole?.label || selectedTShapeRole?.label}
                </div>
              </div>
            </div>
          )}
          <h2 className="iq4-tshape-page__t-tile">{title}</h2>
          {!tShapeGraphicData && !renderOnlySelfSkills && (
            <div style={{ marginTop: '60px' }}>
              <LoadingSkeleton variant="rect" style={{ width: '100%', height: '200px' }} />
              <LoadingSkeleton
                variant="rect"
                style={{ width: '100%', height: '30px', marginTop: '4px', marginBottom: '0' }}
              />
              <div
                style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}
              >
                <LoadingSkeleton
                  variant="rect"
                  style={{ flex: '1', height: '356px', marginTop: '4px' }}
                />
                <LoadingSkeleton
                  variant="rect"
                  style={{
                    width: '30px',
                    height: '360px',
                    marginLeft: '4px',
                    marginRight: '4px',
                  }}
                />
                <LoadingSkeleton
                  variant="rect"
                  style={{ flex: '1', height: '356px', marginTop: '4px' }}
                />
              </div>
            </div>
          )}

          {((tShapeGraphicData && !renderOnlySelfSkills) || renderOnlySelfSkills) && (
            <TShapeV2
              renderOnlySelfSkills={renderOnlySelfSkills}
              {...(!renderOnlySelfSkills
                ? { ...tShapeGraphicData }
                : { ...getMySkillsTShapeData(mySkills) })}
              sectionLabels={{
                top: 'Professional Skills',
                bottomLeft: 'IT Skills',
                bottomRight: 'Soft Skills',
              }}
              onClick={(skill) => {
                dispatch(fetchTShapeSkillInfo({ id: skill.id }));
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
