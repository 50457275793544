import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Toggle } from '../Toggle/Toggle';
import Button from '../Button/Button';
import Select from '../Select/Select';
import { Pill } from '../Pill/Pill';
import { Dots, Info } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import AssessmentGuidlines from '../InfoTemplates/AssessmentGuidlines';
import ContextGuidLines from '../InfoTemplates/ContextGuidlines';
import { selectSkillsMetaData } from 'common/store/features/skillsModuleState/skills/selectors';
import useSkillsCardBodyReducer, { ACTIONS, ASSESSMENT_TYPES } from './useSkillsCardBodyReducer';
import { useSelector } from 'react-redux';

const SkillsCardBody = ({
  assessmentWriteType = 'SELF',
  t1Id,
  children,
  className = '',
  ...props
}) => {
  const [state, dispatch] = useSkillsCardBodyReducer();
  const moduleConfigs = useSelector((state) => state.skillsModule.moduleConfigs);
  const [activePriorityFilter, setActivePriorityFilter] = useState(
    moduleConfigs.defaultHighSkillsPriority,
  );
  const skillsMetaData = useSelector(selectSkillsMetaData);

  useEffect(() => {
    if (assessmentWriteType !== 'SELF') {
      void dispatch({
        type: ACTIONS.CHANGE_ASSESSMENT_TYPE,
        payload: ASSESSMENT_TYPES.MANAGER,
      });
    }
  }, []);

  return (
    <div className={`iq4-skills-card-body ${className}`} {...props}>
      {false && (
        <div className="iq4-skills-card-body-header">
          <div className="iq4-skills-card-body-row" style={{ backgroundColor: '#f7f8f7' }}>
            <div className="iq4-skills-card-body-col iq4-skills-card-body-col-skills iq4-skills-card-body__title">
              <Dots />
              <span
                className="iq4-skills-card-body-col-skills-title"
                style={{ color: 'rgb(68, 68, 68)' }}
              >
                Role Skills
              </span>

              <div className="iq4-skills-card-body__priority-skills-counts">
                <span data-tip data-for="iq4-skills-card-body__priority-skills-counts-high">
                  <Pill type="high">{skillsMetaData[t1Id].role.highPrioritySkillsCount}</Pill>
                  <ReactTooltip
                    id="iq4-skills-card-body__priority-skills-counts-high"
                    effect="solid"
                  >
                    {skillsMetaData[t1Id].role.highPrioritySkillsCount} High Priority Skills
                    (Required)
                  </ReactTooltip>
                </span>

                <span data-tip data-for="iq4-skills-card-body__priority-skills-counts-medium">
                  <Pill type="medium">{skillsMetaData[t1Id].role.mediumPrioritySkillsCount}</Pill>
                  <ReactTooltip
                    id="iq4-skills-card-body__priority-skills-counts-medium"
                    effect="solid"
                  >
                    {skillsMetaData[t1Id].role.mediumPrioritySkillsCount} Medium Priority Skills
                    (Optional)
                  </ReactTooltip>
                </span>

                <span data-tip data-for="iq4-skills-card-body__priority-skills-counts-low">
                  <Pill type="low">{skillsMetaData[t1Id].role.lowPrioritySkillsCount}</Pill>
                  <ReactTooltip
                    id="iq4-skills-card-body__priority-skills-counts-low"
                    effect="solid"
                  >
                    {skillsMetaData[t1Id].role.lowPrioritySkillsCount} Low Priority Skills
                    (Optional)
                  </ReactTooltip>
                </span>
              </div>

              <Button
                className="iq4-skills-card-expand-btn"
                aria-label="Expand all KSA's"
                aria-expanded={state.expandAll ? 'true' : 'false'}
                type="link"
                onClick={() => void dispatch({ type: ACTIONS.TOGGLE, key: 'expandAll' })}
              >
                {state.expandAll ? 'close' : 'expand'} all
              </Button>
            </div>

            <div className="iq4-skills-card-body-col iq4-skills-card-body-col-skill-priority">
              {/* <Toggle
              leftLabel="All priority"
              rightLabel="HIGH priority"
              active={activePriorityFilter}
              onToggle={setActivePriorityFilter}
            /> */}
            </div>

            {moduleConfigs.includeTools && (
              <div className="iq4-skills-card-body-col iq4-skills-card-body-col-tools">
                <Button
                  aria-label="Show tools guidlines"
                  type="link"
                  data-testid="skills-card-body-tools"
                  onClick={() => void dispatch({ type: ACTIONS.TOGGLE, key: 'tools' })}
                >
                  <Info />
                </Button>
                <span style={{ color: 'rgb(68, 68, 68)' }}>Tools</span>
              </div>
            )}

            <div className="iq4-skills-card-body-col iq4-skills-card-body-col-assessments">
              {/* <Select
              aria-label="Select the assessment type to display."
              value={state.assessmentType}
              data-testid="skills-card-body-assessments"
              onChange={(e) =>
                void dispatch({
                  type: ACTIONS.CHANGE_ASSESSMENT_TYPE,
                  payload: e.target.value,
                })
              }
            >
              <option value={ASSESSMENT_TYPES.SELF}>Self Assessment</option>
              {moduleConfigs.includeValidatedCredentials && (
                <option value={ASSESSMENT_TYPES.TESTED}>Tested Assessment</option>
              )}

              {moduleConfigs.includeOtherCredentials && (
                <option value={ASSESSMENT_TYPES.CERTIFIED}>Certified Assessment</option>
              )}

              {moduleConfigs.includeManagerAssessments && (
                <option value={ASSESSMENT_TYPES.MANAGER}>Manager Assessment</option>
              )}

              {(moduleConfigs.includeOtherCredentials ||
                moduleConfigs.includeValidatedCredentials) && (
                <option value={ASSESSMENT_TYPES.AVERAGE}>Average Assessment</option>
              )}
            </Select> */}
            </div>

            {state.tools && (
              <Modal
                isOpen={state.tools}
                contentLabel={`Tools Guidlines`}
                onRequestClose={() => void dispatch({ type: ACTIONS.TOGGLE, key: 'tools' })}
                handleClose={() => void dispatch({ type: ACTIONS.TOGGLE, key: 'tools' })}
                renderHeader={() => <h3>Tools Guidelines</h3>}
              >
                {() => <ContextGuidLines />}
              </Modal>
            )}

            {moduleConfigs.includeRecommendedCourses && (
              <div className="iq4-skills-card-body-col iq4-skills-card-body-col-recommended-courses">
                <h2>RECOMMENDED COURSES</h2>
              </div>
            )}
          </div>

          <div className="iq4-skills-card-body-row">
            <div
              className="iq4-skills-card-body-col skill-guildlines-header"
              style={{ borderRight: 0 }}
            >
              <Button
                type="link"
                onClick={() => void dispatch({ type: ACTIONS.TOGGLE, key: 'guidlines' })}
              >
                <Info />
                Proficiency assessment guidlines
              </Button>
            </div>
          </div>

          {state.guidlines && (
            <Modal
              isOpen={state.guidlines}
              contentLabel={`Proficiency Guidlines`}
              onRequestClose={() => void dispatch({ type: ACTIONS.TOGGLE, key: 'guidlines' })}
              renderHeader={() => <h3>Assessment Guidelines</h3>}
              handleClose={() => void dispatch({ type: ACTIONS.TOGGLE, key: 'guidlines' })}
            >
              {() => <AssessmentGuidlines />}
            </Modal>
          )}
        </div>
      )}

      <div className="iq4-skills-card-body-container">
        <div className="iq4-skills-card-body-table">
          {children({
            ...state,
            activePriorityFilter,
          })}
        </div>
        {/* 
          <div
          className="iq4-skills-card-body-row"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h1>Graph</h1>
        </div>
          */}
      </div>
    </div>
  );
};

SkillsCardBody.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SkillsCardBody;
