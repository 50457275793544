import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { debounce } from 'lodash';
import { TShapePage } from '../TShapePage';
import {
  Container,
  Drawer,
  RecommendedRolesPanel,
  RecommendedRolesEmptyPanel,
  FixedTooltip,
  Footer,
  InnerTabs,
  CountIndicator,
  SimpleIcon,
  Button,
} from 'common/components';
import { BrowseRolesInput, PathwayDetail, RolesSearchBar } from 'common/components/Pathways';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { PathwaysGraph, PathwayDonut } from 'common/components/PathwaysGraph';
import {
  selectRecommendedWorkRoles,
  selectSelectedPathwaysSpokes,
} from 'common/store/features/pathways/selectors';
import { selectJobFamilies, selectWorkRole } from 'common/store/features/framework/selectors';
import { selectIsPublicProfile, selectProfile } from 'common/store/features/profile/selectors';
import { fetchTShapeGraphicData } from 'common/store/features/t/slice';
import { selectTShapeData } from 'common/store/features/t/selectors';
import {
  fetchWorkrolesByJobFamilyReq,
  fetchRecommendedRolesReq,
  fetchUserSelectedWorkRolesReq,
  addPathwayReq,
  removePathwayReq,
  removeAddedSuggestion,
} from 'common/store/features/pathways/pathwaysSlice';
import {
  fetchJobFamiliesReq,
  fetchWorkRoleReq,
  setIsFetchingDemographicData,
} from 'common/store/features/framework/frameworkSlice';
import { ROUTES } from 'routes/routeNames';

import { fetchAllSkillsReq } from 'common/store/features/skills/skillsSlice';
import { fetchProfileReq } from 'common/store/features/profile/profileSlice';
import { selectFeatures } from 'common/store/features/config/selectors';
import PathwayGrid from 'common/assets/svg/pathway-grid.svg';
import { useScrollToTop } from 'common/hooks/useScrollToTop';
import { useRolesSearch } from 'common/hooks/useRolesSearch';
import { selectSkills } from '../../common/store/features/skills/selectors';
import { SelectWorkRoles } from 'common/components/SelectWorkRoles';
import './CareerPathwaysPage.scss';
import { selectPublicPathways, selectUserProfile } from '../../store/features/search/selectors';
import { selectCurrentUser } from '../../store/features/dashboard/selectors';

export const CareerPathwaysPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedWorkRoleId, setSelectedWorkRoleId] = useState(null);
  const [showTooltips, setShowTooltips] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSpecialtyArea, setSelectedSpecialtyArea] = useState(null);
  const [selectedJobFamily, setSelectedJobFamily] = useState(null);
  const [showResumeParser, setShowResumeParser] = useState(false);

  const roleResults = useRolesSearch(searchTerm);

  const [isConsentDisplayed, setIsConsentDisplayed] = useState(false);
  const [activeTShapeView, setActiveTShapeView] = useState(false);
  const footerContainerRef = useRef(null);

  const isPublicProfile = true;
  const { id } = useParams();

  const {
    applicationTitle,
    personalProfileSource,
    displayBrowseRoles,
    displaySearchRoles,
    consentForPlanningEnabled,
  } = useSelector(selectFeatures);

  const recommendedWorkRoles = useSelector(selectRecommendedWorkRoles);
  const searchRoles = [];
  const jobFamilies = useSelector(selectJobFamilies);

  const selectedPathwaysSpokes = useSelector(selectPublicPathways);
  const { personal } = useSelector(selectUserProfile);
  const tShapeGraphicData = useSelector(selectTShapeData);
  const { skills } = useSelector(selectSkills);
  const dashboardUser = useSelector(selectCurrentUser);
  const enableDemographicData = true;

  const selectedWorkRoleDetails = useSelector(
    selectWorkRole({ id: selectedWorkRoleId, isPublicProfile }),
  );

  const [pathwaysBackgroundOffset, setPathwaysBackgroundOffset] = useState({ x: null, y: null });

  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));

  const [currentPanelState, setCurrentPanelState] = useState(null);
  const scrollableNodeRef = useRef(null);

  useScrollToTop(scrollableNodeRef, [currentPanelState]);

  useEffect(() => {
    const listener = () => {
      const headerNode = document.querySelector('.iq4-topbar__container');
      const consentNode = document.querySelector('.iq4-consent-banner');
      const drawerContainerNode = document.querySelector('.iq4-career-pathways__drawer-paper');

      if (headerNode && drawerContainerNode) {
        drawerContainerNode.style.setProperty(
          'top',
          `${headerNode.offsetHeight + (consentNode ? consentNode.offsetHeight : 0)}px`,
          'important',
        );

        drawerContainerNode.style.setProperty(
          'height',
          `calc(100% - ${
            headerNode.offsetHeight + (consentNode ? consentNode.offsetHeight : 0)
          }px)`,
          'important',
        );
      }
    };

    window.addEventListener('resize', listener);

    // init
    listener();

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [breakpoint, activeTShapeView, isConsentDisplayed]);

  useEffect(() => {
    dispatch(fetchUserSelectedWorkRolesReq());
  }, []);

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);

  // load work role data after framework data has loaded.
  useEffect(() => {
    if (selectedWorkRoleId) {
      if (enableDemographicData && !selectedWorkRoleDetails) {
        dispatch(setIsFetchingDemographicData(true));
      }

      if (
        !selectedWorkRoleDetails ||
        (selectedWorkRoleDetails && !selectedWorkRoleDetails.demographics && enableDemographicData)
      ) {
        dispatch(
          fetchWorkRoleReq({
            roleId: selectedWorkRoleId,
            fetchDemographicData: enableDemographicData,
            isPublicProfile,
            id: personal?.userProfile?.user?.userId,
          }),
        );
      }

      setIsDrawerOpen(true);
    }
  }, [selectedWorkRoleId]);

  useEffect(() => {
    if (isPublicProfile) {
      const roleId = selectedWorkRoleId;

      if (roleId) {
        if (enableDemographicData && !selectedWorkRoleDetails) {
          dispatch(setIsFetchingDemographicData(true));
        }

        if (
          !selectedWorkRoleDetails ||
          (selectedWorkRoleDetails &&
            !selectedWorkRoleDetails.demographics &&
            enableDemographicData)
        ) {
          dispatch(
            fetchWorkRoleReq({
              roleId,
              fetchDemographicData: enableDemographicData,
              isPublicProfile,
              id,
            }),
          );
        }

        setIsDrawerOpen(true);
      }
    }
  }, [isPublicProfile]);

  // set the tshape data based on the currently selected role
  useEffect(() => {
    if (selectedWorkRoleDetails) {
      dispatch(
        fetchTShapeGraphicData({
          roleId: selectedWorkRoleDetails.id,
          isPublicProfile,
          id: personal?.userProfile?.user?.userId,
          dashboardUserId: dashboardUser?.id,
        }),
      );
    }
  }, [selectedWorkRoleDetails?.id]);

  useEffect(() => {
    personalProfileSource && dispatch(fetchProfileReq(personalProfileSource));
  }, [personalProfileSource]);

  useEffect(() => {
    dispatch(fetchRecommendedRolesReq());
    dispatch(fetchAllSkillsReq());

    if (!jobFamilies.length) {
      dispatch(fetchJobFamiliesReq());
    }
  }, []);

  const reset = () => {
    setSelectedCategory(null);
    setSelectedSpecialtyArea(null);
    setSelectedWorkRoleId(null);
    setSelectedJobFamily(null);
    toggleWorkRolePanel();
  };

  const handleClose = () => {
    reset();
    setIsDrawerOpen(false);
  };

  const handleSelectWorkRole = (workRole) => {
    toggleWorkRolePanel(workRole.id);
    setSelectedWorkRoleId(workRole.id);
  };

  const handleViewRolesDetails = (role) => {
    if (role) {
      toggleWorkRolePanel(role.id);
    }
  };

  const handleSelectJobFamily = (value) => {
    dispatch(fetchWorkrolesByJobFamilyReq(value.id));
    setSelectedJobFamily(value);
    setIsDrawerOpen(true);
  };

  const handlePathwaysGraphRemove = (role) => {
    dispatch(removePathwayReq(role));
    dispatch(removeAddedSuggestion(role));
  };

  const handleCancelWorkRole = () => {
    toggleWorkRolePanel();
  };

  const handleRecommendedWorkRoleDetails = (workRole) => {
    toggleWorkRolePanel(workRole.id);
  };

  const handleAddRoleToPathway = (role) => {
    dispatch(addPathwayReq(role));
  };

  useEffect(() => {
    const handler = (e) => {
      setShowTooltips(false);
    };

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    const handleResize = debounce(function () {
      const pathwaysRootNode = document.getElementsByClassName('iq4-pathway__manual-hexagon')[0];
      if (!pathwaysRootNode) return;
      const pathwaysRootNodePositionData = pathwaysRootNode.getBoundingClientRect();
      const { x, y } = pathwaysRootNodePositionData;
      const svgPatternWidth = 110;
      const svgPatternHeight = 64;
      const svgPatternOffsetHeight = 21;
      const xBackgroundOffset = x % svgPatternWidth;
      const yBackgroundOffset = (y % svgPatternHeight) - svgPatternOffsetHeight;

      setPathwaysBackgroundOffset({ x: xBackgroundOffset, y: yBackgroundOffset });
    }, 24);

    window.addEventListener('resize', handleResize);

    if (pathwaysBackgroundOffset.x === null) {
      handleResize();
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function toggleWorkRolePanel(roleId) {
    if (!isPublicProfile) {
      history.push(`${ROUTES.CAREER_PATHWAYS}${roleId ? `?r=${roleId}` : ''}`);
    } else {
      setSelectedWorkRoleId(roleId);
    }
  }

  function getIsMobile(breakpoint) {
    return breakpoint === 'xs' || breakpoint === 'sm';
  }

  const showFooter = () => {
    return !isDrawerOpen && !isPublicProfile;
  };

  function getCareerPathwaysContent() {
    return (
      <>
        {!selectedPathwaysSpokes.length && (
          <p>
            You have not yet added any Career Pathways. Search for a role above or view 'Suggested
            Roles'
          </p>
        )}
        {selectedPathwaysSpokes.map((spoke, index) => {
          return (
            <div className="iq4-career-pathways__page__mobile-donut">
              <PathwayDonut
                key={index}
                readOnly={false}
                spoke={spoke}
                onDelete={() => {
                  dispatch(removeAddedSuggestion(spoke));
                  handlePathwaysGraphRemove(spoke);
                }}
                onSelect={() => {
                  handleViewRolesDetails(spoke);
                }}
              />
            </div>
          );
        })}
      </>
    );
  }

  function displayMobileLayout() {
    if (recommendedWorkRoles.length) {
      return (
        <InnerTabs
          id="iq4-career-pathways__page__mobile-tabs"
          className="iq4-career-pathways__page__mobile-tabs"
          tabsProps={{ variant: 'fullWidth' }}
          data={[
            {
              title: 'Career Pathways',
              content: getCareerPathwaysContent(),
            },
          ]}
        />
      );
    }

    return (
      <div className="iq4-career-pathways__page__mobile-tabs">{getCareerPathwaysContent()}</div>
    );
  }

  const recommendedWorkRolesToUse =
    selectedPathwaysSpokes && selectedPathwaysSpokes.length
      ? recommendedWorkRoles.filter(
          (wr) => !selectedPathwaysSpokes.map((x) => x.id).includes(wr.id),
        )
      : recommendedWorkRoles;

  const shouldDisplayRecommendedRolesPanel = () => {
    if (!skills?.length) {
      return false;
    }

    return !!recommendedWorkRoles.length;
  };

  const closeSelectWorkRoles = () => {
    setIsDrawerOpen(false);
    setSelectedJobFamily(null);
  };

  return (
    <>
      <main
        className={`iq4-career-pathways__page ${
          isConsentDisplayed ? `iq4-career-pathways__page__with-consent-banner` : ''
        }`}
        id="main"
      >
        {activeTShapeView && (
          <TShapePage
            selectedRole={selectedWorkRoleDetails}
            renderOnlySelfSkills={!selectedWorkRoleDetails}
            onClose={() => void setActiveTShapeView(false)}
          />
        )}
        {!activeTShapeView && (
          <>
            {' '}
            <Container
              className={`iq4-career-pathways__container ${
                isConsentDisplayed ? `iq4-career-pathways__container--with-consent` : ''
              } ${isPublicProfile ? 'iq4-career-pathways__container--public-profile' : ''}`}
            >
              <Grid container alignItems="flex-start">
                {!isPublicProfile && (
                  <Grid
                    item
                    classes={{
                      root: `iq4-career-pathways__left`,
                    }}
                  >
                    <h1 className="iq4-career-pathways__title">Career Pathways</h1>

                    <div
                      className={`iq4-career-pathways__left-container ${
                        isDrawerOpen ? 'iq4-career-pathways__left-container--display-none' : ''
                      }`}
                    >
                      <>
                        {displaySearchRoles && (
                          <>
                            <RolesSearchBar
                              results={roleResults}
                              onChange={(searchTerm) => {
                                setSearchTerm(searchTerm);
                              }}
                              onSkillClick={(role) => {
                                handleViewRolesDetails(role);
                              }}
                            />
                          </>
                        )}
                        {showTooltips && (
                          <FixedTooltip className="iq4-career-pathways__tooltip-search">
                            <strong>Search</strong> or <strong>Browse</strong> categories for roles
                          </FixedTooltip>
                        )}
                        {displayBrowseRoles && (
                          <BrowseRolesInput
                            roles={jobFamilies}
                            selectedJobFamily={selectedJobFamily}
                            onSelect={handleSelectJobFamily}
                          />
                        )}
                        {isMobile && displayMobileLayout()}
                      </>
                    </div>
                  </Grid>
                )}
                <Grid item classes={{ root: 'iq4-career-pathways__main' }}>
                  {!isMobile && selectedPathwaysSpokes && personal && (
                    <div className="iq4-career-pathways__graph">
                      <PathwaysGraph
                        onSuggestingState={selectedPathwaysSpokes.length === 0}
                        personal={personal}
                        spokes={selectedPathwaysSpokes}
                        onSelectPath={(role) => handleViewRolesDetails(role)}
                        onAddPath={(role) => handleAddRoleToPathway(role)}
                        readOnly={isPublicProfile}
                        onRemovePath={(role) => handlePathwaysGraphRemove(role)}
                        suggestions={
                          shouldDisplayRecommendedRolesPanel ? recommendedWorkRolesToUse : []
                        }
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Container>
            <Drawer
              variant="persistent"
              isOpen={isDrawerOpen}
              classes={{
                root: 'iq4-career-pathways__drawer',
                paper: 'iq4-career-pathways__drawer-paper',
              }}
            >
              <div className="iq4-career-pathways__drawer-content">
                {!selectedWorkRoleDetails && selectedJobFamily && (
                  <SelectWorkRoles
                    jobFamily={selectedJobFamily}
                    onSelectWorkRole={handleSelectWorkRole}
                    onClose={closeSelectWorkRoles}
                  />
                )}
                {!selectedWorkRoleDetails && selectedPathwaysSpokes && (
                  <div className="iq4-career-pathways__drawer__pathway-detail">
                    <div className="iq4-pathway-detail">
                      <div className="iq4-pathway-detail__header">
                        <button className="iq4-pathway-detail__header-title iq4-pathway-detail__header-title--small-font">
                          <SimpleIcon name="arrow" /> Back to Career Pathways
                        </button>

                        <h1 className="iq4-pathway-detail__header-name">
                          <Skeleton
                            variant="rect"
                            style={{ width: '220px', height: '20px', marginBottom: '4px' }}
                          />
                        </h1>
                        {!isPublicProfile && (
                          <Button
                            variation="ghost"
                            className="iq4-pathway-detail__header__add-to-pathways"
                          >
                            <SimpleIcon
                              className="iq4-pathway-detail__header__add-to-pathways__icon"
                              name="add"
                              role="presentation"
                            />
                            Loading...
                          </Button>
                        )}
                      </div>
                      <div
                        className="iq4-pathway-detail__selection-container"
                        style={{ marginTop: '30px' }}
                      >
                        {!isPublicProfile && (
                          <>
                            <div className="iq4-pathway-detail__selection-container--row">
                              <div className="iq4-pathway-detail__selection-container--row-item-1">
                                JOB FAMILY
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-2">
                                <Skeleton
                                  variant="rect"
                                  style={{ width: '200px', height: '10px' }}
                                />
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-3">
                                Change
                              </div>
                            </div>
                            <div className="iq4-pathway-detail__selection-container--row">
                              <div className="iq4-pathway-detail__selection-container--row-item-1">
                                ROLE
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-2">
                                <Skeleton
                                  variant="rect"
                                  style={{ width: '140px', height: '10px' }}
                                />
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-3">
                                Change
                              </div>
                            </div>
                            <div className="iq4-pathway-detail__selection-container--row">
                              <div className="iq4-pathway-detail__selection-container--row-item-1">
                                LOCATION
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-2">
                                <Skeleton
                                  variant="rect"
                                  style={{ width: '220px', height: '10px' }}
                                />
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-3">
                                Change
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {selectedWorkRoleDetails && (
                  <div
                    ref={scrollableNodeRef}
                    className="iq4-career-pathways__drawer__pathway-detail"
                  >
                    <PathwayDetail
                      onPanelStateChange={(newPanelState) => {
                        setCurrentPanelState(newPanelState);
                      }}
                      workRoles={searchRoles}
                      selectedWorkroles={selectedPathwaysSpokes}
                      onTShapeClick={() => setActiveTShapeView(true)}
                      onWorkRoleChange={(e) => {
                        const roleId = e.target.label;
                        handleViewRolesDetails({ id: roleId });
                      }}
                      onBackClick={handleClose}
                      category={selectedCategory}
                      specialtyArea={selectedSpecialtyArea}
                      role={{
                        ...selectedWorkRoleDetails,
                        salary: true,
                        education: true,
                        tShape: tShapeGraphicData,
                      }}
                      onCancel={handleCancelWorkRole}
                      onViewRole={(selected) => handleViewRolesDetails(selected)}
                      filterByInstitution={personal?.school}
                    />
                  </div>
                )}
              </div>
            </Drawer>
          </>
        )}
        {showFooter() && (
          <div className="iq4-career-pathways__footer-container" ref={footerContainerRef}>
            <Footer />
          </div>
        )}
      </main>
    </>
  );
};
