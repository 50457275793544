import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import './DataTable.scss';

export const TableLoading = () => (
  <div className="iq4-datatable__loading-rows">
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
    <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={48} />
  </div>
);
