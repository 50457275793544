import React from 'react';
import './DashboardTableDataCell.scss';

export const DashboardTableDataCell = ({ content, onClick, className }) => (
  <span className={`iq4-dashboard-table-data-cell ${className ? className : ''}`}>
    {content === 0 && '-'}
    {content !== 0 && (
      <button onClick={onClick} className="iq4-dashboard-table-data-cell__button">
        {content}
      </button>
    )}
  </span>
);
