import {
  SET_RAW_SKILL,
  SET_SKILLS_SUMMARY,
  UPDATE_SKILL_PROFICIENCY,
  UPDATE_SKILL_PROFICIENCY_T4,
  UPDATE_SEARCH_RESULTS,
  UPDATE_BROWSE_CATEGORIES,
  UPDATE_BROWSE_KSAS,
  CLEAR_BROWSE_RESULTS,
  REMOVE_BROWSE_RESULT,
  UPDATE_SAVED_ADDITIONAL_SKILLS,
  REMOVE_ADDTIONAL_SKILLS,
  SET_GLOBAL_TIER1S,
  SET_BROWSE_BY_ROLE_LIST,
} from '../../actionTypes';

const intialState = {
  rawSkillsData: {}, // contains the raw skill data from server. { [ID]: {}, [ID]: {}}
  rawAdditionalSkillsData: {}, // users additional skills, same structure as rawSkillsData
  skills: {}, // v2 of skills api
  summary: [],
  searchResults: [],
  browseCategories: [],
  browseResults: {},
  browseSkillsByRoleList: [],
  tier1s: [], // holds all the tier1s in the taxonomy
  assessments: {
    t1: {},
    t2: {},
    t3: {},
    t4: {
      // holds tier4 assessments, where each property is in the format {t1ID}_{t2ID}_{t3ID}_{t4ID}
      self: {},
      tested: {},
      certified: {},
      manager: {},
    },
  },
};

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_RAW_SKILL:
      // derive all tier4 assessments
      let assessmentsMap = { self: {}, manager: {}, certified: {} };
      const skillsMap = action.payload.skills;
      for (let t1Key in skillsMap) {
        const skillsUnderT1 = skillsMap[t1Key];

        skillsUnderT1.forEach((skillData) => {
          const tier4s = skillData.skillTier3.skillTier4List;
          tier4s.forEach((t4) => {
            const selfAssessment = t4.assessments.find((a) => a.type === 'self');

            const mA = t4.assessments.find((a) => a.type === 'manager');

            const cA = t4.assessments.find((a) => a.type === 'certified');

            if (selfAssessment) {
              assessmentsMap.self[
                `${skillData.skillTier1.id}_${skillData.skillTier2.id}_${skillData.skillTier3.skillTier3.id}_${t4.skillTier4.id}`
              ] = selfAssessment.assessment;
            }

            if (mA) {
              assessmentsMap.manager[
                `${skillData.skillTier1.id}_${skillData.skillTier2.id}_${skillData.skillTier3.skillTier3.id}_${t4.skillTier4.id}`
              ] = mA.assessment;

              // add manager details
              assessmentsMap.manager[
                `${skillData.skillTier1.id}_${skillData.skillTier2.id}_${skillData.skillTier3.skillTier3.id}_${t4.skillTier4.id}_manager`
              ] = mA.manager;
            }

            if (cA) {
              assessmentsMap.certified[
                `${skillData.skillTier1.id}_${skillData.skillTier2.id}_${skillData.skillTier3.skillTier3.id}_${t4.skillTier4.id}`
              ] = cA.assessment;
            }
          });
        });
      }

      return {
        ...state,
        comtechLabsInfo: action.payload.comtechLabsInfo,
        skills: {
          ...state.skills,
          ...action.payload.skills,
        },
        assessments: {
          ...state.assessments,
          t4: {
            ...state.assessments.t4,
            self: {
              ...state.assessments.t4.self,
              ...assessmentsMap.self,
            },
            manager: {
              ...state.assessments.t4.manager,
              ...assessmentsMap.manager,
            },
            certified: {
              ...state.assessments.t4.certified,
              ...assessmentsMap.certified,
            },
          },
        },
      };
    case SET_SKILLS_SUMMARY:
      return { ...state, summary: action.payload };
    case UPDATE_SKILL_PROFICIENCY:
      const newAssessments = [];
      let skillIndex = null;
      state.skills[action.payload.skill.t1Id].forEach((s, i) => {
        if (
          s.skillTier2.id === action.payload.skill.t2Id &&
          s.skillTier3.skillTier3.id === action.payload.skill.t3Id
        ) {
          skillIndex = i;

          // found skill that needs updating
          const newAssessmentItem = {
            type: action.payload.type,
            assessment: action.payload.prof,
          };

          newAssessments.push(newAssessmentItem);

          s.skillTier3.assessments.forEach((a) => {
            if (a.type !== action.payload.type) {
              newAssessments.push(a);
            }
          });
        } // end if
      }); // end forEach

      let newState = {
        ...state,
        skills: {
          ...state.skills,
          [action.payload.skill.t1Id]: [].concat(
            state.skills[action.payload.skill.t1Id].slice(0, skillIndex),
            [
              {
                ...state.skills[action.payload.skill.t1Id][skillIndex],
                skillTier3: {
                  ...state.skills[action.payload.skill.t1Id][skillIndex].skillTier3,
                  assessments: newAssessments,
                },
              },
            ],
            state.skills[action.payload.skill.t1Id].slice(skillIndex + 1),
          ),
        },
      };

      return newState;
    case UPDATE_SKILL_PROFICIENCY_T4:
      const {
        skill: { t1Id, t2Id, t3Id, t4Id },
        assessment,
      } = action.payload;

      return {
        ...state,
        assessments: {
          ...state.assessments,
          t4: {
            ...state.assessments.t4,
            self: {
              ...state.assessments.t4.self,
              [`${t1Id}_${t2Id}_${t3Id}_${t4Id}`]: assessment,
            },
          },
        },
      };
    case UPDATE_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload };
    case UPDATE_BROWSE_CATEGORIES:
      return { ...state, browseCategories: action.payload };
    case UPDATE_BROWSE_KSAS:
      const { skillTier1, skillTier2, skillTier3 } = action.payload;
      const id = `${skillTier1.id}-${skillTier2.id}-${skillTier3.id}`;
      return {
        ...state,
        browseResults: {
          ...state.browseResults,
          [id]: action.payload,
        },
      };
    case CLEAR_BROWSE_RESULTS:
      return {
        ...state,
        browseResults: {},
      };

    case REMOVE_BROWSE_RESULT: {
      const { skillTier1, skillTier2, skillTier3 } = action.payload;
      const id = `${skillTier1.id}-${skillTier2.id}-${skillTier3.id}`;

      const { [id]: _, ...browseResults } = state.browseResults;

      return {
        ...state,
        browseResults,
      };
    }

    case REMOVE_ADDTIONAL_SKILLS: {
      let skillsMap = { ...state.skills };
      let newSummary;

      action.payload.forEach((skill) => {
        const { t1Id, t2Id, t3Id, t4Id } = skill;
        let currentIndex = null;
        let foundSkill = skillsMap[t1Id].find((skillConfig, index) => {
          if (skillConfig.skillTier2.id === t2Id && skillConfig.skillTier3.skillTier3.id === t3Id) {
            currentIndex = index;
            return true;
          }
          return false;
        });

        let t4Index = null;
        const tier4Skill = foundSkill.skillTier3.skillTier4List.find((t4, index) => {
          if (t4.skillTier4.id === t4Id) {
            t4Index = index;
            return true;
          }

          return false;
        });

        // remove tier4
        const newSkillTier4s = [].concat(
          foundSkill.skillTier3.skillTier4List.slice(0, t4Index),
          foundSkill.skillTier3.skillTier4List.slice(t4Index + 1),
        );

        foundSkill.skillTier3.skillTier4List = newSkillTier4s;

        if (!newSkillTier4s.length) {
          // remove whole tier3 from
          skillsMap[t1Id] = [].concat(
            skillsMap[t1Id].slice(0, currentIndex),
            skillsMap[t1Id].slice(currentIndex + 1),
          );
        } // end if

        if (!skillsMap[t1Id].length) {
          // remove whole tier3 from
          const { [t1Id]: _, ...rest } = skillsMap;
          skillsMap = rest;
          // update the summary to remove the card if there are no more skills assicated with the above t1ID
          newSummary = state.summary.filter((skillSummary) => t1Id !== skillSummary.skill_tier1_id);
        } // end if
      });

      return {
        ...state,
        summary: newSummary ? newSummary : state.summary,
        skills: {
          ...skillsMap,
        },
      };
    }

    case SET_GLOBAL_TIER1S:
      return {
        ...state,
        tier1s: action.payload,
      };
    case UPDATE_SAVED_ADDITIONAL_SKILLS:
      return state;
    case SET_BROWSE_BY_ROLE_LIST:
      return { ...state, browseSkillsByRoleList: action.payload };
    default:
      return state;
  }
};

export default reducer;
