import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === 'development') {
  // expose store as a global var in __DEV__
  window.reduxStore = store;
}

export default store;
