export const COURSE_LEVELS_AND_PROFICIENCIES = [
  {
    level: 'entry',
    proficiency: 1
  },
  {
    level: 'intermediate',
    proficiency: 2
  },
  {
    level: 'advanced',
    proficiency: 3
  },
  {
    level: 'expert',
    proficiency: 4
  }
];
