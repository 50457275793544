import React from 'react';
import { TShapeV2Graph } from './TShapeV2Graph';
import './TShapeV2.scss';

export const STACK_ITEM_TYPES = {
  FILLED: 'FILLED',
  LINES: 'LINES',
  FILLED_LINES: 'FILLED_LINES',
};

/**
 * T-Shape V2
 * @param {object[]} top - top quadrant data
 * @param {object[]} bottomRight - bottom right quadrant data
 * @param {object[]} bottomLeft - bottom left quadrant data
 * @param {object} [sectionLabels] - Each quadrant labels
 * @param {boolean} [displayOnly] - if set to true, it will show a non-interactive graph
 * @param {function} [onClick] - handle clicking on boxes
 */
export const TShapeV2 = ({
  top,
  bottomRight,
  bottomLeft,
  sectionLabels = {},
  displayOnly = false,
  onClick,
}) => {
  return (
    <div className="iq4-tshape-v2">
      <TShapeV2Graph
        top={top}
        bottomRight={bottomRight}
        bottomLeft={bottomLeft}
        sectionLabels={sectionLabels}
        displayOnly={displayOnly}
        onClick={onClick}
      />
    </div>
  );
};
