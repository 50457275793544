import React, { useRef, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import { SimpleIcon, Logout, Notifications } from 'common/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotifyInvites } from 'store/features/dashboard/selectors';
import { ROUTES } from 'routes';
import './MainNavbar.scss';
import { updateInviteStatus } from '../../../store/features/dashboard/dashboardSlice';

export const MainNavbar = ({ className, MainNavOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);

  const dispatch = useDispatch();

  const notifyInvites = useSelector(selectNotifyInvites);
  const notificationCounts = notifyInvites?.length;

  const anchorElCredentials = useRef(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationsButton = (e) => {
    setIsNotificationsOpen(true);
    setAnchorElNotifications(e.currentTarget);
  };

  const open = Boolean(anchorEl);

  function buildNotificationInvites() {
    return notifyInvites.map((invite) => {
      return {
        invite,
        message: `${invite.fullName} is now a connection`,
        actionLabel: 'View',
        actionRoute: `${ROUTES.USER_PROFILE}/${invite.studentId}`,
      };
    });
  }

  const notificationsCollection = [
    ...(!!notifyInvites?.length > 0 ? buildNotificationInvites() : []),
  ];

  function closeNotifications(notification) {
    dispatch(
      updateInviteStatus({
        type: 'counsellor',
        userId: notification.invite?.studentId,
        status: notification.invite?.status,
        notifyCounsellor: false,
      }),
    );
    setIsNotificationsOpen(false);
  }

  return (
    <AppBar
      position="static"
      classes={{
        root: `iq4-main-navbar ${className ? className : ''}`,
      }}
    >
      <Toolbar
        disableGutters
        classes={{
          root: 'iq4-main-navbar__toolbar',
        }}
      >
        <div className="iq4-main-navbar__toolbar--content-container">
          <div className="iq4-main-navbar__left-side">
            <MainNavOptions />
          </div>

          <div className="iq4-main-navbar__right-side">
            <div className="iq4-topbar__links-actions-item">
              <IconButton
                aria-label="Notifications"
                color="inherit"
                ref={anchorElCredentials}
                onClick={(e) => handleNotificationsButton(e)}
              >
                {notificationCounts && (
                  <Badge max={999} badgeContent={notificationsCollection?.length} color="secondary">
                    <NotificationsIcon
                      className={`iq4-topbar__notifications-icon ${
                        isNotificationsOpen ? 'iq4-topbar__notifications-icon--active' : ''
                      }`}
                    />
                  </Badge>
                )}
              </IconButton>
            </div>
            <Popover
              id="notifications-popover"
              classes={{ paper: 'iq4-notification-menu__paper' }}
              open={isNotificationsOpen}
              anchorEl={anchorElNotifications}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setIsNotificationsOpen(false)}
            >
              <div className="iq4-avatar-menu">
                <div className="iq4-avatar-menu__triangle-with-shadow"></div>
                <Notifications
                  onClose={closeNotifications}
                  notifications={notificationsCollection}
                />
              </div>
            </Popover>
            <IconButton
              className="iq4-main-navbar__account-btn"
              aria-label="Account"
              aria-owns={open ? 'iq4-main-navbar__account-popover' : undefined}
              aria-haspopup="true"
              onClick={handlePopoverOpen}
            >
              <SimpleIcon name="personCircle" />
            </IconButton>
          </div>
          <Popover
            id="iq4-main-navbar__account-popover"
            classes={{ paper: 'iq4-main-navbar__account-popover-root' }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div className="iq4-main-navbar__account-popover__content">
              <div className="iq4-avatar-menu__triangle-with-shadow"></div>
              <Logout />
            </div>
          </Popover>
        </div>
      </Toolbar>
    </AppBar>
  );
};
