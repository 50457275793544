import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import './CustomBulletList.scss';

const CustomBulletList = ({ listItems }) => {
  let delayLoad = 300;
  const bulletList = listItems.map((item, i) => {
    if (i !== 0) {
      delayLoad = delayLoad + 200;
    }
    return (
      <Fade top delay={delayLoad} duration={500}>
        <li className="custom-bullet-list__item" key={item.key}>
          <img
            className="custom-bullet-list__item-image"
            src={item.bulletImage}
            alt=""
            role="presentation"
          />
          <p className="custom-bullet-list__item-text">{item.bulletCopy}</p>
        </li>
      </Fade>
    );
  });

  return <ul className="custom-bullet-list">{bulletList}</ul>;
};

CustomBulletList.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      bulletImage: PropTypes.string.isRequired,
      bulletCopy: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

CustomBulletList.defaultProps = {};

export default CustomBulletList;
