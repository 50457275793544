import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextInput,
  TextArea,
  AutoComplete,
  Select,
  FileUpload,
} from 'common/components/Form/fieldTypes';
import { useSkillsSearch } from 'common/hooks/useSkillsSearch';
import { validations } from 'common/components/Form/validations';
import { selectPortfolioSources, selectProfile } from 'common/store/features/profile/selectors';
import { PROFILE_PORTFOLIO_URL } from 'common/constants/endpoints';
import { THUMBNAIL_MIME_TYPES } from 'common/constants/mimeTypes';
import {
  setProfileSection,
  updateProfileSection,
} from 'common/store/features/profile/profileSlice';
import {
  PORTFOLIO_TYPE,
  PROFILE_TYPE,
  transformProfileFormsPutData,
  sendFormDataRequest,
} from 'common/utils';

export const usePortfolioVideoFormDefinition = ({ values, onComplete }) => {
  const { portfolio } = useSelector(selectProfile);
  const sourceOptions = useSelector(selectPortfolioSources);
  const dispatch = useDispatch();
  const [competenciesSearchTerm, setCompetenciesSearchTerm] = useState('');
  const competenciesData = useSkillsSearch(competenciesSearchTerm);

  const fieldsDefinition = [
    {
      label: 'Title',
      name: 'title',
      component: TextInput,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'YouTube link',
      name: 'url',
      component: TextInput,
      placeholder: 'https://www.youtube.com/watch?v=XXXXXXXXX',
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Description (500 characters)',
      name: 'description',
      component: TextArea,
      validators: {
        maxLength: validations.hasMaxLength(500),
      },
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <AutoComplete
          name="competencies"
          label="Competencies"
          placeholder="List your skills"
          options={competenciesData}
          optionLabel="value"
          onInputChange={(e, value) => setCompetenciesSearchTerm(value)}
          tooltip={<p>Competencies are your core skills. Add between 5 and 12 keywords here.</p>}
          {...formProps}
        />
      ),
    },
    ...(!!sourceOptions.length
      ? [
          {
            isCustom: true,
            component: ({ ...formProps }) => (
              <div className="iq4-portfolio-source-select">
                <Select
                  maxWidth
                  label="Where did you complete this project"
                  name="associatedRecord"
                  placeholder="This is a dropdown list of Education and Experience"
                  options={sourceOptions}
                  {...formProps}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <FileUpload
          triggerLabel="Upload a cover image"
          fileTypes={THUMBNAIL_MIME_TYPES}
          name="image"
          type="thumbnail"
          {...formProps}
        />
      ),
    },
  ];

  const onEdit = (data) =>
    sendFormDataRequest({
      url: `${PROFILE_PORTFOLIO_URL}/${values.id}`,
      verb: 'PUT',
      data: {
        ...transformProfileFormsPutData(data, values),
        type: PORTFOLIO_TYPE.VIDEO,
      },
    }).then((resp) => {
      return dispatch(updateProfileSection({ section: PROFILE_TYPE.PORTFOLIO, value: resp }));
    });

  const onCreate = (data) =>
    sendFormDataRequest({
      url: PROFILE_PORTFOLIO_URL,
      verb: 'POST',
      data: {
        ...data,
        type: PORTFOLIO_TYPE.VIDEO,
        featured: portfolio.length === 0,
      },
    }).then((resp) => {
      return dispatch(setProfileSection({ section: PROFILE_TYPE.PORTFOLIO, value: resp }));
    });

  return {
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};
