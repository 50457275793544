import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './Expand.scss';

/**
 * Custom Expand panel component overriding the Material UI ExpansionPanel component
 * @param {*} [title] - The title of the expand
 * @param {String} [className] - Custom className for component
 * @param {boolean} [isOuterCard] - Add's outer card styles, if set
 * @param {*} children - children to render in the expand content section
 */
export const Expand = ({
  children,
  className,
  title = '',
  isOuterCard = false,
  uniqueId = '',
  label = '',
  ...props
}) => {
  return (
    <ExpansionPanel
      className={`iq4-expand-panel ${isOuterCard ? 'iq4-expand-panel--outer' : ''} ${
        className ? className : ''
      }`}
      {...props}
    >
      <ExpansionPanelSummary
        className="iq4-expand-panel__title-container"
        expandIcon={<ExpandMoreIcon />}
        aria-label={label}
        id={uniqueId}
      >
        <div className="iq4-expand-panel__title">{title}</div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="iq4-expand-panel__content">
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
