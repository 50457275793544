import React from 'react';
import './RecommendedWorkRolesList.scss';

/**
 * Recommended work roles list component
 * @param {object[]} roles - an array of roles to list
 * @param {function} onClick - Hndle clicking the details btn for roles
 */
export const RecommendedWorkRolesList = ({ roles, onClick }) => {
  return (
    roles &&
    roles.map((role) => {
      return (
        <button
          key={role.id}
          className="iq4-recommended-work-roles-list"
          onClick={() => void onClick(role)}
        >
          <p className="iq4-recommended-work-roles-list__title">{role.label}</p>
          <span className="iq4-recommended-work-roles-list__detail-btn">Details</span>
        </button>
      );
    })
  );
};
