import React from 'react';
import PropTypes from 'prop-types';
import './ValidationMessage.scss';

export const ValidationMessage = (props) => {
  const { message } = props;
  return <p className="iq4-validation-message">{message}</p>;
};

ValidationMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

ValidationMessage.defaultProps = {
  message: 'Invalid entry',
};
