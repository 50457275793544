import { createSelector } from 'reselect';
import { COURSE_LEVELS_AND_PROFICIENCIES } from 'common/constants/courses';

export const selectTier4Assessments = createSelector(
  (state) => state.skillsModule.skills.assessments.t4,
  (assessments) => {
    console.log('assessments', assessments);
    return assessments;
  },
);

export const sortedSkillsSummary = createSelector(
  (state) => state.skillsModule.skills.summary,
  (skillsSummary) => {
    return skillsSummary.slice().sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });
  },
);

export const nonEmptyTier1sSelector = createSelector(
  (state) => state.skillsModule.skills.skills,
  (skillsMap) => {
    if (!skillsMap) return {};

    return Object.keys(skillsMap).reduce((map, t1Id) => {
      map[t1Id] = true;
      return map;
    }, {});
  },
);

/*
{
  [tier1ID]: {
    // sorted by value
    tier2s: [{id: Number, value: String}, ...],

    // sorted by value
    tier3s: {
      [t2ID]: [{ id: Number, value: String, assessment: {}}, ...],
    },

    // sorted by value
    tier4s: {
      [t3id]: [{ id: Number, value: String }]
    }
  },
  ...
}
*/
export const skillsSelector = createSelector(
  (state) => state.skillsModule.skills.skills,
  (state) => state.skillsModule.skills.comtechLabsInfo,
  (skillsMap, comtechLabsInfo) => {
    const derivedSkills = {
      roleSkills: {},
      additionalSkills: {},
    };

    const lookedAtSkills = {
      r: { t1: {}, t2: {}, t3: {} },
      a: { t1: {}, t2: {}, t3: {} },
    };

    for (let t1Id in skillsMap) {
      const skillsUnderT1 = skillsMap[t1Id];
      skillsUnderT1.forEach((skill) => {
        // derive assessments
        const testedSum = [];
        const assessments = skill.skillTier3.assessments.reduce((map, value) => {
          if (value.type === 'comtech' || value.type === 'simspace')
            testedSum.push(value.assessment);

          map[value.type] = value.assessment;
          return map;
        }, {});

        if (testedSum.length) {
          assessments.tested =
            testedSum.reduce((sum, value) => {
              return sum + value;
            }, 0) / testedSum.length;
        } // end if

        // add the skills to the derivedSkills map above
        skill.skillTier3.skillTier4List.forEach((tier4Config) => {
          // determine if skill should be added to role || additional skills
          let lookupKey = 'r'; // r = role, a = additional
          let typeOfSkill = 'roleSkills'; // roleSkills || additionalSkills
          if (tier4Config.includes.indexOf('role') === -1) {
            lookupKey = 'a';
            typeOfSkill = 'additionalSkills';
          }

          const t1 = skill.skillTier1;
          const t2 = skill.skillTier2;
          const t3 = {
            ...skill.skillTier3.skillTier3,
            courseCount: skill.skillTier3.courseCount,
            course: skill.skillTier3.course,
          };
          const t4 = tier4Config.skillTier4;

          // add skill structure to this tier1 id
          if (!derivedSkills[typeOfSkill][t1.id]) {
            derivedSkills[typeOfSkill][t1.id] = {
              tier2s: [],
              tier3s: {},
              tier4s: {},
              isTestedMap: {}, // keep track of tier2's that have a tested assessment
              isCertifiedMap: {},
            };
          } // end if

          // add tier2
          if (!lookedAtSkills[lookupKey].t2[`${t1.id}_${t2.id}`]) {
            const t2Skill = { id: t2.id, value: t2.label };
            derivedSkills[typeOfSkill][t1.id].tier2s.push(t2Skill);
            lookedAtSkills[lookupKey].t2[`${t1.id}_${t2.id}`] = true;

            // add tier3 structure for this tier2 id
            derivedSkills[typeOfSkill][t1.id].tier3s[t2.id] = [];
          } // end if

          // add tier3
          if (!lookedAtSkills[lookupKey].t3[`${t1.id}_${t2.id}_${t3.id}`]) {
            const t3Skill = {
              id: t3.id,
              value: t3.label,
              courseCount: t3.courseCount,
              assessment: assessments,
              context: skill.skillTier3.context,
              compId: skill.skillTier3.compId,
              course: t3.course,
            };

            derivedSkills[typeOfSkill][t1.id].tier3s[t2.id].push(t3Skill);
            lookedAtSkills[lookupKey].t3[`${t1.id}_${t2.id}_${t3.id}`] = true;

            // add tier4 structure for this tier3 id
            derivedSkills[typeOfSkill][t1.id].tier4s[t3.id] = [];

            if (assessments.hasOwnProperty('comtech') || assessments.hasOwnProperty('simspace')) {
              derivedSkills[typeOfSkill][t1.id].isTestedMap[t2.id] = 'tested';
            }

            if (assessments.hasOwnProperty('sans')) {
              derivedSkills[typeOfSkill][t1.id].isCertifiedMap[t2.id] = 'certified';
            }
          } // end if

          // add the tier4
          const t4Skill = {
            id: t4.id,
            value: t4.label,
            priority: tier4Config.priority,
            assessments: tier4Config.assessments,
            jobSkillPayGrade: {
              id: (tier4Config.ups.jobSkillPayGrade && tier4Config.ups.jobSkillPayGrade.id) || null,
              skillLevel: tier4Config.skillLevel,
              proficiency: tier4Config.requiredProficiency,
            },
            uspId: tier4Config.ups.id,
            scId: tier4Config.scId,
            courseCount: tier4Config.courseCount,
            course: tier4Config.course,

            includes: tier4Config.includes.reduce((map, s) => {
              map[s] = true;
              return map;
            }, {}),
          };

          derivedSkills[typeOfSkill][t1.id].tier4s[t3.id].push(t4Skill);
        }); // end forEach tier4List
      }); // end forEach skillsUnderT1
    } // end for skillsConfig.skillsMap

    // sort the skills
    const sortedSkills = {
      additionalSkills: sortSkills(derivedSkills.additionalSkills),
      roleSkills: sortSkills(derivedSkills.roleSkills),
    };

    return sortedSkills;

    function sortSkills(skills) {
      const priorityMap = { low: 0, medium: 1, high: 2 };
      for (let t1Id in skills) {
        const tier2s = skills[t1Id].tier2s;
        // sort tier2s
        tier2s.sort((a, b) => ('' + a.value).localeCompare(b.value));

        // sort tier3s
        for (let t2Id in skills[t1Id].tier3s) {
          const tier3s = skills[t1Id].tier3s[t2Id];
          tier3s.sort((a, b) => ('' + a.value).localeCompare(b.value));
        }

        // sort tier4s
        for (let t3Id in skills[t1Id].tier4s) {
          const tier4s = skills[t1Id].tier4s[t3Id];
          tier4s.sort((a, b) => {
            if (!a.priority) return ('' + a.value).localeCompare(b.value);
            return priorityMap[b.priority] - priorityMap[a.priority];
          });
        }
      }

      return skills;
    }
  },
); // end skillsSelector

export const browseResultsSelector = createSelector(
  (state) => state.skillsModule.skills.browseResults,
  (skills) => {
    let results = [];
    const tierMap = {
      t1: {},
      t2: {},
      t3: {},
    };

    for (let key in skills) {
      const skill = skills[key];
      const t1 = skill.skillTier1;
      const t2 = skill.skillTier2;
      const t3 = skill.skillTier3;
      const t4 = skill.skillTier4;

      // already added t1
      if (tierMap[t1.id] !== undefined) {
        if (tierMap[t2.id] !== undefined) {
          if (tierMap[t3.id] !== undefined) {
            const skillTier4Item = t4;
            results[tierMap[t1.id]].skillTier2List[tierMap[t2.id]].skillTier3List[
              tierMap[t3.id]
            ].skillTier4List = [].concat(
              results[tierMap[t1.id]].skillTier2List[tierMap[t2.id]].skillTier3List[tierMap[t3.id]]
                .skillTier4List,
              skillTier4Item,
            );
          } else {
            const skillTier3ListItem = {
              skillTier3: t3,
              skillTier4List: t4,
            };

            results[tierMap[t1.id]].skillTier2List[tierMap[t2.id]].skillTier3List.push(
              skillTier3ListItem,
            );
            tierMap[t3.id] =
              results[tierMap[t1.id]].skillTier2List[tierMap[t2.id]].skillTier3List.length - 1;
          }
        } else {
          // create skill Tier2 list
          const skillTier2ListItem = {
            skillTier2: t2,
            skillTier3List: [
              {
                skillTier3: t3,
                skillTier4List: t4,
              },
            ],
          };

          results[tierMap[t1.id]].skillTier2List.push(skillTier2ListItem);
          tierMap[t2.id] = results[tierMap[t1.id]].skillTier2List.length - 1;
        }
      } else {
        const payload = {
          skillTier1: t1,
          skillTier2List: [
            {
              skillTier2: t2,
              skillTier3List: [
                {
                  skillTier3: t3,
                  skillTier4List: t4,
                },
              ],
            },
          ],
        };

        results.push(payload);

        tierMap[t1.id] = results.length - 1;
        tierMap[t2.id] = 0;
        tierMap[t3.id] = 0;
      }
    }

    return results;
  },
);

export const getTier1sSelector = createSelector(
  (state) => state.skillsModule.skills.tier1s,
  (tier1s) => {
    return tier1s.map((t1) => ({
      id: t1.skillTier1.id,
      label: t1.skillTier1.label,
    }));
  },
);

export const selectBrowseSkillsByRoleList = createSelector(
  (state) => state.skillsModule.skills.browseSkillsByRoleList,
  (list) => list,
);

const LEVELS_MAP = {
  entry: 0,
  intermediate: 1,
  advanced: 2,
  expert: 3,
};
export const selectRecommededCoursesMap = createSelector(
  skillsSelector,
  getTier1sSelector,
  (skills) => {
    const allT1Ids = Object.keys(skills).reduce((t1list, typeOfSkill) => {
      t1list = t1list.concat(Object.keys(skills[typeOfSkill]));
      return t1list;
    }, []);

    const uniqueT1Ids = allT1Ids.filter((item, i, ar) => ar.indexOf(item) === i);

    // initilize total recommended courses count
    let totalRecommendedCourseCount = uniqueT1Ids.reduce((map, t1Id) => {
      map[t1Id] = 0;
      return map;
    }, {});

    uniqueT1Ids.forEach((skillId) => {
      Object.keys(skills).forEach((typeOfSkill) => {
        const skillsByType = skills[typeOfSkill];

        // skills within this t1
        const skillsByTypeAndTier1 = skillsByType[skillId];
        if (!skillsByTypeAndTier1) return;

        const tier3sMap = skillsByTypeAndTier1.tier3s;
        const tier4sMap = skillsByTypeAndTier1.tier4s;
        Object.keys(tier4sMap).forEach((t3Id) => {
          const tier4s = tier4sMap[t3Id];

          // keep track of averages to be used when calculating t3 courses count
          let sumOfAllT4Assessments = { sum: 0, count: 0 };

          tier4s.forEach((t4) => {
            // average assessment for this skill;
            const sumMap = t4.assessments.reduce(
              (map, assessmentData) => {
                if (assessmentData.assessment) {
                  map.sum += assessmentData.assessment;
                  map.count++;
                }

                return map;
              },
              { sum: 0, count: 0 },
            );
            const t4AverageAssessment = sumMap.count ? Math.floor(sumMap.sum / sumMap.count) : null;

            // keep track for t3 calculations
            if (t4AverageAssessment) {
              sumOfAllT4Assessments.sum += t4AverageAssessment;
              sumOfAllT4Assessments.count++;
            }

            // course count for this tier4 skills based on the average
            const assessmentLevel = t4AverageAssessment
              ? COURSE_LEVELS_AND_PROFICIENCIES[t4AverageAssessment - 1].level
              : null;

            const t4RecommendedCourseCount = assessmentLevel
              ? Object.keys(t4.courseCount).reduce((set, courseCountKey) => {
                  if (
                    LEVELS_MAP.hasOwnProperty(courseCountKey) &&
                    LEVELS_MAP[assessmentLevel] - LEVELS_MAP[courseCountKey] <= 0
                  ) {
                    set = new Set([...set, ...t4.courseCount[courseCountKey]]);
                  }

                  return set;
                }, new Set()).size
              : Object.keys(t4.courseCount).reduce((set, courseCountKey) => {
                  if (LEVELS_MAP.hasOwnProperty(courseCountKey)) {
                    set = new Set([...set, ...t4.courseCount[courseCountKey]]);
                  }

                  return set;
                }, new Set()).size;

            totalRecommendedCourseCount[skillId] += t4RecommendedCourseCount;
          });

          // derive average at t3
          const t3Average = sumOfAllT4Assessments.count
            ? Math.floor(sumOfAllT4Assessments.sum / sumOfAllT4Assessments.count)
            : null;
          const t3AssessmentLevel = t3Average
            ? COURSE_LEVELS_AND_PROFICIENCIES[t3Average - 1].level
            : null;

          // get t3 recommended courses based on average
          let tier3 = tier3sMap[null].find((t3Data) => t3Data.id == t3Id);

          const t3RecommendedCourseCount = t3AssessmentLevel
            ? Object.keys(tier3.courseCount).reduce((set, courseCountKey) => {
                if (
                  LEVELS_MAP.hasOwnProperty(courseCountKey) &&
                  LEVELS_MAP[t3AssessmentLevel] - LEVELS_MAP[courseCountKey] <= 0
                ) {
                  set = new Set([...set, ...tier3.courseCount[courseCountKey]]);
                }

                return set;
              }, new Set()).size
            : Object.keys(tier3.courseCount).reduce((set, courseCountKey) => {
                if (LEVELS_MAP.hasOwnProperty(courseCountKey)) {
                  set = new Set([...set, ...tier3.courseCount[courseCountKey]]);
                }

                return set;
              }, new Set()).size;

          totalRecommendedCourseCount[skillId] += t3RecommendedCourseCount;
        });
      });
    });

    return { totalCoursesCount: totalRecommendedCourseCount };
  },
);

export const selectSkillsMetaData = createSelector(
  (state) => state.skillsModule.skills.skills,
  (state) => state.skillsModule.skills.summary,
  (skills, skillsSummary) => {
    if (!Object.keys(skills).length && skillsSummary.length) {
      return skillsSummary.reduce((map, t1) => {
        map[t1.skill_tier1_id] = getInitialObj();
        return map;
      }, {});
    }

    let skillsMetaData = {};
    Object.keys(skills).forEach((t1Id) => {
      skillsMetaData[t1Id] = getInitialObj();
      const skillsUnderT1 = skills[t1Id];
      skillsUnderT1.forEach(({ skillTier1, skillTier2, skillTier3: skillTier3Config }) => {
        const tier4s = skillTier3Config.skillTier4List;
        tier4s.forEach((tier4) => {
          // figure out all sources for this skill
          let sourcesMap = tier4.includes.reduce((map, source) => {
            map[source] = true;
            return map;
          }, {});
          sourcesMap = tier4.assessments.reduce((map, a) => {
            map[a.type] = true;
            return map;
          }, sourcesMap);

          if (sourcesMap.role) {
            if (tier4.priority === 'high') {
              skillsMetaData[t1Id].role.highPrioritySkillsCount++;
            }

            if (tier4.priority === 'medium') {
              skillsMetaData[t1Id].role.mediumPrioritySkillsCount++;
            }

            if (tier4.priority === 'low') {
              skillsMetaData[t1Id].role.lowPrioritySkillsCount++;
            }
            skillsMetaData[t1Id].role.skillsCount++;
          }
          if (sourcesMap.role && sourcesMap.certified) {
            skillsMetaData[t1Id].role.certifiedSkillsCount++;
          }
          if (sourcesMap.role && sourcesMap.manager) {
            skillsMetaData[t1Id].role.managerSkillsCount++;
          }

          if (sourcesMap.additional) {
            skillsMetaData[t1Id].additional.skillsCount++;
          }
          if (sourcesMap.additional && sourcesMap.certified) {
            skillsMetaData[t1Id].additional.certifiedSkillsCount++;
          }
          if (sourcesMap.additional && sourcesMap.manager) {
            skillsMetaData[t1Id].additional.managerSkillsCount++;
          }
        });
      });
    });

    if (skillsSummary.length && Object.keys(skillsMetaData).length !== skillsSummary.length) {
      skillsSummary.forEach((t1) => {
        if (!skillsMetaData[t1.skill_tier1_id]) {
          skillsMetaData[t1.skill_tier1_id] = getInitialObj();
        }
      });
    }
    return skillsMetaData;

    function getInitialObj() {
      return {
        role: {
          skillsCount: 0,
          managerSkillsCount: 0,
          certifiedSkillsCount: 0,
          highPrioritySkillsCount: 0,
          mediumPrioritySkillsCount: 0,
          lowPrioritySkillsCount: 0,
        },

        additional: {
          skillsCount: 0,
          managerSkillsCount: 0,
          certifiedSkillsCount: 0,
        },
      };
    }
  },
);
