import React from 'react';
import memoize from 'memoize-one';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { renderColumn } from './tableUtils';
import './DataTable.scss';
import { Button } from '../Button';

const ROW_SIZE = 50;

const VirtualizedRow = ({ index, style, data: { tableDefinition, items } }) => {
  const item = items[index];
  const RenderedRow = () =>
    tableDefinition.columns.map((column, colIndex) => {
      return (
        <div
          key={item.id + colIndex}
          style={{
            width: column.width || false,
            height: ROW_SIZE,
          }}
        >
          {renderColumn({ column, colIndex, row: item })}
        </div>
      );
    });

  return (
    <TableRow component="div" style={style} classes={{ root: 'iq4-datatable__virtualized-row' }}>
      <RenderedRow />
    </TableRow>
  );
};

const itemKey = (index, data) => data.items[index].id;

const createItemData = memoize((currentRows, tableDefinition, currentSort) => ({
  tableDefinition,
  currentSort,
  items: currentRows,
}));

/**
 * Virtualized Datatable Component that caters for a big number of rows
 * @param headerDefinition
 * @param handleSorting
 * @param currentSort
 * @param renderColumnHeader
 * @param currentRows
 * @param columnsDefinition
 * @returns {*}
 * @constructor
 */
export const VirtualizedTable = ({
  tableDefinition,
  handleSorting,
  currentSort,
  renderColumnHeader,
  currentRows,
  setCurrentFilter,
  rows,
}) => {
  const itemData = createItemData(currentRows, tableDefinition, currentSort);

  return (
    <div className="iq4-datatable__virtualized-root">
      <Table classes={{ root: 'iq4-datatable__virtualized-table' }} component="div">
        <TableHead component="div">
          <TableRow classes={{ root: 'iq4-datatable__virtualized-header' }}>
            {tableDefinition.columns.map((col) =>
              col.isEmpty ? (
                <TableCell />
              ) : (
                <TableCell
                  classes={{ root: 'iq4-datatable__cell-header' }}
                  style={{
                    width: col.width || false,
                  }}
                  onClick={col.isSortable ? () => handleSorting(col.prop) : null}
                  {...col}
                >
                  <span
                    className={`iq4-datatable__cell-header--inner ${
                      col.isSortable && currentSort.prop !== col.prop
                        ? 'iq4-datatable__cell-header--disabled'
                        : ''
                    }`}
                  >
                    {renderColumnHeader(col)}
                  </span>
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>

        <TableBody component="div" classes={{ root: 'iq4-datatable__virtualized-body' }}>
          {currentRows.length ? (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  width={width}
                  itemCount={currentRows.length}
                  itemSize={ROW_SIZE}
                  itemKey={itemKey}
                  itemData={itemData}
                >
                  {VirtualizedRow}
                </List>
              )}
            </AutoSizer>
          ) : rows.length ? (
            <tr>
              <td colSpan={tableDefinition.columns.length}>
                <div className="iq4-datatable__no-results-container">
                  <div className="iq4-datatable__no-results">no results returned!</div>
                  <div className="iq4-datatable__no-results">try changing the filters above</div>
                  <div className="iq4-datatable__no-results-normal">or</div>
                  <div className="iq4-datatable__no-results-normal">
                    <Button variation="ghost" onClick={() => setCurrentFilter({})}>
                      Clear
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          ) : null}
        </TableBody>
      </Table>
    </div>
  );
};
