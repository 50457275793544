import React from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

const CertificatesInfoModal = ({ isOpen, toggle, assessments }) => {
  const uniqueCerts = () => {
    return assessments.reduce(
      (map, a) => {
        a.labs.certificates.forEach(cert => {
          const { certName, sansCert } = cert;
          const key = `${certName}__${sansCert}`;

          if (map.track[key]) return map;

          map.uniqueVals.push({ certName, sansCert });
          map.track[key] = true;
        });

        return map;
      },
      { track: {}, uniqueVals: [] }
    ).uniqueVals;
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`Tools for X`}
      onRequestClose={() => void toggle(false)}
      handleClose={() => toggle(false)}
      renderHeader={() => (
        <React.Fragment>
          <h3>Certified Assessment</h3>
        </React.Fragment>
      )}
    >
      {() => (
        <div className="iq4-skills-certificates-info-modal">
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <span style={{ flex: 2 }}>COURSE</span>
            <span style={{ flex: 1 }}>CODE</span>
          </div>

          {uniqueCerts().map(a => (
            <div
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              key={`${a.certName}__${a.sansCert}`}
            >
              <span style={{ flex: 2 }}>{a.certName}</span>
              <span style={{ flex: 1 }}>{a.sansCert}</span>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default CertificatesInfoModal;
