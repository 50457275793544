import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PROFILE_TYPE } from 'common/utils/constants';
import {
  PROFILE_PORTFOLIO_URL,
  PROFILE_PERSONAL_URL,
  PROFILE_URL,
  NSC_STUDENT_PROFILE_URL,
  SKILLS_URL,
  PROFILE_AGREE_TERMS_URL,
  PROFILE_SHARE_URL,
  PUBLIC_PROFILE_URL,
} from 'common/constants/endpoints';
import { transformProfilePersonal, transformProfileRecord } from './transformations';
import { sendFormDataRequest } from 'common/utils';
import { fetchAllSkillsReq, setSkills } from 'common/store/features/skills/skillsSlice';
import { setIsBootstrapping } from 'common/store/features/session/sessionSlice';
import { setUserSelectedWorkRoles } from '../pathways/pathwaysSlice';

const matchesPayloadElement = (item, action) => item.id === +action.payload.value.id;

export const fetchIQ4Profile = createAsyncThunk(
  'records/fetchIQ4Profile',
  async (_, { rejectWithValue }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;

    return fetch(`${PROFILE_URL}${isPublic ? '/?userId=' + userId : ''}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response.json());
      }
    });
  },
);

export const fetchNSCProfile = createAsyncThunk(
  'records/fetchNSCProfile',
  async (undefined, { rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    return fetch(NSC_STUDENT_PROFILE_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response.json());
      }
    });
  },
);

export const fetchProfileReq = createAsyncThunk(
  'profile/fetchProfileReq',
  async (personalProfileSource, { rejectWithValue, dispatch }) => {
    const iQ4Profile = await dispatch(fetchIQ4Profile());
    if (iQ4Profile.payload) {
      let profile = iQ4Profile.payload;

      if (personalProfileSource === 'nsc') {
        const nscProfile = await dispatch(fetchNSCProfile());
        if (nscProfile.payload) {
          if (!profile.personal.userProfile) profile.personal.userProfile = { user: {} };
          if (!profile.personal.userProfilePhoto) profile.personal.userProfilePhoto = {};

          profile.personal.userProfile.user = {
            ...profile.personal.userProfile.user,
            ...nscProfile.payload,
          };
        } else {
          return rejectWithValue(nscProfile.json());
        }
      }

      dispatch(setIsBootstrapping(false));

      return profile;
    } else {
      return rejectWithValue(iQ4Profile.json());
    }
  },
);

export const ensurePortfolioFeaturedItem = createAsyncThunk(
  'profile/ensurePortfolioFeaturedItem',
  async ({ item, featuredItems, portfolio }) => {
    // NOTE: If user is unsetting / deleting the only featured item, need to flag another one as featured
    if (item.featured && featuredItems.length === 1 && portfolio.length > 1) {
      const otherItems = portfolio.filter((el) => el.id !== item.id);
      const latestItem = otherItems[otherItems.length - 1];

      const res = await sendFormDataRequest({
        url: `${PROFILE_PORTFOLIO_URL}/${latestItem.id}`,
        verb: 'PUT',
        data: {
          ...latestItem,
          featured: !latestItem.featured,
        },
      });

      return {
        value: res,
      };
    }

    return {
      value: item,
    };
  },
);

export const updateHasOnboardedReq = createAsyncThunk(
  'profile/updateHasOnboardedReq',
  async ({ id, hasOnboarded }) => {
    return await fetch(`${PROFILE_PERSONAL_URL}/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hasOnboarded,
      }),
    });
  },
);

export const acceptUserTerms = createAsyncThunk('profile/updateHasOnboardedReq', async ({ id }) => {
  return await fetch(`${PROFILE_PERSONAL_URL}/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      hasAcceptedTerms: true,
    }),
  });
});

export const updateProfileSection = createAsyncThunk(
  '/skills/updateProfileSection',
  async ({ section, value }, { dispatch }) => {
    dispatch(fetchAllSkillsReq());
    return {
      section,
      value,
    };
  },
);

export const fetchProfileAgreeTerms = createAsyncThunk(
  'profile/fetchProfileAgreeTerms',
  async (_, { rejectWithValue }) => {
    return fetch(PROFILE_AGREE_TERMS_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const acceptFCRATerms = createAsyncThunk(
  'profile/acceptFCRATerms',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setIsAcceptingFCRA(true));
    return fetch(PROFILE_AGREE_TERMS_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type: 'FCRA', value: true }),
    }).then((response) => {
      dispatch(setIsAcceptingFCRA(false));
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const getShareProfileSettings = createAsyncThunk(
  'profile/getShareProfileSettings',
  async (_, { rejectWithValue }) => {
    return fetch(PROFILE_SHARE_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchPublicData = createAsyncThunk(
  'profile/fetchPublicData',
  async ({ id, section }, { dispatch, rejectWithValue }) => {
    return fetch(`${PUBLIC_PROFILE_URL}/${id}?filter=${section}`, {
      method: 'GET',
    }).then(async (response) => {
      if (response.ok) {
        const result = await response.json();

        if (section === 'skills') {
          dispatch(setSkills(result));
        }

        if (section === 'personal') {
          dispatch(
            setProfileFromPublic({
              personal: result?.personal,
              experience: result?.experience,
              education: result?.education,
              portfolio: result?.portfolio,
            }),
          );
        }

        if (section === 'pathways') {
          dispatch(setUserSelectedWorkRoles(result));
        }

        return result;
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const shareProfile = createAsyncThunk(
  'profile/shareProfile',
  async (shareObj, { rejectWithValue, dispatch }) => {
    dispatch(setIsSharingProfile(true));

    const newShareObj = {
      ...shareObj,
      profile: {
        ...shareObj.profile,
        personal: {
          ...shareObj.profile.personal,
          link: true,
        },
      },
    };

    return fetch(PROFILE_SHARE_URL, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newShareObj),
    }).then((response) => {
      dispatch(setIsSharingProfile(false));
      dispatch(setProfileShareSettings(newShareObj));
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const acceptSharingDisclaimer = createAsyncThunk(
  'profile/acceptSharingDisclaimer',
  async (shareObj, { rejectWithValue, dispatch }) => {
    dispatch(setIsSharingProfile(true));

    const newShareObj = {
      ...shareObj,
      profile: {
        ...shareObj.profile,
        personal: {
          ...shareObj.profile.personal,
          showSharingDisclaimer: false,
        },
      },
    };

    return fetch(PROFILE_SHARE_URL, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newShareObj),
    }).then((response) => {
      dispatch(setIsSharingProfile(false));
      dispatch(setProfileShareSettings(newShareObj));
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const setProfileSection = createAsyncThunk(
  '/skills/setProfileSection',
  async ({ section, value }, { dispatch }) => {
    dispatch(fetchAllSkillsReq());
    return {
      section,
      value,
    };
  },
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    loading: false,
    hasError: false,
    hasFetchedProfile: false,
    personal: {},
    education: [],
    experience: [],
    portfolio: [],
    hasLoaded: false,
    profileWorkRoles: [],
    selectedJobFamily: null,
    selectedTShapeRole: null,
    hasAcceptedFCRA: false,
    isAcceptingFCRA: false,
    isSharingProfile: false,
    shareProfileSettings: null,
    isPublicProfile: false,
    publicData: {
      skills: [],
    },
  },
  reducers: {
    setProfilePhoto: (state, action) => {
      if (!action.payload.userProfilePhoto) {
        state.personal.userProfilePhoto = {
          id: null,
          image: null,
          imageName: null,
          thumbnail: null,
          type: null,
        };
        return;
      }

      state.personal.userProfilePhoto = action.payload.userProfilePhoto;
    },
    setProfilePersonal: (state, action) => {
      state.personal = {
        ...state.personal,
        ...transformProfilePersonal(action.payload, state.personal),
      };
    },
    removeProfileSection: (state, action) => {
      function removePortfolioSource(item) {
        const { associatedRecord: removed, ...itemWithoutPortfolioSource } = item;
        return itemWithoutPortfolioSource;
      }

      if (action.payload.section !== 'portfolio') {
        state['portfolio'] = state['portfolio'].map((item) => {
          return item?.associatedRecord?.id === +action.payload.value &&
            item?.associatedRecord?.type === action.payload.section
            ? removePortfolioSource(item)
            : item;
        });
      }

      state[action.payload.section] = state[action.payload.section].filter(
        (x) => x.id !== +action.payload.value,
      );
    },
    addProfileSectionCompetency: (state, action) => {
      const competency = action.payload.competency;
      const recordId = action.payload.id;
      const section = action.payload.section;

      const recordIndex = state[section].findIndex((x) => x.id === recordId);
      const competencies = state[section][recordIndex].competencies || [];

      const competencyExists = competencies.filter((item) => item.id === competency.id).length > 0;

      state[section][recordIndex].competencies = competencyExists
        ? state[section][recordIndex].competencies
        : [...competencies, competency];
    },
    removeProfileSectionCompetency: (state, action) => {
      const competency = action.payload.competency;
      const recordId = action.payload.id;
      const section = action.payload.section;

      if (!['credentials', 'manual', 'resume'].includes(section)) {
        const recordIndex = state[section].findIndex((x) => x.id === recordId);
        let competencies = state[section][recordIndex].competencies || [];
        const competencyIndex = competencies.findIndex((item) => item.id === competency.id);

        competencies.splice(competencyIndex, 1);

        state[section][recordIndex].competencies = competencies;
      }
    },
    setProfileWorkRoles: (state, action) => {
      state.profileWorkRoles = action.payload;
    },
    setSelectedJobFamily: (state, action) => {
      state.selectedJobFamily = action.payload;
    },
    setSelectedTShapeRole: (state, action) => {
      state.selectedTShapeRole = action.payload;
    },
    setIsAcceptingFCRA: (state, action) => {
      state.isAcceptingFCRA = action.payload;
    },
    setIsSharingProfile: (state, action) => {
      state.isSharingProfile = action.payload;
    },
    setProfileShareSettings: (state, action) => {
      state.shareProfileSettings = action.payload;
    },
    setIsPublicProfile: (state, action) => {
      state.isPublicProfile = action.payload;
    },
    setProfileFromPublic: (state, action) => {
      state.experience = action.payload.experience;
      state.education = action.payload.education;
      state.portfolio = action.payload.portfolio;
    },
    setProfileFromSession: (state, action) => {
      Object.keys(PROFILE_TYPE).forEach((key) => {
        const sectionName = PROFILE_TYPE[key];
        state[sectionName] = Array.isArray(action.payload[sectionName])
          ? action.payload[sectionName].map(transformProfileRecord)
          : action.payload[sectionName];
      });

      state.hasFetchedProfile = true;
    },
  },
  extraReducers: {
    [fetchProfileReq.fulfilled]: (state, action) => {
      Object.keys(PROFILE_TYPE).forEach((key) => {
        const sectionName = PROFILE_TYPE[key];
        state[sectionName] = Array.isArray(action.payload[sectionName])
          ? action.payload[sectionName].map(transformProfileRecord)
          : action.payload[sectionName];
      });

      state.hasFetchedProfile = true;
    },

    [ensurePortfolioFeaturedItem.fulfilled]: (state, action) => {
      const itemIndex = state.portfolio.findIndex((x) => matchesPayloadElement(x, action));
      state.portfolio[itemIndex] = action.payload.value;
    },
    [setProfileSection.fulfilled]: (state, action) => {
      state[action.payload.section].push(transformProfileRecord(action.payload.value));
    },
    [updateProfileSection.fulfilled]: (state, action) => {
      const itemIndex = state[action.payload.section].findIndex((x) =>
        matchesPayloadElement(x, action),
      );

      state[action.payload.section][itemIndex] = transformProfileRecord(action.payload.value);
    },
    [updateHasOnboardedReq.fulfilled]: (state, action) => {
      const { hasOnboarded } = action.meta.arg;
      state.personal.userProfile.hasOnboarded = hasOnboarded;
    },
    [acceptUserTerms.fulfilled]: (state) => {
      state.personal.userProfile.hasAcceptedTerms = true;
    },
    [fetchProfileAgreeTerms.fulfilled]: (state, action) => {
      state.hasAcceptedFCRA = !!action.payload.fcraAgreement;
    },
    [acceptFCRATerms.fulfilled]: (state, action) => {
      state.hasAcceptedFCRA = !!action.payload;
    },
    [getShareProfileSettings.fulfilled]: (state, action) => {
      state.shareProfileSettings = action.payload;
    },
    [fetchPublicData.fulfilled]: (state, action) => {
      state.publicData[action.meta.arg.section] = action.payload;
    },
  },
});

// action creators
export const {
  setProfileLoaded,
  setProfilePhoto,
  setProfilePersonal,
  removeProfileSection,
  addProfileSectionCompetency,
  removeProfileSectionCompetency,
  setProfileWorkRoles,
  setSelectedJobFamily,
  setSelectedTShapeRole,
  setIsSharingProfile,
  setProfileShareSettings,
  setIsPublicProfile,
  setProfileFromPublic,
  setIsAcceptingFCRA,
  setProfileFromSession,
} = profileSlice.actions;

// TODO: Thunks will come here (will do it once we have the API in place

export default profileSlice.reducer;
