import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Tooltip from '@material-ui/core/Tooltip';
import { SimpleIcon, SkillsLegendTile, CountIndicator } from 'common/components';
import './SkillsStatsChart.scss';

/**
 * Skills Insitution/Market chart
 * @param {object[]} skillsMetrics - Type of skills to display on the graph: [{ count: Number, label: String }, ...]
 * @param {string} instituion - Institution Name
 * @param {function} [onClick] - Handle clicking on one of the bar segments
 * @param {string} className - Custom className
 * @param {boolean} [showTile] - show/hide tile
 * @param {boolean} [showTopLabels] - show/hide labels
 * @param {boolean} [showCountIndicator] - show/hide count indicator
 * @param {boolean} [withLegend] - show/hide legend
 */
export const SkillsStatsChart = ({
  skillsMetrics,
  institution,
  className,
  onClick = () => {},
  showTile,
  showTopLabels = true,
  showCountIndicator = true,
  withLegend = true,
}) => {
  const sum = useMemo(() => getSum(skillsMetrics), [skillsMetrics]);
  const renderBars = (bars) => {
    return bars.map((bar, i) => {
      return (
        <Tooltip
          arrow
          placement="top"
          interactive
          classes={{
            popper: 'iq4-skills-stats-chart__tooltip',
          }}
          title={
            <div
              className={`iq4-skills-stats-chart__tooltip-content ${
                className ? `${className}__tooltip-content` : ''
              }`}
            >
              <button onClick={() => void onClick(bar)}>
                {bar.label}. View <SimpleIcon name="arrow" />
              </button>
            </div>
          }
          key={`${bar.label}-${i}`}
        >
          <div
            className="iq4-skills-stats-chart__bar"
            style={{ width: `${(bar.count / sum) * 100}%` }}
          >
            {bar.type === 'additional' && (
              <ResponsiveBar
                id={`lineChart`}
                data={[
                  {
                    [`lineChart`]: 100,
                  },
                ]}
                keys={[`lineChart`]}
                layout="horizontal"
                padding={0}
                enableLabel={false}
                axisLeft={null}
                axisRight={null}
                axisBottom={null}
                defs={[
                  {
                    id: 'lines',
                    type: 'patternLines',
                    rotation: -45,
                    lineWidth: 10,
                    spacing: 20,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: `lineChart`,
                    },
                    id: 'lines',
                  },
                ]}
                isInteractive={false}
                animate={false}
                role="document"
              />
            )}
          </div>
        </Tooltip>
      );
    });
  };

  const renderLegendLabels = (labels) => {
    return labels.map((labelData, i) => {
      return (
        <button
          key={`${labelData.label}-${i}`}
          aria-label={`${labelData.count} ${labelData.type} available. Click to view more.`}
          className="iq4-skills-stats-chart__legend-label"
          onClick={() => void onClick(labelData)}
        >
          {showTile && <SkillsLegendTile type={labelData.type} />}
          <div className="iq4-skills-stats-chart__legend-label-value">{labelData.label}</div>
          {showCountIndicator && (
            <>
              <CountIndicator count={labelData.count} variation="dark" />
              <span className="iq4-skills-stats-chart__legend-label-arrow">
                <SimpleIcon name="arrow" />
              </span>
            </>
          )}
        </button>
      );
    });
  };

  function getSum(metrics) {
    return metrics.reduce((sum, metric) => sum + metric.count, 0);
  }

  return (
    <div className={`iq4-skills-stats-chart ${className ? className : ''}`}>
      {showTopLabels && (
        <div className="iq4-skills-stats-chart__bar-top-labels">
          <div className="iq4-skills-stats-chart__bar-label iq4-skills-stats-chart__bar-label-left">
            <div className="iq4-skills-stats-chart__bar-label-icon">
              <SimpleIcon name="education" />
            </div>
            <div className="iq4-skills-stats-chart__bar-label-value">{institution}</div>
          </div>
          <div className="iq4-skills-stats-chart__bar-label iq4-skills-stats-chart__bar-label-right">
            <div className="iq4-skills-stats-chart__bar-label-value">MARKET</div>
            <div className="iq4-skills-stats-chart__bar-label-icon">
              <SimpleIcon name="person" />
            </div>
          </div>
        </div>
      )}

      <div className="iq4-skills-stats-chart__bar-chart-container">{renderBars(skillsMetrics)}</div>

      {withLegend && (
        <div className="iq4-skills-stats-chart__legend-container">
          {renderLegendLabels(skillsMetrics)}
        </div>
      )}
    </div>
  );
};
