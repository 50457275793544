import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import {
  DASHBOARD_USER_URL,
  DASHBOARD_USERS_URL,
  DASHBOARD_ACHIEVEMENTS_URL,
  DASHBOARD_ACHIEVEMENT_URL,
  DASHBOARD_SKILLS_URL,
  DASHBOARD_SKILL_URL,
  DASHBOARD_FRAMEWORK_URL,
  DASHBOARD_TOP_CARDS_URL,
  DASHBOARD_QUERY_URL,
  DASHBOARD_WORK_ROLE_URL,
  AUTHENTICATION_URL,
} from 'common/constants/endpoints';

export const fetchIsLoggedInReq = createAsyncThunk('dashboard/fetchIsLoggedInReq', async () => {
  const res = await fetch(DASHBOARD_USER_URL, {
    method: 'GET',
  });

  return res.json();
});

export const loginReq = createAsyncThunk(
  'records/loginReq',
  async (params, { rejectWithValue }) => {
    const searchParams = Object.keys(params)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

    return fetch(AUTHENTICATION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: searchParams,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
);

export const fetchDashboardUserReq = createAsyncThunk(
  'dashboard/fetchDashboardUserReq',
  async (params, { rejectWithValue }) => {
    const res = await fetch(DASHBOARD_USER_URL, {
      method: 'GET',
    });

    return res.json();
  },
);

export const fetchDashboardUsersReq = createAsyncThunk(
  'dashboard/fetchDashboardUsersReq',
  async (params, { rejectWithValue }) => {
    const res = await fetch(DASHBOARD_USERS_URL, {
      method: 'GET',
    });
    return res.json();
  },
);

export const fetchDashboardAchievementsReq = createAsyncThunk(
  'dashboard/fetchDashboardAchievementsReq',
  async (params, { rejectWithValue }) => {
    return fetch(DASHBOARD_ACHIEVEMENTS_URL, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchDashboardSkillsReq = createAsyncThunk(
  'dashboard/fetchDashboardSkillsReq',
  async (params, { rejectWithValue }) => {
    return fetch(DASHBOARD_SKILLS_URL, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchDashboardFrameworkReq = createAsyncThunk(
  'dashboard/fetchDashboardFrameworkReq',
  async (params, { rejectWithValue }) => {
    return fetch(DASHBOARD_FRAMEWORK_URL, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

const createQuerystring = (skills, roles) => {
  const skillsAll = skills === 'ALL';
  const rolesAll = roles === 'ALL';

  let querystring = '';
  querystring = skillsAll || rolesAll ? querystring + '?' : querystring;
  querystring = skillsAll ? querystring + 'skills=ALL' : querystring;
  querystring = skillsAll && rolesAll ? querystring + '&' : querystring;
  querystring = rolesAll ? querystring + 'roles=ALL' : querystring;
  return querystring;
};

const createPayload = (skills, roles) => {
  const skillsAll = skills === 'ALL';
  const rolesAll = roles === 'ALL';

  return {
    ...(!skillsAll && { skills: skills.map((skill) => ({ id: skill })) }),
    ...(!rolesAll && { roles: roles.map((role) => ({ id: role })) }),
  };
};

const fetchDashboardQuery = async (querystring, payload) => {
  const res = await fetch(`${DASHBOARD_QUERY_URL}${querystring}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return res.json();
};

const debouncedFetchDashboardQuery = debounce(fetchDashboardQuery, 500, {
  leading: true,
  trailing: true,
});

export const fetchQueryReq = createAsyncThunk(
  'dashboard/fetchQueryReq',
  async ({ skills, roles }, { rejectWithValue }) => {
    const querystring = createQuerystring(skills, roles);
    const payload = createPayload(skills, roles);

    const result = await debouncedFetchDashboardQuery(querystring, payload);
    return result;
  },
);

const fetchTopCardsQuery = async (querystring, payload) => {
  const res = await fetch(`${DASHBOARD_TOP_CARDS_URL}${querystring}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return res.json();
};

const debouncedFetchTopCardsQuery = debounce(fetchTopCardsQuery, 500, {
  leading: true,
  trailing: true,
});

export const fetchTopCardsReq = createAsyncThunk(
  'dashboard/fetchTopCardsReq',
  async ({ skills, roles }, { rejectWithValue }) => {
    const querystring = createQuerystring(skills, roles);
    const payload = createPayload(skills, roles);

    const result = await debouncedFetchTopCardsQuery(querystring, payload);
    return result;
  },
);

export const fetchWorkRoleReq = createAsyncThunk(
  'dashboard/fetchWorkRoleReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${DASHBOARD_WORK_ROLE_URL}/${id}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchSkillReq = createAsyncThunk(
  'dashboard/fetchSkillReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${DASHBOARD_SKILL_URL}/${id}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchAchievementReq = createAsyncThunk(
  'dashboard/fetchAchievementReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${DASHBOARD_ACHIEVEMENT_URL}/${id}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    isBootstrapping: true,
    isLoggingIn: false,
    isLoggedIn: false,
    isSessionExpired: false,
    hasLoginError: false,
    user: null,
    metrics: {
      users: null,
      skills: null,
      missing: null,
      roles: null,
    },
    users: [],
    achievements: [],
    skills: [],
    framework: [],
    topCardsLoaded: false,
    usersLoaded: false,
    skillsLoaded: false,
    rolesLoaded: false,
    dashboardQueryLoaded: false,
    results: {
      top: {
        roles: [],
        skills: [],
        courses: [],
      },
      skills: [],
    },
  },
  reducers: {
    toggleIsLoggingIn: (state) => {
      state.isLoggingIn = !state.isLoggingIn;
    },
    toggleIsSessionExpired: (state) => {
      state.isSessionExpired = true;
      state.isLoggedIn = false;
    },
  },
  extraReducers: {
    [fetchDashboardUserReq.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [fetchDashboardUsersReq.fulfilled]: (state, action) => {
      const users = action.payload.sort(function (a, b) {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });

      state.metrics.users = users.length;
      state.users = users;
      state.usersLoaded = true;
    },
    [fetchDashboardAchievementsReq.fulfilled]: (state, action) => {
      state.achievements = action.payload;
    },
    [fetchDashboardSkillsReq.fulfilled]: (state, action) => {
      const skills = action.payload;

      state.metrics.skills = skills.length;
      state.skills = skills;
      state.skillsLoaded = true;
    },
    [fetchDashboardFrameworkReq.fulfilled]: (state, action) => {
      const workRoles = [];
      const framework = action.payload;

      framework.forEach((category) => {
        category.specialtyAreas.forEach((specialtyArea) => {
          specialtyArea.workRoles.forEach((workRole) => {
            workRoles.push(workRole);
          });
        });
      });

      state.metrics.roles = workRoles.length;
      state.framework = framework;
      state.rolesLoaded = true;
    },
    [fetchQueryReq.fulfilled]: (state, action) => {
      const results = action.payload;

      if (!state.metrics.missing) {
        state.metrics.missing = results.filter((skill) => skill.type === 'missing').length;
      }

      state.results.skills = results;
      state.dashboardQueryLoaded = true;
    },
    [fetchTopCardsReq.fulfilled]: (state, action) => {
      state.results.top = action.payload;
      state.topCardsLoaded = true;
    },
    [fetchWorkRoleReq.fulfilled]: (state, action) => {
      state.framework = state.framework.map((category) => ({
        ...category,
        specialtyAreas: category.specialtyAreas.map((specialtyArea) => ({
          ...specialtyArea,
          workRoles: specialtyArea.workRoles.map((workRole) =>
            workRole.id === action.payload.id
              ? {
                  ...workRole,
                  ...action.payload,
                }
              : workRole,
          ),
        })),
      }));
    },
    [fetchSkillReq.fulfilled]: (state, action) => {
      state.skills.map((skill) =>
        skill.id === action.payload.id
          ? {
              ...action.payload,
            }
          : skill,
      );
    },
    [fetchAchievementReq.fulfilled]: (state, action) => {
      state.achievements.map((achievement) =>
        achievement.id === action.payload.id
          ? {
              ...action.payload,
            }
          : achievement,
      );
    },
  },
});

export default dashboardSlice.reducer;
