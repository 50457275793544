import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionCard,
  Dropdown,
  LoadingSkeleton,
  DashboardModal,
  DashboardList,
  UserProfileSkillsAnalysisChart,
  UserProfileSkillsAnalysisResources,
} from 'common/components';
import { selectIsFetchingRoleProfile } from 'store/features/search/selectors';
import './UserProfileSkillsAnalysis.scss';

export const UserProfileSkillsAnalysis = ({
  roleProfiles,
  profile,
  userId,
  fetchRoleProfile,
  selectRoleProfileDetailsWithPreview,
}) => {
  const dispatch = useDispatch();
  const [selectedRoleProfileId, setSelectedRoleProfileId] = useState(null);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
  const [activeSkillsType, setActiveSkillsType] = useState(null);

  const isFetchingRoleProfile = useSelector(selectIsFetchingRoleProfile)

  useEffect(() => {
    setSelectedRoleProfileId(roleProfiles[0].id);
    dispatch(fetchRoleProfile({roleProfileId: roleProfiles[0].id, userId}));
  }, []);

  const handleRoleProfileChange = (roleProfileId) => {
    setSelectedRoleProfileId(roleProfileId);
    setIsPreviewEnabled(false);
    if (!isFetchingRoleProfile) {
      dispatch(fetchRoleProfile({roleProfileId, userId}));
    }
  };

  const handlePreviewClick = () => {
    setIsPreviewEnabled(!isPreviewEnabled);
  };

  const selectedRoleProfile = useSelector((state) =>
    selectRoleProfileDetailsWithPreview(state, selectedRoleProfileId, isPreviewEnabled),
  );

  const renderLoadingPanel = () => (
    <div style={{ display: 'block' }}>
      <LoadingSkeleton variant="rect" width="100%" height={50} />
    </div>
  );

  return (
    <>
      <ActionCard
        title="Skills analysis"
        icon="skillsCircle"
        isDivideVisible={false}
        className="iq4-user-profile-skills-analysis"
      >
        <p className="iq4-user-profile-skills-analysis__intro">
          Choose a role to see the skills overlap and view potential courses for those roles if
          needed
        </p>

        <div className="iq4-user-profile-skills-analysis__content">
          <div className="iq4-user-profile-skills-analysis__left">
            <Dropdown
              className="iq4-user-profile-skills-analysis__role-dropdown"
              options={roleProfiles}
              value={selectedRoleProfileId}
              onChange={(e) => handleRoleProfileChange(e.target.value)}
            />
          </div>

          <div className="iq4-user-profile-skills-analysis__right">
            {selectedRoleProfile && selectedRoleProfile.skills && (
              <>
                <UserProfileSkillsAnalysisChart
                  isPreviewEnabled={isPreviewEnabled}
                  skills={selectedRoleProfile.skills}
                  onSelectSkillsType={(type) => setActiveSkillsType(type)}
                />

                {selectedRoleProfile.topResource && selectedRoleProfile.topResource.id && (
                  <UserProfileSkillsAnalysisResources
                    profile={profile}
                    topResource={selectedRoleProfile.topResource}
                    onPreviewClick={handlePreviewClick}
                    isPreviewEnabled={isPreviewEnabled}
                  />
                )}
              </>
            )}
            {(!selectedRoleProfile || !selectedRoleProfile.skills) && renderLoadingPanel()}
          </div>
        </div>
      </ActionCard>
      {activeSkillsType && selectedRoleProfile && selectedRoleProfile.skills && (
        <DashboardModal
          title={`${activeSkillsType} skills`}
          titleCount={selectedRoleProfile.skills[activeSkillsType].length}
          isOpen={!!activeSkillsType}
          onClose={() => void setActiveSkillsType(null)}
        >
          {selectedRoleProfile.skills[activeSkillsType].length === 0 && (
            <p className="iq4-dashboard-modal__intro">No skills found</p>
          )}
          {selectedRoleProfile.skills[activeSkillsType].length > 0 && (
            <>
              <p className="iq4-dashboard-modal__intro">{activeSkillsType.description}</p>
              <DashboardList
                list={selectedRoleProfile.skills[activeSkillsType].map((skill) => ({
                  ...skill,
                  type: activeSkillsType,
                }))}
                type="skill"
              />
            </>
          )}
        </DashboardModal>
      )}
    </>
  );
};
