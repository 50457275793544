import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { ValidationMessage, Tooltip } from 'common/components';
import './TextInput.scss';

/**
 * TextInput form input, connected to RHF form context
 * @param validators
 * @param name
 * @param label
 * @param placeholder
 * @param tooltip
 * @param tooltipVariation
 * @returns {*}
 * @constructor
 */
export const TextInput = ({
  validators,
  id,
  name,
  label,
  placeholder,
  tooltip,
  tooltipVariation,
  isDisabled,
  exposeFormContext,
  triggerExternalValidation,
}) => {
  const { register, triggerValidation, formState, errors } = useFormContext();
  const touched = formState.touched[name];
  const dirty = formState.dirty;
  const hasError = errors[name];
  const isSubmitted = formState.isSubmitted;
  const showError = () => {
    if (exposeFormContext && !dirty) return false;

    return (touched || isSubmitted || triggerExternalValidation) && hasError;
  };

  return (
    <div className="iq4-text-input">
      {label && (
        <label className="iq4-text-input__label" htmlFor={name}>
          {label}
          {tooltip && <Tooltip message={tooltip} variation={tooltipVariation} />}
        </label>
      )}
      <div className="iq4-text-input__wrapper">
        <input
          id={id || name}
          name={name}
          disabled={isDisabled}
          ref={register({ ...validators })}
          placeholder={placeholder}
          type="text"
          className={`iq4-text-input__field ${
            !!hasError && showError() ? 'iq4-text-input__field--error' : ''
          }`}
          onChange={triggerValidation}
          aria-invalid={hasError ? 'true' : 'false'}
          aria-describedby={`${name}Error`}
        />
      </div>
      {showError() && <ValidationMessage id={`${name}Error`} message={hasError.message} />}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.bool,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  tooltip: PropTypes.node,
  tooltipVariation: PropTypes.string,
  exposeFormContext: PropTypes.bool,
  triggerExternalValidation: PropTypes.bool,
};
