import {
  SET_SKILLS_LOADER,
  SET_DEFAULT_LOADER,
  SET_SKILLS_UPDATE
} from '../../actionTypes';

// key = 'savingSkills'
export const toggleSkillsLoader = (key, value) => ({
  type: SET_SKILLS_LOADER,
  payload: { key, value }
});

export const toggleDefaultLoader = (key, data) => ({
  type: SET_DEFAULT_LOADER,
  payload: {
    key,
    ...data
  }
});

export const toggleSkillsUpdater = payload => ({
  type: SET_SKILLS_UPDATE,
  payload
});
