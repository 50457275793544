import React, { useState, useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { Star, StarEmpty, X, Person } from '../Icon/Icon';
import Button from '../Button/Button';
import { Pill } from '../Pill/Pill';
import { RecommendedCourses, RightArrow } from '../Icon/Icon';
import ToolsModal from '../ToolsModal/ToolsModal';
import CertificatesInfoModal from '../CertificatesInfoModal/CertificatesInfoModal';
import { Assessment } from '../Assessment/Assessment';
import { AssessmentInfoModal } from './AssessmentInfoModal/AssessmentInfoModal';
import { AverageInfoModal } from './AverageInfoModal/AverageInfoModal';
import { RemoveSkillConfirmModal } from './RemoveSkillConfirmModal/RemoveSkillConfirmModal';
import RecommendedCoursesCount from '../RecommendedCoursesCount/RecommendedCoursesCount';
import { COURSE_LEVELS_AND_PROFICIENCIES } from 'common/constants/courses';

/**
 * Renders a skill row within the skills table
 * @param {object} [tier4Assessments] - Keeps track of tier4 assessments { self: { [t1ID_t2ID_t3ID_t4ID]: Number } }
 * @param {boolean} [showKSAs] - Expand/Close skill to show ksas
 * @param {string} assessmentType - The type of assessment showing: 'SELF' | 'TESTED' | 'AVERAGE' | 'CERTIFIED'
 * @param {object} [skill] - skill data
 * @param {object[]} [tier4s] - tier4's list
 * @param {object[]} [tools] -list of tools data for skill
 * @param {object} [featureConfig] - config object specifying the active configurations for the skill
 * @param {function} [onChangeAssessmentDropdown] - get's called when the assessment dropdown was toggled
 * @param {function} [handleSkillProfUpdate] - get's called when assessment was changed on the tier3 skill
 * @param {function} [onProfChangeTier4] - get's called when assessment was changed on the tier4 skill
 * @param {function} [onRemove] - on skill remove
 * @param {function} [onToolsSave] - on tools save
 */
const Skill = ({
  tier4Assessments = {},
  showKSAs: showKSAsFromProps = false,
  assessmentType,
  skill = {},
  tier4s = [],
  tools = [],
  canRemoveT3 = true,
  highPriorityOnly = false,
  writeType = 'SELF',
  featureConfig: {
    includeTools,
    includeValidatedCredentials,
    includeOtherCredentials,
    includeRecommendedCourses,
    skillAssessmentTier,
    includeManagerAssessments,
  } = {
    includeTools: true,
    includeValidatedCredentials: true,
    includeOtherCredentials: true,
    includeRecommendedCourses: true,
    skillAssessmentTier: true,
    includeManagerAssessments: true,
  },
  onChangeAssessmentDropdown = () => {},
  handleSkillProfUpdate = () => {},
  onProfChangeTier4 = () => {},
  onRequestRecommendedCourses = () => {},
  onRemove = () => {},
  onToolsSave = () => {},
  ...props
}) => {
  const [showKSAs, changeShowKSAs] = useState(showKSAsFromProps);
  // modals
  const [showTools, changeShowTools] = useState(false);
  const [showAssessmentModal, changeShowAssessmentModal] = useState(false);
  const [showAverageModal, changeShowAverageModal] = useState(false);
  const [showRemoveSkillModal, changeShowRemoveSkillModal] = useState({
    value: false,
    type: null,
  });
  const [showCertificatesModal, changeShowCertificatesModal] = useState(false);

  const [filteredT4s, setFilteredT4s] = useState(getFilteredT4s(tier4s, assessmentType));

  const [averageAssessment, setAverageAssessment] = useState(
    getAverageAssessment(skill, tier4s, tier4Assessments),
  );

  const [hasCertifiedAssessments, setHasCertifiedAssessments] = useState(
    checkHasCertifiedAssessments(tier4s),
  );

  useEffect(() => {
    setHasCertifiedAssessments(checkHasCertifiedAssessments(tier4s));
  }, [tier4s]);

  useEffect(() => {
    const newAverageAssessment = getAverageAssessment(skill, tier4s, tier4Assessments);
    setAverageAssessment(newAverageAssessment);
  }, [skill, tier4s, tier4Assessments]);

  function getCourseLevelForKSA(tier4Assessments, assessmentType, skillMeta, ksa) {
    if (assessmentType === 'AVERAGE') return;

    const proficiency =
      tier4Assessments[assessmentType.toLowerCase()][
        `${skillMeta.t1Id}_${skillMeta.t2Id}_${skillMeta.t3Id}_${ksa.id}`
      ];

    return proficiency
      ? COURSE_LEVELS_AND_PROFICIENCIES.find((item) => item.proficiency === proficiency).level
      : COURSE_LEVELS_AND_PROFICIENCIES[0].level;
  }

  function getCourseLevelForCompetency(
    tier4Assessments,
    assessmentType,
    skillMeta,
    averageAssessment,
  ) {
    const prof = Math.floor(averageAssessment[assessmentType.toLowerCase()]);
    return prof ? COURSE_LEVELS_AND_PROFICIENCIES[prof - 1].level : null;
  }

  function getAverageAssessment(skill, tier4s, tier4Assessments) {
    const assessmentTypes = ['self', 'tested', 'manager', 'certified'];
    const initAssessmentMap = assessmentTypes.reduce((map, type) => {
      map[type] = { sum: 0, count: 0 };
      return map;
    }, {});

    let assessmentsMap = tier4s.reduce((map, t4) => {
      assessmentTypes.forEach((type) => {
        const assessment =
          tier4Assessments[type][
            `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${t4.id}`
          ];

        if (assessment) {
          map[type].sum += assessment;
          map[type].count++;
        }
      });
      return map;
    }, initAssessmentMap);

    // calculate averages
    assessmentTypes.forEach((type) => {
      // no assessments
      if (!assessmentsMap[type].count) {
        assessmentsMap[type] = null;
      } else {
        assessmentsMap[type] = assessmentsMap[type].sum / assessmentsMap[type].count;
      }
    });

    // add overall average to assessment map
    const averageMap = Object.keys(assessmentsMap).reduce(
      (map, key) => {
        if (assessmentsMap[key] !== null) {
          map.sum += assessmentsMap[key];
          map.count++;
        }

        return map;
      },
      {
        sum: 0,
        count: 0,
      },
    );
    const overallAverage = averageMap.count ? averageMap.sum / averageMap.count : null;
    assessmentsMap.average = overallAverage;

    return assessmentsMap;
  }

  const hasAddedTools = useMemo(() => checkIfAddedTools(tools), [tools]);

  // keep filtered t4's up to date
  useEffect(() => {
    const newFilteredSkills = getFilteredT4s(tier4s, assessmentType, highPriorityOnly);
    setFilteredT4s(newFilteredSkills);
  }, [tier4s, assessmentType, highPriorityOnly]);

  // showKSAs should work with props too
  useEffect(() => {
    changeShowKSAs(showKSAsFromProps);
  }, [showKSAsFromProps]);

  function checkIfAddedTools(tools) {
    return !!tools.find((t) => t.added);
  }

  // derive filtered t4s
  function getFilteredT4s(tier4s, assessmentType, highPriorityOnly) {
    return tier4s.filter((t4) => {
      // remove any roles skills with priority of na
      if (t4.includes.role && t4.priority === 'na') return false;

      // if highPriorityOnly variable for high priority skills is set, then only return high priority skills
      if (highPriorityOnly && t4.priority !== 'high') return false;

      if (assessmentType === 'SELF') {
        return t4.includes.role || t4.includes.additional;
      } else if (assessmentType === 'CERTIFIED') {
        return t4.includes.certified;
      } else if (assessmentType === 'MANAGER') {
        return writeType === 'MANAGER'
          ? t4.includes.role || t4.includes.additional
          : tier4Assessments.manager.hasOwnProperty(
              `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${t4.id}`,
            );
      } else if (assessmentType === 'TESTED') {
        return t4.includes.comtech || t4.includes.simspace || t4.includes.sans;
      } else if (assessmentType === 'AVERAGE') {
        return true;
      }

      // if it's a different type of assessment that isn't handled then don't include.
      return false;
    });
  }

  function checkHasCertifiedAssessments(tier4s = []) {
    return !!tier4s.find((t4) => t4.includes.certified);
  }

  const renderSkillLabelCol = () => {
    return (
      <div className="iq4-skills-card-body-col iq4-skills-card-body-col-skills iq4-passport-module-skill__label">
        <Button
          aria-label={`Expand KSA's for ${skill.value}`}
          aria-expanded={showKSAs ? 'true' : 'false'}
          data-testid="iq4-skill-card-skill-btn"
          type="link"
          onClick={() => void changeShowKSAs(!showKSAs)}
        >
          <span>{skill.value}</span>
        </Button>
        {false && writeType === 'SELF' && canRemoveT3 && !hasCertifiedAssessments && (
          <Button
            className="iq4-skills-module-remove-skill-btn"
            type="link"
            aria-label={`Remove ${skill.value} and associated KSA's`}
            onClick={() =>
              void changeShowRemoveSkillModal({
                value: true,
                type: skill,
              })
            }
          >
            Delete
          </Button>
        )}
      </div>
    );
  };

  const renderSkillToolsCol = () => {
    return (
      <div className="iq4-skills-card-body-col iq4-skills-card-body-col-tools iq4-passport-module-skill__tools">
        {!!tools.length && (
          <Button
            style={{
              backgroundColor: !hasAddedTools ? 'white' : null,
              border: !hasAddedTools ? '1px solid rgba(171, 171, 171, 1.00)' : null,
              color: !hasAddedTools ? 'rgba(171, 171, 171, 1.00)' : null,
            }}
            type="link"
            onClick={() => void changeShowTools(!showTools)}
          >
            <span>+</span>
          </Button>
        )}
        {/* {!tools.length && <small>No tools</small>} */}
      </div>
    );
  };

  const renderSkillAssessmentCol = () => {
    return (
      <div className="iq4-skills-card-body-col iq4-skills-card-body-col-assessments iq4-skills-card-body-col-assessments-values iq4-passport-module-skill__assessments">
        {assessmentType === 'AVERAGE' && (
          <React.Fragment>
            <Assessment
              displayOnly
              className="iq4-skills-card-t4-assessment-dropdown"
              value={averageAssessment.average}
            />
            <Button type="link" onClick={() => void changeShowAverageModal(!showAverageModal)}>
              <small>sources</small>
            </Button>
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderSkillCoursesCol = (tier, id, label, courseCount, level) => {
    return (
      <div className="iq4-skills-card-body-col iq4-skills-card-body-col-recommended-courses iq4-passport-module-skill__courses">
        {assessmentType === 'AVERAGE' && tier === 4 ? null : (
          <RecommendedCoursesCount
            courseCount={courseCount}
            level={level}
            tier={tier}
            id={id}
            label={label}
            onClick={(payload, e) => onRequestRecommendedCourses(payload, e)}
          />
        )}
      </div>
    );
  };

  const renderSkillPriorityCol = () => {
    return (
      <div className="iq4-skills-card-body-col iq4-skills-card-body-col-skill-priority iq4-passport-module-skill__skill_priority"></div>
    );
  };

  const renderSkillModals = () => {
    return (
      <React.Fragment>
        {showTools && (
          <ToolsModal
            isOpen={showTools}
            toggle={changeShowTools}
            tools={tools}
            skill={skill}
            onSave={(tools) => void onToolsSave(tools)}
          />
        )}

        {showAverageModal && (
          <AverageInfoModal
            isOpen={showAverageModal}
            toggle={changeShowAverageModal}
            assessments={{ tier3: skill, tier4s }}
          >
            {() => (
              <div className="skills-assessments-modal">
                <div>
                  <p>Self Assessment</p>
                  <div>
                    {averageAssessment.self !== null ? (
                      averageAssessment.self
                    ) : (
                      <small>Assess your skills to see your average assessment</small>
                    )}
                  </div>
                </div>

                {includeValidatedCredentials && (
                  <div>
                    <p>Tested Assessment</p>
                    <div>
                      {averageAssessment.tested !== null ? (
                        averageAssessment.tested
                      ) : (
                        <small>No tested assessments available</small>
                      )}
                    </div>
                  </div>
                )}

                {includeOtherCredentials && (
                  <div>
                    <p>Certified Assessment</p>
                    <div>
                      {averageAssessment.certified !== null ? (
                        averageAssessment.certified
                      ) : (
                        <small>No certified assessments available</small>
                      )}
                    </div>
                  </div>
                )}

                {includeManagerAssessments && (
                  <div>
                    <p>Manager Assessment</p>
                    <div>
                      {averageAssessment.manager !== null ? (
                        averageAssessment.manager
                      ) : (
                        <small>No manager assessments available</small>
                      )}
                    </div>
                  </div>
                )}

                {
                  <div>
                    <p>Average Assessment</p>
                    <div>
                      {averageAssessment.average !== null ? (
                        averageAssessment.average
                      ) : (
                        <small>Assess your skills to see your an overall average</small>
                      )}
                    </div>
                  </div>
                }
              </div>
            )}
          </AverageInfoModal>
        )}

        {showAssessmentModal && (
          <AssessmentInfoModal
            isOpen={showAssessmentModal}
            toggle={changeShowAssessmentModal}
            assessments={{ tier3: skill, tier4s }}
          />
        )}

        {showRemoveSkillModal.value && (
          <RemoveSkillConfirmModal
            isOpen={showRemoveSkillModal.value}
            toggle={() => void changeShowRemoveSkillModal({ value: false, type: null })}
            skill={showRemoveSkillModal.type}
            onRemove={(skill) => {
              if (skill) {
                onRemove([skill]);
              } else {
                onRemove();
              }
              changeShowRemoveSkillModal({ value: false, type: null });
            }}
            assessments={{ tier3: skill, tier4s }}
          />
        )}

        {showCertificatesModal && (
          <CertificatesInfoModal
            isOpen={showCertificatesModal}
            toggle={changeShowCertificatesModal}
            assessments={tier4s}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {!!filteredT4s.length && (
        <React.Fragment>
          <div
            className="iq4-skill-card-skill iq4-skills-card-body-row iq4-passport-module-skill"
            {...props}
          >
            {/* {renderSkillLabelCol()} */}

            {renderSkillPriorityCol()}

            {includeTools && renderSkillToolsCol()}

            {renderSkillAssessmentCol()}

            {includeRecommendedCourses &&
              renderSkillCoursesCol(
                3, // tier
                skill.compId, // id
                skill.value, // label
                skill.course, // coursesCount
                getCourseLevelForCompetency(
                  tier4Assessments,
                  assessmentType,
                  skill.meta,
                  getAverageAssessment(skill, tier4s, tier4Assessments),
                ),
              )}
          </div>

          {showKSAs &&
            filteredT4s.map((sk4) => (
              <div
                key={sk4.value}
                className="iq4-skill-card-skill iq4-skill-card-skill--t4 iq4-skills-card-body-row iq4-passport-module-skill"
              >
                <div className="iq4-skills-card-body-col iq4-skills-card-body-col-skills iq4-passport-module-skill__label">
                  <span>{sk4.value}</span>
                  {writeType === 'SELF' &&
                    canRemoveT3 &&
                    !tier4Assessments.certified.hasOwnProperty(
                      `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${sk4.id}`,
                    ) && (
                      <Button
                        className="iq4-skills-module-remove-skill-btn"
                        type="link"
                        aria-label={`Remove ${skill.value} and associated KSA's`}
                        onClick={() =>
                          void changeShowRemoveSkillModal({
                            value: true,
                            type: sk4,
                          })
                        }
                      >
                        Delete
                      </Button>
                    )}
                </div>
                <div className="iq4-skills-card-body-col iq4-skills-card-body-col-skill-priority iq4-passport-module-skill__skill_priority">
                  {sk4.priority && (
                    <>
                      <Pill type={sk4.priority}>{sk4.priority}</Pill>
                      <span
                        className={`iq4-passport-module-skill__skill_priority-pill-info iq4-passport-module-skill__skill_priority-pill-info--${sk4.priority}`}
                      >
                        {sk4.priority === 'high' ? 'Required' : 'Optional'}
                      </span>
                    </>
                  )}
                </div>
                {false && (
                  <div className="iq4-skills-card-body-col iq4-skills-card-body-col-assessments iq4-skills-card-body-col-assessments-values iq4-passport-module-skill__assessments">
                    {skillAssessmentTier === 'TIER_4' && (
                      <>
                        {assessmentType === 'SELF' && (
                          <Assessment
                            displayOnly={writeType !== 'SELF'}
                            key={`${skill.id}_${sk4.id}`}
                            className="iq4-skills-card-t4-assessment-dropdown"
                            value={
                              tier4Assessments.self[
                                `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${sk4.id}`
                              ]
                            }
                            handleSkillProfUpdate={(value) => {
                              onProfChangeTier4(value, sk4);
                            }}
                          />
                        )}

                        {assessmentType === 'CERTIFIED' && (
                          <Assessment
                            displayOnly
                            key={`${skill.id}_${sk4.id}`}
                            className="iq4-skills-card-t4-assessment-dropdown"
                            value={
                              tier4Assessments.certified[
                                `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${sk4.id}`
                              ]
                            }
                          />
                        )}

                        {assessmentType === 'TESTED' && (
                          <Assessment
                            displayOnly
                            key={`${skill.id}_${sk4.id}`}
                            className="iq4-skills-card-t4-assessment-dropdown"
                            value={
                              tier4Assessments.tested[
                                `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${sk4.id}`
                              ]
                            }
                          />
                        )}

                        {assessmentType === 'MANAGER' && (
                          <>
                            <Assessment
                              displayOnly={writeType !== 'MANAGER'}
                              key={`${skill.id}_${sk4.id}`}
                              className="iq4-skills-card-t4-assessment-dropdown"
                              value={
                                tier4Assessments.manager[
                                  `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${sk4.id}`
                                ]
                              }
                              handleSkillProfUpdate={(value) => {
                                onProfChangeTier4(value, sk4);
                              }}
                            />
                            {tier4Assessments.manager[
                              `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${sk4.id}_manager`
                            ] && (
                              <span
                                data-tip
                                data-for="iq4-skills-card-t4-assessment-manager-tooltip"
                                className="iq4-skills-card-t4-assessment-dropdown__manager-tooltip"
                              >
                                <Person />
                                <ReactTooltip
                                  id="iq4-skills-card-t4-assessment-manager-tooltip"
                                  effect="solid"
                                  place="bottom"
                                >
                                  <div className="iq4-skills-card-t4-assessment-dropdown__manager-tooltip-container">
                                    <span className="iq4-skills-card-t4-assessment-dropdown__manager-tooltip ">
                                      <Person />
                                    </span>{' '}
                                    <span>Manager Assessment</span>
                                  </div>

                                  <div className="iq4-skills-card-t4-assessment-dropdown__manager-details">
                                    {`${
                                      tier4Assessments.manager[
                                        `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${sk4.id}_manager`
                                      ].firstName
                                    } ${
                                      tier4Assessments.manager[
                                        `${skill.meta.t1Id}_${skill.meta.t2Id}_${skill.meta.t3Id}_${sk4.id}_manager`
                                      ].lastName
                                    }`}
                                  </div>
                                </ReactTooltip>
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
                {includeRecommendedCourses &&
                  renderSkillCoursesCol(
                    4, // tier, id, label, courseCount, level
                    sk4.scId, // id
                    sk4.value, // label
                    sk4.course, // courseCount
                    getCourseLevelForKSA(
                      tier4Assessments,
                      assessmentType,
                      skill.meta,
                      sk4,
                      averageAssessment,
                    ), // level
                  )}
              </div>
            ))}

          {renderSkillModals()}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Skill;
