import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'common/components/Button';
import './Notifications.scss';

export const Notifications = ({ notifications, onClose }) => {
  const history = useHistory();

  const handleAction = (notification) => {
    history.push(notification.actionRoute);
    onClose(notification);
  };
  return (
    <div className="iq4-notifications">
      <div className="iq4-notifications__heading">Notifications</div>
      <ul className="iq4-notifications__list">
        {notifications &&
          notifications.map((notification) => (
            <li className="iq4-notifications__list-item">
              <span>{notification.message}</span>
              <Button
                className="iq4-notifications__list-button"
                variation="ghost"
                onClick={() => handleAction(notification)}
              >
                {notification.actionLabel}
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
};
