import React from 'react';
import { SimpleIcon } from 'common/components';
import './Toast.scss';

export const Toast = ({ title, onClose, children }) => {
  return (
    <div className="iq4-toast">
      <SimpleIcon onClick={onClose} className="iq4-toast--close" name="close" />
      <div className="iq4-toast--title">{title}</div>
      <div className="iq4-toast--content">{children}</div>
    </div>
  );
};
