import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { RecommendedCourses } from '../Icon/Icon';

export const RecommendedCoursesModal = ({ isOpen, toggle, courses }) => {
  const goToLearningResource = (url) => {
    window.open(url, '_blank');
  }
  return (
    <Modal
      className="recommended-courses-modal"
      isOpen={isOpen}
      contentLabel={`Average assessment overview for`}
      onRequestClose={() => void toggle(false)}
      handleClose={() => toggle(false)}
      renderHeader={() => (
        <React.Fragment>
          <h3 className="recommended-courses-modal-title">Courses</h3>
          <p className="recommended-courses-modal-intro">To get educational support learn more about these available programmes:</p>
        </React.Fragment>
      )}
    >
        {() => (
          <ul className="recommended-courses-modal-list">
            {courses.map(course => (
              <li className="recommended-courses-modal-list-item">
                <span className="recommended-courses-modal-list-item--label">
                  <RecommendedCourses /> {course.label}
                </span>
                <span className="recommended-courses-modal-list-item--source">Source: {course.source}</span>
                {course.duration && (
                  <span className="recommended-courses-modal-list-item--duration">Duration: {course.duration} hours</span>
                )}
                <span className="recommended-courses-modal-list-item--level">Level: {course.level.join(',')}</span>
                {course.audience && course.audience.length > 0 && (
                  <span className="recommended-courses-modal-list-item--audience">Audience: {course.audience.join(',')}</span>
                )}
                <Button className="recommended-courses-modal-list-item--learn-more" type="outline" onClick={() => void goToLearningResource(course.url)}>Learn More</Button>
              </li>  
            ))}
          </ul>
        )}
    </Modal>
  );
};

export default RecommendedCoursesModal;