import React from 'react';
import './DashboardSkill.scss';

export const DashboardSkill = ({ skill, index }) => {
  const renderKSAs = (index, ksas) => {
    return ksas.map((ksa, i) => {
      return (
        <li className="iq4-compass-skill__item-list__item" key={`ksa-${index}-${ksa.id}-${i}`}>
          {ksa.label}
        </li>
      );
    });
  };

  const renderRelatedRoles = (index, relatedRoles) => {
    return relatedRoles.map((relatedRole, i) => {
      return (
        <li
          className="iq4-compass-skill__item-list__item"
          key={`relatedRole-${index}-${relatedRole.id}-${i}`}
        >
          {relatedRole.label}
        </li>
      );
    });
  };

  const renderCourses = (index, courses) => {
    return courses.map((course, i) => {
      return (
        <li
          className="iq4-compass-skill__item-list__item"
          key={`course-${index}-${course.id}-${i}`}
        >
          {course.label}
        </li>
      );
    });
  };

  return (
    <div className="iq4-compass-skill">
      {skill.ksas && (
        <>
          <div className="iq4-compass-skill__item">
            <h4 className="iq4-compass-skill__item-heading">KSAs</h4>
            <ul className="iq4-compass-skill__item-list">{renderKSAs(index, skill.ksas)}</ul>
          </div>
        </>
      )}
      {skill.relatedRoles && (
        <div className="iq4-compass-skill__item">
          <h4 className="iq4-compass-skill__item-heading">Related Roles</h4>
          <ul className="iq4-compass-skill__item-list">
            {renderRelatedRoles(index, skill.relatedRoles)}
          </ul>
        </div>
      )}
      {skill.courses && (
        <div className="iq4-compass-skill__item">
          <h4 className="iq4-compass-skill__item-heading">Related Courses</h4>
          <ul className="iq4-compass-skill__item-list">{renderCourses(index, skill.courses)}</ul>
        </div>
      )}
    </div>
  );
};
