import { useState, useEffect } from 'react';
import { useBreakpoints } from 'common/hooks/useBreakpoints';

export const useIsMobile = (checkFnc) => {
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);

  function getIsMobile(breakpoint) {
    return checkFnc ? checkFnc(breakpoint) : breakpoint === 'xs';
  }

  return isMobile;
};
