import { SET_CERTS_LIST, SET_MY_CERTS } from '../../actionTypes';

const initialState = {
  myCerts: [],
  list: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CERTS_LIST:
      return { ...state, list: action.payload };
    case SET_MY_CERTS:
      return { ...state, myCerts: action.payload };
    default:
      return state;
  }
};

export default reducer;
