import React, { useState, useEffect, useMemo } from 'react';
import { PathwaysPanelHeader } from '../PathwaysPanelHeader';
import { BAR_IDS } from '../';
import { PathwaysSkillList } from '../PathwaysSkillList';
import './RoleSkillsInfoPanel.scss';
/**
 * A component that shows the breakdown of a role's skills
 * @param {object[]} userSkills - An array containing the users skills that aren't required by the role
 * @param {object[]} overlapSkills - An array containing the overap of the user's skills and the role skills
 * @param {object[]} roleSkills - An array containing the role skills the user doesn't have yet
 * @param {string} [activeBar] - An id representing which bar is active
 * @param {function} [onBackClick] - Handle back click
 * @param {function} [onActiveBarChange] - Notify parent when active bar has changed
 * @param {function} [onSkillClick] - Handle skills click
 */
export const RoleSkillsInfoPanel = ({
  userSkills,
  overlapSkills,
  roleSkills,
  activeBar,
  onBackClick = () => {},
  onSkillClick = () => {},
  onActiveBarChange = () => {},
  onSkillToggleClick = () => {},
}) => {
  const [currentActiveBar, setCurrentActiveBar] = useState(activeBar);
  const currentSkillsInfo = useMemo(() => {
    function getCurrentSkillsListInfo(barID, userSkills, overlapSkills, roleSkills) {
      const skillsMap = {
        [BAR_IDS.UNMATCHED_SKILLS]: { skills: userSkills, title: 'Unmatched Skills' },
        [BAR_IDS.MISSING_SKILLS]: { skills: roleSkills, title: 'Missing Skills' },
        [BAR_IDS.MATCHED_SKILLS]: { skills: overlapSkills, title: 'Matched Skills' },
      };

      return skillsMap[barID];
    }

    return getCurrentSkillsListInfo(currentActiveBar, userSkills, overlapSkills, roleSkills);
  }, [currentActiveBar, userSkills, overlapSkills, roleSkills]);

  // update the current active bar if parent updates it.
  useEffect(() => {
    setCurrentActiveBar(activeBar);
  }, [activeBar]);

  if (!currentSkillsInfo) {
    return null;
  }

  return (
    <div className="iq4-role-skills-info-panel">
      <PathwaysPanelHeader
        title={
          <>
            <span className="iq4-pathway-detail__header-title">{currentSkillsInfo.title}</span>{' '}
            <span className="iq4-pathway-detail__header-title-pill">
              {currentSkillsInfo.skills.length}
            </span>
          </>
        }
        onLeftClick={onBackClick}
        className="iq4-role-skills-info-panel__header"
      />

      {/* <SkillsAnalytics
        analytics={{
          userSkills: userSkills.length,
          overlapSkills: overlapSkills.length,
          roleSkills: roleSkills.length,
        }}
        onClickSegment={(segment) => handleActiveBarChange(segment.id)}
        withLegend={false}
      /> */}
      {currentActiveBar && (
        <PathwaysSkillList
          className="iq4-role-skills-info-panel__skill-list"
          title={currentActiveBar !== 'MISSING_SKILLS' ? 'Matched Skills' : 'Missing Skills'}
          skills={currentSkillsInfo.skills}
          onClick={onSkillClick}
          addedSkills={currentActiveBar !== 'MISSING_SKILLS'}
          onSkillToggleClick={onSkillToggleClick}
        />
      )}
    </div>
  );
};
