import React, { useRef, useState } from 'react';
import Button from '../../Button/Button';
import { Checkbox } from '../../Checkbox/Checkbox';
import useCloseOnClick from '../useCloseOnClick';

const SkillsBrowseBar = ({
  categories,
  onChange,
  skillTier1,
  tier1s,
  onTier1Select
}) => {
  const compRef = useRef(null);
  const [activeDropdown, toggleActiveDropdown] = useCloseOnClick(compRef);
  const [selectedSkillTier1, selectSkillTier1] = useState({});
  const [selectedTier2, selectTier2] = useState({});
  const [selectedTier3, selectTier3] = useState({});
  const [currentTier3List, setCurrentTier3List] = useState([]);

  const hasTier2s = !!(
    selectSkillTier1.id &&
    categories[0].skillTier2List[0] &&
    categories[0].skillTier2List[0].skilltier2.id
  );

  return (
    <div className="iq4-skills-search-browse-bar" ref={compRef}>
      <p className="iq4-skills-search-browse-bar__title">
        Browse for skills to add to your profile by competency
      </p>
      <Button
        aria-expanded={activeDropdown ? 'true' : 'false'}
        className="iq4-skills-search-browse-btn"
        type="secondary"
        onClick={() => void toggleActiveDropdown((current) => !current)}
      >
        Select Skills{' '}
        {skillTier1.id !== null ? `within ${skillTier1.value}` : ''}
        {!activeDropdown && (
          <i className="material-icons" role="presentation" aria-hidden="true">
            keyboard_arrow_down
          </i>
        )}
        {activeDropdown && (
          <i className="material-icons" role="presentation" aria-hidden="true">
            keyboard_arrow_up
          </i>
        )}
      </Button>

      {activeDropdown && (
        <div className="iq4-skills-browse-bar-dropdown">
          {skillTier1.id === null && (
            <div className="iq4-skills-browse-list">
              {tier1s.map((t1) => (
                <button
                  aria-label={`${t1.label} ,skill tier 1`}
                  aria-expanded={
                    selectedSkillTier1.id === t1.id ? 'true' : 'false'
                  }
                  key={t1.id}
                  onClick={() => {
                    selectSkillTier1({
                      id: t1.id,
                      value: t1.label
                    });
                    onTier1Select({
                      id: t1.id,
                      value: t1.label
                    });

                    setCurrentTier3List(
                      !hasTier2s
                        ? categories[0].skillTier2List[0].skillTier3List
                        : []
                    );
                  }}
                  className={
                    selectedSkillTier1.id === t1.id
                      ? 'iq4-skills-browse-active'
                      : null
                  }
                >
                  {t1.label}
                </button>
              ))}
            </div>
          )}

          {(skillTier1.id !== undefined ||
            (skillTier1.id === undefined &&
              selectedSkillTier1.id !== undefined)) &&
            hasTier2s && (
              <div className="iq4-skills-browse-list">
                {categories[0] &&
                  categories[0].skillTier2List.map((sk2) => (
                    <button
                      aria-label={`${sk2.skillTier2.label} ,skill tier 2`}
                      tabIndex={skillTier1.id !== undefined ? '0' : '-1'}
                      aria-expanded={
                        selectedTier2.id === sk2.skillTier2.id
                          ? 'true'
                          : 'false'
                      }
                      onClick={() => {
                        selectTier2(sk2.skillTier2);
                        setCurrentTier3List(sk2.skillTier3List);
                      }}
                      key={sk2.skillTier2.id}
                      className={
                        selectedTier2.id === sk2.skillTier2.id
                          ? 'iq4-skills-browse-active'
                          : null
                      }
                    >
                      {sk2.skillTier2.label}
                    </button>
                  ))}
              </div>
            )}

          {(selectedTier2 || !hasTier2s) && (
            <ul className="iq4-skills-browse-list">
              {categories &&
                categories[0] &&
                categories[0].skillTier2List[0] &&
                categories[0].skillTier2List[0] &&
                categories[0].skillTier2List[0].skillTier3List.map(
                  ({ skillTier3 }) => (
                    <li key={skillTier3.id}>
                      <label>
                        <Checkbox
                          aria-label={`${skillTier3.label} ,skill tier 3`}
                          style={{ marginRight: '7px' }}
                          type="checkbox"
                          checked={!!selectedTier3[skillTier3.id]}
                          onChange={(e) => {
                            const newVal = e.target.checked;
                            selectTier3((currentState) => ({
                              ...currentState,
                              [skillTier3.id]: newVal
                            }));

                            onChange({
                              skillTier1:
                                skillTier1.id !== null
                                  ? skillTier1
                                  : selectedSkillTier1,
                              skillTier2: selectedTier2,
                              skillTier3: skillTier3,
                              value: newVal
                            });
                          }}
                        />
                        {skillTier3.label}
                      </label>
                    </li>
                  )
                )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default SkillsBrowseBar;
