import React from 'react';
import { connect } from 'react-redux';

import SkillsList from './SkillsList/SkillsList';

const SkillsModule = ({ skills }) => {
  return (
    <SkillsList skills={skills} />
  );
};


const mapStateToProps = (state) => {
  const skills = getSkills(state);

  return {
    skills,
  };
};

// connect redux state to compoent
export default connect(mapStateToProps)(SkillsModule);

// helper function to get skills from redux state.
function getSkills(state) {
  return state.skills;
} // end getSkills