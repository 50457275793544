import React, { useState, useEffect, useMemo, useReducer } from 'react';
import Button from '../../Button/Button';
import { RightArrow } from '../../Icon/Icon';
import { Checkbox } from '../../Checkbox/Checkbox';

const initialState = {}; // [{ sk1: sk2: sk3: sk4: }]
const ACTIONS = {
  UPDATE_SELECTED: 'UPDATE_SELECTED',
  REMOVE_SKILLS: 'REMOVE_SKILLS',
  TOGGLE_SELECT_ALL: 'TOGGLE_SELECT_ALL',
};

// payload = { sk1, sk2, sk3, sk4 }
const updateSelectedAction = (payload) => {
  return {
    type: ACTIONS.UPDATE_SELECTED,
    payload,
  };
};

const removeSkills = (payload) => {
  return {
    type: ACTIONS.REMOVE_SKILLS,
    payload,
  };
};

const toggleAllAction = (payload) => {
  return {
    type: ACTIONS.TOGGLE_SELECT_ALL,
    payload,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_SELECTED:
      const { sk1, sk2, sk3, sk4 } = action.payload;
      const id = `${sk1.id}_${sk2.id}_${sk3.id}_${sk4.id}`;

      // remove skill if in state
      if (state[id]) {
        const { [id]: _, ...newState } = state;
        return newState;
      }

      // add new skill, if not already present
      return { ...state, [id]: action.payload };
    case ACTIONS.REMOVE_SKILLS:
      let newState = { ...state };
      action.payload.forEach((key) => {
        const { [key]: _, ...newStateWithRemovedKey } = newState;
        newState = newStateWithRemovedKey;
      });

      return { ...newState };
    case ACTIONS.TOGGLE_SELECT_ALL:
      const skills = action.payload;
      // uncheck all
      if (Object.keys(state).length === skills.length) {
        return {};
      }

      // check all the skills from payload

      const skillsMap = skills.reduce((map, skill) => {
        const { sk1, sk2, sk3, sk4 } = skill;
        const id = `${sk1.id}_${sk2.id}_${sk3.id}_${sk4.id}`;
        map[id] = skill;
        return map;
      }, {});

      return skillsMap;
    default:
      console.warn("Reducer doesn't regognize that action");
      break;
  }
}; // end reducer

const useSkillSelectReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
}; // end useSkillSelectReducer

const SkillsSearchResults = ({ skills, searchTerm, skillTier1, onSave, savingLoader }) => {
  const isGlobalResults = skills.length && skills[0].skillTier1;
  const [showAll, toggleShowAll] = useState(true);
  const [showKSAsMap, changeShowKSAsMap] = useState({});
  const [searchTermPerResult, setSearchTermPerResult] = useState(searchTerm);
  const memoizedTier3Ids = useMemo(() => getTier3Ids(skills), [skills]);
  const [state, dispatch] = useSkillSelectReducer();
  const [canSelectCount, setCanSelectCount] = useState(null);

  // if state has added skills that are not part of the skills results, remove them
  useEffect(() => {
    if (Object.keys(state).length) {
      const skillsToRemove = findAndRemoveSkills();
      if (skillsToRemove.length) {
        dispatch(removeSkills(skillsToRemove));
      }
    } // end if

    function findAndRemoveSkills() {
      // const skillsToRemove = [];
      const foundSkills = [];
      for (let skillKey in state) {
        skills.forEach((st2) => {
          const t2id = st2.skillTier2.id;
          st2.skillTier3List.forEach((st3) => {
            const t3id = st3.skillTier3.id;
            st3.skillTier4List.forEach((st4) => {
              const t4id = st4.skillTier4.id;
              const skillKey = `${skillTier1.id}_${t2id}_${t3id}_${t4id}`;

              if (state[skillKey]) {
                foundSkills.push(skillKey);
              } // end outer if
            }); // end st4
          }); // end st3
        }); // end st2
      } // end for in

      return Object.keys(state).filter((k) => foundSkills.indexOf(k) === -1);
    } // end findAndRemoveSkills
  }, [skills]);

  // only update the search term in the ui when the skills results update.
  useEffect(() => {
    setSearchTermPerResult(searchTerm);
  }, [skills]);

  // show/hide all ksas
  useEffect(() => {
    const showKSASs = memoizedTier3Ids.reduce((map, st3ID) => {
      map[st3ID] = showAll;
      return map;
    }, {});

    changeShowKSAsMap(showKSASs);
  }, [showAll]);

  const handleSave = () => {
    onSave(Object.keys(state).map((key) => state[key]));
  };

  const handleSelectAll = () => {
    let allSkills = [];
    if (isGlobalResults) {
      skills.forEach((sk1) => {
        allSkills = allSkills.concat(skillsToFlatArray(sk1.skillTier2List, sk1.skillTier1));
      });
    } else {
      allSkills = allSkills.concat(skillsToFlatArray(skills, skillTier1));
    }

    setCanSelectCount(allSkills.length);
    dispatch(toggleAllAction(allSkills));

    function skillsToFlatArray(skills, resultSkillTier1) {
      if (skillTier1.id && skillTier1.id != resultSkillTier1.id) {
        return [];
      }

      let skillsToToggle = [];
      skills.forEach((st2) => {
        st2.skillTier3List.forEach((st3) => {
          st3.skillTier4List.forEach((st4) => {
            if (st4.skillAdded) return;

            skillsToToggle.push({
              sk1: {
                id: resultSkillTier1.id,
                label: resultSkillTier1.value || resultSkillTier1.label,
              },
              sk2: {
                id: st2.skillTier2.id,
                label: st2.skillTier2.label,
              },
              sk3: {
                id: st3.skillTier3.id,
                label: st3.skillTier3.label,
              },
              sk4: {
                id: st4.skillTier4.id,
                label: st4.skillTier4.label,
              },
              meta: {
                compId: st3.compId || (st4.competence && st4.competence.id) || null,
                scId: st4.scId || (st4.skillCategory && st4.skillCategory.id) || null,
              },
            });
          });
        });
      });

      return skillsToToggle;
    }
  };

  const renderSkills = (skills, skillTier1) => {
    return (
      <React.Fragment>
        {/* <h3>{skillTier1.value || skillTier1.label}</h3> */}
        <hr />
        {skills.map((st2) => (
          <div key={st2.skillTier2.id}>
            {/* <h4>{st2.skillTier2.label}</h4> */}

            {st2.skillTier3List.map((st3) => (
              <div key={st3.skillTier3.id}>
                {/* <div>
                  <Button
                    aria-expanded={showKSAsMap[st3.skillTier3.id] ? 'true' : 'false'}
                    type="link"
                    onClick={() =>
                      void changeShowKSAsMap((state) => ({
                        ...state,
                        [st3.skillTier3.id]: !state[st3.skillTier3.id],
                      }))
                    }
                  >
                    <RightArrow
                      style={{
                        transform: showKSAsMap[st3.skillTier3.id] ? 'rotate(90deg)' : 'none',
                      }}
                    />
                    {st3.skillTier3.label}
                  </Button>
                </div> */}

                {
                  <ul>
                    {st3.skillTier4List.map((st4) => (
                      <li key={st4.skillTier4.id}>
                        <label
                          className={`iq4-skills-module-results__li ${
                            st4.skillAdded ? 'iq4-skills-module-results__li--disabled' : ''
                          }`}
                        >
                          <Checkbox
                            disabled={st4.skillAdded}
                            type="checkbox"
                            checked={
                              st4.skillAdded ||
                              !!state[
                                `${skillTier1.id}_${st2.skillTier2.id}_${st3.skillTier3.id}_${st4.skillTier4.id}`
                              ]
                            }
                            onChange={() =>
                              void dispatch(
                                updateSelectedAction({
                                  sk1: {
                                    id: skillTier1.id,
                                    label: skillTier1.value || skillTier1.label,
                                  },
                                  sk2: {
                                    id: st2.skillTier2.id,
                                    label: st2.skillTier2.label,
                                  },
                                  sk3: {
                                    id: st3.skillTier3.id,
                                    label: st3.skillTier3.label,
                                  },
                                  sk4: {
                                    id: st4.skillTier4.id,
                                    label: st4.skillTier4.label,
                                  },
                                  meta: {
                                    compId:
                                      st3.compId || (st4.competence && st4.competence.id) || null,
                                    scId:
                                      st4.scId ||
                                      (st4.skillCategory && st4.skillCategory.id) ||
                                      null,
                                  },
                                }),
                              )
                            }
                          />
                          <span className="iq4-skills-module-results__li-label">
                            {st4.skillTier4.label}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                }
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  };

  function getTier3Ids(skills) {
    const tier3Ids = [];

    if (isGlobalResults) {
      skills.forEach((sk1) => {
        sk1.skillTier2List.forEach((st2) => {
          st2.skillTier3List.forEach((st3) => {
            tier3Ids.push(st3.skillTier3.id);
          });
        });
      });
      return tier3Ids;
    }

    skills.forEach((st2) => {
      st2.skillTier3List.forEach((st3) => {
        tier3Ids.push(st3.skillTier3.id);
      });
    });

    return tier3Ids;
  }

  return (
    <section className="iq4-skills-module-search-results">
      <button className="iq4-skills-module-search-results__select-all" onClick={handleSelectAll}>
        {Object.keys(state).length === canSelectCount ? 'Deselect All' : 'Select All'}
      </button>
      {!!skills.length && (
        <div className="iq4-skills-search-result-list">
          {/* <Button
            className="iq4-skills-search-result-list__toggle-expand"
            type="link"
            onClick={() => void toggleShowAll(!showAll)}
            aria-expanded={showAll ? 'true' : 'false'}
          >
            {showAll ? 'Close all' : 'Expand all'}
          </Button> */}

          {!isGlobalResults && renderSkills(skills, skillTier1)}

          {isGlobalResults &&
            skills
              .filter((sk1) => {
                if (!skillTier1.id) return true;

                // not global search
                return skillTier1.id == sk1.skillTier1.id;
              })
              .map((sk1) => {
                return (
                  <React.Fragment key={sk1.skillTier1.id}>
                    {renderSkills(sk1.skillTier2List, sk1.skillTier1)}
                  </React.Fragment>
                );
              })}
        </div>
      )}

      <div className="iq4-skills-module-search-results-footer">
        <Button type="secondary" disabled={savingLoader === 'LOADING'} onClick={handleSave}>
          Add skills and assess
          {(savingLoader === 'LOADING' && <span>&hellip;</span>) || null}
        </Button>
      </div>
    </section>
  );
};

export default SkillsSearchResults;
