import React, { useState } from 'react';
import { SimpleIcon, DashboardModal, DashboardList } from 'common/components';
import './UserProfilePathways.scss';

export const UserProfilePathways = ({ profile, pathways }) => {
  const [isPathwaysModalOpen, setIsPathwaysModalOpen] = useState(false);
  const NUMBER_PATHWAYS_TO_SHOW = 2;

  return (
    <>
      <ul className="iq4-user-profile-pathways">
        {pathways.map((pathway, index) => {
          if (index > NUMBER_PATHWAYS_TO_SHOW - 1) return null;

          return (
            <li key={pathway.id} className="iq4-user-profile-pathways--item">
              <span>{pathway.label}</span>
            </li>
          );
        })}
      </ul>
      {pathways.length > NUMBER_PATHWAYS_TO_SHOW && (
        <button
          className="iq4-user-profile-pathways--see-more"
          onClick={() => setIsPathwaysModalOpen(!isPathwaysModalOpen)}
        >
          See all Pathways
          <SimpleIcon className="arrow" name="arrow" />
        </button>
      )}

      <DashboardModal
        titleIcon="pathwaysGraph"
        title={`${profile.firstName} ${profile.lastName} - Career Pathways`}
        titleCount={pathways && pathways.length}
        isOpen={isPathwaysModalOpen}
        onClose={() => setIsPathwaysModalOpen(false)}
      >
        <DashboardList
          isExpandable={true}
          list={pathways}
          type="pathway"
          institution={`${profile.firstName} ${profile.lastName}`}
        />
      </DashboardModal>
    </>
  );
};
