import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '@assets/svg/footer-logo.svg';
import { ReactComponent as LogoDark } from '@assets/svg/logo.svg';
import { selectFeatures } from 'common/store/features/config/selectors';

import './Footer.scss';

const date = new Date().getFullYear();

const organizationUrl = process.env.REACT_APP_ORGANIZATION_URL;
const organizationName = process.env.REACT_APP_ORGANIZATION_LABEL;

const footerItems = [
  {
    url: process.env.REACT_APP_FOOTER_ITEM_1_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_1_LABEL,
  },
  {
    url: process.env.REACT_APP_FOOTER_ITEM_2_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_2_LABEL,
  },
  {
    url: process.env.REACT_APP_FOOTER_ITEM_3_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_3_LABEL,
  },
];

const DefaultFooter = () => (
  <div className="footer-container">
    <div className="footer">
      <div className="iq4-footer-links no-print">
        <div className="item logo">
          <a href={organizationUrl} target="_blank" rel="noopener noreferrer">
            <Logo className="iq4-nsc-logo" />
          </a>
        </div>
        <div className="item copyright">
          &copy; {`${date} ${organizationName}. All Rights Reserved.`}
        </div>
        <div className="item footer-links">
          {footerItems.map((item, index) => (
            <>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.label}
                <span className="sr-only">This will open in a new tab.</span>
              </a>
              {index < footerItems.length - 1 && <span className="divider">|</span>}
            </>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const MiniFooter = () => {
  return (
    <footer className="iq4-mini-footer">
      <LogoDark className="iq4-mini-footer__logo" />
      <p className="iq4-mini-footer__copy">&copy; {date} iQ4. All Rights Reserved</p>
    </footer>
  );
};

const MinimalFooter = ({ className, ...props }) => {
  return (
    <footer
      className={`
      iq4-footer ${className ? className : ''}
    `}
      {...props}
    >
      <p className="iq4-footer__text">&copy; 2020 iQ4</p>
    </footer>
  );
};

const FOOTER_TYPES = {
  DEFAULT: DefaultFooter,
  MINI: MiniFooter,
  MINIMAL: MinimalFooter,
};
export const Footer = (props) => {
  const { footerType } = useSelector(selectFeatures);

  if (!footerType) return null;

  const FooterComp = FOOTER_TYPES[footerType];

  return <FooterComp {...props} />;
};
