import React from 'react';
import { SkillsStatsChart, SkillsLegendTile } from 'common/components';
import './UserProfileSkillsAnalysisChart.scss';

export const UserProfileSkillsAnalysisChart = ({
  isPreviewEnabled,
  skills,
  onSelectSkillsType = () => {},
}) => {
  return (
    <SkillsStatsChart
      withLegend={true}
      showTopLabels={false}
      showCountIndicator={false}
      className={`iq4-user-profile-skills-analysis-chart ${
        isPreviewEnabled ? `iq4-user-profile-skills-analysis-chart__with-preview` : ''
      }`}
      skillsMetrics={[
        {
          id: 'MATCHED_SKILLS',
          type: 'matched',
          count: skills.matched.length,
          label: (
            <div className="iq4-user-profile-skills-analysis-chart__label">
              <SkillsLegendTile type="matched" value={skills.matched.length} />
              <span>Matched</span>
            </div>
          ),
          description: 'This is the description for matched skills',
        },
        ...(isPreviewEnabled
          ? [
              {
                id: 'ADDITIONAL_SKILLS',
                type: 'additional',
                count: skills.additional.length,
                label: (
                  <div className="iq4-user-profile-skills-analysis-chart__label">
                    <SkillsLegendTile type="additional" value={skills.additional.length} />
                    <span>Additional</span>
                  </div>
                ),
                description: 'This is the description for matched skills',
              },
            ]
          : []),
        {
          id: 'MISSING_SKILLS',
          type: 'missing',
          count: skills.missing.length,
          label: (
            <div className="iq4-user-profile-skills-analysis-chart__label">
              <SkillsLegendTile type="missing" value={skills.missing.length} />
              <span>Missing</span>
            </div>
          ),
          description: 'This is the description for missing skills',
        },
      ]}
      onClick={(bar) => {
        onSelectSkillsType(bar.type);
      }}
    />
  );
};
