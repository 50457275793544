import React from 'react';
import './FilterBanner.scss';
import { RatingStars, SimpleIcon } from 'common/components';
import { RATING_STARS_SIZE } from 'common/constants/miscellaneous';

export const FilterBanner = ({ currentFilter, onRemoveFilter }) => {
  const hasNoFilterApplied = Object.keys(currentFilter).every((f) => !currentFilter[f]);
  if (hasNoFilterApplied) return null;

  const activeFilters = Object.keys(currentFilter).filter((f) => currentFilter[f]);

  const GenericFilterItem = ({ label, prop }) => (
    <div className="iq4-filter-banner__item-container">
      <span>{label}</span>
      <SimpleIcon name="close" onClick={() => onRemoveFilter(prop)} />
    </div>
  );

  const filterContainers = {
    technologyCategory: (index) => (
      <GenericFilterItem key={index} label="IT Skills" prop="technologyCategory" />
    ),
    professionalSkillsCategory: (index) => (
      <GenericFilterItem
        key={index}
        label="Professional Skills"
        prop="professionalSkillsCategory"
      />
    ),
    businessDevelopmentCategory: (index) => (
      <GenericFilterItem key={index} label="Soft Skills" prop="businessDevelopmentCategory" />
    ),
    portfolioSource: (index) => (
      <GenericFilterItem key={index} label="Portfolio" prop="portfolioSource" />
    ),
    educationSource: (index) => (
      <GenericFilterItem key={index} label="Education" prop="educationSource" />
    ),
    experienceSource: (index) => (
      <GenericFilterItem key={index} label="Experience" prop="experienceSource" />
    ),
    publicPermission: (index) => (
      <GenericFilterItem key={index} label="Public" prop="publicPermission" />
    ),
    privatePermission: (index) => (
      <GenericFilterItem key={index} label="Private" prop="privatePermission" />
    ),
    proficiencyOne: (index) => (
      <GenericFilterItem
        key={index}
        label={<RatingStars value={1} max={1} size={RATING_STARS_SIZE.SMALL} readOnly />}
        prop="proficiencyOne"
      />
    ),
    proficiencyTwo: (index) => (
      <GenericFilterItem
        key={index}
        label={<RatingStars value={2} max={2} size={RATING_STARS_SIZE.SMALL} readOnly />}
        prop="proficiencyTwo"
      />
    ),
    proficiencyThree: (index) => (
      <GenericFilterItem
        key={index}
        label={<RatingStars value={3} max={3} size={RATING_STARS_SIZE.SMALL} readOnly />}
        prop="proficiencyThree"
      />
    ),
    proficiencyFour: (index) => (
      <GenericFilterItem
        key={index}
        label={<RatingStars value={4} max={4} size={RATING_STARS_SIZE.SMALL} readOnly />}
        prop="proficiencyFour"
      />
    ),
    outcomeTypeCommon: (index) => (
      <GenericFilterItem key={index} label="Common" prop="outcomeTypeCommon" />
    ),
    outcomeTypeHard: (index) => (
      <GenericFilterItem key={index} label="Hard" prop="outcomeTypeHard" />
    ),
  };

  return (
    <div className="iq4-filter-banner__container">
      {activeFilters.map((filter, index) => filterContainers[filter](index))}
    </div>
  );
};
