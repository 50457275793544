import React from 'react';
import PropTypes from 'prop-types';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './Button.scss';

export const Button = (props) => {
  const { children, loading, variation, className, icon, ...buttonProps } = props;
  return (
    <button
      className={`iq4-button iq4-button-${variation} ${className}`}
      type="button"
      {...buttonProps}
      disabled={loading}
    >
      {children}
      {icon && <SimpleIcon className="iq4-button__icon" name={icon} />}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variation: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Button.defaultProps = {
  variation: 'primary',
  className: '',
  icon: null,
};
