import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { SimpleIcon } from 'common/components';
import { PathwayDonut } from './PathwayDonut';
import { PathwayGraphLegend } from './PathwaysGraphLegend';
import { PathwaySteps } from './PathwaySteps';
import { SPOKE_SIZES } from 'common/constants/miscellaneous';
import placeholderAvatar from '@assets/svg/placeholder-avatar.svg';
import { selectAddedSuggestions } from 'common/store/features/pathways/selectors';
import {
  removeAddedSuggestion,
  addAddedSuggestion,
} from 'common/store/features/pathways/pathwaysSlice';
import './PathwaysGraph.scss';
import { MiddleStep } from './MiddleStep';

export const PathwaysGraph = ({
  personal,
  spokes,
  suggestions,
  onSuggestingState,
  onSelectPath,
  onAddPath,
  onRemovePath,
  readOnly = false,
  maxNumberSpokes = 5,
  hide = false,
}) => {
  const avatarUploaded = !!(
    personal &&
    personal.userProfilePhoto &&
    personal.userProfilePhoto.image
  );
  const avatarPhoto = avatarUploaded ? personal.userProfilePhoto.image : placeholderAvatar;

  const addedSuggestions = useSelector(selectAddedSuggestions);
  const dispatch = useDispatch();
  const [isSimpleRender, setIsSimpleRender] = useState(
    !!(spokes?.length + suggestions?.length <= 3),
  );

  useEffect(() => {
    setIsSimpleRender(!!(spokes?.length + suggestions?.length <= 3));
  }, [spokes, suggestions]);

  const getLabelPosition = (index) => {
    const rightIndexes = [0, 1, 2, 3];

    if (rightIndexes.includes(index)) {
      return 'right';
    }
    return 'left';
  };

  const getStepLabelPosition = (index) => {
    const labelIndexes = [
      'bottom',
      'bottom-right',
      'top-right',
      'bottom',
      'top-left',
      'bottom-left',
    ];
    return labelIndexes[index];
  };

  const getSpokeSize = (distance) =>
    distance > 33 ? (distance > 66 ? SPOKE_SIZES.LARGE : SPOKE_SIZES.MEDIUM) : SPOKE_SIZES.SMALL;

  const addSuggestion = (spoke) => {
    dispatch(addAddedSuggestion(spoke));
    if (onAddPath) {
      onAddPath(spoke);
    }
  };

  const renderNormalGraph = () => {
    const suggestionIndexes = [1, 3, 4];
    const normalIndexes = [1, 2, 3, 4, 5, 6];
    const spokesToRender = spokes.length <= 3 ? spokes.concat(suggestions) : spokes;
    return spokesToRender.map((spoke, index) => {
      const spokeSize = getSpokeSize(spoke.distance);
      const isWorkRole = !!spoke.id;
      const isContainedWithinSuggestions = suggestions.map((x) => x.id).includes(spoke.id);
      const renderIndex = isSimpleRender ? suggestionIndexes[index] : normalIndexes[index];
      const hasMiddleStep = !!spoke?.paths?.length;

      if (isWorkRole && !isContainedWithinSuggestions) {
        return (
          <div className="iq4-pathway__node" key={`${index}-${spoke.id}`}>
            <div className={`iq4-pathway__line iq4-pathway__line--${spokeSize}-${renderIndex}`} />
            {hasMiddleStep && <MiddleStep stepIndex={renderIndex} step={spoke?.paths?.[0][0]} />}
            <div
              className={`iq4-pathway__circle-out-container iq4-pathway__circle-out-container--${spokeSize}-${renderIndex}`}
            >
              <PathwayDonut
                readOnly={readOnly}
                spoke={spoke}
                positionLabel={getLabelPosition(index)}
                onDelete={() => onRemovePath(spoke)}
                onSelect={() => onSelectPath(spokes[index])}
              />
            </div>
          </div>
        );
      }

      if (isWorkRole) {
        return (
          <div
            className="iq4-pathway__node iq4-pathway__node-suggestion"
            key={`${index}-${spoke.label}`}
          >
            <div className={`iq4-pathway__line iq4-pathway__line--${spokeSize}-${renderIndex}`} />
            <div
              className={`iq4-pathway__circle-out-container iq4-pathway__circle-out-container--${spokeSize}-${renderIndex}`}
              onClick={() => onSelectPath(spoke)}
            >
              <>
                <SimpleIcon name="ghostNode" className="iq4-pathway__ghost" />
                <div
                  className={`iq4-pathway__suggestion-label-container iq4-pathway__suggestion-label-container-${getLabelPosition(
                    index,
                  )}`}
                >
                  <span
                    className={`iq4-pathway__suggestion-label-container-${getLabelPosition(
                      index,
                    )}--text`}
                  >
                    {spoke.label}
                  </span>
                  <span
                    className={`iq4-pathway__suggestion-label-container--actions iq4-pathway__suggestion-label-container-${getLabelPosition(
                      index,
                    )}--action`}
                  >
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => addSuggestion(spoke)}
                      onKeyPress={() => addSuggestion(spoke)}
                    >
                      <SimpleIcon name="add" />
                      <span className="iq4-pathway__suggestion-label-container--actions-label">
                        Add
                      </span>
                    </span>
                  </span>
                </div>
              </>
            </div>
          </div>
        );
      }

      return (
        <div className="iq4-pathway__node iq4-pathway__node-suggestion" key={`${index}-suggestion`}>
          <div
            className={`iq4-pathway__line iq4-pathway__line--${spokeSize}-${suggestionIndexes[index]}`}
          />
          <div
            className={`iq4-pathway__circle-out-container iq4-pathway__circle-out-container--${spokeSize}-${suggestionIndexes[index]}`}
          >
            <>
              <SimpleIcon name="ghostNode" className="iq4-pathway__ghost" />
              <div className="iq4-pathway__suggestion-label-container" />
            </>
          </div>
        </div>
      );
    });
  };

  const renderSuggestionsGraph = () => {
    const suggestionIndexes = [1, 3, 4];

    return (suggestions?.length ? suggestions : [{}, {}, {}]).map((spoke, index) => {
      const spokeSize = getSpokeSize(spoke.distance);
      const isAdded = !!addedSuggestions.find((x) => x.id === spoke.id);
      const isWorkRole = !!spoke.id;
      const hasMiddleStep = !!spoke?.paths?.length;

      if (index > maxNumberSpokes) {
        return null;
      }

      const renderGhostNode = () => (
        <>
          <SimpleIcon name="ghostNode" className="iq4-pathway__ghost" />
          <div className="iq4-pathway__suggestion-label-container">
            <span>{spoke.label}</span>
            {!readOnly && isWorkRole && (
              <span className="iq4-pathway__suggestion-label-container--actions">
                <span
                  role="button"
                  tabIndex={0}
                  onClick={() => addSuggestion(spoke)}
                  onKeyPress={() => addSuggestion(spoke)}
                >
                  <SimpleIcon name="add" />
                  <span className="iq4-pathway__suggestion-label-container--actions-label">
                    Add
                  </span>
                </span>
              </span>
            )}
          </div>
        </>
      );

      return (
        <div
          className="iq4-pathway__node iq4-pathway__node-suggestion"
          key={`${index}-${spoke.label}`}
        >
          <div
            className={`iq4-pathway__line iq4-pathway__line--${spokeSize}-${suggestionIndexes[index]}`}
          />
          {hasMiddleStep && (
            <MiddleStep stepIndex={suggestionIndexes[index]} step={spoke?.paths?.[0][0]} />
          )}
          <div
            className={`iq4-pathway__circle-out-container iq4-pathway__circle-out-container--${spokeSize}-${suggestionIndexes[index]}`}
            onClick={isAdded ? null : () => onSelectPath(spoke)}
          >
            {isAdded ? (
              <PathwayDonut
                spoke={spoke}
                positionLabel="right"
                onDelete={() => {
                  dispatch(removeAddedSuggestion(spoke));
                  onRemovePath(spoke);
                }}
                onSelect={() => onSelectPath(spokes[index])}
              />
            ) : (
              renderGhostNode()
            )}
          </div>
        </div>
      );
    });
  };

  if (hide) {
    return null;
  }

  return (
    <div className="iq4-pathway__outer-container">
      <PathwayGraphLegend />
      <div className="iq4-pathway__container">
        {onSuggestingState ? renderSuggestionsGraph() : renderNormalGraph()}
        {!avatarPhoto && (
          <SimpleIcon
            name="hexagonAvatar"
            aria-label="User avatar"
            className="iq4-pathway__avatar"
          />
        )}
        {avatarPhoto && (
          <div className="iq4-pathway__manual-hexagon">
            {avatarUploaded && <img src={avatarPhoto} alt="Avatar" />}
            {!avatarUploaded && (
              <Avatar className="iq4-pathway__empty-avatar" alt="Avatar" src={null} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
