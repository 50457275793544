import React from 'react';
import { ROUTES } from 'routes/routeNames';
import { useSelector } from 'react-redux';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { ActionCard } from 'common/components/ActionCard';
import { selectIsPublicProfile, selectProfile } from 'common/store/features/profile/selectors';
import PortfolioItem from '../PortfolioItem';
import { EmptyState } from 'common/components/EmptyState';
import GraphicPortfolio from '@assets/svg/empty-states_portfolio.svg';
import './ProfilePortfolioDetail.scss';
import { useParams } from 'react-router-dom';
import {
  selectPortfolioItems,
  selectUserProfile,
} from '../../../../store/features/search/selectors';

export const ProfilePortfolioDetail = () => {
  const portfolio = useSelector(selectPortfolioItems);
  const isPopulated = portfolio.length > 0;
  const isMobile = useIsMobile();
  const isPublicProfile = true;

  return (
    <ActionCard
      title={<div className="iq4-profile-portfolio-detail__title">Portfolio</div>}
      icon="portfolioCircle"
      isDivideVisible={false}
      isHeaderVisible={!isMobile}
      backLink={isPublicProfile ? 'profile' : ROUTES.PROFILE_ROOT}
      isPublic={isPublicProfile}
    >
      {isPopulated && (
        <ul className="iq4-profile-portfolio-detail__list">
          {portfolio.map((item, i) => (
            <>
              <li className="iq4-profile-portfolio-summary__list-item" key={i}>
                <PortfolioItem item={item} associatedRecord={item?.associatedRecord} />
              </li>
            </>
          ))}
        </ul>
      )}

      {!isPopulated && (
        <div className="iq4-profile-portfolio-summary__empty-state-section">
          <img
            src={GraphicPortfolio}
            alt="Graphic for Portfolio"
            aria-hidden="true"
            className="iq4-profile-portfolio-summary___empty-state-graphic"
          />
        </div>
      )}
    </ActionCard>
  );
};
