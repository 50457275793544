import React from 'react';
import { Modal, CountIndicator, SimpleIcon } from 'common/components/index';
import './DashboardModal.scss';

export const DashboardModal = ({
  title,
  titleIcon,
  titleCount,
  actions,
  isOpen,
  onClose,
  children,
  navList,
  currentNavState,
  onNavChange,
}) => {
  return (
    <Modal
      className="iq4-dashboard-modal"
      BackdropProps={{
        classes: {
          root: 'iq4-dashboard-modal__backdrop',
        },
      }}
      title={
        <div className="iq4-dashboard-modal__header">
          <div className="iq4-dashboard-modal__title">
            {titleIcon && (
              <div className="iq4-dashboard-modal__title-icon">
                <SimpleIcon name={titleIcon} />
              </div>
            )}
            {title && <span>{title}</span>}
            {titleCount && parseInt(titleCount) > 0 ? (
              <CountIndicator count={titleCount} variation="primary" />
            ) : null}
          </div>
          {actions && <div className="iq4-dashboard-modal__actions">{actions}</div>}
        </div>
      }
      variation="2"
      isOpen={isOpen}
      handleClose={() => void onClose(null)}
      navList={navList}
      currentNavState={currentNavState}
      onNavChange={onNavChange}
      onClose={() => onClose()}
    >
      {children}
    </Modal>
  );
};
