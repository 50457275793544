import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleIcon, InnerTabs, TShapeCategory, PromptModal, Pill } from 'common/components';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import {
  SkillDetails,
  SkillDescription,
  SkillProficiency,
  SkillSources,
  SkillLearningResources,
} from './Tabs';
import { SkillCardExpander } from './SkillCardExpander';
import { updateSkillSelfAssessmentReq } from 'common/store/features/skills/skillsSlice';
import { removeSkillReq } from 'common/store/features/skills/skillsSlice';
import { selectFeatures } from 'common/store/features/config/selectors';
import './SkillCard.scss';
import { selectIsPublicProfile } from '../../store/features/profile/selectors';

export const SkillCard = ({
  skill,
  isExpanded,
  isExpanding,
  handleClick,
  index,
  readOnly = false,
  onDelete,
}) => {
  const { isNewSkill, competenceGroupId } = skill;
  const dispatch = useDispatch();
  const [isPromptActive, setIsPromptActive] = useState(false);
  const { displayUpperTiers, selfAttestedEnabled } = useSelector(selectFeatures);
  const isPublicProfile = window.location.href.indexOf('/user/') >= 0;

  const ksaEnabled = true;
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));

  const isVerified = skill?.sources?.filter((source) => source.verifiedBy).length > 0;

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);

  const onSelfAssessment = (assessment) => {
    dispatch(updateSkillSelfAssessmentReq(assessment));
  };

  const tabContent = [
    ...(ksaEnabled && skill?.ksas?.length > 0
      ? [
          {
            title: !isMobile ? 'Skill Details' : '',
            icon: 'skills',
            content: (
              <>
                {isMobile && <h2 className="iq4-skill-card__mobile-tab-title">Skill Details</h2>}
                <SkillDetails skill={skill} readOnly={readOnly || isPublicProfile} />
              </>
            ),
          },
        ]
      : []),
    ...(skill?.outcomeDescription
      ? [
          {
            title: !isMobile ? 'Description' : '',
            icon: 'skills',
            content: (
              <>
                {isMobile && <h2 className="iq4-skill-card__mobile-tab-title">Description</h2>}
                <SkillDescription skill={skill} />
              </>
            ),
          },
        ]
      : []),
    ...(selfAttestedEnabled
      ? [
          {
            title: !isMobile ? 'Proficiency' : '',
            icon: 'star',
            content: (
              <>
                {isMobile && <h2 className="iq4-skill-card__mobile-tab-title">Proficiency</h2>}
                <SkillProficiency
                  onSelfAssessment={(assessment) => onSelfAssessment(assessment)}
                  skill={skill}
                  readOnly={isPublicProfile ? true : readOnly}
                />
              </>
            ),
          },
        ]
      : []),
    {
      title: !isMobile ? 'Sources' : '',
      icon: 'source',
      content: (
        <>
          {isMobile && <h2 className="iq4-skill-card__mobile-tab-title">Sources</h2>}
          <SkillSources skill={skill} readOnly={readOnly} />
        </>
      ),
      disabled: readOnly,
    },
    {
      title: !isMobile ? 'Learning Resources' : '',
      icon: 'education',
      content: (
        <>
          {isMobile && <h2 className="iq4-skill-card__mobile-tab-title">Learning Resources</h2>}
          <SkillLearningResources skill={skill} />
        </>
      ),
      disabled: !skill.achievements || skill.achievements.length === 0,
    },
  ];

  const handleDelete = (id) => {
    dispatch(removeSkillReq(id));
    setIsPromptActive(false);
  };

  function getIsMobile(breakpoint) {
    return breakpoint === 'xs';
  }

  return (
    <>
      <PromptModal
        hideDismiss
        isOpen={isPromptActive}
        handleClose={() => setIsPromptActive(false)}
        title="Are you sure you want to delete this?"
        buttonLabel="Delete"
        buttonAction={() => handleDelete(skill.competenceId)}
      />
      <div className={`iq4-skill-card ${isExpanded ? 'iq4-skill-card--active' : ''}`}>
        {isExpanding && (
          <div className="iq4-skill-card__loading">
            <span className="iq4-skill-card__loading--label">Loading...</span>
          </div>
        )}
        <div className="iq4-skill-card__top" onClick={handleClick}>
          <div className="iq4-skill-card__title-wrapper">
            <div className="iq4-skill-card__title-wrapper__tier3">
              <h3 className="iq4-skill-card__title">{skill.tier3Label}</h3>

              {!isExpanded && skill.outcomeType && (
                <Pill
                  label={skill.outcomeType === 'Emsi Hard Skill' ? 'hard' : 'common'}
                  type={skill.outcomeType === 'Emsi Hard Skill' ? 'hard' : 'common'}
                />
              )}

              {!isExpanded && skill.competenceGroup && (
                <Pill label={skill.competenceGroup} type={skill.competenceGroup.toLowerCase()} />
              )}
            </div>
            {displayUpperTiers && (
              <div className="iq4-skill-card__title-wrapper__tier1-tier2">
                {`${skill.tier1Label} > ${skill.tier2Label}`}
              </div>
            )}
          </div>
          <div className="iq4-skill-card__expand-button-wrapper">
            <SkillCardExpander
              isExpanded={isExpanded}
              isExpanding={isExpanding}
              id={skill.competenceId}
            />
          </div>

          {isExpanded && (
            <div style={{ display: 'block' }}>
              <div className="iq4-skill-card__tshape-category-wrapper">
                {skill.outcomeType && (
                  <Pill
                    label={skill.outcomeType === 'Emsi Hard Skill' ? 'hard' : 'common'}
                    type={skill.outcomeType === 'Emsi Hard Skill' ? 'hard' : 'common'}
                  />
                )}

                {skill.competenceGroup && (
                  <Pill label={skill.competenceGroup} type={skill.competenceGroup.toLowerCase()} />
                )}
              </div>
              {selfAttestedEnabled && (
                <div className="iq4-skill-card__delete-button-wrapper">
                  {!isVerified && !isPublicProfile && (
                    <button
                      onClick={() => setIsPromptActive(true)}
                      className="iq4-skill-card__delete-button"
                    >
                      <SimpleIcon name="bin" />
                      Delete this skill
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div
          style={{
            display: isExpanded ? 'block' : 'none',
          }}
          id={`iq4-skill-card__content--` + skill.competenceId}
          className="iq4-skill-card__bottom"
        >
          <InnerTabs id={`skills-${index}`} data={tabContent} />
        </div>
      </div>
    </>
  );
};

SkillCard.propTypes = {};

SkillCard.defaultProps = {};
