import { combineReducers } from '@reduxjs/toolkit';

import config from 'common/store/features/config/configSlice';
import dashboard from './features/dashboard/dashboardSlice';
import search from './features/search/searchSlice.js';
import jobReqs from './features/jobReqs/jobReqsSlice';
import profile from 'common/store/features/profile/profileSlice';
import pathways from 'common/store/features/pathways/pathwaysSlice';
import onboarding from 'common/store/features/onboarding/onboardingSlice';
import skills from 'common/store/features/skills/skillsSlice';
import records from 'common/store/features/records/recordsSlice';
import ui from 'common/store/features/ui/uiSlice';
import t from 'common/store/features/t/slice';
import framework from '../common/store/features/framework/frameworkSlice';
import skillsModule from 'common/store/features/skillsModuleState/rootReducer';

const rootReducer = combineReducers({
  config,
  dashboard,
  search,
  profile,
  onboarding,
  skills,
  pathways,
  records,
  ui,
  t,
  jobReqs,
  skillsModule,
  framework,
});

export default rootReducer;
