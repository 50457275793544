import React from 'react';
import { ActionCard, UserProfileAvatar, UserProfilePathways, Pill } from 'common/components';
import './UserProfilePersonal.scss';

export const UserProfilePersonal = ({ profile, origin, pathways }) => {
  const { firstName, lastName, occupation, location } = profile;

  const cardTitle = <>Personal {origin === 'external' && <Pill label="External" />}</>;

  return (
    <ActionCard
      title={cardTitle}
      icon="personCircle"
      isDivideVisible={false}
      className="iq4-user-profile-personal"
    >
      <div className="iq4-user-profile-personal__content">
        <div className="iq4-user-profile-personal__content--avatar">
          <UserProfileAvatar profile={profile} />
        </div>
        <div className="iq4-user-profile-personal__content--details">
          <div className="iq4-user-profile-personal__content--details--item">
            <span className="label">Name</span>
            <span className="value">{firstName} {lastName}</span>
          </div>
          {occupation && (
            <div className="iq4-user-profile-personal__content--details--item">
              <span className="label">Occupation</span>
              <span className="value">{occupation}</span>
            </div>
          )}
          {location && (
            <div className="iq4-user-profile-personal__content--details--item">
              <span className="label">Location</span>
              <span className="value">{location}</span>
            </div>
          )}
          {pathways && pathways.length > 0 && (
            <div className="iq4-user-profile-personal__content--details--item">
              <span className="label">Career Pathways</span>
              <UserProfilePathways profile={profile} pathways={pathways} />
            </div>
          )}
        </div>
      </div>
    </ActionCard>
  );
};
