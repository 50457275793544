import React from 'react';
import PropTypes from 'prop-types';

const TYPES = {
  default: 'iq4-skills-button-default',
  link: 'iq4-skills-button-link',
  secondary: 'iq4-skills-button-secondary',
  outline: 'iq4-skills-button-outline'
};

const Button = ({
  children,
  className = '',
  type = 'default',
  disabled = null,
  ...props
}) => {
  return (
    <button
      className={`iq4-skills-button ${TYPES[type]} ${className} ${
        (disabled && 'iq4-skills-btn-disabled') || ''
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,

  className: PropTypes.string,

  type: PropTypes.oneOf(['default', 'link', 'secondary', 'outline'])
};

export default Button;
