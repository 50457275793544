import React from 'react';
import Modal from '../../Modal/Modal';

export const AverageInfoModal = ({ isOpen, toggle, assessments, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`Average assessment overview for ${assessments.tier3.value}`}
      onRequestClose={() => void toggle(false)}
      handleClose={() => toggle(false)}
      renderHeader={() => (
        <React.Fragment>
          <h3>Average Assessment Overview</h3>
          <p>{assessments.tier3.value}</p>
        </React.Fragment>
      )}
    >
      {children}
    </Modal>
  );
};

export default AverageInfoModal;
