import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SimpleIcon, Button } from 'common/components';
import Logo from '@assets/svg/logo.svg';
import { loginReq, toggleIsLoggingIn } from 'store/features/dashboard/dashboardSlice';
import './LoginPage.scss';
import {
  selectIsLoggedIn,
  selectIsLoggingIn,
  selectHasLoginError,
} from 'store/features/dashboard/selectors';
import { ROUTES } from 'routes/routeNames';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isLoggingIn = useSelector(selectIsLoggingIn);
  const hasLoginError = useSelector(selectHasLoginError);

  useEffect(() => {
    if (isLoggedIn) {
      history.push(ROUTES.JOB_REQS);
    }
  }, [isLoggedIn]);

  const handleEmailChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const login = () => {
    dispatch(toggleIsLoggingIn());
    dispatch(loginReq({ username, password }));
  };

  return (
    <div className="iq4-login-page__container">
      <div className="iq4-login-page__box">
        <img src={Logo} alt="Enterprise Logo" className="iq4-login-page__box--logo" />
        <span className="iq4-login-page__box--title">Sign in</span>
        <div role="form" className="iq4-login-page__box--login-container" onSubmit={login}>
          <div className="iq4-login-page__box--field-container">
            <span>Username:</span>
            <TextField
              classes={{
                root: hasLoginError
                  ? 'iq4-login-page__box--field-input-root-error'
                  : 'iq4-login-page__box--field-input-root',
              }}
              value={username}
              onChange={handleEmailChange}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: hasLoginError
                    ? 'iq4-login-page__box--field-input-error'
                    : 'iq4-login-page__box--field-input',
                  notchedOutline: hasLoginError ? 'iq4-login-page__box--no-border' : '',
                },
              }}
              variant="outlined"
            />
          </div>
          <div className="iq4-login-page__box--field-container">
            <span className="iq4-login-page__box--field-label">Password:</span>
            <TextField
              type="password"
              value={password}
              onChange={handlePasswordChange}
              clas
              classes={{
                root: hasLoginError
                  ? 'iq4-login-page__box--field-input-root-error'
                  : 'iq4-login-page__box--field-input-root',
              }}
              InputProps={{
                classes: {
                  input: hasLoginError
                    ? 'iq4-login-page__box--field-input-error'
                    : 'iq4-login-page__box--field-input',
                  notchedOutline: hasLoginError ? 'iq4-login-page__box--no-border' : '',
                },
              }}
              variant="outlined"
            />
          </div>
          {hasLoginError && (
            <div className="iq4-login-page__box--error-container">
              <SimpleIcon name="error" /> Incorrect Username or Password
            </div>
          )}
          <Button
            loading={isLoggingIn}
            onClick={login}
            className={`iq4-login-page__box--button ${
              hasLoginError ? 'iq4-login-page__box--error-button' : ''
            }`}
            variation="ghost"
          >
            Log In
          </Button>
        </div>
      </div>
    </div>
  );
};
