import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

import { PROFILE_ASSOCIATE_PORTFOLIO_URL } from 'common/constants/endpoints';
import { PROFILE_TYPE } from 'common/utils/constants';
import { selectAvailablePortfolioItems } from 'common/store/features/profile/selectors';
import { updateProfileSection } from 'common/store/features/profile/profileSlice';
import { ProfilePortfolioForm } from 'common/components/Forms';
import { Checkbox } from 'common/components/Form/fieldTypes/Checkbox';
import { Button } from 'common/components/Button';
import { SimpleIcon } from 'common/components/SimpleIcon';
import GraphicPortfolio from 'common/assets/svg/graphic-portfolio.svg';
import { selectIsUISubmittingForm } from 'common/store/features/ui/selectors';
import { setIsSubmittingForm } from 'common/store/features/ui/uiSlice';

import './ProfileAssociatePortfolioItems.scss';

export const ProfileAssociatePortfolioItems = ({ sourceType, sourceId, onComplete }) => {
  const dispatch = useDispatch();

  const originalPortfolioItems = useSelector((state) =>
    selectAvailablePortfolioItems(state, sourceType, sourceId),
  );
  const isSubmittingForm = useSelector(selectIsUISubmittingForm);
  const [portfolioItems, setPortfolioItems] = useState(originalPortfolioItems);
  const [showCreateNew, setShowCreateNew] = useState(false);

  const handleSelectItem = (id) => {
    setPortfolioItems(
      portfolioItems.map((item) => {
        return item.id === id
          ? {
              ...item,
              associated: !item.associated,
            }
          : item;
      }),
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setIsSubmittingForm(true));

    const itemsToAssociate = portfolioItems
      .filter(
        (item) =>
          item.associated &&
          !originalPortfolioItems.find((originalItem) => originalItem.id === item.id).associated,
      )
      .map((item) => ({
        id: item.id,
      }));

    const itemsToDisassociate = portfolioItems
      .filter(
        (item) =>
          !item.associated &&
          originalPortfolioItems.find((originalItem) => originalItem.id === item.id).associated,
      )
      .map((item) => ({
        id: item.id,
      }));

    fetch(PROFILE_ASSOCIATE_PORTFOLIO_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...(itemsToAssociate &&
          itemsToAssociate.length > 0 && { associatePortfolios: itemsToAssociate }),
        ...(itemsToDisassociate &&
          itemsToDisassociate.length > 0 && { disAssociatePortfolios: itemsToDisassociate }),
        record: {
          type: sourceType,
          id: sourceId,
        },
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        originalPortfolioItems
          .map((originalPortfolioItem) => {
            if (resp.findIndex((item) => item.id === originalPortfolioItem.id) > -1) {
              return {
                ...originalPortfolioItem,
                associatedRecord: {
                  id: sourceId,
                  type: sourceType,
                },
              };
            } else {
              const { associatedRecord, ...newPortfolioItem } = originalPortfolioItem;

              return newPortfolioItem;
            }
          })
          .forEach((newPortfolioItem) => {
            dispatch(
              updateProfileSection({
                section: PROFILE_TYPE.PORTFOLIO,
                value: newPortfolioItem,
              }),
            );
          });
        onComplete();
        dispatch(setIsSubmittingForm(false));
      });
  };

  const renderEmptyState = () => (
    <div className="iq4-profile-associate-portfolio-items__empty">
      <p>All your portfolio items have been assigned.</p>
      <p>You can uncheck any Portfolio items to make them available.</p>
      <p>
        <img src={GraphicPortfolio} alt="Portfolio items graphic" />
      </p>
    </div>
  );

  const renderPortfolioItems = (portfolioItems) => (
    <form>
      <p>Select the items you would like to add:</p>

      <ul className="iq4-profile-associate-portfolio-items__list">
        {portfolioItems.map((item, index) => (
          <li className="iq4-profile-associate-portfolio-items__list-item">
            <div className="iq4-profile-associate-portfolio-items__list-item__label">
              <SimpleIcon
                className="iq4-profile-associate-portfolio-items__list-item__icon"
                name={`${item.type}Circle`}
                role="presentation"
              />
              {item.title}
            </div>
            <div className="iq4-profile-associate-portfolio-items__list__input">
              <Checkbox
                isChecked={item.associated}
                handleChange={() => handleSelectItem(item.id)}
              />
            </div>
          </li>
        ))}
      </ul>

      <div className="iq4-profile-associate-portfolio-items__button-container">
        <Button
          className={`iq4-profile-associate-portfolio-items__button-submit ${
            isSubmittingForm ? 'iq4-profile-associate-portfolio-items__button-submit--loading' : ''
          }`}
          type="submit"
          onClick={handleSubmit}
        >
          Save
        </Button>
        {isSubmittingForm && (
          <CircularProgress
            size={24}
            className="iq4-profile-associate-portfolio-items__progress__indicator"
          />
        )}
      </div>
    </form>
  );

  return (
    <>
      {showCreateNew && <ProfilePortfolioForm onComplete={onComplete} />}
      {!showCreateNew && (
        <div className="iq4-profile-associate-portfolio-items">
          {portfolioItems.length === 0 && renderEmptyState()}
          {portfolioItems.length > 0 && renderPortfolioItems(portfolioItems)}
          <Button
            className="iq4-profile-associate-portfolio-items__button-create"
            variation="clear"
            onClick={() => setShowCreateNew(true)}
          >
            I want to create a new Portfolio item
          </Button>
        </div>
      )}
    </>
  );
};
