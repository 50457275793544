import React from 'react';
import SkillsModule from './SkillsModule/SkillsModule';

import './styles/scss/index.scss';

export const ManageSkillsModule = () => {
  return (
    <section id="iq4-skills-module">
      <SkillsModule />
    </section>
  );
};

export default ManageSkillsModule;
