import { useState, useEffect } from 'react';
import { SKILLS_SEARCH_URL } from 'common/constants/endpoints';

const MIN_CHAR_COUNT = 3;
export const useSkillsSearch = (query) => {
  const [skillsResults, setSkillsResults] = useState([]);

  useEffect(() => {
    if (!query || (query && query.length < MIN_CHAR_COUNT)) return;

    fetch(`${SKILLS_SEARCH_URL}?q=${query}&limit=10`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        const filteredResults = data.map((skill) => ({
          id: skill.competence,
          value: `${skill.skillTier3} (${skill.skillTier2})`,
          tier1: skill.skillTier1,
          tier2: skill.skillTier2,
          tier3: skill.skilltier3,
          alreadyAdded: false, // current api can't handle this
        }));

        setSkillsResults(filteredResults);
      });
  }, [query]);

  return skillsResults;
};
