import React from 'react';
import { Switch } from '@material-ui/core';
import { FixedTooltip } from 'common/components';
import './PrivatePublicToggle.scss';

export const PrivatePublicToggle = ({ record, onSelecting }) => {
  return (
    <div className="iq4-private-public-toggle" key={record?.id}>
      <span className="iq4-private-public-toggle__private">Private</span>
      <label className="form-switch">
        <input
          type="checkbox"
          checked={!!record?.isPublic}
          onChange={(e) => onSelecting({ selected: e.target.checked })}
        />
        <i />
      </label>
      <span
        className={
          !!record?.isPublic
            ? 'iq4-private-public-toggle__public'
            : 'iq4-private-public-toggle__private'
        }
      >
        Public
      </span>
    </div>
  );
};
