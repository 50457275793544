import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isSubmittingForm: false,
    isLoadingFilterResults: false,
    hasError: false,
    skillExpandingId: -1,
    skillExpandedId: -1,
  },
  reducers: {
    setIsSubmittingForm: (state, action) => {
      state.isSubmittingForm = action.payload;
    },
    setIsLoadingFilterResults: (state, action) => {
      state.isLoadingFilterResults = action.payload;
    },
    setHasError: (state, action) => {
      state.hasError = action.payload;
    },
    setSkillExpandingId: (state, action) => {
      state.skillExpandingId = action.payload;
    },
    setSkillExpandedId: (state, action) => {
      state.skillExpandedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('rejected'),
      (state, action) => {
        if (action.payload && action.payload.status >= 500) {
          state.hasError = true;
        }
      },
    );
  },
});

// action creators
export const {
  setIsSubmittingForm,
  setIsLoadingFilterResults,
  setHasError,
  setSkillExpandingId,
  setSkillExpandedId,
} = uiSlice.actions;

export default uiSlice.reducer;
