import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  fetchWorkrolesByJobFamily,
  setIsFetchingWorkRoles,
} from 'common/store/features/framework/frameworkSlice';
import {
  selectJobFamilyWorkRoles,
  selectIsFetchingWorkRoles,
  selectJobFamilyByRoleJobFamilyId,
} from 'common/store/features/framework/selectors';

export const SelectWorkRoles = ({
  displayBackButton = true,
  jobFamily,
  onSelectWorkRole,
  onClose,
  selectedWorkRole,
}) => {
  const workRoles = useSelector(
    selectJobFamilyWorkRoles(selectedWorkRole ? selectedWorkRole.jobFamilyId : jobFamily?.id),
  );

  const selectedWorkRoleJobFamily = useSelector(
    selectJobFamilyByRoleJobFamilyId(selectedWorkRole?.jobFamilyId),
  );

  const isFetchingWorkRoles = useSelector(selectIsFetchingWorkRoles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedWorkRoleJobFamily && !selectedWorkRoleJobFamily.hasFetchedAllRoles) {
      dispatch(setIsFetchingWorkRoles(true));
      dispatch(fetchWorkrolesByJobFamily(selectedWorkRole.jobFamilyId));
    }
  }, [workRoles]);

  useEffect(() => {
    if (jobFamily && Object.keys(jobFamily).length && !jobFamily.hasFetchedAllRoles) {
      dispatch(setIsFetchingWorkRoles(true));
      dispatch(fetchWorkrolesByJobFamily(jobFamily.id));
    }
  }, [jobFamily]);

  function displayLoadingState() {
    return (
      <>
        <div>
          <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={25} />
        </div>
        <div>
          <Skeleton variant="rect" style={{ width: '33%', marginBottom: 2 }} height={25} />
        </div>
        <div>
          <Skeleton variant="rect" style={{ width: '76%', marginBottom: 2 }} height={25} />
        </div>
        <div>
          <Skeleton variant="rect" style={{ width: '90%', marginBottom: 2 }} height={25} />
        </div>
      </>
    );
  }

  return (
    <div className="iq4-pathway-detail__selection-container">
      {displayBackButton && (
        <button className="iq4-pathway-detail__selection-container--cancel" onClick={onClose}>
          {selectedWorkRole ? 'Cancel' : 'Back'}
        </button>
      )}
      <div className="iq4-pathway-detail__selection-container--title">
        {selectedWorkRole ? 'ROLE' : 'JOB FAMILY'}
      </div>
      <TextField
        value={selectedWorkRole ? selectedWorkRole?.label : jobFamily?.label}
        classes={{
          root: 'iq4-pathway-detail__location-input--field-input-root',
        }}
        InputProps={{
          readOnly: true,
          classes: {
            input: 'iq4-pathway-detail__location-input--field-input',
          },
        }}
        variant="outlined"
      />
      <div className="iq4-pathway-detail__selection-container--root">
        {(!workRoles || isFetchingWorkRoles) && displayLoadingState()}
        {(workRoles || []).map((workRole) => {
          const isSelectedWorkRole = selectedWorkRole?.id === workRole.id;
          return (
            <button
              className={`iq4-pathway-detail__selection-container--item ${
                isSelectedWorkRole ? 'iq4-pathway-detail__selection-container--selected' : ''
              }`}
              key={workRole.id}
              onClick={isSelectedWorkRole ? undefined : () => onSelectWorkRole(workRole)}
              aria-label={`Select ${workRole.label} work role`}
            >
              <span className="iq4-pathway-detail__selection-container--label">
                {workRole.label}
              </span>
              {isSelectedWorkRole && <CheckCircleIcon />}
            </button>
          );
        })}
      </div>
    </div>
  );
};
