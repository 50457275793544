import React, { useState } from 'react';
import { ROUTES } from 'routes/routeNames';
import { ActionCard, EmptyState, Modal, LoadingSkeleton, CredentialPill } from 'common/components';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { selectProfile } from 'common/store/features/profile/selectors';
import GraphicEducation from '@assets/svg/empty-states_education.svg';
import { FormWrapper } from 'common/components/Form';
import { useProfileEducationFormDefinition } from 'common/hooks/forms';
import { DEFAULT_COUNTRY } from 'common/constants/countries';
import { orderEducationRecords } from 'common/utils/orderRecords';
import './ProfileEducationSummary.scss';
import { useParams } from 'react-router-dom';
import { setSelectedPublicView } from 'store/features/search/searchSlice';
import { selectUserProfile } from '../../../../store/features/search/selectors';

export const ProfileEducationSummary = () => {
  const MAX_NUM_RECORDS = 2;
  const dispatch = useDispatch();
  const { education } = useSelector(selectUserProfile);
  const isPopulated = education?.length > 0;
  const hasLoaded = true;

  return (
    <>
      <ActionCard
        title="Education"
        icon="educationCircle"
        seeMore="education"
        isEmpty={!isPopulated}
        isPublic={true}
      >
        {hasLoaded ? (
          <>
            {!isPopulated && (
              <>
                <img
                  src={GraphicEducation}
                  className="empty-state-graphic"
                  role="presentation"
                  alt="Education Graphic"
                />
              </>
            )}

            {isPopulated && (
              <ul className="iq4-profile-education-summary__list">
                {orderEducationRecords(education)
                  .slice(0, MAX_NUM_RECORDS)
                  .map((item) => (
                    <li className="iq4-profile-education-summary__list-item" key={item.id}>
                      <CredentialPill credential={item} />
                      <p className="iq4-profile-education-summary__degree">{item.title}</p>
                      <p className="iq4-profile-education-summary__school">{item.school}</p>
                      <p className="iq4-profile-education-summary__year">
                        {item.fromYear} {item.fromYear !== item.toYear ? `- ${item.toYear}` : ``}
                      </p>
                      <hr className="iq4-profile-education-summary__divide" />
                    </li>
                  ))}
              </ul>
            )}
          </>
        ) : (
          <div style={{ display: 'block', padding: '10px 0px' }}>
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={24} />
          </div>
        )}
      </ActionCard>
    </>
  );
};
