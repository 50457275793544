import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { ClickAwayListener, Checkbox } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { SimpleIcon, CountIndicator } from 'common/components';
import { OPTION_TYPE } from 'common/constants/miscellaneous';
import './DropdownFilter.scss';

const ITEM_SIZE = 34;

const ListOption = ({ option, onChange }) => {
  return (
    <label key={option.label} className="iq4-dropdown-filter__open-filter-list-item">
      {option.multiple && (
        <Checkbox
          aria-label={option.label}
          className="iq4-dropdown-filter__open-filter-list-item-checkbox"
          checked={option.checked}
          name={`iq4-iq4-dropdown-filter__open-checkbox-${option.label}`}
          indeterminate={option.partiallySelected}
          onChange={(e) => onChange(e, option)}
        />
      )}

      <p
        className={`iq4-dropdown-filter__open-filter-list-item-label iq4-dropdown-filter__open-filter-list-item-virtualized`}
      >
        {option.label}
      </p>
    </label>
  );
};

// render props function for the List component, that renders the options virtually
const VirtulizedListOption = ({ index, style, data: { allOptions } }) => {
  const item = allOptions[index];

  return (
    <div key={item.label} style={style} classes={{ root: 'iq4-datatable__virtualized-row' }}>
      {item.optionType === OPTION_TYPE.SELECTED ? (
        <ListOption option={item} onChange={item.onChange} />
      ) : item.optionType === OPTION_TYPE.DIVIDER ? (
        <hr />
      ) : (
        <ListOption option={item} onChange={item.onChange} />
      )}
    </div>
  );
};

/**
 * Dropdown filter
 * @param {object[]} filterList - The list to select from: {id: Number|String, value: String, selected: Boolean }
 * @param {*} filterLabel - The label to show on the closed select button
 * @param {object[]} selected - selected values from the filterList
 * @param {object[]} partiallySelected - partially selected values from the filterList
 * @param {boolean} [multiple] - Whether to allow for multiple options or not
 * @param {function} onChange - Handle changing an item
 * @param {function} onSelectAll - toggle all filters
 * @param {string} className - custom className
 * @param {boolean} useVirtualized - choose between normal / virtualized rendering
 *
 */
export const DropdownFilter = ({
  filterList,
  filterLabel,
  selected,
  partiallySelected = null,
  onChange,
  onSelectAll,
  multiple = true,
  className,
  useVirtualized = false,
  disabled = false,
}) => {
  const [openFilterBox, setOpenFilterBox] = useState(false);

  // hash map of selected options
  const [selectedOptionsMap, setSelectedOptionsMap] = useState(
    deriveSelectedOptionsMap(selected, filterList),
  );

  // hash map of partially selected options
  const [partiallySelectedMap, setPartiallySelected] = useState(
    derivePartiallySelected(partiallySelected),
  );

  // hash map of selected options at opening
  const [selectedAtOpening, setSelectedAtOpening] = useState({});

  // all options to render in the required order
  const [allOptions, setAllOptions] = useState([]);

  // search bar related
  const [searchTerm, setSearchTerm] = useState('');
  const [internalSearchResults, setInternalSearchResults] = useState({});
  const debounceSearchResults = debounce((searchTerm) => {
    let searchResults = {};

    if (searchTerm) {
      searchResults = filterList.reduce((map, option) => {
        if (option.label.toLowerCase().indexOf(searchTerm) !== -1) {
          map[option.label] = true;
        }

        return map;
      }, {});
    }

    setInternalSearchResults(searchResults);
  }, 150);

  // reset internal search when the filter dropdown toggles
  useEffect(() => {
    handleInternalSearch('');
  }, [openFilterBox]); // eslint-disable-line react-hooks/exhaustive-deps

  // checks and keeps track of options that are selected, when the dropdown filter is opened
  useEffect(() => {
    if (openFilterBox) {
      const selectedFilters = selected.reduce((map, filterItem) => {
        map[filterItem.label] = true;
        return map;
      }, {});

      const finalSelectedFilters = partiallySelected.reduce((map, filterItem) => {
        map[filterItem.label] = true;
        return map;
      }, selectedFilters);

      setSelectedAtOpening(finalSelectedFilters);
    }
  }, [openFilterBox]); // eslint-disable-line react-hooks/exhaustive-deps

  // keep selected map up to date.
  useEffect(() => {
    const updatedSelected = deriveSelectedOptionsMap(selected, filterList);
    setSelectedOptionsMap(updatedSelected);
  }, [selected, filterList]);

  // keep partially selected map up to date
  useEffect(() => {
    const updatedSelected = derivePartiallySelected(partiallySelected);
    setPartiallySelected(updatedSelected);
  }, [partiallySelected]);

  // derive all the options to render in the required order
  useEffect(() => {
    const selectedOptions = [];
    const unselectedOptions = [];
    filterList.forEach((option) => {
      // don't include it if it's not part of the options
      if (searchTerm && !internalSearchResults[option.label]) return;

      if (!!selectedAtOpening[option.label]) {
        selectedOptions.push({
          ...option,
          optionType: OPTION_TYPE.SELECTED,
          checked: selectedOptionsMap[option.label],
          partiallySelected: partiallySelectedMap[option.label],
          multiple: multiple,
          onChange: handleOptionChange,
        });
      } else {
        unselectedOptions.push({
          ...option,
          optionType: OPTION_TYPE.UNSELECTED,
          checked: selectedOptionsMap[option.label],
          partiallySelected: partiallySelectedMap[option.label],
          multiple: multiple,
          onChange: handleOptionChange,
        });
      }
    });

    setAllOptions([...selectedOptions, { optionType: OPTION_TYPE.DIVIDER }, ...unselectedOptions]);
  }, [
    // eslint-disable-line react-hooks/exhaustive-deps
    filterList,
    internalSearchResults,
    selectedAtOpening,
    selectedOptionsMap,
    partiallySelectedMap,
  ]);

  // handle changing option
  const handleOptionChange = (e, option) => {
    onChange(e.target.checked, option);
  };

  // handle search input change
  const handleInternalSearch = (newVal) => {
    setSearchTerm(newVal);
    // debounce getting the re
    debounceSearchResults(newVal);
  };

  // creates a hash map of all the selected options.
  function deriveSelectedOptionsMap(selectedOptions, allOptions) {
    return allOptions.reduce((map, option) => {
      map[option.label] = !!selectedOptions.find(
        (selectedOption) => selectedOption.label === option.label,
      );
      return map;
    }, {});
  }

  // creates a hash map of the partially selected options.
  function derivePartiallySelected(options) {
    if (!options) return [];

    return options.reduce((map, option) => {
      map[option.label] = true;
      return map;
    }, {});
  }

  // returns an array of jsx options
  const renderOptions = (options) =>
    options.map((option) => {
      return option.optionType === OPTION_TYPE.SELECTED ? (
        <ListOption option={option} onChange={handleOptionChange} />
      ) : option.optionType === OPTION_TYPE.DIVIDER ? (
        <hr />
      ) : (
        <ListOption option={option} onChange={handleOptionChange} />
      );
    });

  return (
    <div className={`iq4-dropdown-filter ${className ? className : ''}`}>
      {/* jsx for when the dropdown filter is CLOSED */}
      {!openFilterBox && (
        <div
          className={`iq4-dropdown-filter__closed ${
            disabled ? 'iq4-dropdown-filter__disabled' : ''
          }`}
        >
          <button
            aria-label={`${filterLabel} dropdown. There are ${filterList.length} ${filterLabel} options available. ${selected.length} ${filterLabel} options selected. Click to view more.`}
            className={`iq4-dropdown-filter__closed-select-btn ${
              selected.length ? 'iq4-dropdown-filter__closed-select-btn--selected' : ''
            }`}
            onClick={() => !disabled && setOpenFilterBox(true)}
          >
            <CountIndicator
              count={(selected && selected.length) || 0}
              variation="dark iq4-dropdown-filter__closed-select-btn-selected-count"
            />

            <div className="iq4-dropdown-filter__closed-select-btn-label">
              {multiple
                ? selected.length && selected.length !== filterList.length
                  ? selected.map((v) => v.label).join(', ')
                  : filterLabel
                : selected.length
                ? selected[0].label
                : filterLabel}
            </div>
            <SimpleIcon name="caretDown" className="iq4-dropdown-filter__closed-select-btn-icon" />
          </button>
        </div>
      )}

      {/* jsx for when the dropdown filter is OPEN */}
      {openFilterBox && (
        <ClickAwayListener onClickAway={() => void setOpenFilterBox(false)}>
          <div className="iq4-dropdown-filter__open">
            {/* dropdown header */}
            <div className="iq4-dropdown-filter__open-header">
              <div className="iq4-dropdown-filter__open-search-wrapper">
                <div className="iq4-dropdown-filter__search-icon">
                  <SimpleIcon name="search" />
                </div>

                <input
                  aria-label={`Start typing to filter down available options.`}
                  type="text"
                  className="iq4-dropdown-filter__search-input"
                  value={searchTerm}
                  onChange={(e) => void handleInternalSearch(e.target.value.toLowerCase())}
                />
              </div>
              <button
                aria-label={`Click to close ${filterLabel} dropdown`}
                className="iq4-dropdown-filter__open-toggle-btn"
                onClick={() => void setOpenFilterBox(false)}
              >
                <SimpleIcon name="caretUp" />
              </button>
            </div>

            {!!allOptions.length && (
              <div className="iq4-dropdown-filter__open-filter-list">
                {multiple && (
                  <>
                    {/* options count indicator */}
                    <div className="iq4-dropdown-filter__open-selected-title">
                      <CountIndicator count={selected.length} variation="dark" /> Selections
                    </div>

                    {/* Select All option */}
                    <label className={`iq4-dropdown-filter__open-filter-list-item`}>
                      <Checkbox
                        aria-label={`Select All`}
                        className="iq4-dropdown-filter__open-filter-list-item-checkbox"
                        checked={
                          selected.length === filterList.length &&
                          ((partiallySelected && !partiallySelected.length) || !partiallySelected)
                        }
                        onChange={onSelectAll}
                        name={`iq4-iq4-dropdown-filter__open-checkbox-(SELECT-ALL)`}
                      />
                      <p className="iq4-dropdown-filter__open-filter-list-item-label">
                        (Select All)
                      </p>
                    </label>

                    {useVirtualized ? (
                      // renders the options in virtualize mode
                      <div className="iq4-dropdown-filter__virtualized-container">
                        <AutoSizer>
                          {({ height, width }) => (
                            <List
                              height={height}
                              width={width}
                              itemCount={allOptions.length}
                              itemSize={ITEM_SIZE}
                              itemKey={(index, data) => data.allOptions[index].label}
                              itemData={{ allOptions }}
                            >
                              {VirtulizedListOption}
                            </List>
                          )}
                        </AutoSizer>
                      </div>
                    ) : (
                      // renders the options without any virtualization
                      renderOptions(allOptions)
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};
