import React from 'react';
import { CountIndicator } from '../CountIndicator';
import { RatingStars } from '../RatingStars';
import './DashboardDevelopmentResource.scss';

export const DashboardDevelopmentResource = ({
  description,
  topSkills = [],
  proficiencyLevel = null,
  roles = [],
}) => {
  return (
    <div className="iq4-dashboard-development-resource">
      <h2 className="iq4-dashboard-development-resource__header">Description</h2>
      <p className="iq4-dashboard-development-resource__description iq4-dashboard-development-resource__space-divider">
        {description}
      </p>

      {!!topSkills.length && (
        <>
          <h2 className="iq4-dashboard-development-resource__header">Top Skills</h2>
          <div className="iq4-dashboard-development-resource__top-skills iq4-dashboard-development-resource__space-divider">
            {topSkills.map((skill) => (
              <CountIndicator key={skill.label} count={skill.label} variation="dark" />
            ))}
          </div>
        </>
      )}

      <h2 className="iq4-dashboard-development-resource__header">Proficiency Level</h2>
      <RatingStars value={proficiencyLevel} readOnly />

      <h2 className="iq4-dashboard-development-resource__header">Audience</h2>
      <p className="iq4-dashboard-development-resource__description">
        Audience Roles this project is suited to:{' '}
      </p>
      <ul className="iq4-dashboard-development-resource__role-list">
        {roles.map((role) => (
          <li className="iq4-dashboard-development-resource__role" key={role.label}>
            {role.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
