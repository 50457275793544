import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Search as SearchIcon } from '../../Icon/Icon';
import useCloseOnClick from '../useCloseOnClick';
import { Loader } from '../../Loader/Loader';

const SkillsSearchBar = ({
  searchResults = [],
  onChange,
  onSearchSelect,
  includeKSAs,
  skillTier1,
  tier1s,
}) => {
  const compRef = useRef(null);
  const [displaySuggestions, toggleDisplaySuggestions] = useCloseOnClick(compRef);
  const isGlobalSearch = skillTier1.id === null;

  const [isLoadingSkills, setIsLoadingSkills] = useState(false);

  useEffect(() => {
    setIsLoadingSkills(false);
  }, [searchResults]);

  const renderSuggestions = (skills, skillTier1) => {
    return skills.map((st2) => {
      return st2.skillTier3List.map((st3) => {
        return (
          <li
            role="option"
            key={st3.skillTier3.id}
            onClick={() => {
              const payload = [
                {
                  skillTier1,
                  skillTier2List: [{ ...st2, skillTier3List: [st3] }],
                },
              ];

              onSearchSelect(payload);
              toggleDisplaySuggestions(false);
            }}
          >
            {st3.skillTier3.label}
          </li>
        );
      });
    });
  };

  const renderSuggestionsKSAs = (skills, skillTier1) => {
    return skills.map((st2Config) =>
      st2Config.skillTier3List.map((st3Config) =>
        st3Config.skillTier4List.map((st4Config, i) => (
          <li
            role="option"
            className={st4Config.skillAdded && 'iq4-skill-search-disabled'}
            key={st4Config.skillTier4.id}
            onClick={() => {
              if (!st4Config.skillAdded) {
                const payload = [
                  {
                    skillTier1,
                    skillTier2List: [{ ...st2Config, skillTier3List: [st3Config] }],
                  },
                ];

                onSearchSelect(payload);
                toggleDisplaySuggestions(false);
              }
            }}
          >
            <p>{st3Config.skillTier3.label}</p>
            <small>{st4Config.skillTier4.label}</small>
          </li>
        )),
      ),
    );
  };

  return (
    <div className="iq4-skills-search-bar" ref={compRef}>
      <p className="iq4-skills-search-bar__title">Search and add skills</p>

      <div className="iq4-skills-search-bar__searchbox">
        <SearchIcon />
        <input
          aria-label={`Search for skills ${
            skillTier1.id !== null ? `within ${skillTier1.value}` : ''
          }`}
          aria-expanded="false"
          aria-autocomplete="list"
          placeholder={`Search for skills ${
            skillTier1.id !== null ? `within ${skillTier1.value}` : ''
          }`}
          type="text"
          onChange={(e) => {
            onChange(e.target.value);
            toggleDisplaySuggestions(true);

            if (e.target.value.length >= 3) {
              setIsLoadingSkills(true);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSearchSelect(searchResults);
            }
          }}
        />

        {isLoadingSkills && <Loader />}
      </div>
    </div>
  );
};

export default SkillsSearchBar;
