import _orderBy from 'lodash/orderBy';
import moment from 'moment';

export const orderExperienceRecords = (experienceRecords) => {
  return _orderBy(
    experienceRecords,
    ['isCurrentRole', 'endYear', 'endMonth', 'startYear', 'startMonth'],
    ['desc', 'desc', 'desc', 'desc', 'desc'],
  );
};

export const orderEducationRecords = (educationRecords) => {
  educationRecords = educationRecords.map((educationRecord) => {
    return educationRecord.verifiedBy
      ? {
          ...educationRecord,
          awardDate: moment(
            JSON.parse(educationRecord?.verifiedCredentialDetails)?.awardDate,
          ).format(),
        }
      : educationRecord;
  });

  return _orderBy(
    educationRecords,
    ['isCurrentStudent', 'awardDate', 'endYear', 'endMonth', 'startYear', 'startMonth'],
    ['desc', 'asc', 'desc', 'desc', 'desc', 'desc'],
  );
};
