import React from 'react';
import { ActionCard, SimpleIcon } from 'common/components';
import './UserProfileSkillsSnapshot.scss';

export const UserProfileSkillsSnapshot = ({ skillCountsByCategory, onClick }) => {
  const totalCount = skillCountsByCategory.reduce(
    (total, current) => total + current.skillCount,
    0,
  );

  return (
    <ActionCard
      title="Skills snapshot"
      icon="skillsCircle"
      isDivideVisible={false}
      className="iq4-user-profile-skills-snapshot"
    >
      <div className="iq4-user-profile-skills-snapshot__content">
        <p className="iq4-user-profile-skills-snapshot__content--intro">Total number of skills</p>
        <p className="iq4-user-profile-skills-snapshot__content--count">{totalCount}</p>
        <ul className="iq4-user-profile-skills-snapshot__content--categories">
          {skillCountsByCategory.map((category) => (
            <li key={category.categoryId}>
              <button onClick={() => void onClick(category)}>
                <div className="iq4-user-profile-skills-snapshot__content--categories-label">
                  <span>{category.categoryLabel}</span>
                  <SimpleIcon name="arrow" />
                </div>
                <div
                  className={`iq4-user-profile-skills-snapshot__content--categories-count ${category.categoryId}`}
                >
                  {category.skillCount}
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </ActionCard>
  );
};
