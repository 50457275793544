import React, { useState, useEffect } from 'react';
import Modal from '../Modal/Modal';
import SkillsSearch from '../SkillsSearch/SkillsSearch';

const AddMoreSkillsModal = ({
  isOpen,
  toggle,
  skillTier1 = { value: null, id: null }
}) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`Add more skills`}
      className="iq4-skills-add-more-skills"
      onRequestClose={() => void toggle(false)}
      id="iq4-skills-add-more-skills"
    >
      {() => <SkillsSearch skillTier1={skillTier1} onClose={toggle} />}
    </Modal>
  );
};

export default AddMoreSkillsModal;
