import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

function checkSessionExpired(state, action) {
  if (action.payload && action.payload.status === 401) {
    state.isSessionExpired = true;
  }
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected');
}

export const fetchIsLoggedInReq = createAsyncThunk('session/fetchIsLoggedInReq', async (url) => {
  const res = await fetch(url, {
    method: 'GET',
  });

  return res.json();
});

export const loginReq = createAsyncThunk(
  'session/loginReq',
  async (params, { rejectWithValue }) => {
    const { loginEndpoint, ...userAndPass } = params;

    const searchParams = Object.keys(userAndPass)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

    return fetch(loginEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: searchParams,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    isBootstrapping: true,
    isLoggingIn: false,
    isLoggedIn: false,
    isSessionExpired: false,
  },
  reducers: {
    toggleIsLoggingIn: (state) => {
      state.isLoggingIn = !state.isLoggingIn;
    },
    toggleIsSessionExpired: (state) => {
      state.isSessionExpired = true;
      state.isLoggedIn = false;
    },
    setIsBootstrapping: (state, action) => {
      state.isBootstrapping = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIsLoggedInReq.fulfilled, (state, action) => {
        if (!Object.keys(action.payload).length || action.payload.status === 401) {
          state.isLoggedIn = false;
        } else {
          state.isLoggedIn = true;
          state.isSessionExpired = false;
          state.user = action.payload;
        }

        state.isBootstrapping = false;
      })
      .addCase(fetchIsLoggedInReq.rejected, (state) => {
        state.isLoggedIn = false;
        state.isBootstrapping = false;
      })
      .addCase(loginReq.fulfilled, (state) => {
        state.isLoggedIn = true;
        state.isLoggingIn = false;
        state.isBootstrapping = false;
        state.hasLoginError = false;
        state.isSessionExpired = false;
      })
      .addCase(loginReq.rejected, (state) => {
        state.hasLoginError = true;
        state.isLoggedIn = false;
        state.isLoggingIn = false;
        state.isBootstrapping = false;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        checkSessionExpired(state, action);
      });
  },
});

export const {
  toggleIsLoggingIn,
  toggleIsSessionExpired,
  setIsBootstrapping,
} = sessionSlice.actions;

export default sessionSlice.reducer;
