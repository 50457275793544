import React, { useRef, useState, useEffect } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Checkbox from '@material-ui/core/Checkbox';
import './DataTableHeaderFilter.scss';
import { useClickOutside } from 'common/hooks/useClickOutside';

export const DataTableHeaderFilter = ({
  columnName,
  filterValues,
  onFilterChange,
  rows,
  currentFilter,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const tooltipRef = useRef(null);
  const [clickedOutside, setClickedOutside] = useClickOutside(tooltipRef);

  const initFilters = () =>
    filterValues.reduce((acc, value) => {
      return {
        ...acc,
        [value]: true,
      };
    }, {});

  const [currentColumnFilters, setCurrentColumnFilters] = useState(initFilters());

  useEffect(() => {
    if (clickedOutside && showFilter) {
      setShowFilter(false);
    } else {
      setClickedOutside(false);
    }
  }, [clickedOutside, tooltipRef, showFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentColumnFilters(initFilters());
  }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!Object.keys(currentFilter).length) {
      setCurrentColumnFilters(initFilters());
    }
  }, [currentFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateFilter = (val) => {
    const updatedFilter = {
      ...currentColumnFilters,
      [val]: !currentColumnFilters[val],
    };

    setCurrentColumnFilters(updatedFilter);

    const selectedKeys = Object.keys(updatedFilter).filter((key) => updatedFilter[key]);

    onFilterChange({
      columnName,
      applyFilter: (rows) => rows.filter((row) => selectedKeys.includes(row[columnName])),
      toRemove: Object.keys(updatedFilter).every((f) => !!updatedFilter[f]),
    });
  };

  return (
    <>
      <div
        className="iq4-datatable-filter__container"
        role="button"
        tabIndex={0}
        onClick={() => setShowFilter(!showFilter)}
        onKeyPress={() => setShowFilter(!showFilter)}
        aria-label="Filter table"
      >
        <ArrowDropDownIcon />
      </div>
      {showFilter && (
        <div className="iq4-datatable-filter__selection" ref={tooltipRef}>
          <ul className="iq4-datatable-filter__selection--list">
            {filterValues.map((val) => (
              <li className="iq4-datatable-filter__selection--list-item">
                <Checkbox
                  checked={!!currentColumnFilters[val]}
                  onChange={() => updateFilter(val)}
                />
                {val}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
