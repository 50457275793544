import { createSlice } from '@reduxjs/toolkit';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    features: {
      applicationTitle: null,
      authenticationType: null,
      lcnEnabled: null,
      resumeParserEnabled: null,
      defaultRoute: null,
      personalProfileSource: null,
      modules: {
        profile: false,
        skills: false,
        records: false,
        pathways: false,
        dashboard: false,
        explore: false,
        students: false,
      },
    },
  },
  reducers: {
    setFeatures: (state, action) => {
      state.features = action.payload;
    },
  },
});

export const { setFeatures } = configSlice.actions;
export default configSlice.reducer;
