import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

const ToolsModal = ({ isOpen, toggle, tools = [], skill, onSave }) => {
  // keep interanl state of checked tools
  const [checkedTools, setCheckedTools] = useState({});

  // init internal state & watch for new state updates from parent.
  useEffect(() => {
    setCheckedTools(_setCheckedTools());
  }, [tools]);

  // render the tools list
  const _toolsList = () => {
    if (!tools.length)
      return <small>No tools availabel for {skill.value}</small>;

    return (
      <ul>
        {tools.map(t => {
          return (
            <li key={t.id}>
              <label>
                <input
                  type="checkbox"
                  checked={checkedTools[t.id]}
                  onChange={e =>
                    void _handleToolsChange(t.id, e.target.checked)
                  }
                />
                {t.context}
              </label>
            </li>
          );
        })}
      </ul>
    );
  };

  // update the checked tools handler
  function _handleToolsChange(id, checked) {
    setCheckedTools(state => ({
      ...state,
      [id]: checked
    }));
  }

  // derive state for checked tools
  function _setCheckedTools() {
    return tools.reduce((map, t) => {
      map[t.id] = !!t.added;
      return map;
    }, {});
  }

  // pass back the tools array, with the internal checkbox states
  function _handleSave() {
    const newTools = tools.filter(t => checkedTools[t.id]);

    onSave(newTools);
  }

  return (
    <Modal
      className="iq4-skill-tools-modal"
      isOpen={isOpen}
      contentLabel={`Tools for ${skill.value}`}
      onRequestClose={() => void toggle(false)}
      handleClose={() => toggle(false)}
      renderHeader={() => (
        <React.Fragment>
          <h3>{skill.value}</h3>
          <p>Additional tools for this Skill Category</p>
        </React.Fragment>
      )}
      renderFooter={() => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => toggle(false)}
            type="secondary"
            style={{ marginRight: '7px' }}
          >
            Cancel
          </Button>
          <Button onClick={() => void _handleSave()}>Save</Button>
        </div>
      )}
    >
      {() => _toolsList()}
    </Modal>
  );
};

export default ToolsModal;
