import React, { useState, useEffect } from 'react';
import { ROUTES } from 'routes/routeNames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useIsMobile } from 'common/hooks/useIsMobile';
import {
  ActionCard,
  MenuButton,
  TriggerModal,
  LoadingSkeleton,
  SimpleIcon,
  MultilineDisplayer,
  CredentialPill,
} from 'common/components';
import CompetenciesList from '../CompetenciesList';
import PortfolioItemSummary from '../PortfolioItemSummary/PortfolioItemSummary';
import { ProfileThumbnailUpload } from '../ProfileThumbnailUpload';
import { ProfileAssociatePortfolioItems } from '../ProfileAssociatePortfolioItems';
import { EmptyState } from 'common/components/EmptyState';
import GraphicExperience from '@assets/svg/empty-states_experience.svg';
import {
  useProfileExperienceFormDefinition,
  useProfileExperienceImageFormDefinition,
} from 'common/hooks/forms';
import { selectFeatures } from 'common/store/features/config/selectors';
import { orderExperienceRecords } from 'common/utils/orderRecords';
import './ProfileExperienceDetail.scss';
import { useParams } from 'react-router-dom';
import { selectUserProfile } from '../../../../store/features/search/selectors';

const ExperienceSkeleton = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginTop: '10px',
    }}
  >
    <div style={{ padding: '15px 15px 0px 30px' }}>
      <LoadingSkeleton variant="rect" width={75} height={75} />
    </div>
    <div style={{ padding: '15px', width: '100%' }}>
      <LoadingSkeleton variant="rect" width={220} height={20} />
      <LoadingSkeleton variant="rect" width={180} height={20} style={{ marginTop: '4px' }} />
      <LoadingSkeleton variant="rect" width="100%" height={18} style={{ marginTop: '36px' }} />
      <LoadingSkeleton variant="rect" width="100%" height={18} style={{ marginTop: '10px' }} />
      <LoadingSkeleton variant="rect" width="75%" height={18} style={{ marginTop: '10px' }} />
      <LoadingSkeleton variant="rect" width="100%" height={18} style={{ marginTop: '10px' }} />
      <LoadingSkeleton variant="rect" width="100%" height={18} style={{ marginTop: '36px' }} />
      <LoadingSkeleton variant="rect" width="30%" height={18} style={{ marginTop: '10px' }} />
    </div>
  </div>
);

export const ProfileExperienceDetail = () => {
  const { experience } = useSelector(selectUserProfile);
  const { profilePortfolioLabel } = useSelector(selectFeatures);
  const isBootstrapping = false;
  const hasLoaded = !isBootstrapping;
  const isMobile = useIsMobile();
  const isPopulated = experience.length > 0;
  const [orderedExperience, setOrderedExperience] = useState(orderExperienceRecords(experience));
  const [expandedMap, setExpandedMap] = useState(getInitialExpandedMap(orderedExperience));
  const isPublicProfile = true;

  useEffect(() => {
    setOrderedExperience(orderExperienceRecords(experience));
  }, [experience]);

  useEffect(() => {
    setExpandedMap(getInitialExpandedMap(orderedExperience));
  }, [orderedExperience]);

  function generatePublicCompetencies(competencies) {
    return competencies.map((competency, i) => ({
      id: competency.id,
      value: competency.skillTier3?.label,
    }));
  }

  const renderMobileExpCard = (item, menuItems) => {
    return (
      <div
        className={`iq4-profile-experience-detail__mobile-card ${
          !expandedMap[item.id] ? 'iq4-profile-experience-detail__mobile-card--closed' : ''
        }`}
      >
        <div className="iq4-profile-experience-detail__mobile-card-section iq4-profile-experience-detail__mobile-card-header">
          <div className="iq4-profile-experience-detail__mobile-card-header-col">
            <p className="iq4-profile-experience-detail__mobile-card-p iq4-profile-experience-detail__mobile-card-p--bold">
              {item.title}
            </p>
            <p className="iq4-profile-experience-detail__mobile-card-p iq4-profile-experience-detail__mobile-card-p--bold">
              {item.company}
            </p>
            <p className="iq4-profile-experience-detail__mobile-card-p">{item.employmentType}</p>
            <p className="iq4-profile-experience-detail__mobile-card-p">
              {item.startMonth} {item.startYear} -{' '}
              {item.isCurrentRole ? 'Present' : `${item.endMonth} ${item.endYear}`}
            </p>
          </div>

          <div className="iq4-profile-experience-detail__mobile-card-header-col">
            <button
              className="iq4-profile-experience-detail__mobile-card-expand-btn"
              style={{ transform: `rotate(${expandedMap[item.id] ? 0 : 180}deg)` }}
              onClick={() =>
                void setExpandedMap((state) => ({ ...state, [item.id]: !state[item.id] }))
              }
            >
              <KeyboardArrowUpIcon />
            </button>
            {expandedMap[item.id] && (
              <MenuButton
                className="iq4-profile-experience-detail__options-button"
                items={menuItems}
              />
            )}
          </div>
        </div>
        {expandedMap[item.id] && (
          <>
            {item.description && (
              <div className="iq4-profile-experience-detail__mobile-card-section iq4-profile-experience-detail__mobile-card-section--description">
                <p className="iq4-profile-experience-detail__mobile-card-p iq4-profile-experience-detail__mobile-card-p--bold">
                  Description
                </p>
                <p className="iq4-profile-experience-detail__mobile-card-p">{item.description}</p>
              </div>
            )}

            {!!item.competencies?.length && (
              <div className="iq4-profile-experience-detail__mobile-card-section iq4-profile-experience-detail__mobile-card-section--competencies">
                <p className="iq4-profile-experience-detail__mobile-card-p iq4-profile-experience-detail__mobile-card-p--bold">
                  Competencies
                </p>
                <CompetenciesList
                  competencies={
                    isPublicProfile
                      ? generatePublicCompetencies(item.competencies)
                      : item.competencies
                  }
                />
              </div>
            )}

            <TriggerModal
              className="iq4-profile-experience__manage-portfolio__button"
              title={`${profilePortfolioLabel} List`}
              form={<ProfileAssociatePortfolioItems sourceType="experience" sourceId={item.id} />}
              label="Would you like to associate this project to your Experience or Education"
            />

            {!!item.portfolio?.length && (
              <div className="iq4-profile-experience-detail__mobile-card-section">
                <p className="iq4-profile-experience-detail__section-title">Related Projects</p>

                <ul className="iq4-provide-experience-detail__portfolio-items-list">
                  {item.portfolio.map((portfolioItem, i) => (
                    <li
                      className="iq4-provide-experience-detail__portfolio-items-list-item"
                      key={i}
                    >
                      <SimpleIcon
                        className="iq4-portfolio-item-summary__type-icon"
                        name={portfolioItem.type !== 'other' ? portfolioItem.type : 'link'}
                      />
                      <p className="iq4-portfolio-item-summary__title">{portfolioItem.title}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  function getInitialExpandedMap(items) {
    return items.reduce((map, item, i) => {
      map[item.id] = i === 0 ? true : false;
      return map;
    }, {});
  }

  return (
    <>
      <ActionCard
        title={<div className="iq4-profile-education-detail__card-title">EXPERIENCE</div>}
        icon="experienceCircle"
        backLink={isPublicProfile ? 'profile' : ROUTES.PROFILE_ROOT}
        isHeaderVisible={!isMobile}
        isDivideVisible={!isMobile}
        isPublic={isPublicProfile}
      >
        {!isPopulated && (
          <>
            <img
              src={GraphicExperience}
              className="empty-state-graphic"
              alt="Graphic for Experience"
              aria-hidden="true"
            />
          </>
        )}

        {hasLoaded ? (
          <>
            {isPopulated && (
              <ul className="iq4-profile-experience-detail__list">
                {orderedExperience.map((item, i) => {
                  const menuItems = [];

                  if (isMobile) return renderMobileExpCard(item, menuItems);

                  const itemStart = moment(`${item.startYear}-${item.startMonth}-01`).format(
                    'MMM YYYY',
                  );
                  const itemEnd = item.isCurrentRole
                    ? 'present'
                    : moment(`${item.endYear}-${item.endMonth}-01`).format('MMM YYYY');

                  return (
                    <>
                      {i !== 0 && <hr className="iq4-profile-experience-detail__divide" />}
                      <li className="iq4-profile-experience-detail__list-item" key={item.id}>
                        {!isPublicProfile && (
                          <MenuButton
                            className="iq4-profile-experience-detail__options-button"
                            items={menuItems}
                          />
                        )}
                        <div className="iq4-profile-experience-detail__company-logo-wrapper">
                          <ProfileThumbnailUpload
                            item={item}
                            type="Experience"
                            formDefinition={useProfileExperienceFormDefinition}
                            imageFormDefinition={useProfileExperienceImageFormDefinition}
                          />
                        </div>
                        <div className="iq4-profile-experience-detail__content">
                          <div className="iq4-profile-experience-detail__heading">
                            <CredentialPill credential={item} />
                            <p className="iq4-profile-experience-detail__title">{item.title}</p>
                            <p className="iq4-profile-experience-detail__company">{item.company}</p>
                            <p className="iq4-profile-experience-detail__location">
                              {item.location}
                            </p>
                            <p className="iq4-profile-experience-detail__year">
                              {itemStart} - {itemEnd}
                            </p>
                          </div>
                          {item.description && (
                            <div className="iq4-profile-experience-detail__description">
                              <p className="iq4-profile-experience-detail__section-title">
                                Description
                              </p>
                              <p className="iq4-profile-experience-detail__description-text">
                                <MultilineDisplayer text={item.description} />
                              </p>
                            </div>
                          )}
                          {item.competencies && item.competencies.length > 0 && (
                            <div className="iq4-profile-experience-detail__competencies">
                              <p className="iq4-profile-experience-detail__section-title">
                                Competencies
                              </p>
                              <CompetenciesList
                                competencies={
                                  isPublicProfile
                                    ? generatePublicCompetencies(item.competencies)
                                    : item.competencies
                                }
                              />
                            </div>
                          )}
                          {item.portfolio && item.portfolio.length > 0 && (
                            <>
                              <p className="iq4-profile-experience-detail__section-title">
                                Portfolio Items
                              </p>
                              <ul className="iq4-provide-experience-detail__portfolio-items-list">
                                {item.portfolio.map((portfolioItem, i) => (
                                  <li
                                    className="iq4-provide-experience-detail__portfolio-items-list-item"
                                    key={i}
                                  >
                                    <PortfolioItemSummary item={portfolioItem} />
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                          <div>
                            {!isPublicProfile && (
                              <TriggerModal
                                className="iq4-profile-experience__manage-portfolio__button"
                                title={`${profilePortfolioLabel} List`}
                                form={
                                  <ProfileAssociatePortfolioItems
                                    sourceType="experience"
                                    sourceId={item.id}
                                  />
                                }
                                label="Manage associated portfolio items"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            )}
          </>
        ) : (
          <>
            <ExperienceSkeleton />
            <ExperienceSkeleton />
          </>
        )}
      </ActionCard>
    </>
  );
};
