import { createSelector } from 'reselect';

export const toolsMapSelector = createSelector(
  state => state.skillsModule.tools.all,
  state => state.skillsModule.tools.added,
  (allTools, addedTools) => {
    const addedMap = addedTools.reduce(
      function(map, t) {
        // check for a role or additional tool.
        if (t.job) {
          map.role[
            t.competenceContext.context + '_' + t.competenceContext.competence
          ] = true;
        } else {
          map.additional[
            t.competenceContext.context + '_' + t.competenceContext.competence
          ] = true;
        }

        return map;
      },
      { role: {}, additional: {} }
    );

    // add tools
    const derivesTools = allTools.reduce(function(map, t) {
      if (!map[t.competence]) {
        map[t.competence] = [];
      }

      // add property that check if this tools is checked by the user
      t.added = !!addedMap.role[t.context + '_' + t.competence];

      const tool = {
        ...t,
        added: !!addedMap.role[t.context + '_' + t.competence]
      };
      map[t.competence].push(tool);

      return map;
    }, {});

    const derivesToolsAdditional = allTools.reduce(function(map, t) {
      if (!map[t.competence]) {
        map[t.competence] = [];
      }

      // add property that check if this tools is checked by the user
      const tool = {
        ...t,
        added: !!addedMap.additional[t.context + '_' + t.competence]
      };

      map[t.competence].push(tool);

      return map;
    }, {});

    return { role: derivesTools, additional: derivesToolsAdditional };
  }
);
