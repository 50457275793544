import React, { useState, useMemo } from 'react';

import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { Star, StarEmpty, RightArrow } from '../../Icon/Icon';

export const AssessmentInfoModal = ({ isOpen, toggle, assessments }) => {
  const [showKSAs, toggleShowKSAs] = useState({});
  const derivedAssessments = useMemo(
    () => deriveAssessments(assessments.tier4s),
    [assessments.tier4s],
  );

  // render proficiency stars
  const renderTestedAssessment = (proficiency) => {
    if (proficiency === null || proficiency === undefined)
      return (
        <small
          style={{
            width: '100%',
            paddingTop: '3px',
          }}
        >
          Not assessed
        </small>
      );

    return ['beginner', 'intermediate', 'advanced', 'expert'].map((v, i) => {
      return (
        <span key={v}>{proficiency - i >= 1 ? <Star fill={'#A1A1A1'} /> : <Star outline />}</span>
      );
    });
  }; // end renderTestedAssessment

  function deriveAssessments(tier4s = []) {
    const assessmentsMap = tier4s.reduce(
      (map, t4) => {
        if (t4.includes.comtech) {
          const labIds = t4.labs.comtech || [];

          labIds.forEach((id) => {
            if (!map.comtech[id.labName]) {
              map.comtech[id.labName] = [];
            }

            map.comtech[id.labName].push(t4);
          });
        }

        if (t4.includes.simspace) {
          if (!map.simspace[t4.labs.simspace.simspace]) {
            map.simspace[t4.labs.simspace.simspace] = [];
          }
          map.simspace[t4.labs.simspace.simspace].push(t4);
        }

        return map;
      },
      { comtech: {}, simspace: {} },
    );

    return assessmentsMap;
  } // end deriveAssessments

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`Assessment Details for ${assessments.tier3.value}`}
      onRequestClose={() => void toggle(false)}
      handleClose={() => toggle(false)}
      renderHeader={() => (
        <React.Fragment>
          <h3>Assessment Details</h3>
          <p>{assessments.tier3.value}</p>
        </React.Fragment>
      )}
    >
      {() => (
        <div className="skills-assessments-modal">
          {Object.keys(derivedAssessments).map((testerId) =>
            Object.keys(derivedAssessments[testerId]).map((labId) => {
              const labSkills = derivedAssessments[testerId][labId];

              return (
                <div key={labId}>
                  <p>{labId}</p>
                  <small>{testerId === 'comtech' ? 'ComTech' : 'Simspace'}</small>
                  <div>{renderTestedAssessment(assessments.tier3.assessment[testerId])}</div>

                  <Button
                    type="link"
                    onClick={() =>
                      void toggleShowKSAs((state) => ({
                        ...state,
                        [`${testerId}-${labId}`]: !state[`${testerId}-${labId}`],
                      }))
                    }
                  >
                    {showKSAs[`${testerId}-${labId}`]
                      ? "Hide assessed KSA's"
                      : "Show assessed KSA's"}
                    <RightArrow
                      width="12"
                      height="12"
                      style={{
                        marginLeft: '2px',
                        transform: showKSAs[`${testerId}-${labId}`] ? 'rotate(90deg)' : 'none',
                      }}
                    />
                  </Button>

                  {showKSAs[`${testerId}-${labId}`] && (
                    <ul>
                      {labSkills.map((s) => (
                        <li key={s.id}>{s.value}</li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            }),
          )}
        </div>
      )}
    </Modal>
  );
};

export default AssessmentInfoModal;
