import { useReducer } from 'react';

export const ASSESSMENT_TYPES = {
  SELF: 'SELF',
  TESTED: 'TESTED',
  AVERAGE: 'AVERAGE',
  CERTIFIED: 'CERTIFIED',
  MANAGER: 'MANAGER'
};

export const ACTIONS = {
  TOGGLE: 'TOGGLE',
  CHANGE_ASSESSMENT_TYPE: 'CHANGE_ASSESSMENT_TYPE'
};

const initialState = {
  expandAll: true,
  guidlines: false,
  tools: false,
  assessmentType: ASSESSMENT_TYPES.SELF
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE:
      return { ...state, [action.key]: !state[action.key] };
    case ACTIONS.CHANGE_ASSESSMENT_TYPE:
      return { ...state, assessmentType: action.payload };
    default:
      throw new Error("SkillsCardBody's reducer doesn't recognize this action");
  }
};

const useSkillsCardBodyReducer = () => useReducer(reducer, initialState);

export default useSkillsCardBodyReducer;
