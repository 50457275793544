const context = process.env.REACT_APP_CONTEXT;

export const ROUTES = {
  DASHBOARD: `${context}/dashboard`,
  EXPLORE_TALENT: `${context}/explore`,
  USER_PROFILE: `${context}/profile`,
  STUDENTS: `${context}/students`,
  PATHWAYS: `${context}/pathways`,
  T_SHAPE: `${context}/t`,
  LOGIN: `${context}/home`,
  SEARCH: `${context}/search`,
  SHORTLIST: `${context}/shortlist`,
  INBOX: `${context}/inbox`,
  JOB_REQS: `${context}/job-requisitions`,
  CAREER_PATHWAYS: `${context}/career-pathways`,
};
