import { SET_TOOLS, SET_ADDED_TOOLS } from '../../actionTypes';

export const setTools = payload => ({
  payload,
  type: SET_TOOLS
});

export const setAddedTools = payload => ({
  payload,
  type: SET_ADDED_TOOLS
});
