import React, { useState, useEffect } from 'react';
import { RightArrow, Search as SearchIcon } from '../../Icon/Icon';

/**
 *  Browse through roles available
 * @param {object[]} list - The list of roles to render [{ label: String, id: String|Number: roles: [{ label: String, id: String|Number }, ...]}, ...]
 * @param {function} onClick - handle clicking on a role
 */
export const SkillsBrowseByRole = ({ list, onClick }) => {
  const [searchText, setSearchText] = useState('');
  const [searchFilteredList, setSearchFilteredList] = useState(list);
  const [showRolesList, setShowRolesList] = useState(
    initShowRolesList(searchFilteredList, false)
  );
  const [allExpanded, setAllExpanded] = useState(false);

  useEffect(() => {
    const newFilteredList = getFilteredList(list, searchText);
    setSearchFilteredList(newFilteredList);

    // show the roles list if it's toggled off
    if (searchText) {
      newFilteredList.forEach((item) => {
        toggleShowRolesList(item.id, true);
      });
    }
  }, [list, searchText]);

  useEffect(() => {
    setAllExpanded(getAllExpanded(showRolesList));
  }, [showRolesList]);

  const handleInputChange = (e) => {
    const newVal = e.target.value;
    setSearchText(newVal);
  };

  const toggleShowRolesList = (id, status) => {
    setShowRolesList((state) => ({
      ...state,
      [id]: status ? status : !state[id]
    }));
  };

  const toggleExpandAll = () => {
    const setAllExpanded = !allExpanded;
    const newShowRolesListMap = Object.keys(showRolesList).reduce(
      (map, item) => {
        map[item] = setAllExpanded;
        return map;
      },
      {}
    );

    setShowRolesList(newShowRolesListMap);
  };

  function getFilteredList(list, searchText) {
    const newList = [];
    list.forEach((item) => {
      const rolesThatContainSearchTerm = item.roles.filter((role) => {
        return (
          role.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
      });

      if (rolesThatContainSearchTerm.length) {
        newList.push({ ...item, roles: rolesThatContainSearchTerm });
      }
    });

    return newList;
  }

  function initShowRolesList(list, status) {
    return list.reduce((map, item) => {
      map[item.id] = status;
      return map;
    }, {});
  }

  function getAllExpanded(expandedMap) {
    for (let key in expandedMap) {
      if (!expandedMap[key]) {
        return false;
      }
    }

    return true;
  }

  return (
    <div className="iq4-skills-module-browse-by-roles">
      <p className="iq4-skills-module-browse-by-roles__title">
        Browse to add skills from the below role profiles to your assessment
      </p>

      <label className="iq4-skills-module-browse-by-roles__search-input">
        <SearchIcon />
        <input onChange={handleInputChange} value={searchText} type="text" placeholder="Search for a Role Profile" />
      </label>

      {!!searchFilteredList.length && (
        <div className="iq4-skills-module-browse-by-roles__top-level-list">
          <button
            onClick={toggleExpandAll}
            className="iq4-skills-module-browse-by-roles__top-level-list-expand-btn"
          >
            {allExpanded ? 'Close all' : 'Expand all'}
          </button>
          {searchFilteredList.map((listItem) => {
            return (
              <div
                key={listItem.id}
                className="iq4-skills-module-browse-by-roles__top-level-list-item"
              >
                <button
                  onClick={() => void toggleShowRolesList(listItem.id)}
                  className="iq4-skills-module-browse-by-roles__top-level-list-btn"
                >
                  <RightArrow
                    style={{
                      transform: showRolesList[listItem.id]
                        ? 'rotate(90deg)'
                        : 'none'
                    }}
                  />
                  <span>{listItem.label}</span>
                </button>

                {!!listItem.roles.length && showRolesList[listItem.id] && (
                  <div className="iq4-skills-module-browse-by-roles__inner-level-list">
                    {listItem.roles.map((role) => {
                      return (
                        <button
                          key={role.id}
                          onClick={() =>
                            void onClick({ role, meta: { ...listItem } })
                          }
                          className="iq4-skills-module-browse-by-roles__inner-level-list-item"
                        >
                          {role.label}
                        </button>
                      );
                    })}
                  </div>
                )}

                {!listItem.roles.length && <p>No roles available</p>}
              </div>
            );
          })}
        </div>
      )}

      {!searchFilteredList.length && <p>No roles available to select from.</p>}
    </div>
  );
};
