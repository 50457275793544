import React from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { updateSkillKSAReq } from 'common/store/features/skills/skillsSlice';
import './SkillDetails.scss';

export const SkillDetails = ({ skill, readOnly = false }) => {
  const dispatch = useDispatch();
  const hasKsas = !!(skill.ksas && skill.ksas.length);

  const handleToggleKSA = (ksa) => {
    dispatch(
      updateSkillKSAReq({
        ksa: {
          ...ksa,
          isSelected: !ksa.isSelected,
        },
      }),
    );
  };

  return (
    <div className="iq4-manage-skills__skill-details">
      {!hasKsas && (
        <span className="iq4-manage-skills__skill-details--empty">
          There are no skill details for this skill
        </span>
      )}
      {hasKsas &&
        skill.ksas.map((ksa) => {
          const isSelected = ksa.isSelected;
          return (
            <span className="iq4-manage-skills__skill-details--content">
              <Checkbox
                disabled={readOnly}
                disableRipple
                checked={isSelected}
                onChange={() => handleToggleKSA(ksa)}
                classes={{
                  root: isSelected
                    ? 'iq4-manage-skills__skill-details--checkbox-selected'
                    : 'iq4-manage-skills__skill-details--checkbox-unselected',
                }}
                inputProps={{
                  'aria-label': `Checkbox for KSA - ${ksa.label}`,
                }}
              />
              <span
                className={isSelected ? '' : 'iq4-manage-skills__skill-details--unselected-label'}
              >
                {ksa.label}
              </span>
            </span>
          );
        })}
    </div>
  );
};
