import React from 'react';
import placeholderAvatar from '@assets/svg/placeholder-avatar.svg';
import './UserProfileAvatar.scss';

export const UserProfileAvatar = ({ profile }) => {
  const userAvatar = profile && profile.userProfilePhoto && profile.userProfilePhoto.image;

  return (
    <>
      <div className="iq4-user-profile-avatar">
        <div className="iq4-user-profile-avatar__image-wrapper">
          <img
            className="iq4-user-profile-avatar__image"
            src={userAvatar ? userAvatar : placeholderAvatar}
            alt="User Avatar"
          />
        </div>
      </div>
    </>
  );
};
