import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Button, SimpleIcon } from 'common/components';
import { useHover } from 'common/hooks/useHover';
import { useIsMobile } from 'common/hooks/useIsMobile';
import './PathwaysGraph.scss';
import { useSelector } from 'react-redux';
import { selectIsPublicProfile } from '../../store/features/profile/selectors';

export const PathwayDonut = ({ spoke, positionLabel, onDelete, onSelect, readOnly = false }) => {
  const [hoverRef, isHovered] = useHover();
  const [showPopover, setShowPopover] = useState(false);
  const isMobile = useIsMobile((b) => b === 'xs' || b === 'sm');
  const isPublicProfile = useSelector(selectIsPublicProfile);

  const { label } = spoke;

  const data = [
    {
      id: 'roleSkills',
      value: spoke.skills?.roleSkills?.length,
    },
    {
      id: 'overlapSkills',
      value: spoke.skills?.overlapSkills?.length,
    },
  ];

  useEffect(() => {
    const handler = (e) => {
      const isOutside = !(hoverRef.current && hoverRef.current.contains(e.target));
      if (isOutside && showPopover) {
        setShowPopover(false);
      }
    };

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [hoverRef, showPopover]);

  const DonutPopover = () => (
    <div className="iq4-pathway__donut-tooltip">
      <div className="iq4-pathway__donut-tooltip--legend">
        <div className="iq4-pathway__donut-tooltip--left">
          <span className="iq4-pathway__donut-tooltip--square-dark" />
          <div className="iq4-pathway__donut-tooltip--left-label">Matched</div>
        </div>
        <div>{spoke.skills?.overlapSkills?.length}</div>
      </div>
      <div className="iq4-pathway__donut-tooltip--legend">
        <div className="iq4-pathway__donut-tooltip--left">
          <span className="iq4-pathway__donut-tooltip--square-purple" />
          <div className="iq4-pathway__donut-tooltip--left-label">Missing</div>
        </div>
        <div>{spoke.skills?.roleSkills?.length}</div>
      </div>
      <div className="iq4-pathway__donut-tooltip--bottom-container">
        <Button onClick={() => onSelect(spoke)}>View role details</Button>
      </div>
      {!readOnly && !isPublicProfile && (
        <div
          className="iq4-pathway__donut-tooltip--bottom-container"
          role="button"
          tabIndex={0}
          onClick={onDelete}
          onKeyPress={onDelete}
        >
          <SimpleIcon name="bin" />
          <span className="iq4-pathway__donut-tooltip--delete">Delete this role from pathway</span>
        </div>
      )}
    </div>
  );

  const onSelectDonut = () => (isMobile ? onSelect(spoke) : setShowPopover(true));

  return (
    <>
      <button
        ref={hoverRef}
        aria-label={`Click to see details for ${label} role`}
        className="iq4-pathway__circle-out-container--icon-btn"
        onClick={onSelectDonut}
      >
        <SimpleIcon
          name="experienceCirclePathways"
          className={`iq4-pathway__circle-out-container--icon ${
            isHovered ? 'iq4-pathway__circle-out-container--hovered' : ''
          }`}
        />
      </button>

      <div
        className="iq4-pathway__circle-container"
        ref={(ref) => {
          if (ref) {
            const svgEl = ref.querySelector('svg');
            if (svgEl) {
              svgEl.setAttribute('role', 'presentation');
              svgEl.setAttribute('aria-hidden', true);
            }
          }
        }}
      >
        <ResponsivePie
          data={data}
          innerRadius={0.77}
          enableRadialLabels={false}
          enableSlicesLabels={false}
          slicesLabelsSkipAngle={0}
          animate={true}
          isInteractive={false}
          role="document"
        />
        {!!showPopover && <DonutPopover />}
      </div>
      <div
        onClick={() => onSelect(spoke)}
        className={`iq4-pathway__circle-container__label iq4-pathway__circle-container--label-${positionLabel}`}
      >
        {label}
      </div>
    </>
  );
};
