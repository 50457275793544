import React from 'react';
import { RoleCourseInnerCard } from '../RoleCourseInnerCard';
import { PathwaysSkillList } from '../PathwaysSkillList';
import './PathwayTopSkillsCourse.scss';

/**
 * Component that shows the top course with the skills it covers
 * @param {object} achievement - The achievement details
 * @param {object[]} skillsCovered - The skills covered in the course
 * @param {string} [className] - Custom className
 * @param {function} onClick - Handle clicking on course
 */
export const PathwayTopSkillsCourse = ({ achievement, skillsCovered, className, onClickSkill }) => (
  <div className={`iq4-pathway-top-skills-course ${className ? className : ''}`}>
    <RoleCourseInnerCard
      achievementName={achievement?.name}
      achievementId={achievement?.achievementId}
      school={achievement?.school?.name}
      className="iq4-pathway-top-skills-course__course-card"
    />

    {/* <PathwaysSkillList
      className="iq4-role-skills-info-panel__skill-list"
      title={`This course will get you the following ${skillsCovered.length} missing skills`}
      skills={skillsCovered}
      onClick={(skill) => onClickSkill(skill)}
    /> */}
  </div>
);
