import { createSelector } from '@reduxjs/toolkit';
import placeholderAvatar from '@assets/svg/placeholder-avatar.svg';

// Returns entire profile object
export const selectProfile = ({ profile, ...rest }) => {
  // Add associated portfolio items to education and experience records
  const addPortfolioItems = (records, type) => {
    return records.map((record) => {
      return {
        ...record,
        portfolio: profile.portfolio
          ? profile.portfolio.filter((portfolioItem) => {
              return (
                portfolioItem.associatedRecord &&
                portfolioItem.associatedRecord.id === record.id &&
                portfolioItem.associatedRecord.type === type
              );
            })
          : [],
      };
    });
  };

  const result = {
    personal: profile.personal ? profile.personal : {},
    education: profile.isPublicProfile
      ? profile.publicData.personal?.education
        ? addPortfolioItems(profile.publicData.personal.education, 'education')
        : []
      : profile.education
      ? addPortfolioItems(profile.education, 'education')
      : [],
    experience: profile.experience ? addPortfolioItems(profile.experience, 'experience') : [],
    portfolio: profile.portfolio ? addPortfolioSources(profile) : [],
  };

  return result;
};

export const selectUserDetails = createSelector(
  (state) => state.profile.personal,
  (personalInfo) => {
    if (!personalInfo.userProfile)
      return {
        firstName: '',
        lastName: '',
        email: '',
        currentPosition: '',
        description: '',
      };

    return {
      id: personalInfo.userProfile.id,
      firstName: personalInfo.userProfile.user.firstName,
      lastName: personalInfo.userProfile.user.lastName,
      email: personalInfo.userProfile.user.email,
      currentPosition: personalInfo.userProfile.currentPosition,
      description: personalInfo.userProfile.description,
    };
  },
);

export const selectUserProfile = ({ profile }) => profile.personal.userProfile;

// Add portfolio sources (education / experience records) to portfolio items
const addPortfolioSources = (profile) => {
  const portfolioItems = profile.portfolio;
  return portfolioItems.map((portfolioItem) => {
    if (!portfolioItem.associatedRecord) return portfolioItem;
    return {
      ...portfolioItem,
      associatedRecord: {
        ...profile[portfolioItem.associatedRecord.type].find(
          (item) => item.id === portfolioItem.associatedRecord.id,
        ),
        type: portfolioItem.associatedRecord.type,
      },
    };
  });
};

// Returns a combined array of education + experience records i.e. portfolio sources
export const selectPortfolioSources = ({ profile }) => {
  return [
    ...profile.education.map((item) => {
      return { key: { type: 'education', id: item.id }, value: item.title || item.major };
    }),
    ...profile.experience.map((item) => {
      return { key: { type: 'experience', id: item.id }, value: item.title };
    }),
  ];
};

// Returns portfolio items 'available' to a particular education / experience record
// 1) Filter: Includes items that have no associatedRecord + items which have associatedRecord of current record
// 2) Map: Returns required fields (id, title, type) + adds associated boolean (is associated with current)
export const selectAvailablePortfolioItems = (state, type, id) => {
  return state.profile.portfolio
    .filter(
      (item) =>
        !item.associatedRecord ||
        (item.associatedRecord.type === type && item.associatedRecord.id === id),
    )
    .map((item) => {
      return {
        id: item.id,
        title: item.title,
        type: item.type,
        resources: item.resources,
        associated: item.associatedRecord
          ? item.associatedRecord.type === type && item.associatedRecord.id === id
          : false,
      };
    });
};

export const selectFeaturedPortfolioItems = ({ profile }) => {
  return addPortfolioSources(profile).filter((item) => item.featured);
};

export const selectAvatar = createSelector(
  (state) =>
    state.profile.isPublicProfile
      ? state.profile.publicData.personal?.personal?.userProfilePhoto
      : state.profile.personal.userProfilePhoto,
  (userProfilePhoto) => {
    // still loading
    if (!userProfilePhoto) return null;

    return userProfilePhoto.image ? userProfilePhoto.image : placeholderAvatar;
  },
);

export const selectProfileWorkRoles = (state) => state.profile.profileWorkRoles;

export const selectSelectedJobFamily = (state) => {
  const jobFam = state.framework.jobFamilies.find(
    (x) => x.id === state.profile.selectedTShapeRole?.jobFamilyId,
  );
  return jobFam;
};

export const selectSelectedTShapeRole = (state) => state.profile.selectedTShapeRole;

export const selectHasAcceptedTerms = (state) =>
  !!state.profile.personal?.userProfile?.hasAcceptedTerms;

export const selectHasFetchedProfile = (state) => !!state.profile.hasFetchedProfile;

export const selectIsAcceptingFCRA = (state) => !!state.profile.isAcceptingFCRA;
export const selectHasAcceptedFCRA = (state) => !!state.profile.hasAcceptedFCRA;
export const selectIsSharingProfile = (state) => !!state.profile.isSharingProfile;
export const selectShareProfileSettings = (state) => state.profile.shareProfileSettings;
export const selectIsPublicProfile = (state) => !!state.profile.isPublicProfile;

export const selectPublicPersonalProfile = ({ profile }) => profile.publicData.personal;
export const selectPublicSkills = ({ profile }) => profile.publicData.skills;
