import _differenceBy from 'lodash/differenceBy';
import _uniqBy from 'lodash/uniqBy';

export const selectAllRoleProfiles = (state) => state.search.roleProfiles || [];

export const selectAllManagers = (state) => state.search.managers || [];

export const selectAllPathways = (state) => state.search.pathways || [];

export const selectAllLocations = (state) => state.search.locations || [];

export const selectAllCredentials = (state) => state.search.credentials || [];

export const selectAllSchools = (state) => state.search.schools || [];

export const selectSearchUsers = (state) =>
  state.search.filteredUsers
    ? state.search.filteredUsers.map((user) => {
        return {
          ...user,
          topRoleProfile: state.search.roleProfiles.find((role) => role.id === user.roles.ids[0])
            .label,
        };
      })
    : [];

export const selectRoleProfileDetailsWithPreview = (state, id, isPreviewEnabled) => {
  if (!id) return;

  const roleProfile = state.search.roleProfiles.find((roleProfile) => roleProfile.id === id);

  if (!roleProfile.skills) return roleProfile;

  return {
    ...roleProfile,
    skills: {
      matched: roleProfile.skills.matched,
      missing: isPreviewEnabled
        ? _differenceBy(roleProfile.skills.missing, roleProfile.skills.additional, 'competencyId')
        : roleProfile.skills.missing,
      additional: roleProfile.skills.additional,
    },
  };
};

export const selectUser = (state) => state.search.userProfile;

export const selectUserPersonal = (state) =>
  state.search.userProfile && state.search.userProfile.profile
    ? state.search.userProfile.profile
    : null;

export const selectUserCredentials = (state) =>
  state.search.userProfile && state.search.userProfile.credentials
    ? state.search.userProfile.credentials
    : [];

export const selectUserPathways = (state) =>
  state.search.userProfile && state.search.userProfile.pathways
    ? state.search.userProfile.pathways
    : [];

export const selectUserSkills = (state) =>
  state.search.userProfile && state.search.userProfile.skills
    ? state.search.userProfile.skills.map((skill) => ({
        type: 'matched',
        label: skill.tier3Label,
        specialtyArea: skill.tier2Label,
      }))
    : [];

export const selectUserSkillCountsByCategory = (state) => [
  {
    categoryId: 'technology',
    categoryLabel: 'Technology',
    skillCount: state.search.userProfile
      ? state.search.userProfile.skills.filter((skill) =>
          ['CG01'].includes(skill.competenceGroupId),
        ).length
      : 0,
  },
  {
    categoryId: 'professional',
    categoryLabel: 'Professional',
    skillCount: state.search.userProfile
      ? state.search.userProfile.skills.filter((skill) =>
          ['CG03', 'CG04'].includes(skill.competenceGroupId),
        ).length
      : 0,
  },
  {
    categoryId: 'businessDevelopment',
    categoryLabel: 'Business Development',
    skillCount: state.search.userProfile
      ? state.search.userProfile.skills.filter((skill) =>
          ['CG02'].includes(skill.competenceGroupId),
        ).length
      : 0,
  },
];

export const selectUserTopTenSkills = (state) => {
  if (!state.search.userProfile || !state.search.userProfile.skills) return [];

  return state.search.userProfile.skills
    .filter((skill) => !!skill.rank)
    .sort((a, b) => a.rank - b.rank)
    .map((skill) => ({
      id: skill.id,
      value: skill.tier3Label,
      tier1: skill.tier1Label,
      tier2: skill.tier2Label,
      proficiency: skill.proficiency,
    }));
};

export const selectUserProfileRoleProfiles = (state) => {
  const combinedRoles = [
    ...state.search.searchedRoleProfiles,
    ...(state.search.searchedRoleProfiles.length > 0
      ? [
          {
            id: -1,
            label: '------------------------------------------------------------------',
            disabled: true,
          },
        ]
      : []),
    ...state.search.roleProfiles,
  ];

  return _uniqBy(combinedRoles, 'id');
};

export const selectPerformedInitialSearch = (state) => state.search.performedInitialSearch;

export const selectSelectedRoleProfiles = (state) => state.search.selectedRoleProfiles;

export const selectSelectedDropdownFilters = (state) => state.search.selectedDropdownFilters;

export const selectIsFetchingRoleProfile = (state) => state.search.isFetchingRoleProfile;

export const selectInvites = (state) => state.search.invites;

export const selectIsSendingMessage = (state) => state.search.isSendingMessage;

export const selectWalletSharingSettings = (state) => state.search.walletSharingSettings;

export const selectUserConnectMessage = (state) =>
  state.search.invites?.find((invite) => invite.studentId === state.search.userProfile?.id);

export const selectUserProfile = (state) => state.search.publicProfile.personal;

// select portfolio items from personal, each portfolio item has an associatedRecord, with id and type,
// also fetch the associated record from the store (wither experience or education)
export const selectPortfolioItems = (state) => {
  const portfolioItems = state.search.publicProfile.personal?.portfolio;

  const result = (portfolioItems || []).map((portfolioItem) => {
    const associatedRecord = portfolioItem.associatedRecord;
    const type = associatedRecord?.type;
    const id = associatedRecord?.id;
    const enhancedAssociatedRecord = state.search.publicProfile.personal[type]?.find(
      (record) => record.id === id,
    );

    return {
      ...portfolioItem,
      associatedRecord: enhancedAssociatedRecord,
    };
  });

  return result;
};

export const selectPublicSkills = (state) => state.search.publicProfile.skills;

export const selectPublicCredentials = (state) => state.search.publicProfile.credentials;

export const selectPublicPathways = (state) => state.search.publicProfile.pathways;

export const selectPublicSkillsBreakUp = (state) => {
  const skills = state.search.publicProfile.skills;

  function competenceGroupIdToSection(competenceGroupId) {
    switch (competenceGroupId) {
      case 'CG01':
        return 'it skills';
      case 'CG02':
        return 'soft skills';
      case 'CG03':
        return 'professional skills';
      case 'CG04':
        return 'professional skills';
      default:
        return '';
    }
  }

  const result = skills.reduce(
    (map, skill) => {
      map[competenceGroupIdToSection(skill.competenceGroupId)]++;
      return map;
    },
    { 'it skills': 0, 'soft skills': 0, 'professional skills': 0 },
  );

  return result;
};

export const selectSelectedPublicView = (state) => state.search.selectedPublicView;
