import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/routeNames';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';

export const MainNavOptions = () => (
  <>
    <NavLink to={ROUTES.JOB_REQS}>
      <Logo className="iq4-main-navbar__logo" role="presentation" aria-hidden="true" />
    </NavLink>
    <div className="iq4-main-navbar__links-container">
      {/* <NavLink
        to={ROUTES.DASHBOARD}
        className="iq4-main-navbar__link"
        activeClassName="iq4-main-navbar__link--active"
      >
        Dashboard
      </NavLink> */}
      <NavLink
        to={ROUTES.JOB_REQS}
        className="iq4-main-navbar__link"
        activeClassName="iq4-main-navbar__link--active"
      >
        Job Requisitions
      </NavLink>
      <NavLink
        to={ROUTES.SEARCH}
        className="iq4-main-navbar__link"
        activeClassName="iq4-main-navbar__link--active"
        isActive={(match, location) => {
          const locationSplit = location.pathname.split('/');
          const profileRouteSplit = ROUTES.USER_PROFILE.split('/');
          if (match || locationSplit[2] === profileRouteSplit[profileRouteSplit.length - 1])
            return true;
        }}
      >
        Search
      </NavLink>
    </div>
  </>
);
