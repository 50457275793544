import React, { useMemo, useEffect } from 'react';
import { NavLink as Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';

import { MainNavOptions } from 'routes/MainNavOptions';
import { MainNavbar, Button, DashboardTable, SimpleIcon, JobReqsTable } from 'common/components';

import { selectJobReqPositions } from 'store/features/jobReqs/selectors';
import { fetchJobReqs } from 'store/features/jobReqs/jobReqsSlice';
import { ROUTES } from 'routes/routeNames';

import './JobReqsPage.scss';

const VALID_SEARCH_PARAMS_MAP = {
  FULLTIME: 'fulltime',
  INTERNSHIP: 'internship',
  CONTRACTOR: 'contractor',
  MISSING: 'missing',
};

const VALID_SEARCH_PARAM_ARRAY = [
  VALID_SEARCH_PARAMS_MAP.FULLTIME,
  VALID_SEARCH_PARAMS_MAP.INTERNSHIP,
  VALID_SEARCH_PARAMS_MAP.CONTRACTOR,
  // VALID_SEARCH_PARAMS_MAP.MISSING,
];

const POSITIONS_HEADER_STATIC_METADATA = {
  [VALID_SEARCH_PARAMS_MAP.FULLTIME]: {
    title: 'Full time open positions',
    searchParam: VALID_SEARCH_PARAMS_MAP.FULLTIME,
  },

  [VALID_SEARCH_PARAMS_MAP.INTERNSHIP]: {
    title: 'Internship open positions',
    searchParam: VALID_SEARCH_PARAMS_MAP.CONTRACTOR,
  },

  [VALID_SEARCH_PARAMS_MAP.CONTRACTOR]: {
    title: 'Contractor open positions',
    searchParam: VALID_SEARCH_PARAMS_MAP.INTERNSHIP,
  },

  [VALID_SEARCH_PARAMS_MAP.MISSING]: {
    title: 'Missing Job Reqs',
    searchParam: VALID_SEARCH_PARAMS_MAP.MISSING,
  },
};

export const JobReqsPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const reqTypeSearchParam = useMemo(() => getReqTypeSearchParam(search), [search]);

  const jobReqPositions = useSelector(selectJobReqPositions);

  useEffect(() => {
    dispatch(fetchJobReqs());
  }, []);

  function getReqTypeSearchParam(search) {
    let reqTypeParam = new URLSearchParams(search).get('reqType');

    // by default if no reqType param is available, return the fist value from valid params array
    if (!reqTypeParam) return VALID_SEARCH_PARAMS_MAP.FULLTIME;

    reqTypeParam = reqTypeParam.toLowerCase();

    // not part of valid params, return null
    if (VALID_SEARCH_PARAM_ARRAY.indexOf(reqTypeParam) == -1) return null;

    return reqTypeParam;
  }

  const renderLoadingCard = (noMargin) => (
    <Skeleton variant="rect" width="33%" height={200} style={{ marginRight: noMargin ? 0 : 20 }} />
  );

  const renderLoadingCards = () => {
    return [renderLoadingCard(), renderLoadingCard(), renderLoadingCard(true)];
  };

  const arePositionsReady = !!jobReqPositions['fulltime'];

  return (
    <div className="iq4-job-reqs-page">
      <MainNavbar className="iq4-job-reqs-page__navbar" MainNavOptions={MainNavOptions} />

      <div className="iq4-job-reqs-page__container">
        <div className="iq4-job-reqs-page__positions-header">
          {!arePositionsReady && renderLoadingCards()}
          {arePositionsReady &&
            VALID_SEARCH_PARAM_ARRAY.map((positionSearchParam) => {
              return (
                <Link
                  key={positionSearchParam}
                  to={`${ROUTES.JOB_REQS}?reqType=${positionSearchParam}`}
                  className="iq4-job-reqs-page__positions-header-link"
                  activeClassName="iq4-job-reqs-page__positions-header-link--active"
                  isActive={() => reqTypeSearchParam === positionSearchParam}
                >
                  <div className="iq4-job-reqs-page__positions-header-link-title">
                    {POSITIONS_HEADER_STATIC_METADATA[positionSearchParam].title}
                  </div>
                  <div className="iq4-job-reqs-page__positions-header-link-count">
                    {jobReqPositions[positionSearchParam].length}
                  </div>
                  {reqTypeSearchParam !== positionSearchParam && (
                    <div className="iq4-job-reqs-page__positions-header-link-footertext">
                      View now
                    </div>
                  )}
                </Link>
              );
            })}
        </div>

        <div className="iq4-job-reqs-page__add-new-job-req-container">
          <div>
            <div className="iq4-job-reqs-page__add-new-job-req-container-title">
              {POSITIONS_HEADER_STATIC_METADATA[reqTypeSearchParam].title}
            </div>
            <div className="iq4-job-reqs-page__add-new-job-req-container-desc">
              These are the positions actively open and available to fill. You can add any
              shortlisted Candidates for each full time role.
            </div>
          </div>

          <Button
            className="iq4-job-reqs-page__job-req-btn"
            variation="primary"
            onClick={() => void history.push(`${ROUTES.JOB_REQS}/new`)}
          >
            Add a Job Req
          </Button>
        </div>

        <div className="iq4-job-reqs-page__table-container">
          {!jobReqPositions[reqTypeSearchParam] && (
            <Skeleton variant="rect" width="100%" height={400} />
          )}

          {jobReqPositions[reqTypeSearchParam] && (
            <JobReqsTable
              title={POSITIONS_HEADER_STATIC_METADATA[reqTypeSearchParam].title}
              data={jobReqPositions[reqTypeSearchParam] || []}
            />
          )}
        </div>
      </div>
    </div>
  );
};
