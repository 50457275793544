import { toggleSkillsLoader, toggleDefaultLoader, toggleSkillsUpdater } from './loaders/actions';
import { setCertsList, setMyCerts } from './certs/actions';
import {
  setJPMCSkillsSummary,
  setJPMCSkillsData,
  updateSkillProfT4,
  updateJPMCSkillsProf,
  updateSearchResults,
  updateBrowseCategories,
  clearBrowseResults,
  updateBrowseKsas,
  removeBrowseResult,
  removeAdditionalSkills,
  updateSavedAdditionalSkills,
  setJPMCTier1s,
  setSkillsByRoleList,
} from './skills/actions';
import { setTools, setAddedTools } from './tools/actions';
import { setRecommendedCourses } from './courses/actions';

import { resetBroadcaster } from './broadcaster/actions';

export default {
  skills: {
    setJPMCSkillsData,
    setJPMCSkillsSummary,
    updateSkillProfT4,
    updateJPMCSkillsProf,
    updateSearchResults,
    updateBrowseCategories,
    updateBrowseKsas,
    updateSavedAdditionalSkills,
    clearBrowseResults,
    removeBrowseResult,
    removeAdditionalSkills,
    setJPMCTier1s,
    setSkillsByRoleList,
  },

  loaders: {
    toggleSkillsLoader,
    toggleDefaultLoader,
    toggleSkillsUpdater,
  },

  certs: {
    setCertsList,
    setMyCerts,
  },

  tools: {
    setTools,
    setAddedTools,
  },

  courses: {
    setRecommendedCourses,
  },

  broadcasters: {
    resetBroadcaster,
  },
};
