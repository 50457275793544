import React, { useState } from 'react';
import { Modal } from 'common/components/Modal';
import { Button, ActionIcon, SimpleIcon } from 'common/components';
import { useSelector } from 'react-redux';
import { FormWrapper } from 'common/components/Form';
import { OnboardingHeader } from 'common/components/Onboarding';
import { useProfilePersonalFormDefinition } from 'common/hooks/forms';
import { ensureHTTPSProtocol } from 'common/utils';
import { ProfileAvatar } from './ProfileAvatar';
import { selectUserProfile } from 'store/features/search/selectors';
import './ProfilePersonal.scss';

export const ProfilePersonal = () => {
  const { personal } = useSelector(selectUserProfile);
  const userProfile = personal?.userProfile;

  const userProfilePhotoData = personal?.userProfilePhoto || {
    id: null,
    image: null,
    imageName: null,
    thumbnail: null,
    type: null,
  };

  const goToLinkedIn = () => {
    window.open(ensureHTTPSProtocol(userProfile?.linkedIn), '_blank');
  };

  return (
    <>
      <div className="iq4-personal-card">
        <div className="iq4-personal-card__heading">
          <div className="iq4-personal-card__heading-link">
            <SimpleIcon
              className="iq4-personal-card__heading-icon"
              name="personCircle"
              role="presentation"
              hidden
            />
            <h2 className="iq4-personal-card__heading-title">Personal</h2>
          </div>
        </div>

        <ProfileAvatar />

        <div className="iq4-personal-card__body">
          <>
            <p className="iq4-personal-card__item iq4-personal-card__item--name">
              {userProfile?.user?.firstName} {userProfile?.user?.lastName}
            </p>
            {userProfile?.currentPosition && (
              <p className="iq4-personal-card__item">{userProfile?.currentPosition}</p>
            )}
            {userProfile?.description && (
              <p className="iq4-personal-card__item">{userProfile?.description}</p>
            )}
            {userProfile?.linkedIn && (
              <p className="iq4-personal-card__item">
                <Button
                  variation="ghost"
                  className="iq4-personal-card__linkedin"
                  onClick={() => goToLinkedIn()}
                >
                  <SimpleIcon name="linkedIn" />
                  View LinkedIn Page
                </Button>
              </p>
            )}
          </>
        </div>
      </div>
    </>
  );
};
