import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { default as MUISelect } from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './Select.scss';
import { useFormContext, Controller } from 'react-hook-form';
import { ValidationMessage } from '../../../ValidationMessage';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';

/**
 * Inner Select that gets enhanced by RHF's HOC
 * @param id
 * @param name
 * @param label
 * @param placeholder
 * @param onChange
 * @param errors
 * @param formState
 * @param maxWidth
 * @param options
 * @returns {*}
 * @constructor
 */
export const InnerSelect = ({
  id,
  name,
  label,
  placeholder,
  onChange,
  errors,
  formState,
  maxWidth,
  options,
  value,
  exposeFormContext,
  triggerExternalValidation,
}) => {
  const [selectedValue, setSelectedValue] = useState(-1);
  const touched = formState.touched[name];
  const dirty = formState.dirty;
  const hasError = errors[name];
  const isSubmitted = formState.isSubmitted;
  const showError = () => {
    if (exposeFormContext && !dirty) return false;
    return (touched || isSubmitted || triggerExternalValidation) && hasError;
  };

  const handleChange = ({ target: { value } }) => {
    setSelectedValue(options[value]);
    onChange(options[value].key);
  };

  useEffect(() => {
    // specifying undefined because we might want to set to 0
    if (value !== undefined) {
      const index = options.findIndex((option) => isEqual(option.key, value));
      setSelectedValue(index);
    } else {
      setSelectedValue(-1);
    }
  }, [value, options]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 40 * 4.5 + 8,
      },
    },
  };

  return (
    <div className="iq4-select__wrapper">
      {label && (
        <label className="iq4-select__label" htmlFor={name}>
          {label}
        </label>
      )}
      <FormControl
        variant="outlined"
        className={`iq4-select__form-control ${
          maxWidth ? 'iq4-select__form-control-max-width' : ''
        } ${showError() ? 'iq4-select__form-control--error' : ''}`}
      >
        <MUISelect
          value={selectedValue}
          onChange={handleChange}
          IconComponent={ExpandMoreIcon}
          inputProps={{ id, name }}
          MenuProps={MenuProps}
          classes={{
            outlined: 'iq4-select__input',
          }}
        >
          <MenuItem value={-1} disabled classes={{ root: 'iq4-select__placeholder' }}>
            {placeholder ? placeholder : ''}
          </MenuItem>
          {options &&
            options.map((option, index) => (
              <MenuItem
                disableRipple
                key={index}
                value={index}
                classes={{ root: 'iq4-select__option', selected: 'iq4-select__option--selected' }}
              >
                {option.icon && (
                  <SimpleIcon className="iq4-select__option-icon" name={options.icon} />
                )}
                {option.value}
              </MenuItem>
            ))}
        </MUISelect>
      </FormControl>
      {hasError &&
        showError() &&
        (hasError.type === 'validate' ? (
          <ValidationMessage id={`${name}Error`} message={hasError.message} />
        ) : (
          <ValidationMessage
            id={`${name}Error`}
            message={<FormattedMessage id="form.validation.required" />}
          />
        ))}
    </div>
  );
};

/**
 * Select wrapper
 * This needs to use the Controller component from react-hook-form
 * see: https://react-hook-form.com/api#Controller
 * @param name
 * @param props
 * @returns {*}
 * @constructor
 */
export const Select = ({ name, validators, onChange, ...props }) => {
  const { control, getValues } = useFormContext();
  const values = getValues();

  return (
    <Controller
      as={<InnerSelect {...props} />}
      control={control}
      rules={{ ...validators }}
      onChange={([selected]) => {
        onChange && onChange(selected);
        return selected;
      }}
      name={name}
      value={values[name]}
    />
  );
};
