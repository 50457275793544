import React from 'react';
import moment from 'moment';
import { Select } from 'common/components/Form/fieldTypes';
import { MONTHS } from 'common/constants/dates';
import { validations } from 'common/components/Form/validations';
import { getYearRange } from 'common/utils';
import './DateRange.scss';

const requiredValidator = { required: validations.isRequired() };
const yearValues = getYearRange(1940, 2020);

export const DateRange = ({ isPresent, key, getValues, ...formProps }) => {
  const fromToValidator = {
    validate: () => {
      const { startMonth, startYear, endMonth, endYear } = getValues();
      const startDate = moment([+startYear, +startMonth]);
      const endDate = moment([+endYear, endMonth]);
      const isValid = startDate.isBefore(endDate);
      if (!isValid) {
        return 'Needs to be after "start" date';
      }
      return isValid;
    },
  };

  return (
    <span className="iq4-form__date-range" key={key}>
      <span className="iq4-form__date-range--item">
        <span>Start date:</span>
        <span className="iq4-form__date-range--item-selects">
          <Select
            name="startMonth"
            validators={{ ...requiredValidator }}
            placeholder="Month"
            options={MONTHS}
            key={`${key}-startMonth`}
            {...formProps}
          />
          <Select
            name="startYear"
            validators={{ ...requiredValidator }}
            placeholder="Year"
            options={yearValues}
            key={`${key}-startYear`}
            {...formProps}
          />
        </span>
      </span>
      <span className="iq4-form__date-range-separator">to</span>
      {!isPresent && (
        <span className="iq4-form__date-range--item">
          <span>End date:</span>
          <span className="iq4-form__date-range--item-selects">
            <Select
              name="endMonth"
              validators={{ ...requiredValidator, ...fromToValidator }}
              placeholder="Month"
              options={MONTHS}
              key={`${key}-endMonth`}
              {...formProps}
            />
            <Select
              name="endYear"
              validators={{ ...requiredValidator, ...fromToValidator }}
              placeholder="Year"
              options={yearValues}
              key={`${key}-endYear`}
              {...formProps}
            />
          </span>
        </span>
      )}
      {isPresent && <span className="iq4-form__date-range-present">Present</span>}
    </span>
  );
};
