export const INTERSTITIAL_COPY = {
  BADGR_INTRO_TEXT: 'Select "Continue" to leave this site and go to Badgr.',
  BADGR_MAIN_TEXT:
    'Badgr is unaffiliated with your institution or the National Student Clearinghouse and has its own separate Terms of Use and Privacy Policy. Any information you provide to Badgr is subject to those terms.',
  JOB_BOARD_INTRO_TEXT: 'Select "Continue" to leave this site and go to an external job board.',
  JOB_BOARD_MAIN_TEXT:
    'The external job board is unaffiliated with your institution or the National Student Clearinghouse and has its own separate Terms of Use and Privacy Policy. Any information you provide to the external job board is subject to those terms.',
  WIKIPEDIA_INTRO_TEXT: 'Select "Continue" to leave this site and go to Wikipedia.',
  WIKIPEDIA_MAIN_TEXT:
    'Wikipedia is unaffiliated with your institution or the National Student Clearinghouse and has its own separate Terms of Use and Privacy Policy. Any information you provide to Wikipedia is subject to those terms.',
};
