import { useState, useEffect } from 'react';

export default function useCloseOnClick(nodeRef, stateChangeCallback) {
  const [active, toggle] = useState(false);

  useEffect(() => {
    // handler for clicking body.
    const handleBodyClick = e => {
      if (!nodeRef.current.contains(e.target) && active) {
        toggle(false);
      }
    };

    // setup body click handler
    document.body.addEventListener('click', handleBodyClick);

    // if there's a state change callback, call it.
    if (
      stateChangeCallback &&
      {}.toString.call(stateChangeCallback) === '[object Function]'
    ) {
      stateChangeCallback(active);
    }

    return () => {
      // remove body click handler
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [active]);

  return [active, toggle];
}
